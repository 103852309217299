import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip from MUI
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Switch from '@mui/material/Switch';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const HeadlinesAppbar = ({handleArchive}) => {
    
  const handleSearchChange = (event) => {
    // Handle search field input changes here
    // event.target.value contains the search input value
    console.log('Search input:', event.target.value);
  };

const handlePrint = () => {
    window.print();
  };


  return (
   <>
   <AppBar position="static" sx={{ bgcolor: '#2596be' }}>
        <Toolbar>
        <div className="row w-100">
         <div className="col-12 col-lg-4 col-md-12 col-sm-12 d-flex">
          <Typography variant="p" component="div" sx={{ flexGrow: 1, fontSize: '16px', display: 'flex' }}>
            <span className="mt-2" style={{ color: '#ffffff91', marginRight: '8px' }}>FILTERS</span> {/* Add the "Filter" text here */}
            <label className="mt-2" htmlFor="owner-select">Team:</label>
            <Select
              variant="standard"
              id="owner-select"
              label="Teams"
              sx={{
                icon: {
                  fill: 'white', // Change the color to white
                },
                marginRight: '16px',
                marginLeft: '10px',
                width: '150px',
                '&:before': {
                  borderColor: 'white',
                },
                '&:after': {
                  borderColor: 'white',
                },
                color: 'white',
              }}
              defaultValue={1}
              inputProps={{ style: { border: 'none' } }} // Add border style here
            >
              <MenuItem value={1}>All</MenuItem>
              <MenuItem value={2}>Team 1</MenuItem>
              <MenuItem value={3}>Team 2</MenuItem>
            </Select>

            </Typography>
           </div>

          <div className="col-12 col-lg-8 col-md-12 col-sm-12 justify-content-end d-flex">
            <label className="mt-2" htmlFor="owner-select">Archive:</label>
            <Switch onChange={handleArchive} />

          <Tooltip title="Print to PDF"> {/* Add Tooltip to show text on hover */}
            <IconButton
              onClick={handlePrint}
              edge="end"
              color="inherit"
              aria-label="zoom-out"
              sx={{ marginRight: '6px' }}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Archive all completed"> {/* Add Tooltip to show text on hover */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="reset-zoom"
              sx={{ marginRight: '6px' }}
              data-bs-toggle="modal" data-bs-target="#exampleModal"
            >
              <DoneAllOutlinedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Search"> {/* Add Tooltip to show text on hover */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="search"
              sx={{ marginRight: '6px' }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>

          <InputBase
            placeholder="Search Headlines"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearchChange}
            sx={{ marginRight: '6px', borderBottom: '1px solid white', color: 'white' }}
          />
          </div>
          </div>

        </Toolbar>
      </AppBar>

{/*archive all modal*/}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content py-3">
      <div class="modal-body">
        <h5 className="fw-bold" style={{fontFamily: 'Poppins,sans-serif'}} >Archive Completed?</h5>
        <hr />
        <h6>All completed Rocks will be archived.</h6>

      </div>
      <div className="text-end me-3">
        <button type="button" class="btn btn-white shadow me-2" data-bs-dismiss="modal">Close</button>
        <button style={{backgroundColor: '#2596be'}} type="button" class="btn text-white shadow">Archive</button>
      </div>
    </div>
  </div>
</div>

   </>
  )
}

export default HeadlinesAppbar
