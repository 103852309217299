import React from 'react'
import { Grid } from '@mui/material'
import TextComponentTop from './textcomponenttop'
import SocialLinkedIn from './sociallinkdin'

export default function Social() {
    return (
        <>
            <Grid>
                <TextComponentTop />
            </Grid>
            <Grid sx={{ margin: '10px 5px 5px 5px' }}>
                <SocialLinkedIn />
            </Grid>
        </>
    )
}
