import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

function EmailPhoneVerification() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [countryCode, setCountryCode] = useState('US'); // Default country code

  const handleEditEmailClick = () => {
    // Handle edit email action here
    console.log('Edit email clicked');
  };

  const handleVerifyPhoneClick = () => {
    // Handle phone verification action here
    console.log('Verify phone clicked');
    setIsVerified(true); // Simulate phone verification success
  };

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  return (
    <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans, sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Login Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans, sans-serif', fontSize: '14px', fontWeight: '400' }}>
            Primary Email
          </Typography>
        </Grid>
        <Grid item xs={8} lg={10} sx={{ marginTop: '-12px' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter Email"
            size="small"
          />
        </Grid>
        <Grid item xs={8} lg={2} sx={{ marginTop: '-12px' }}>
          <Button className="text-black" style={{ backgroundColor: 'white' }} variant="contained"><EditIcon />&nbsp;Edit</Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-15px' }}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans, sans-serif', fontSize: '14px', fontWeight: '400' }}>
            This email is used to log in to ICTM and for platform-related communications.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: '500' }}>
            Primary Phone Number
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="country-code-label">Country Code</InputLabel>
              <Select
                labelId="country-code-label"
                id="country-code"
                value={countryCode}
                onChange={handleCountryCodeChange}
                label="Country Code"
                size="small"
              >
                <MenuItem value="US">+1 (US)</MenuItem>
                <MenuItem value="CA">+1 (CA)</MenuItem>
                {/* Add more country code options here */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <TextField
              fullWidth
              variant="outlined"
              // placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={2} lg={2}>
            <Button
              className="text-black" style={{ backgroundColor: 'white' }}
              variant="contained"
              startIcon={isVerified ? <CheckCircleOutlineIcon /> : null}
              onClick={handleVerifyPhoneClick}
            >
              {isVerified ? 'Verified' : 'Verification'}
            </Button>
          </Grid>
          <Grid item xs={2} lg={2}>
            <Button onClick={handleEditEmailClick} className="text-black" style={{ backgroundColor: 'white' }} variant="contained"><EditIcon />&nbsp;Edit</Button>
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans, sans-serif', fontSize: '14px', fontWeight: '400' }}>
            This phone number is used for MFA authentication.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default EmailPhoneVerification;
