import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import ShortIssues from './table/issuesTable';
import LongIssues from './table/issuesTableLong';
import FunctionContext from './functionalcontext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function IssuesTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    // Define an array of route paths corresponding to each tab
    const routePaths = ['/issues/short-term', '/issues/long-term'];

    // Update the URL based on the selected tab
    window.history.pushState(null, '', routePaths[value]);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [value]);

  const handlePopstate = () => {
    // Get the current URL path
    const currentPath = window.location.pathname;

    // Find the index of the current path in the routePaths array
    // const index = routePaths.indexOf(currentPath);

    // Update the selected tab based on the index
    // if (index !== -1) {
    //   setValue(index);
    // }
  };

  React.useEffect(() => {
    // Add a popstate event listener to handle back/forward navigation
    window.addEventListener('popstate', handlePopstate);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  const archiveButton = () => {
    // Function logic here
    console.log('Function called from ComponentA');
  };

  return (
    <Box sx={{height: '50px', width: '100%' }}>
      <AppBar position="static" sx={{ bgcolor: 'white', boxShadow: 'none'}} >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            "& .MuiTab-root": {
              color: 'black',
              '&.Mui-selected': {
                color: '#1993c6', 
              },
            },
            elevation: 0,
          }}
        >
          <Tab label="Short-Term" />
          <Tab label="Long-Term" {...a11yProps(1)} />
         
        </Tabs>
      </AppBar>
      <FunctionContext.Provider value={archiveButton}>
      <Box m={1}>
        {value === 0 && <ShortIssues/>}
      </Box>
      <Box m={1}>
        {value === 1 && <LongIssues/>}
      </Box>
      </FunctionContext.Provider>
      {/* <div style={tabPanelStyle}>
        {value === 2 && <TabPanel3 />}
      </div>
      <div style={tabPanelStyle}>
        {value === 3 && <TabPanel4 />}
      </div>
      <div style={tabPanelStyle}>
        {value === 4 && <TabPanel5 />} */}
      {/* </div> */}
    
     
    </Box>
  );
}
