import React from 'react'
import {Routes,Route} from 'react-router-dom'
import Meetinglayout from './meetinglayout'
import Seige from './pages/Seige'
import Data from './pages/data/dataTabs'
import Rocks from './pages/rocks/rocks'
import Headlines from './pages/headlines/headlines'
import TodoMain from './pages/todos/todoMain'
import Issues from './pages/issue/IssueMain'
import RecapTable from './pages/conclude/recapTable'
const Videomeetingroutes = () => {
  return (
   <>
   <Routes>
          <Route path="meeting-seige" element={<Seige />} />
          <Route path="meeting-data" element={<Data/>} />
          <Route path="meeting-rocks" element={<Rocks/>} />
          <Route path="meeting-headlines" element={<Headlines/>} />
          <Route path="meeting-todos" element={<TodoMain/>} />
          <Route path="meeting-issues" element={<Issues/>} />
          <Route path="conclude" element={<RecapTable/>} /> *
      </Routes>
   </>
  )
}

export default Videomeetingroutes;
