import Switch from '@mui/material/Switch';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
const AnnualsessionDayonemodal=[
    // 1
    {
        id:1,
        inputtextname:'OBJECTIVES',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        EditIcon:<EditIcon label="Edit section" />,
    },
    // 2
    {
        id:2,
        inputtextname:'CHECK-IN',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        EditIcon:<EditIcon label="Edit section" />,
    },
    // 3
    {
        id:3,
        inputtextname:'REVIEW PRIOR YEAR',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:"",
    },
    // 4
    {
        id:4,
        inputtextname:'Enter Prior Quarter KPIs',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>
    }, 
    // 5
    {
        id:5,
        inputtextname:'REVIEW PRIOR QUARTER',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited' />,
        EditIcon:"",

    },
    // 6
    {
        id:6,
        inputtextname:'TEAM HEALTH',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        EditIcon:<EditIcon label="Edit section" />,

    },
    // 7
    {
        id:7,
        inputtextname:'ORGANIZATIONAL CHECKUP',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        EditIcon:<EditIcon label="Edit section" />,

    },
    // 8
    {
        id:8,
        inputtextname:'SWOT',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:"",


    }, 
    // 9
    {
        id:9,
        inputtextname:'V/TO® REVIEW',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited' />,
        EditIcon:"",

    },
    // 10
    {
        id:10,
        inputtextname:'3 YEAR PICTURE™',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:"",

    },
    // 11
    {
        id:11,
        inputtextname:'CONCLUDE',
        duration:5,
        lockIcon:"",
        EditIcon:<EditIcon label="Edit section" />,
    }
]
export default AnnualsessionDayonemodal;