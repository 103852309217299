import React,{useState} from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SecondaryBar from '../../components/secondaryAppBar/secondaryBar';
import { styled } from '@mui/material/styles';
import Todo from '../../components/dashboardCards/todo';
import PersonalTodo from '../../components/dashboardCards/personalTodo';
import RocksMilestone from '../../components/dashboardCards/rocksMilestones';
import TeamTodoTable from '../../components/teamTodoTable/teamTodoTable';
import ScoreCardTable from '../../components/scoreCardTable/table';
import IssuesTable from '../../components/issuesTable/issuesTable';
import IssuesBar from '../../components/secondaryAppBar/issuesBar';
import CreateTODO from '../../components/teamTodoTable/createtodo';
import TodoTable from '../../components/teamTodoTable/teamTodoTable';
import ArchiveShortIssuesTable from '../../components/issuesTable/archiveshortissues';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const AllTodo = () => {

  const [showComponent, setShowComponent] = useState(true);

 const issuePrint=()=>{
    window.print();
  }

  const handleArchive=()=>{
    console.log("habdke archuve")
    setShowComponent(!showComponent)
  }

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12}>
         <IssuesBar handlePrint={issuePrint} handleArchive={handleArchive} />
        </Grid>
        <Grid container spacing={2} sx={{ margin: '2px'}}>
          <Grid item xs={12}>
          {showComponent ? <CreateTODO/>:<ArchiveShortIssuesTable/>}
          </Grid>
        </Grid>
      </Grid>


    </>
  )
}

export default AllTodo