import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import AddCommentSharpIcon from '@mui/icons-material/AddCommentSharp';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'

const PeopleAnalyzer = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = [
    { firstName: 'Test', lastName: 'Testkilo', seats: 'No Seat' },
    { firstName: 'Jane', lastName: 'Smith', seats: 'No Seat' },
  ];

  const handlePrint = () => {
    window.print();
  };

  const [showReviewCard, setShowReviewCard] = useState(false)
  const [gridMd, setGridMd] = useState(12);

  const showReviewComponent = () => {
    setShowReviewCard(true)
    setGridMd(7);
  }

  const closeEditLayout = () => {
    setShowReviewCard(false);
  }

  const handleMd12Click = () => {
    // Set md prop back to 4 when the second button is clicked
    setGridMd(12)
  };

  const handleCancelClick = () => {
    handleMd12Click();
    closeEditLayout();
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={gridMd} lg={gridMd}>
          <Paper elevation={3} boxShadow={3} sx={{ margin: '10px 5px 5px 5px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3} style={{ fontWeight: '500', fontSize: '16px', fontFamily: 'Poppins, sans-serif' }}>
                    Review each person with the People Analyzer®
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="ps-5" style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif' }}>User</TableCell>
                  <TableCell style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif' }}> Current Accountability Chart Seats</TableCell>
                  <TableCell align="right"></TableCell> {/* Align the 3rd column to the right */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell onClick={showReviewComponent}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar sx={{ bgcolor: 'grey', width: 32, height: 32, marginRight: 1 }}>
                            {`${row.firstName.charAt(0)}${row.lastName.charAt(0)}`}
                          </Avatar>
                          {`${row.firstName} ${row.lastName}`}
                        </div>
                      </TableCell>
                      <TableCell>{row.seats}</TableCell>
                      <TableCell style={{ color: '#A5A5A5' }} align="right"> {/* Align the content of the 3rd column to the right */}
                        <AddCommentSharpIcon /> NEW REVIEWS
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiTablePagination-displayedRows": {
                  marginTop: "12px",
                },
                ".MuiTablePagination-spacer": {
                  paddingTop: 1,
                },
                ".MuiTablePagination-selectLabel": {
                  marginBottom: '8px',
                },
                ".MuiSelect-select": {
                  paddingTop: 0,
                },
              }}
            />
          </Paper>

        </Grid> {/* 1st Col Grid */}

        <Grid item sm={12} md={12} lg={5}>
          {showReviewCard && (
            rows.map((row, index) => (
              <div className="card p-1 mb-2" key={index}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <h6 className="ms-4 my-3"><ArrowForwardIosIcon onClick={handleCancelClick} style={{ fontSize: '17px' }} /></h6>
                    <span className="mx-4" style={{ color: '#E0E0E0', borderLeft: '1px solid #E0E0E0' }}></span>
                    <h6 className="mt-3" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px' }}>{`${row.firstName} ${row.lastName}`}</h6>
                  </div>
                  <h6 className="my-3 me-4"><FontAwesomeIcon icon={faFilePdf} onClick={handlePrint} style={{ fontSize: '20px' }} /></h6>
                </div>

                <hr className="my-2 p-0" />

                <div className="rounded rounded-0 py-2 mb-2 mx-2 text-center" style={{ backgroundColor: '#FFF3CD', color: '#8C6C10', fontSize: '15px' }}>
                  There are no reviews for this user.
                </div>

              </div>
            ))
          )}
        </Grid>


      </Grid>
    </>
  );
};

export default PeopleAnalyzer;
