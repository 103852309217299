import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

function ChangePasswordButton() {
  // Handle the action when the button is clicked
  const handleChangePasswordClick = () => {
    // Implement your logic to change the password here
    console.log('Change Password button clicked');
  };

  return (
    <>
      <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography sx={{ color: '#000000de', fontFamily: 'Poppins,sans-serif', fontSize: '15px', fontWeight: '500' }}>
              Change Password
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '0px' }}>
            <Typography sx={{ color: '#0000008a', fontFamily: 'Poppins,sans-serif', fontSize: '14px', fontWeight: '400' }}>
              We recommend changing your password every ninety days to keep your account secure.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<LockIcon />}
              onClick={handleChangePasswordClick}
            >
              Change My Password
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} className='mt-2' style={{ padding: '16px 16px 25px 16px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography sx={{ color: '#000000de', fontFamily: 'Poppins,sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Create a New Ninety Account
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '0px' }}>
            <Typography sx={{ color: '#0000008a', fontFamily: 'Poppins,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            This account will have separate billing, users and information from any current companies you are in.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<LockIcon />}
              onClick={handleChangePasswordClick}
            >
              Create New Company
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default ChangePasswordButton;
