import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from '@mui/material/CardHeader';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Accountabilitychartmodal from './accountabilitychartmodal'; // Import the new Modal component
import { Tree, TreeNode } from 'react-organizational-chart';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import EventSeatOutlinedIcon from '@mui/icons-material/EventSeatOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Createbtn from '../createbtn/create'

// Zoom in Zoom out Arrow
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function Accountabilitychart() {
  const [isModalOpen, setModalOpen] = useState(false);
  //visionary data
  const [visionaryData, setVisionaryData] = useState({
    title: 'Visionary',
    responsibilities: [
      '20 Ideas',
      'Creativity/Problem Solving',
      'Big Relationships',
      'Culture',
      'R&D',
      'More on Emotion',
    ],
  });

//integrator
const [integratorData, setIntegratorData] = useState({
    title: 'Integrator',
    responsibilities: [
      'Lead, Manage, Accountability',
      'Profit & Loss/Business Plan',
      'Remove Obstacles & Barriers',
      'Special Projects',
      'More on Logic',
    ],
  });

  //marketing
const [marketingData, setMarketingData] = useState({
    title: 'Marketing',
    responsibilities: [
      'Lead generation',
      'Brand definition and management',
      'Competitive landscape analysis',
      'Customer listening and research',
    ],
  });

//sales
const [salesData, setSalesData] = useState({
    title: 'Sales',
    responsibilities: [
      'Client relationships',
      'Client communications',
      'Outreach strategy',
      'Customer retention',
    ],
  });

//operations
const [operationsData, setOperationsData] = useState({
    title: 'Operations',
    responsibilities: [
      'Workflow creation and refinement',
      'Employee hiring, assessments, and management',
      'Quality control',
      'Safety and protocol',
    ],
  });

//finance
const [financeData, setFinanceData] = useState({
    title: 'Finance',
    responsibilities: [
      'Forecasting and budgets',
      'Accounting and reporting',
      'Payroll',
      'Taxes',
    ],
  });


  const handleEditClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSaveChanges = (updatedData) => {
    console.log('Updated Visionary Data:', updatedData);
    setVisionaryData(updatedData);
    handleCloseModal();
  };

//select
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

// select user
  const [user, setUser] = React.useState(null);
  const userOpen = Boolean(user);
  const handleUserClick = (event) => {
    setUser(event.currentTarget);
  };
  const handleUserClose = () => {
    setUser(null);
  };

  // createbtn modal

  const [isModalsOpen, setIsModalsOpen] = React.useState(false);

  const openModals = () => {
    setIsModalsOpen(true);
  };

  const closeModals = () => {
    setIsModalsOpen(false);
  };


  return (
  <>
<div style={{ overflowX: 'scroll',}}>
<Tree label={<div>
{/*Visionary*/}
<div className="mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
  <div className="card shadow" style={{width: '270px'}} >

     <div class="grid gap-3 d-flex justify-content-between">
       <div class="p-2 g-col-6">
         <h6 style={{fontFamily: 'Poppins, sans-serif'}} class="card-title pt-1">{visionaryData.title}</h6>
       </div>
       <div class="p-2 g-col-6 d-flex">
         <h6 className="pt-1" style={{color: '#757575'}} ><EditIcon onClick={handleEditClick} style={{ fontSize: '19px'}} /></h6>
         <h6 className="pt-1 ms-3" style={{color: '#757575'}} ><MoreHorizIcon onClick={handleClick} style={{ fontSize: '19px'}} /></h6>
       </div>
     </div>

     <div className="d-flex ms-3 my-2">
       <PersonAddIcon onClick={handleUserClick}/>
       <h6 className="ms-4" style={{fontSize: '14px', color: '#757575'}} >Click to add seat holder</h6>
     </div>
     <hr className="my-2"/>

     <div style={{height: '40vh'}} >
       <h6 style={{color: '#757575'}} className="text-start ms-3">Roles and Responsibilities</h6>
       <ul className="text-start">
         {visionaryData.responsibilities && visionaryData.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
         ))}
       </ul>
     </div>

     <hr className="my-2"/>

     <div className="text-center mb-2">
       <AddCircleOutlineIcon/>
     </div>

  </div> {/*card*/}
</div>
</div>}>


<TreeNode label={<div>
{/*Integrator*/}
<div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
  <div className="card shadow" style={{width: '270px'}} >

     <div class="grid gap-3 d-flex justify-content-between">
       <div class="p-2 g-col-6">
         <h6 style={{fontFamily: 'Poppins, sans-serif'}} class="card-title pt-1">{integratorData.title}</h6>
       </div>
       <div class="p-2 g-col-6 d-flex">
         <h6 className="pt-1" style={{color: '#757575'}} ><EditIcon onClick={handleEditClick} style={{ fontSize: '19px'}} /></h6>
         <h6 className="pt-1 ms-3" style={{color: '#757575'}} ><MoreHorizIcon onClick={handleClick} style={{ fontSize: '19px'}} /></h6>
       </div>
     </div>

     <div className="d-flex ms-3 my-2">
       <PersonAddIcon onClick={handleUserClick}/>
       <h6 className="ms-4" style={{fontSize: '14px', color: '#757575'}} >Click to add seat holder</h6>
     </div>
     <hr className="my-2"/>

     <div style={{height: '40vh'}} >
       <h6 style={{color: '#757575'}} className="text-start ms-3">Roles and Responsibilities</h6>
       <ul className="text-start">
         {integratorData.responsibilities && integratorData.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
         ))}
       </ul>
     </div>

     <hr className="my-2"/>

     <div className="text-center mb-2">
       <AddCircleOutlineIcon/>
     </div>

  </div> {/*card*/}
</div>
</div>}>

<TreeNode label={<div>
{/*Marketing*/}
  <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
  <div className="card shadow" style={{width: '270px'}} >

     <div class="grid gap-3 d-flex justify-content-between">
       <div class="p-2 g-col-6">
         <h6 style={{fontFamily: 'Poppins, sans-serif'}} class="card-title pt-1">{marketingData.title}</h6>
       </div>
       <div class="p-2 g-col-6 d-flex">
         <h6 className="pt-1" style={{color: '#757575'}} ><EditIcon onClick={handleEditClick} style={{ fontSize: '19px'}} /></h6>
         <h6 className="pt-1 ms-3" style={{color: '#757575'}} ><MoreHorizIcon onClick={handleClick} style={{ fontSize: '19px'}} /></h6>
       </div>
     </div>

     <div className="d-flex ms-3 my-2">
       <PersonAddIcon onClick={handleUserClick}/>
       <h6 className="ms-4" style={{fontSize: '14px', color: '#757575'}} >Click to add seat holder</h6>
     </div>
     <hr className="my-2"/>

     <div style={{height: '30vh'}} >
       <h6 style={{color: '#757575'}} className="text-start ms-3">Roles and Responsibilities</h6>
       <ul className="text-start">
         {marketingData.responsibilities && marketingData.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
         ))}
       </ul>
     </div>

     <hr className="my-2"/>

     <div className="text-center mb-2">
       <AddCircleOutlineIcon/>
     </div>

  </div> {/*card*/}
</div>
</div>} />

<TreeNode label={<div>
{/*sales*/}
<div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
  <div className="card shadow" style={{width: '270px'}} >

     <div class="grid gap-3 d-flex justify-content-between">
       <div class="p-2 g-col-6">
         <h6 style={{fontFamily: 'Poppins, sans-serif'}} class="card-title pt-1">{salesData.title}</h6>
       </div>
       <div class="p-2 g-col-6 d-flex">
         <h6 className="pt-1" style={{color: '#757575'}} ><EditIcon onClick={handleEditClick} style={{ fontSize: '19px'}} /></h6>
         <h6 className="pt-1 ms-3" style={{color: '#757575'}} ><MoreHorizIcon onClick={handleClick} style={{ fontSize: '19px'}} /></h6>
       </div>
     </div>

     <div className="d-flex ms-3 my-2">
       <PersonAddIcon onClick={handleUserClick}/>
       <h6 className="ms-4" style={{fontSize: '14px', color: '#757575'}} >Click to add seat holder</h6>
     </div>
     <hr className="my-2"/>

     <div style={{height: '30vh'}} >
       <h6 style={{color: '#757575'}} className="text-start ms-3">Roles and Responsibilities</h6>
       <ul className="text-start">
         {salesData.responsibilities && salesData.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
         ))}
       </ul>
     </div>

     <hr className="my-2"/>

     <div className="text-center mb-2">
       <AddCircleOutlineIcon/>
     </div>

  </div> {/*card*/}
</div>
</div>} />

<TreeNode label={<div>
{/*operations*/}
<div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
  <div className="card shadow" style={{width: '270px'}} >

     <div class="grid gap-3 d-flex justify-content-between">
       <div class="p-2 g-col-6">
         <h6 style={{fontFamily: 'Poppins, sans-serif'}} class="card-title pt-1">{operationsData.title}</h6>
       </div>
       <div class="p-2 g-col-6 d-flex">
         <h6 className="pt-1" style={{color: '#757575'}} ><EditIcon onClick={handleEditClick} style={{ fontSize: '19px'}} /></h6>
         <h6 className="pt-1 ms-3" style={{color: '#757575'}} ><MoreHorizIcon onClick={handleClick} style={{ fontSize: '19px'}} /></h6>
       </div>
     </div>

     <div className="d-flex ms-3 my-2">
       <PersonAddIcon onClick={handleUserClick}/>
       <h6 className="ms-4" style={{fontSize: '14px', color: '#757575'}} >Click to add seat holder</h6>
     </div>
     <hr className="my-2"/>

     <div style={{height: '30vh'}}>
       <h6 style={{color: '#757575'}} className="text-start ms-3">Roles and Responsibilities</h6>
       <ul className="text-start">
         {operationsData.responsibilities && operationsData.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
         ))}
       </ul>
     </div>

     <hr className="my-2"/>

     <div className="text-center mb-2">
       <AddCircleOutlineIcon/>
     </div>

  </div> {/*card*/}
</div>
</div>} />

<TreeNode label={<div>
{/*finance*/}
<div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
  <div className="card shadow" style={{width: '270px'}} >

     <div class="grid gap-3 d-flex justify-content-between">
       <div class="p-2 g-col-6">
         <h6 style={{fontFamily: 'Poppins, sans-serif'}} class="card-title pt-1">{financeData.title}</h6>
       </div>
       <div class="p-2 g-col-6 d-flex">
         <h6 className="pt-1" style={{color: '#757575'}} ><EditIcon onClick={handleEditClick} style={{ fontSize: '19px'}} /></h6>
         <h6 className="pt-1 ms-3" style={{color: '#757575'}} ><MoreHorizIcon onClick={handleClick} style={{ fontSize: '19px'}} /></h6>
       </div>
     </div>

     <div className="d-flex ms-3 my-2">
       <PersonAddIcon onClick={handleUserClick} />
       <h6 className="ms-4" style={{fontSize: '14px', color: '#757575'}} >Click to add seat holder</h6>
     </div>
     <hr className="my-2"/>

     <div style={{height: '30vh'}} >
       <h6 style={{color: '#757575'}} className="text-start ms-3">Roles and Responsibilities</h6>
       <ul className="text-start">
         {financeData.responsibilities && financeData.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
         ))}
       </ul>
     </div>

     <hr className="my-2"/>

     <div className="text-center mb-2">
       <AddCircleOutlineIcon/>
     </div>

  </div> {/*card*/}
</div>
</div>} />

    </TreeNode>
  </Tree>

      <Accountabilitychartmodal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        data={visionaryData}
        onSaveChanges={handleSaveChanges}
      />

{/*select*/}
  <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem style={{color: '#757575'}} onClick={handleClose}><PriorityHighIcon />&nbsp;&nbsp;&nbsp;Make it an Issue</MenuItem>
        <MenuItem style={{color: '#757575'}} onClick={openModals} disabled={isModalsOpen}><CheckBoxOutlinedIcon />&nbsp;&nbsp;&nbsp;Create a To-Do</MenuItem>
        <Createbtn isOpen={isModalsOpen} onClose={closeModals} />
        <MenuItem style={{color: '#757575'}} onClick={handleClose}><EventSeatOutlinedIcon />&nbsp;&nbsp;&nbsp;Set as Top Seat</MenuItem>
        <MenuItem style={{color: '#757575'}} onClick={handleClose}><FileCopyOutlinedIcon />&nbsp;&nbsp;&nbsp;Clone Seat</MenuItem> <hr className="my-1"/>
        <MenuItem className="text-danger" onClick={handleClose}><DeleteIcon />&nbsp;&nbsp;&nbsp;Delete Seat</MenuItem>
      </Menu>
    </div>

{/*user select*/}
      <Menu
        id="basic-menu"
        anchorEl={user}
        open={userOpen}
        onClose={handleUserClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem><TextField id="standard-basic" label="Search" variant="standard" /></MenuItem>
        <MenuItem onClick={handleUserClose}><Avatar>UN</Avatar>&nbsp;&nbsp;&nbsp;User Name</MenuItem>
      </Menu>

</div>
  </>
  );
}
