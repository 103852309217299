import React, { useState } from 'react'
import { Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import ReactQuill from 'react-quill';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import NativeSelect from '@mui/material/NativeSelect';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import axios from 'axios';
import Cookies from 'js-cookie';
// Add to the other Teams
const names = [
    'Mern Stack',
    'Ai Team (owner is not on team)',
    'Leadership Team (owner is not on Team)',
];

export default function Rock({ onIssueTitleChange,onClose }) {
    // Add to the other Teams
    const [personName, setPersonName] = React.useState([]);
    // ReactQuill text area
    const toolbarOptions = [
        'bold',
        'italic',
        'underline',
        'strike',
        { 'header': [1, 2, 3] },
        'align',
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'script': 'sub' },
        { 'script': 'super' },
        { 'direction': 'rtl' },
        { 'size': ['small', false, 'large', 'huge'] },
        { 'color': [] },
        { 'background': [] },
        { 'font': [] },
        'blockquote',
        'code-block',
        'clean',
    ];

    const modules = {
        toolbar: [toolbarOptions],
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'ordered',
        'bullet',
        'sub',
        'super',
        'direction',
        'size',
        'color',
        'background',
        'font',
        'blockquote',
        'code-block',
        'clean',
    ];
    const [text, setText] = useState('');
    const handleTextChange = (value) => {
        setText(value);
    };
    // file upload
    const [file, setFile] = useState(null);
    //    const handleFileChange = (e) => {
    //        const selectedFile = e.target.files[0];
    //        setFile(selectedFile); // Save the selected file
    //    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile); // Save the selected file

        if (selectedFile) {
            setInputValues({
                ...inputValues,
                attachment: selectedFile.name, // Update attachment in inputValues
            });
        }
    };

    const handleCancel = () => {
        setFile(null); // Remove the file
    };
    // apply the style in the uploaded file
    const HoverableDiv = styled('div')({
        display: 'flex',
        justifyContent: "space-between",
        marginTop: '5px',
        '&:hover': {
            backgroundColor: '#f5f5f5', // Change the background color on hover
        },
    });
    // Add to the other teams
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // Getting the value of Issue
    const [issueTitle, setIssueTitle] = useState('');
    const handleIssueChange = (value) => {
        // Update the state in Create based on the issue title input
        setIssueTitle(value);
        onIssueTitleChange(value.trim() === '');
    };

    //switch
    const [switchStatus, setSwitchStatus] = useState(false);

    const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setInputValues({
            ...inputValues,
            rock: isChecked,
        });
    };
    //

    const [inputValues, setInputValues] = useState({
        title: '',
        date: '',
        description: '',
        status: '',
        team: '',
        rock: '',
        attachment: '',
    });

    const handleInputChange = (e, nativeSelectName) => {
        const { name, value } = e.target;

        if (nativeSelectName) {
            // Handle NativeSelect separately
            setInputValues({
                ...inputValues,
                [nativeSelectName]: value,
            });
        } else {
            setInputValues({
                ...inputValues,
                [name]: value,
            });
        }
    };

    const handleRockForm = () => {
        const payload = {
            name: inputValues.title,
            type: 'rocks',
            date: inputValues.date,
            description: text.replace(/<\/?[^>]+(>|$)/g, ""),
            team: inputValues.team,
            who: inputValues.who,
            priority: inputValues.priority,
            attachments: inputValues.attachment,
            departmentrock: inputValues.rock
        }
        const config = {
            headers: { Authorization: `Bearer ${Cookies.get('token')}` }
        };
        axios.post(`${process.env.REACT_APP_API_URL}todos/create`, payload, config)
            .then(response => {
                // Handle successful response
                console.log('Success:', response.data);
                onClose();
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
            });
            setInputValues({
                title: '',
                date: '',
                description: '',
                status: '',
                team: '',
                rock: '',
                attachment: '',
                who: '',
                priority: '',
                departmentrock: ''
                })

                setText('')
    };

    return (
        <div>
            <CardContent className='container-fluid'
                style={{ maxHeight: '100%', height: "100%" }}>
                <Box style={{ height: "auto" }}
                    component="form"
                    Validate
                    autoComplete="off"

                >
                    <div className='d-flex flex-row justify-content-center'>
                        <AccountCircle className='' style={{ width: "40px", height: "40px" }} />
                        <TextField
                            id="standard-search"
                            label="Name the Rock"
                            type="text"
                            variant="standard"
                            style={{ color: "red", width: "90%" }}
                            name="title"
                            value={inputValues.title}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    {/* date */}
                    <div className='d-flex flex-column px-2 mt-3'>
                        <TextField
                            id="standard-search"
                            label="Due Date"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            name="date"
                            value={inputValues.date}
                            onChange={handleInputChange}
                            variant="standard"
                            style={{ color: "red", width: "40%", textDecoration: "underline" }}
                            required
                        />
                    </div>
                    <div className='d-flex flex-row mt-3'>
                        <ReactQuill
                            value={text}
                            onChange={handleTextChange}
                            modules={modules}
                            formats={formats}
                            placeholder={'Description'}
                            theme="snow"
                            style={{
                                height: '14vh', // Adjust the height as needed
                                fontSize: '18px', // Adjust the font size as needed
                            }}
                        />
                    </div>
                    <div className='w-100' style={{ marginTop: "100px" }}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native" required>
                            Status
                        </InputLabel>
                        <NativeSelect
                            style={{ color: "#2596be", fontSize: "18px", width: "100%" }}
                            defaultValue={'complete'}
                            inputProps={{
                                name: 'status',
                                id: 'uncontrolled-native',
                            }}
                            onChange={(e) => handleInputChange(e, 'status')}
                        >
                            <option value={'off track'} style={{ color: "red" }}>Off-Track</option>
                            <option value={'on track'} style={{ color: "green" }}>On-Track</option>
                            <option value={'complete'} style={{ color: "blue" }}>Complete</option>
                        </NativeSelect>
                    </div>

                    {/* main Container for personal, mernstack and label */}
                    <div style={{ marginTop: "20px" }}>
                        <div className='d-flex flex-row justify-content-between'>
                            {/* Logic to conditionally show/hide and disable the components */}
                            <div style={{ width: "180px" }}>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native" required>
                                    Team
                                </InputLabel>
                                <NativeSelect
                                    style={{ marginLeft: "0px", color: "#2596be", fontSize: "18px" }}
                                    defaultValue={'Mern Stack'}
                                    inputProps={{
                                        name: 'team',
                                        id: 'uncontrolled-native',
                                    }}
                                    onChange={(e) => handleInputChange(e, 'team')}
                                    name="team"
                                >
                                    <option value={'Mern Stack'}>Mern Stack</option>
                                    <option value={'ai team'}>Ai Team (owner is not on team)</option>
                                    <option value={'leadership team'}>Leadership Team (owner is not on Team)</option>
                                </NativeSelect>
                            </div>
                            {/* Add to other Teams */}
                            <div style={{ width: "180px" }}>
                                <InputLabel id="demo-multiple-checkbox-label">Add to the Other Teams</InputLabel>
                                <Select style={{ width: "180px", height: "30px", border: "none" }}
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={personName}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name} disabled={name === 'Mern Stack'}>
                                            <Checkbox checked={personName.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                        </div>
                        <div className='d-flex flex-column px-4 w-100'>
                            <span>
                                Departmental Rock: <Switch onChange={handleSwitchChange} color="secondary" />
                            </span>
                        </div>
                        <div className='col-lg-12 col-md-12 col-sm-12 mt-3'>
                            <small>* Note: Changing teams will filter out selected users not on team</small>
                        </div>
                    </div>

                    <div className='col-lg-2 col-md-12 col-sm-12 mt-2 w-100'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>Attachments</span>
                            <label htmlFor="file-upload">
                                <AddCircleOutlineIcon />
                            </label>
                            <input
                                type="file"
                                id="file-upload"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                        {file && (
                            <HoverableDiv>
                                <Typography>{file.name}</Typography>
                                <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                            </HoverableDiv>
                        )}
                    </div>
                    <div className='container-fluid d-flex flex-row justify-content-center mt-2'>
                        <Button style={{ color: "white", backgroundColor: "#2596be", width: "99%" }} onClick={handleRockForm} >Create Rock</Button>
                        {/* <Button style={{ width: "35%", backgroundColor: "#fff", boxShadow: " 3px 5px 10px rgba(0.8, 0.8, 0, 0.2)" }}>Cancel</Button> */}
                    </div>
                </Box>
            </CardContent>
        </div>
    )
}
