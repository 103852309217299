import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Typography,
} from '@mui/material';

function MaticMyers() {
  const [mbtiType, setMbtiType] = useState('');

  const handleMbtiTypeChange = (event) => {
    setMbtiType(event.target.value);
  };

  return (
    <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Myers-Briggs Type Indicator®
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            The Myers-Briggs Type Indicator assessment uses a series of self-reported questions to identify a person's personality type, strengths, and preferences.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <label className="mb-1" style={{color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif'}}>Myers-Briggs Type Indicator</label>
            {/* <InputLabel id="demo-simple-select-label">Select MBTI Type</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mbtiType}
              onChange={handleMbtiTypeChange}
              // label="Select MBTI Type"
              size='small'
            >
              <MenuItem value="ISTJ">ISTJ</MenuItem>
              <MenuItem value="ISFJ">ISFJ</MenuItem>
              <MenuItem value="INFJ">INFJ</MenuItem>
              <MenuItem value="INTJ">INTJ</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default MaticMyers;
