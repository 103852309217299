import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Interval from './interval';
import Weeks from './weeks';
import axios from 'axios'
import { FormControl } from '@mui/material';
function createData(title, goals, average, total, date1, date2, date3, date4) {
    return {
        title, goals, average, total, date1, date2, date3, date4
    };
}

const rows = [
    createData('Test Measurable', 305, 3.7, 67, 4.3, 3.7, 67, 4.3, 4.3),
    createData('Test Measurable 1', 452, 25.0, 51, 4.9, 3.7, 67, 4.3, 4.3),
    createData('Test Measurable 2', 262, 16.0, 24, 6.0, 3.7, 67, 4.3, 4.3),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'title',
        numeric: false,
        disablePadding: true,
        label: 'Title',
    },
    {
        id: 'goals',
        numeric: true,
        disablePadding: false,
        label: 'Goals',
    },
    {
        id: 'average',
        numeric: true,
        disablePadding: false,
        label: 'Average',
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
    },
    {
        id: 'date1',
        numeric: true,
        disablePadding: false,
        label: 'Oct 14 - Oct 20',
    },
    {
        id: 'date2',
        numeric: true,
        disablePadding: false,
        label: 'Oct 20 - Oct 27',
    },
    {
        id: 'date3',
        numeric: true,
        disablePadding: false,
        label: 'Oct 27 - Nov 04',
    },
    {
        id: 'date4',
        numeric: true,
        disablePadding: false,
        label: 'Nov 04 - Nov 11',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    {/* <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> */}
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%', fontSize: 22, fontWeight: 600 }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Scorecard
            </Typography>
            <Interval />
            <Weeks />

        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function ScoreCardTable() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    // api call below
    const [scorecardData, setScorecardData] = React.useState([]);
    const [isLoading, setisloading] = React.useState();
    const [length, setLength] = React.useState("");
    const columns = [
        { id: '_id', label: 'ID' },
        { id: 'name', label: 'Title' },
        { id: 'description', label: "Description" },
        { id: 'unit', label: 'Unit' },
        { id: 'goal', label: 'Goal' },
        { id: 'goalOrientationRole', label: 'Goal Orientation Role' },
        { id: 'seat', label: 'Seat' },
        { id: 'timeDuration', label: 'Time Duration' }
    ];
    // monthly scorecard filtre
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}scorecards/get`);
                const allScorecards = response.data.scoreCards;

                setScorecardData(allScorecards);
                setLength(allScorecards.length);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);
    return (

        <>
            <div style={{ overflow: 'hidden', overflowY: 'auto' }}>
        {isLoading ? (
          <p>Loading...</p>
        ) : length > 0 ? (
          <TableContainer component={Paper}>
            <Typography variant="h4" style={{ paddingLeft: '30px', paddingTop: '25px', marginRight: '30px' }}>
              Scorecard
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{overflowY:"auto"}}>
                {scorecardData.map((row) => (
                  <TableRow key={row._id}>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{row[column.id]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '70vh',
            }}
          >
            <Typography variant="p" align="center" gutterBottom sx={{ color: '#0000008a', fontSize: '20px', fontWeight: '500', fontFamily: 'Poppins,sans-serif' }}>
              Your team hasn't added any scorecard yet.
            </Typography>
          </div>
        )}
      </div>
        </>
        //     <Box sx={{ width: '100%', overflow: 'auto' }}>
        //         <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none', }}>
        //             <EnhancedTableToolbar numSelected={selected.length} />
        //             <TableContainer>
        //                 <Table
        //                     sx={{ minWidth: 750 }}
        //                     aria-labelledby="tableTitle"
        //                     size={dense ? 'small' : 'medium'}
        //                 >
        //                     <EnhancedTableHead
        //                         numSelected={selected.length}
        //                         order={order}
        //                         orderBy={orderBy}
        //                         onSelectAllClick={handleSelectAllClick}
        //                         onRequestSort={handleRequestSort}
        //                         rowCount={rows.length}
        //                     />
        //                     <TableBody>
        //                         {visibleRows.map((row, index) => {
        //                             const isItemSelected = isSelected(row.name);
        //                             const labelId = `enhanced-table-checkbox-${index}`;

        //                             return (
        //                                 <TableRow
        //                                     hover
        //                                     onClick={(event) => handleClick(event, row.name)}
        //                                     role="checkbox"
        //                                     aria-checked={isItemSelected}
        //                                     tabIndex={-1}
        //                                     key={row.name}
        //                                     selected={isItemSelected}
        //                                     sx={{ cursor: 'pointer' }}
        //                                 >
        //                                     <TableCell padding="checkbox">
        //                                         {/* <Checkbox
        //                     color="primary"
        //                     checked={isItemSelected}
        //                     inputProps={{
        //                       'aria-labelledby': labelId,
        //                     }}
        //                   /> */}

        //                                     </TableCell>
        //                                     <TableCell style={{width:"12%"}}
        //                                         component="th"
        //                                         id={labelId}
        //                                         scope="row"
        //                                         padding="none"
        //                                     >
        //                                         {row.title}
        //                                     </TableCell>
        //                                     <TableCell align="right">{row.goals}</TableCell>
        //                                     <TableCell align="right">{row.average}</TableCell>
        //                                     <TableCell align="right">{row.total}</TableCell>
        //                                     <TableCell align="right">{row.date1}</TableCell>
        //                                     <TableCell align="right">{row.date2}</TableCell>
        //                                     <TableCell align="right">{row.date3}</TableCell>
        //                                     <TableCell align="right">{row.date4}</TableCell>
        //                                 </TableRow>
        //                             );
        //                         })}
        //                         {emptyRows > 0 && (
        //                             <TableRow
        //                                 style={{
        //                                     height: (dense ? 33 : 53) * emptyRows,
        //                                 }}
        //                             >
        //                                 <TableCell colSpan={6} />
        //                             </TableRow>
        //                         )}
        //                     </TableBody>
        //                 </Table>
        //             </TableContainer>
        //             {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        //   <TablePagination
        //     rowsPerPageOptions={[5, 10, 25]}
        //     component="div"
        //     count={rows.length}
        //     rowsPerPage={rowsPerPage}
        //     page={page}
        //     onPageChange={handleChangePage}
        //     onRowsPerPageChange={handleChangeRowsPerPage}
        //   />
        // </div> */}
        //         </Paper>
        //     </Box>
    );
}
