import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { useState } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            By continuing, you agree to the {' '}
            <Link className="text-decoration-none" color="inherit" href="#">
                Terms and conditions
            </Link>{' and'}{' '}
            <Link className="text-decoration-none" color="inherit" href="#">
                Privacy Policy
            </Link>

        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login({ setIsLoggedIn }) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [inputValues, setInputValues] = useState({
        email: '',
        password: '',
    });
    const navigate = useNavigate();


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };

    React.useEffect(() => {
        const token = Cookies.get('token');
        if(token){
            setIsLoggedIn(true);
            navigate('/home')
        }
    },[])

    const handleLoginClick = () => {

        const data={
            email:inputValues.email,
            password:inputValues.password
        }
       axios.post(`${process.env.REACT_APP_API_URL}users/signin`,data)
            .then(response => {
                // Handle successful response
                if(response.data.success){
                    Cookies.set('token',response.data.token);
                    localStorage.setItem('userEmail', inputValues.email);
                    setIsLoggedIn(true);
                    navigate('/home')
                }
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
            });
        // if (inputValues.email.includes('@gmail.com') && (inputValues.password != '')) {
        //     setIsLoggedIn(true);
        //     navigate('/home')
        // } else {
        //     alert("Please add valid Email and input cannot be empty...")
        // }
        // Perform any action with the values
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };


    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                <Grid item xs={12} sm={12} md={6} lg={6} component={Paper} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            //   alignItems: 'center',
                        }}
                    >
                        <Box>
                            <img
                                alt="profile-user"
                                //   width="220px"
                                //   height="220px"
                                src={`WhatsApp Image 2023-09-26 at 06.09.09.jpg`}
                                style={{ cursor: "pointer", marginBottom: "50px", objectFit: 'fill' }}
                                className="img-fluid"
                            />
                        </Box>
                        <Typography component="h1" variant="h1" fontSize={'40px'} fontWeight={'800'} sx={{ marginBottom: '30px' }}>
                            Log In
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address "
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={inputValues.email}
                                onChange={handleInputChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete=""
                                value={inputValues.password}
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"

                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Grid container mt={1}>
                                <Grid item xs>
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" />}
                                        label="Remember me"
                                    />
                                </Grid>
                                <Grid item >
                                    <Link href="#" className="text-decoration-none" sx={{ textDecoration: 'none', fontWeight: '600', color: '#2D86A3 !important' }}>
                                        <h6 className="pt-1 fw-bold" style={{ color: '#2D86A3' }}>FORGOT PASSWORD?</h6>
                                    </Link>
                                </Grid>
                            </Grid>


                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, p: 2, borderRadius: '40px', backgroundColor: '#1993c6' }}
                                onClick={handleLoginClick}
                            >
                                Login
                            </Button>
                            <Divider>OR</Divider>

                            <Button
                                startIcon={<FcGoogle />}
                                type="submit"
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 2, p: 2, borderRadius: '40px', borderColor: '#1993c6', color: 'black' }}
                            >
                                Login with Google
                            </Button>
                            <Box
                                sx={{
                                    marginTop: '40px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >   {"Don't have an account yet?"}

                                <Link className="text-decoration-none" to={'/login/sign-up'} variant="body2" >
                                    <Typography sx={{ textDecoration: 'none', fontWeight: '600', color: '#1993c6' }}>SIGN UP</Typography>
                                </Link>
                            </Box>

                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={6}
                    lg={6}
                    sx={{
                        backgroundImage: 'url(login_img.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Grid>
        </ThemeProvider>
    );
}