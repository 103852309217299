// redux/store.js
import { configureStore, combineReducers, applyMiddleware } from '@reduxjs/toolkit';
import dataReducer from './slices/userSlice/createUserSlice';
import { thunk } from 'redux-thunk';

const rootReducer = combineReducers({
  email: dataReducer.email,
  personalInfo: dataReducer.personalInfo,
  companyInfo: dataReducer.companyInfo,
  // Add other reducers if needed
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
