import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';

function EnableMFA() {
  const [isMFAEnabled, setIsMFAEnabled] = useState(false);

  const handleMFAChange = () => {
    // Handle MFA switch change here
    setIsMFAEnabled(!isMFAEnabled);
  };

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Poppins, sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Multi-Factor Authentication (MFA)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Poppins,sans-serif', fontSize: '14px', fontWeight: '400' }}>
          Enable MFA
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'left', color: '#00000061' }}>
          <FormControlLabel
            control={
              <Switch
                checked={isMFAEnabled}
                onChange={handleMFAChange}
                inputProps={{ 'aria-label': 'Enable MFA switch' }}
              />
            }
            label="Upon login, an authentication SMS will be sent to the phone number listed above. Depending on your carrier, SMS charges may apply."
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default EnableMFA;
