import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import PersonalComponent from './personal';
import Metrics from './metrics';
import Contact from './contact';
import Social from './social';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function ProfileTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{  bgcolor: 'background.#ffffff'}}>
      <AppBar position="relative" sx={{ background: "#ffffff"}} >
      <Toolbar sx={{  bgcolor: 'background.#ffffff', }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Personal" {...a11yProps(0)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', },  }}/>
          <Tab label="Metrics" {...a11yProps(1)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', },  }}/>
          <Tab label="Contact" {...a11yProps(2)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', },  }}/>
          <Tab label="Social" {...a11yProps(4)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', },  }}/>
        </Tabs>         
          <Button
            variant="contained"  
            color="secondary"
            startIcon={<SaveIcon />} // Add the Save icon
            sx={{ marginLeft:'330px' }}
          >
            Save Changes
          </Button>
        </Toolbar>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <PersonalComponent/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Metrics/>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Contact/>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
        <Social/>
        </TabPanel>     
    </Box>
  );
}
