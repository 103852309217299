import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SecondaryBar from '../../../../secondaryAppBar/secondaryBar';
import { styled } from '@mui/material/styles';
import Todo from '../../../../dashboardCards/todo';
import PersonalTodo from '../../../../dashboardCards/personalTodo';
import RocksMilestone from '../../../../dashboardCards/rocksMilestones';
import TeamTodoTable from '../../../../teamTodoTable/teamTodoTable';
import ScoreCardTable from '../../../../scoreCardTable/table';
import IssuesTable from '../../../../issuesTable/issuesTable';
import IssuesBar from '../../../../secondaryAppBar/issuesBar';
import IssuesTabs from './issueTabs';
import FunctionContext from './functionalcontext';
import ArchiveShortIssuesTable from './table/archiveshort';
import IssueTopBar from './issueTopBar';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const IssueMain = ({onChange}) => {
  const issuePrint=()=>{
    window.print();
  }

//archive
const [showComponent, setShowComponent] = React.useState(false);
  const archiveHandle=()=>{
    setShowComponent(!showComponent)
  }

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <IssueTopBar handleArchive={archiveHandle} handlePrint={issuePrint}  />
        </Grid>
        <Grid container xs={12}>
          {/* {showComponent || <ArchiveShortIssuesTable/> } */}
          {showComponent ? <ArchiveShortIssuesTable /> : null}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <IssuesTabs /> */}
            {showComponent ? null :<IssuesTabs /> }
          </Grid>
        </Grid>
      </Grid>


    </>
  )
}

export default IssueMain