import React from 'react'
import { Grid } from '@mui/material'
import TextComponentTop from './textcomponenttop'
import MaticMyers from './matricmyer'
import MaticKolbe from './profilecomp/matricskolbe'
import MatricsClifton from './matricsclifton'



export default function Metrics() {
  return (
    <>
    <Grid>
        <TextComponentTop/>
    </Grid>
    <Grid sx={{ margin: '10px 5px 5px 5px' }}>
        <MaticMyers/>
    </Grid>
    <Grid sx={{ margin: '10px 5px 5px 5px' }}>
        <MaticKolbe/>
    </Grid>
    <Grid sx={{ margin: '10px 5px 5px 5px' }}>
        <MatricsClifton/>
    </Grid>

    </>
  )
}
