import React,{useState} from 'react'
import { Grid } from '@mui/material'
import EostoolboxTopBar from '../components/eostoolbox/eostoolboxtopbar'
import CreateNewTool from '../components/eostoolbox/createnewtool'
import EOSArchive from '../components/eostoolbox/archivedtools'


export default function Eostoolbox() {
  const [showComponent, setShowComponent] = useState(true);
  const archiveHandle=()=>{
    setShowComponent(!showComponent)
  }

  return (
    <>
    <Grid>
    <EostoolboxTopBar handleArchive={archiveHandle}/>
    </Grid>
    <Grid sx={{marginRight:'15px', marginLeft:'15px'}}>
    {showComponent ?<CreateNewTool/> :<EOSArchive/>}
    
    
    </Grid>
    </>
  )
}
