import React, { useState } from 'react'
import { Card, TextField } from '@mui/material'
import Button from '@mui/material/Button';
import { FormControl } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import Tooltip from '@mui/material/Tooltip';
// import DialpadIcon from '@mui/icons-material/Dialpad';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InputAdornment from '@mui/material/InputAdornment';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReactQuill from 'react-quill';
// below are the icons for Add tool section
import WavingHandIcon from '@mui/icons-material/WavingHand';
import FeedIcon from '@mui/icons-material/Feed';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WcIcon from '@mui/icons-material/Wc';
import SchoolIcon from '@mui/icons-material/School';
import DomainVerificationRoundedIcon from '@mui/icons-material/DomainVerificationRounded';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TerrainIcon from '@mui/icons-material/Terrain';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function CustomMeeting() {
    // show or hide conclude card
    const [isconcludecard, setconcludecard] = useState(false)
    const concludeCardVisibility = () => {
        setconcludecard(true);
    }
    // storing the conclude inputfield value
    const [concludeSection, setconcludeSection] = useState("Conclude");
    const handleConcludeChange = (event) => {
        setconcludeSection(event.target.value)
    }
    const [concludeDuration, setconcludeDuration] = useState("5");
    const handleConcludeDurationChange = (event) => {
        setconcludeDuration(event.target.value)
    }
    // tool selection dropdown
    const [selectedTools, setSelectedTools] = useState([]);
    const [availableTools, setAvailableTools] = useState([
        { name: 'Segue™', icon: <WavingHandIcon /> },
        { name: "Scorecard", icon: <DomainVerificationRoundedIcon /> },
        { name: "Rocks", icon: <TerrainIcon /> },
        { name: 'Headlines', icon: <FeedIcon /> },
        { name: 'To-Dos', icon: <ListAltIcon /> },
        { name: 'Issues', icon: <PriorityHighIcon /> },
        { name: 'V/TO®', icon: <WcIcon /> },
        { name: 'V/TO® - Goals', icon: <WcIcon /> },
        { name: 'V/TO® - Long-Term', icon: <WcIcon /> },
        { name: 'V/TO® - SWOT', icon: <WcIcon /> },
        { name: 'EOS Toolbox™', icon: <SchoolIcon /> },
    ]);
    // sorting tools
    const handleToolSelection = (tool) => {
        // Add the selected tool to the list of selected tools
        setSelectedTools([...selectedTools, tool]);

        // Remove the selected tool from the available tools
        setAvailableTools(availableTools.filter((availableTool) => availableTool !== tool));
    };
    const handleDeleteTol = (index) => {
        // Create a copy of the selected tools array
        const updatedSelectedTools = [...selectedTools];

        // Remove the selected tool at the specified index
        const deletedTool = updatedSelectedTools.splice(index, 1)[0];

        // Update the state with the new array of selected tools
        setSelectedTools(updatedSelectedTools);

        // Update the state to add the deleted tool back to the available tools at the original index
        setAvailableTools((prevAvailableTools) => {
            const newAvailableTools = [...prevAvailableTools];

            // Use splice to insert the deleted tool at the original index
            newAvailableTools.splice(index, 0, deletedTool);

            return newAvailableTools;
        });
    };
    const handleToolInputChange = (index, field, value) => {
        const updatedTools = [...selectedTools];
        updatedTools[index][field] = value;
        setSelectedTools(updatedTools);
    };
    //  Card 2
    const handleCloseCard = () => {
        setconcludecard(false);
        setinputfieldcard(false);
        setselectiontoolCard(false);
    };
    // Text Area Field
    const toolbarOptions = [
        'bold',
        'italic',
        'underline',
        'strike',
        { 'header': [1, 2, 3] },
        'align',
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'script': 'sub' },
        { 'script': 'super' },
        { 'direction': 'rtl' },
        { 'size': ['small', false, 'large', 'huge'] },
        { 'color': [] },
        { 'background': [] },
        { 'font': [] },
        'blockquote',
        'code-block',
        'clean',
    ];

    const modules = {
        toolbar: [toolbarOptions],
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'ordered',
        'bullet',
        'sub',
        'super',
        'direction',
        'size',
        'color',
        'background',
        'font',
        'blockquote',
        'code-block',
        'clean',
    ];
    const [concludeText, setConcludeText] = useState('');
    const handleConcludeTextChange = (value) => {
        setConcludeText(value);
    };

    //demo
    const [sectionText, setSectionText] = useState('');
    const handleSectionTextChange = (value) => {
        setSectionText(value);
    };

    const [toolText, setToolText] = useState('');
    const handleToolTextChange = (value) => {
        setToolText(value);
    };

    //  card 3 below is rendering for opening
    const [isinputfieldcard, setinputfieldcard] = useState(false)
    //  creating a input section name for getting value
    const [inputSectionName, setinputSectionName] = useState('')
    // dynamic section Name
    const [inputfield, setinputfield] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);

    const dynamicallyAddInput = () => {
        setinputfield([...inputfield, { sectionName: "", duration: "" }]);
        setEditingIndex(null);
        setinputfieldcard(true)
    };
    // for dynamic input field model
    const handlechange = (index, name, value) => {
        const onChangeVal = [...inputfield];
        onChangeVal[index][name] = value;
        setinputfield(onChangeVal);
    };

    const handleDelete = (index) => {
        const deleteVal = [...inputfield];
        deleteVal.splice(index, 1);
        setinputfield(deleteVal);
        //  for closing card 2 also
        setinputfieldcard(false)
        // for closing card 4
        setselectiontoolCard(false)
    };
    const handleCustomSectionEdit = (index) => {
        setEditingIndex(index);

        // Get the sectionName of the item being edited
        const sectionNameToEdit = inputfield[index].sectionName;

        // Set the SectionName state with the sectionName of the item being edited
        setinputSectionName(sectionNameToEdit);

        // Open the card for editing
        setinputfieldcard(true)
    };

    const [focusedInputIndex, setFocusedInputIndex] = useState(null);
    const handleSectionName = (index, field, value) => {
        // Update the sectionName for the currently focused input field
        if (index === focusedInputIndex && field === 'sectionName') {
            const updatedInputField = [...inputfield];
            updatedInputField[index].sectionName = value;
            setinputfield(updatedInputField);
            setinputSectionName(value);
        }
    };
    // For checking the valid url 
    const [url, setUrl] = useState('');
    const [isValid, setIsValid] = useState(null);

    const validateUrl = (value) => {
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlPattern.test(value);
    };

    const handleUrlCheck = () => {
        // Perform URL validation logic or API calls here
        const isValidUrl = validateUrl(url);
        console.log('URL:', url);
        console.log('isValidUrl:', isValidUrl);

        setIsValid(isValidUrl);

        // Provide user feedback
        if (isValidUrl) {
            alert('URL is valid!');
        } else {
            alert('Invalid URL. Please enter a valid URL.');
        }
    };
    // warning label to show or hide div
    const [isVisible, setIsVisible] = useState(false);

    const toggleWarningDiv = () => {
        setIsVisible(!isVisible);
    };
    // card 4 selection tool
    const [selectiontoolCard, setselectiontoolCard] = useState(false);
    const handleSelectionToolEdit = (index) => {
        // Check if the section name is 'Sege' or 'Headlines'
        const selectedTool = selectedTools[index];
        if (selectedTool && (selectedTool.name === 'Segue™' || selectedTool.name === 'Headlines')) {
            console.log('Opening selection tool card.');
            setselectiontoolCard(true);
        }
    }
    // Total Duration including conclude duration + inputfield duration + 
    const calculateTotalDuration = () => {
        const inputfieldTotalDuration = inputfield.reduce((total, value) => total + parseInt(value.duration, 10) || 0, 0);
        const selectedToolduration = selectedTools.reduce((total, value) => total + parseInt(value.duration, 10) || 0, 0);

        // Convert concludeDuration to a number before using it in calculations
        const concludeDurationInSeconds = parseInt(concludeDuration, 10) || 0;

        const totalDurationInSeconds = concludeDurationInSeconds + inputfieldTotalDuration + selectedToolduration;
        const hours = Math.floor(totalDurationInSeconds / 3600);
        const seconds = Math.floor((totalDurationInSeconds % 3600) / 60);
        const minutes = totalDurationInSeconds % 60;

        return {
            hours,
            minutes,
            seconds
        };
    };
    // Call the function to get the total duration
    const totalDuration = calculateTotalDuration();

    //input values
    const [customMeetingName, setCustomMeetingName] = useState('');
    const [concludeSubtitle, setConcludeSubtitle] = useState('');
    const [toolSubtitle, setToolSubtitle] = useState('');
    const handleGetFieldValues = () => {
        const inputFieldValues = inputfield.map(({ sectionName, duration }) => ({ name: sectionName, duration, url }));
        const selectedToolValues = selectedTools.map(({ name, duration }) => ({ name, duration, subtitle: toolSubtitle, toolText }));

        const sections = inputFieldValues.map(section => ({ ...section, sectionText }));

        const values = {
            name: customMeetingName,
            sections: [
                {
                    CustomSections: sections,
                    SelectedTools: selectedToolValues,
                    ConcludeSection: { name: concludeSection, duration: concludeDuration, subtitle: concludeSubtitle, concludeText },
                }]
        };
        console.log(customMeetingName, values);
        const config = {
            headers: { Authorization: `Bearer ${Cookies.get('token')}` }
        };
        axios.post(`${process.env.REACT_APP_API_URL}meetings/create`, values, config)
            .then(response => {
                console.log(response.data);
            }).catch(error => {
                console.error('Error:', error);
            })
    };



    return (
        <>
            <div className=' d-flex flex-row container-fluid' style={{ overflowY: "auto" }}>
                <div className=''>
                    <Card className=' mt-2 p-2' style={{ width: "1020px", position: 'relative', height: "auto" }}>
                        <FormControl className='px-2 pt-2'>
                            <div>
                                {/* <TextField size='small' id="outlined-basic" name="customMeetingName" label="Custom Meeting Name" variant="outlined" style={{ width: "850px" }} required /> */}
                                <TextField
                                    size='small'
                                    id="outlined-basic"
                                    name="customMeetingName"
                                    label="Custom Meeting Name"
                                    variant="outlined"
                                    style={{ width: "850px" }}
                                    required
                                    value={customMeetingName}
                                    onChange={(e) => setCustomMeetingName(e.target.value)}
                                />
                                <p className='pt-1'>See More Options to push these agenda changes to all existing teams.</p>
                                <div className='d-flex flex-row'>
                                    <h6 style={{ paddingLeft: "70px" }}>Section Name</h6>
                                    <h6 style={{ paddingLeft: "410px" }}>Duration (minutes)</h6>
                                    <h6 className='ps-5'>Visible</h6>
                                </div>
                                {/* Render dynamic Section Name fields */}
                                <div>
                                    {inputfield.map((value, index) => (
                                        <div className="d-flex flex-row mt-2 align-items-center" key={index}>
                                            <Tooltip title='Drag from here' style={{ marginLeft: "30px" }}>
                                                <DragIndicatorIcon className='mt-2 d-flex flex-row align-items-center' />
                                            </Tooltip>
                                            {/* ... Input fields here */}
                                            <input
                                                name="sectionName"
                                                className="mt-2 ms-3"
                                                style={{ height: "30px", width: "480px", borderRadius: "6px", borderWidth: "2px", borderColor: "red" }}
                                                type="text"
                                                value={inputfield[index].inputSectionName}
                                                onChange={(e) => {
                                                    handleSectionName(index, 'sectionName', e.target.value);
                                                }}
                                                onFocus={() => setFocusedInputIndex(index)}
                                                onBlur={() => setFocusedInputIndex(null)}
                                            />
                                            <input
                                                name="Duration"
                                                style={{ height: "30px", width: "100px", borderRadius: "6px", borderWidth: "1px", marginLeft: "20px" }}
                                                type="number"
                                                className="mt-2"
                                                value={value.duration}
                                                onChange={(e) => handlechange(index, 'duration', e.target.value)}
                                            />
                                            {/* ... Icons Here */}
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10px" }}>
                                                <Tooltip title='Delete'>
                                                    <DeleteIcon onClick={handleDelete} style={{ marginLeft: "45px", paddingTop: "2px", top: "10%" }} />
                                                </Tooltip>
                                                <Tooltip title={editingIndex === index ? 'Save' : 'Edit'} className='ms-4'>
                                                    <EditIcon onClick={() => handleCustomSectionEdit(index)} />
                                                </Tooltip>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                {/* tool selection render Below */}
                                <div>
                                    {selectedTools.map((selectedTool, index) => (
                                        <div key={index}>
                                            <div className='d-flex flex-row ms-3 mt-2 align-items-center'>
                                                <div className='pt-2 mx-3'>
                                                    <DragIndicatorIcon className='d-flex justify-content-center align-items-center' />
                                                </div>
                                                <input
                                                    type="text"
                                                    id={`toolInput-${index}`}
                                                    name={`toolInput-${index}`}
                                                    color="primary"
                                                    value={selectedTool.name || ''}
                                                    style={{ height: '30px', width: '480px', borderRadius: '4px', borderWidth: '1px' }}
                                                    className='mt-2'
                                                    placeholder='Section Name' onChange={(e) => handleToolInputChange(index, 'name', e.target.value)}
                                                />
                                                <input
                                                    type="number"
                                                    style={{ height: '30px', width: '100px', borderRadius: '6px', borderWidth: '1px' }}
                                                    className='mt-2 ms-4'
                                                    placeholder='Duration'
                                                    value={selectedTool.duration || ''} onChange={(e) => handleToolInputChange(index, 'duration', e.target.value)}
                                                />
                                                <Tooltip title='Delete'>
                                                    <DeleteIcon onClick={handleDeleteTol} style={{ marginLeft: "40px", top: "10%" }} />
                                                </Tooltip>
                                                {selectedTool.name === 'Segue™' || selectedTool.name === 'Headlines' ? (
                                                    <Tooltip title='Edit'>
                                                        <EditIcon onClick={() => handleSelectionToolEdit(index)} style={{ marginLeft: "30px" }} />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title='Lock'>
                                                        <LockIcon style={{ marginLeft: "30px", paddingTop: "2px", top: "10%" }} />
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='mt-2 d-flex flex-row align-items-center' >
                                    <input
                                        type="text"
                                        color="primary"
                                        value={concludeSection}
                                        style={{ marginLeft: "70px", height: '30px', width: '480px', borderRadius: '4px', borderWidth: '1px' }}
                                        className='mt-2' onChange={handleConcludeChange}
                                    />
                                    <input
                                        type="number"
                                        value={concludeDuration}
                                        style={{ height: '30px', width: '100px', borderRadius: '6px', borderWidth: '1px' }}
                                        className='mt-2 ms-4' onChange={handleConcludeDurationChange}
                                    />

                                    <Tooltip title="Edit section" >
                                        <EditIcon style={{ marginLeft: "95px" }} onClick={concludeCardVisibility} />
                                    </Tooltip>
                                </div>
                            </div>
                        </FormControl>
                        {/* button for creating the input field */}
                        <div className='d-flex flex-row mt-3 mx-5'>
                            <button type='text' className='btn' style={{ border: "none", color: "#1993c6" }} onClick={dynamicallyAddInput}>
                                <AddIcon />  Add Custom Section
                            </button>
                            {/* 2nd Button  */}
                            <div className="dropdown dropup d-flex flex-row">
                                <button className="btn dropdown-toggle mx-2" type="text" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: "none" }}>
                                    <AddIcon />  Add Tool Section
                                </button>
                                <ul className="dropdown-menu" style={{ maxHeight: "200px", height: "600px", overflowY: "scroll" }}>
                                    {availableTools.map((tool, index) => (
                                        <li key={index}>
                                            <a
                                                className="dropdown-item"
                                                href="##"
                                                onClick={() => handleToolSelection(tool)}
                                            >
                                                {tool.icon} {tool.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='d-flex flex-row align-items-center' style={{ paddingLeft: "50px" }}>Total Duration:
                                <div className='ps-3' style={{ fontWeight: "bolder" }}>
                                    {`${totalDuration.hours} hours, ${totalDuration.minutes} minutes, ${totalDuration.seconds} seconds`}
                                </div>
                                <Button
                                    className="ms-4 px-3"
                                    variant="outlined"
                                    startIcon={<SaveIcon />}
                                    color="primary"
                                    style={{ borderColor: '#0000008a', color: '#0000008a', fontSize: '12px', fontWeight: '200', fontFamily: 'Nunito Sans, sans-serif', textTransform: 'capitalize' }}
                                    onClick={handleGetFieldValues}
                                >
                                    {/* marginRight: '30px' */}
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Card>

                </div>
                {/* card 2 below is Rendering */}
                {isconcludecard && (
                    <Card className='ms-2 mt-2 container-fluid p-2' style={{ width: "350px", height: "290px" }}>
                        <div>
                            {/* for close icon and textsection Name*/}
                            <div className="d-flex flex-row">
                                <div>
                                    <Tooltip title={'Close Detail'} onClick={handleCloseCard}>
                                        <KeyboardArrowRightIcon />
                                    </Tooltip>
                                </div>
                                <span style={{ borderLeft: "1px solid grey", height: "36px" }}>

                                </span>
                                <span className='ps-3'>
                                    {concludeSection}
                                </span>
                            </div>
                            {/* input field for url checker */}
                            <div className='ms-4 my-3'>
                                <TextField
                                    style={{ width: "290px" }}
                                    name="concludeSubtitle"
                                    id="outlined-basic"
                                    label="Subtitle"
                                    variant="outlined"
                                    size="small"
                                    placeholder='Subtitle'
                                    value={concludeSubtitle}
                                    onChange={(e) => setConcludeSubtitle(e.target.value)}
                                />
                            </div>
                            <div className='ms-4'>
                                <ReactQuill
                                    value={concludeText}
                                    onChange={handleConcludeTextChange}
                                    modules={modules}
                                    formats={formats}
                                    placeholder={'Description'}
                                    theme="snow"
                                    style={{
                                        width: "90%",
                                        height: '8vh', // Adjust the height as needed
                                        fontSize: '18px', // Adjust the font size as needed
                                    }}
                                />
                            </div>

                        </div>

                    </Card>
                )}
                {/* card 3 rendering below */}
                {isinputfieldcard && (
                    <Card className='ms-2 mt-2' style={{ width: "350px", height: isVisible ? '86vh' : '50vh' }}>
                        <div className='ms-2 mt-2'>
                            {/* for close icon and textsection Name*/}
                            <div className="d-flex flex-row">
                                <div>
                                    <Tooltip title={'Close Detail'} onClick={handleCloseCard}>
                                        <KeyboardArrowRightIcon />
                                    </Tooltip>
                                </div>
                                <span style={{ borderLeft: "1px solid grey", height: "36px" }}>

                                </span>
                                <span className='ps-3'>
                                    {inputSectionName}
                                </span>
                            </div>
                            {/* input field for url checker */}
                            <div className='ms-3 my-2 '>
                                <TextField
                                    id="outlined-basic"
                                    label="Web page URL to load"
                                    variant="outlined"
                                    size="small"
                                    placeholder='https://www.ninety.io'
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    style={{ width: '85%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button variant='text' onClick={handleUrlCheck}>
                                                    Check URL
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <ReportProblemIcon className='ms-2' style={{ color: "#1993c6", paddingTop: "3px" }} onClick={toggleWarningDiv} />
                                {isVisible && (
                                    <div className='mt-3' style={{ backgroundColor: "yellow", width: "70%" }}>
                                        *** Some websites block the ability to be loaded into an iframe on a different domain. Please make sure your page is visible before saving. If you are unable to see the page, double check the URL. Then see if there is an iframe friendly link.
                                    </div>
                                )}
                            </div>
                            <div className='ms-3 '>
                                <ReactQuill
                                    value={sectionText}
                                    onChange={handleSectionTextChange}
                                    modules={modules}
                                    formats={formats}
                                    placeholder={'Description'}
                                    theme="snow"
                                    style={{
                                        width: "90%",
                                        height: '14vh', // Adjust the height as needed
                                        fontSize: '18px', // Adjust the font size as needed
                                    }}
                                />
                            </div>


                        </div>
                    </Card>
                )}
                {selectiontoolCard && (
                    <Card className='ms-2 mt-2 container-fluid' style={{ width: "350px", height: isVisible ? '86vh' : '50vh' }}>
                        <div>
                            {/* for close icon and textsection Name*/}
                            <div className="d-flex flex-row">
                                <div>
                                    <Tooltip title={'Close Detail'} onClick={handleCloseCard}>
                                        <KeyboardArrowRightIcon />
                                    </Tooltip>
                                </div>
                                <span style={{ borderLeft: "1px solid grey", height: "36px" }}>

                                </span>
                                <span className='ps-3'>
                                    {concludeSection}
                                </span>
                            </div>
                            {/* input field for url checker */}
                            <div className='ms-4 my-3'>


                                <TextField
                                    style={{ width: "290px" }}
                                    name="toolSubtitle"
                                    id="outlined-basic"
                                    label="Subtitle"
                                    variant="outlined"
                                    size="small"
                                    placeholder='Subtitle'
                                    value={toolSubtitle}
                                    onChange={(e) => setToolSubtitle(e.target.value)}
                                />
                            </div>
                            <div className='ms-4'>
                                <ReactQuill
                                    value={toolText}
                                    onChange={handleToolTextChange}
                                    modules={modules}
                                    formats={formats}
                                    placeholder={'Description'}
                                    theme="snow"
                                    style={{
                                        width: "90%",
                                        height: '8vh', // Adjust the height as needed
                                        fontSize: '18px', // Adjust the font size as needed
                                    }}
                                />
                            </div>

                        </div>

                    </Card>
                )}
            </div>
        </>
    )
}
