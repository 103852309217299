import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Personalinformation from './personalinformation';
import ImageUploader from './profileimage';
import TextEditor from './biography';
import AddressForm from './addressform';
import AddContactButton from './addcontact';

export default function PersonalComponent() {
  return (
    <>
      <Grid sx={{ border: '1px', borderRadius: '5PX', bgcolor: '#ffffff', marginTop: '7px', marginRight: '3px', marginLeft: '3px', boxShadow: '0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f', }}>
        <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400', padding: '16px' }}>
          Information entered in this section will be visible across all of your Companies.
        </Typography>
      </Grid>
      <Grid sx={{ bgcolor: '#ffffff', border: '1px', borderRadius: '4PX', margin: '10px 5px 5px 5px', padding: '16px 32px 26px 32px', boxShadow: '0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f' }}>
        <div className="container-fluid mt-3">
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500', marginLeft: '-27px', marginBottom: '15px' }}>
              Personal Information
            </Typography>
          </Grid>
          <div className='row'>
            <div className='col-sm-3'>
              <ImageUploader />
            </div>
            <div className='col-sm-9'>
              <item><Personalinformation /></item>
            </div>
          </div>
        </div>
      </Grid>
      <Grid sx={{ margin: '10px 5px 5px 5px' }}>
        <TextEditor />
      </Grid>
      <Grid sx={{ margin: '10px 5px 5px 5px' }}>
        <AddressForm />
      </Grid>
      <Grid sx={{ border: '1px', borderRadius: '4px', bgcolor: '#ffffff', margin: '10px 5px 5px 5px', boxShadow: '0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f', }}>
        <AddContactButton />
      </Grid>
    </>
  )
}
