import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Typography,
} from '@mui/material';

function MaticKolbe() {
  const [factFinder, setFactFinder] = useState('');
  const [followThru, setFollowThru] = useState('');
  const [quickStart, setQuickStart] = useState('');
  const [implementer, setImplementer] = useState('');

  const handleFactFinderChange = (event) => {
    setFactFinder(event.target.value);
  };

  const handleFollowThruChange = (event) => {
    setFollowThru(event.target.value);
  };

  const handleQuickStartChange = (event) => {
    setQuickStart(event.target.value);
  };

  const handleImplementerChange = (event) => {
    setImplementer(event.target.value);
  };

  return (
    <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Kolbe System™
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            The Kolbe System measures a person's conation – how they take action. The Kolbe assessment provides actionable advice on personal productivity, team performance, job alignment, and hiring.
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Fact Finder</label>
            {/* <InputLabel id="fact-finder-label">Fact Finder</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={factFinder}
              onChange={handleFactFinderChange}
              size="small"
            // label="Fact Finder"
            >
              {[...Array(10).keys()].map((number) => (
                <MenuItem key={number + 1} value={number + 1}>{number + 1}</MenuItem>
              ))}
              {/* <MenuItem value="Fact Finder Type 1">Fact Finder Type 1</MenuItem>
              <MenuItem value="Fact Finder Type 2">Fact Finder Type 2</MenuItem> */}
              {/* Add more Fact Finder types as needed */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Follow Thru</label>
            {/* <InputLabel id="follow-thru-label">Follow Thru</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size='small'
              value={followThru}
              onChange={handleFollowThruChange}
            >
              {[...Array(10).keys()].map((number) => (
                <MenuItem key={number + 1} value={number + 1}>{number + 1}</MenuItem>
              ))}
              {/* Add more Follow Thru types as needed */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Quick Start</label>
            {/* <InputLabel id="quick-start-label">Quick Start</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size='small'
              value={quickStart}
              onChange={handleQuickStartChange}
            >
              {[...Array(10).keys()].map((number) => (
                <MenuItem key={number + 1} value={number + 1}>{number + 1}</MenuItem>
              ))}
              {/* Add more Quick Start types as needed */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Implementer</label>
            {/* <InputLabel id="implementer-label">Implementer</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size='small'
              value={implementer}
              onChange={handleImplementerChange}
            >
               {[...Array(10).keys()].map((number) => (
                <MenuItem key={number + 1} value={number + 1}>{number + 1}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default MaticKolbe;
