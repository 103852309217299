import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setEmail, selectEmail } from '../../store/slices/userSlice/createUserSlice';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" {...props}>
            By continuing, you agree to the {' '}
            <Link className="text-decoration-none" color="inherit" href="#">
                Terms and conditions
            </Link>{' and'}{' '}
            <Link className="text-decoration-none" color="inherit" href="#">
                Privacy Policy
            </Link>

        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Signup() {

    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    const dispatch = useDispatch();
    const email = useSelector(state => state.email.email);
  
    const handleEmailChange = (e) => {
      const newEmail = e.target.value;
      dispatch(setEmail(newEmail));
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                <Grid item xs={12} sm={12} md={6} component={Paper} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            //   alignItems: 'center',
                        }}
                    >
                        {/* <Box>
                            <img
                                alt="profile-user"
                                //   width="220px"
                                //   height="220px"
                                src={`WhatsApp Image 2023-09-26 at 06.09.09.jpg`}
                                style={{ cursor: "pointer", marginBottom: "50px" }}
                            />
                        </Box> */}
                        <Typography component="h1" variant="h1" fontSize={'30px'} fontWeight={'800'} sx={{ marginBottom: '30px' }}>
                            Welcome to ICTM
                        </Typography>
                        <Typography component="h1" variant="h1" fontSize={'25px'} fontWeight={'800'} sx={{ marginBottom: '30px', color: '#1993c6' }}>
                            Let's get that 30 day free trial rolling.
                        </Typography>
                        <Typography component="h1" variant="h1" fontSize={'14px'} fontWeight={'400'} sx={{ marginBottom: '30px' }}>
                            <b>No credit card. No contract. All of the features.</b> <br />
                            Set up your account in just a few simple steps.
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Work Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={handleEmailChange}
                            />
                            <Copyright sx={{ mt: 2 }} />
                            <Button
                                onClick={() => {
                                    navigate('/login/sign-up/create-account');
                                }}
                                // type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, p: 2, borderRadius: '40px', backgroundColor: '#1993c6' }}
                            >
                                Let's Go
                            </Button>
                            <Divider>OR</Divider>

                            <Button
                                startIcon={<FcGoogle />}
                                // type="submit"

                                fullWidth
                                variant="outlined"
                                sx={{ mt: 3, mb: 2, p: 2, borderRadius: '40px', borderColor: '#1993c6', color: '#1993c6' }}
                            >
                                Signup with Google
                            </Button>
                            <Box
                                sx={{
                                    marginTop: '40px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >   {"Already have an account? "}

                                <Link className="text-decoration-none" to={'/login'} variant="body2" >
                                    <Typography sx={{ textDecoration: 'none', fontWeight: '600', color: '#1993c6' }}>LOG IN</Typography>
                                </Link>
                            </Box>


                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={6}
                    sx={{
                        backgroundImage: 'url(login_img.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Grid>
        </ThemeProvider>
    );
}
