import React, { useState } from 'react'
import HeadlinesTable from './headlinestable'
import CascadingMessages from './cascadingmessage'
import { Grid } from '@mui/material'

export default function HeadlinesPage() {
  const [isEditCardOpen, setIsEditCardOpen] = useState(true);

  const handleEditCardOpen = () => {
    setIsEditCardOpen(true);
  };

  // const handleEditCardClose = () => {
  //   setIsEditCardOpen(false);
  // };
  return (
    <>
      <div className='w-100 h-100'>
        {isEditCardOpen && (
          <>
            <Grid item xs={isEditCardOpen ? 12 : 6}>
              <HeadlinesTable handleEditCardOpen={handleEditCardOpen} />
              <CascadingMessages handleEditCardOpen={handleEditCardOpen} />
            </Grid>
            {/* <Grid item xs={isEditCardOpen ? 6 : 12} className={`w-auto ${isEditCardOpen ? 6 : 12}`}>
              
            </Grid> */}
          </>
        )}

      </div>
    </>
  )
}