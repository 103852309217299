import React from 'react'
import VisionTabs from '../../components/vto/vtotabs'
import { Grid } from '@mui/material'

const VTO = () => {
  return (
    <>
    <Grid>
      <VisionTabs />
    </Grid>
    </>
  )
}

export default VTO