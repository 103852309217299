import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'; // Import Button from Material-UI
import SaveIcon from '@mui/icons-material/Save';

function AppBarPreferences() {
  const handleSaveClick = () => {
    // Handle the save button click event here
    // You can add your save logic in this function
    console.log('Save button clicked');
  };

  return (
    <div>
      <AppBar position="static" color="default" sx={{bgcolor:'#ffffff', height:'65px'}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* Empty left side */}
          </Typography>
          <Button
            variant="contained"
            color="primary" // Background color
            startIcon={<SaveIcon />} // Icon on the left side
            onClick={handleSaveClick}
          >
            Save Changes
          </Button>
        </Toolbar>
      </AppBar>

      {/* Your content goes here */}
      {/* Add your form fields or other content */}
    </div>
  );
}

export default AppBarPreferences;
