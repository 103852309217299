import { createSlice } from '@reduxjs/toolkit';

const emailSlice = createSlice({
  name: 'email',
  initialState: {
    email: '',
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState: {
    firstName: '',
    lastName: '',
    password: '',
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
  },
});

const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState: {
    companyName: '',
    industry: '',
    employee: '',
    os: '',
  },
  reducers: {
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
   },
    setIndustry: (state, action) => {
      state.industry = action.payload;
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
   },
    setOS: (state, action) => {
      state.os = action.payload;
    },
  },
});

export const {
  setEmail,
} = emailSlice.actions;

export const {
  setFirstName,
  setLastName,
  setPassword,
} = personalInfoSlice.actions;

export const {
  setCompanyName,
  setIndustry,
  setEmployee,
  setOS,
} = companyInfoSlice.actions;

export const selectEmail = (state) => state.email;
export const selectPersonalInfo = (state) => state.personalInfo;
export const selectCompanyInfo = (state) => state.companyInfo;

export default {
  email: emailSlice.reducer,
  personalInfo: personalInfoSlice.reducer,
  companyInfo: companyInfoSlice.reducer,
};
