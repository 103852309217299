import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Modal } from '@mui/material';
import { TextField, IconButton, Menu, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import NativeSelect from '@mui/material/NativeSelect';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ProvideFeedback = ({ onClose, open }) => {
  // const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // below is minimized icon code
  const [minimized, setMinimized] = React.useState(false);
  const toggleMinimized = () => {
    setMinimized(!minimized);
  };
  const [fullscreen, setFullscreen] = React.useState(false);
  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
    if (!fullscreen) {
      // If exiting fullscreen, reset minimized state
      setMinimized(false);
    }
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <>
{/* <Button onClick={handleOpen}>modal</Button> */}
      {/*onClick={handleOpen}*/}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={true}
      >
        <Box
          sx={{
            position: fullscreen ? 'fixed' : 'absolute',
            top: minimized ? '90%' : fullscreen ? '10%' : '18%',
            left: minimized ? '0%' : fullscreen ? '30%' : '0%',
            width: minimized ? '33%' : fullscreen ? '40%' : '400px',
            height: minimized ? '10%' : fullscreen ? '80%' : '100%',
            // bottom: minimized ? '1%' : fullscreen ? '80%' : '20%',
            bgcolor: fullscreen ? 'background.paper' : 'background.paper',
            boxShadow: " 0 -2px 4px #0000001a",
            p: 0
          }}
          className="ms-3 rounded-top-3"
        >

          <div style={{ backgroundColor: '#262626' }} className=" rounded-top-3 d-flex" >
            <h6 className="text-white ms-4 py-2 mt-1" style={{ fontFamily: "Poppins, sans-serif", fontSize: '14px', letterSpacing: '0.5px' }} >Feature Requests & Feedback</h6>
            <button style={{ marginLeft: '150px' }} onClick={handleClose} className="btn p-0 text-white"><CloseIcon style={{ fontSize: '16px' }} /></button>
          </div>

          <div className="bg-white d-flex pt-2">
            <Avatar style={{ backgroundColor: '#CFD8DC' }} className="ms-4">AN</Avatar>
            <div className="d-flex flex-column ms-3">
              <p style={{ fontSize: '14px' }} className="p-0 m-0">Admin Name</p>
              <p style={{ fontSize: '14px' }} className="p-0 m-0">admin@gmail.com</p>
            </div>
          </div>

          <div className="ms-4 mt-3" style={{ width: '150px' }} >
            <FormControl fullWidth variant="filled">
              <NativeSelect
                defaultValue={'Request a feature'}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native',
                }}
              >
                <option value={'Request a feature'}>Request a feature</option>
                <option value={'Report a problem'}>Report a problem</option>
              </NativeSelect>
            </FormControl>
          </div>

          <div className="mx-4 mt-3">
            <TextField placeholder="Enter a subject" size="small" id="outlined-basic" variant="outlined" fullWidth />
            <textarea placeholder="Share your thoughts here" class="form-control mt-3" id="exampleFormControlTextarea1" rows="5"></textarea>
          </div>

          <div className="justify-content-center align-items-center text-center mt-3">
            <button className="btn bg-black text-white px-4">Share feedback</button>
          </div>

        </Box>

      </Modal>

    </>
  )
};

export default ProvideFeedback;
