import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import { TextField, IconButton, Menu, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import NativeSelect from '@mui/material/NativeSelect';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import Textarea from '@mui/joy/Textarea';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import ExploreIcon from '@mui/icons-material/Explore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import HeadphonesIcon from '@mui/icons-material/Headphones';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const LearningSupport = ({ onClose, open }) => {
  // const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

    // below is minimized icon code
  const [minimized, setMinimized] = React.useState(false);
  const toggleMinimized = () => {
    setMinimized(!minimized);
  };
  const [fullscreen, setFullscreen] = React.useState(false);
  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
    if (!fullscreen) {
      // If exiting fullscreen, reset minimized state
      setMinimized(false);
    }
  };
  const handleClose = () => {
    console.log("button clicked")
    onClose();
  };

  return (
  <>

  {/*onClick={handleOpen}*/}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={true}
      >
        <Box
        sx={{
          position: fullscreen ? 'fixed' : 'absolute',
          top: minimized ? '90%' : fullscreen ? '10%' : '20%',
          left: minimized ? '0%' : fullscreen ? '30%' : '18%',
          width: minimized ? '33%' : fullscreen ? '40%' : '460px',
          height: minimized ? '10%' : fullscreen ? '80%' : '73%',
          // bottom: minimized ? '1%' : fullscreen ? '80%' : '20%',
          bgcolor: fullscreen ? 'background.paper' : 'background.paper',
          boxShadow:" 0 -2px 4px #0000001a",
          p: 0
        }}
        className="ms-3 rounded-3"
      >

      <div className="p-2">
   <div className='d-flex align-items-center justify-content-between'>
   <h6 style={{fontFamily: 'Poppins, sans-serif'}} className="fw-bold ms-2 mt-3">LEARNING</h6>
        {/* <button style={{ display:"flex",justifyContent:"flex-end" }} onClick={handleClose} className="btn p-0"><CloseIcon style={{ fontSize: '16px' }} /></button> */}
        <CloseIcon style={{ fontSize: '16px' }} onClick={handleClose} />
   </div>
        <div className="d-flex ms-2 mt-3">
          <ExploreIcon style={{fontSize: '35px', color: '#9B9B9B'}} />
          <div className="d-flex flex-column ms-3">
            <p style={{fontSize: '15px', fontFamily: 'Poppins, sans-serif'}} className="m-0 p-0">Products Updates</p>
            <p style={{color: '#737373', fontSize: '13px', fontFamily: 'Poppins, sans-serif'}} className="m-0 p-0">Learn about our latest products update.</p>
          </div>
        </div>
      </div>

      <div className="p-2">
        <h6 style={{fontFamily: 'Poppins, sans-serif'}} class="fw-bold ms-2 mt-3">SUPPORT</h6>

        <div className="d-flex ms-2 mt-3">
          <FontAwesomeIcon icon={faCommentDots} style={{fontSize: '34px', color: '#9B9B9B'}}/>
          <div className="d-flex flex-column ms-3">
            <p style={{fontSize: '15px', fontFamily: 'Poppins, sans-serif'}} className="m-0 p-0">Contact Support</p>
            <p style={{color: '#737373', fontSize: '13px', fontFamily: 'Poppins, sans-serif'}} className="m-0 p-0">Real-time chat support. Get answers to your questions right away.</p>
          </div>
        </div>

        <div className="d-flex ms-2 mt-3">
          <HelpOutlineRoundedIcon style={{fontSize: '35px', color: '#9B9B9B'}} />
          <div className="d-flex flex-column ms-3">
            <p style={{fontSize: '15px', fontFamily: 'Poppins, sans-serif'}} className="m-0 p-0">Help Center</p>
            <p style={{color: '#737373', fontSize: '13px', fontFamily: 'Poppins, sans-serif'}} className="m-0 p-0">Solution-driven articles to support your mastery of ICTM.</p>
          </div>
        </div>

        <div className="d-flex ms-2 my-3 ">
          <HeadphonesIcon style={{fontSize: '35px', color: '#9B9B9B'}} />
          <div className="d-flex flex-column ms-3">
            <p style={{fontSize: '15px', fontFamily: 'Poppins, sans-serif'}} className="m-0 p-0">Live Training</p>
            <p style={{color: '#737373', fontSize: '13px', fontFamily: 'Poppins, sans-serif'}} className="m-0 p-0">Sign up for a live training session with our ICTM products experts.</p>
          </div>
        </div>
      </div>



      </Box>

      </Modal>

  </>
  )
};

export default LearningSupport;
