import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import NativeSelect from '@mui/material/NativeSelect';


// import all forms here
import IssueForm from './issueform'
import ToDoForm from './toDoform'
import RockForm from './rock'
import HeadlineForm from './headlineform'
import CascadingMessaggeForm from './cascadingMesssageform';
const Create = ({ isOpen, onClose, setCheckData }) => {

  const [formType, setFormType] = useState();
  // Now we handelling the issue title change
  const renderSelectedForm = ({ setCheckData }) => {
    switch (formType) {
      case 'issue':
        return <IssueForm onIssueTitleChange={handleIssueTitleChange} onClose={onClose} />;
      case 'to-do':
        return <ToDoForm onIssueTitleChange={handleIssueTitleChange} setCheckData={setCheckData} onClose={onClose} />;
      case 'rock':
        return <RockForm onIssueTitleChange={handleIssueTitleChange} onClose={onClose} />;
      case 'headline': // New case for HeadlineForm
        return <HeadlineForm onIssueTitleChange={handleIssueTitleChange} onClose={onClose} />;
      case 'cascadingMessage': // New case for cascadingMessage
        return <CascadingMessaggeForm onIssueTitleChange={handleIssueTitleChange} onClose={onClose} />;
      default:
        return <IssueForm onIssueTitleChange={handleIssueTitleChange} />;
    }
  };
  // below is minimized icon code
  const [minimized, setMinimized] = useState(false);
  const toggleMinimized = () => {
    setMinimized(!minimized);
  };
  const [fullscreen, setFullscreen] = useState(false);
  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
    if (!fullscreen) {
      // If exiting fullscreen, reset minimized state
      setMinimized(false);
    }
  };


  // change code according to dropdown menu
  // const [formType, setFormType] = useState('issue');
  const handleFormTypeChange = (e) => {
    setFormType(e.target.value);
  };

  // handelling the create button functionality
  const [isCreateButtonDisabled, setCreateButtonDisabled] = useState(true);
  const handleIssueTitleChange = (isTitleEmpty) => {
    setCreateButtonDisabled(isTitleEmpty);
  };



  return (
    <Modal hideBackdrop={true} open={isOpen} onClose={onClose}>
      <Box
        sx={{
          // position: 'absolute',
          // top: '10%',
          // right: '0%',
          // width: 450,
          // height: "100%",
          // bgcolor: 'background.paper',
          // borderTop: "5px solid #2596be",
          // backgroundColor:"red",
          position: fullscreen ? 'fixed' : 'absolute',
          top: minimized ? '90%' : fullscreen ? '10%' : '8%',
          right: minimized ? '0%' : fullscreen ? '30%' : '0%',
          width: minimized ? '33%' : fullscreen ? '40%' : '540px',
          height: minimized ? '10%' : fullscreen ? '80%' : '100%',
          // bottom: minimized ? '1%' : fullscreen ? '80%' : '20%',
          bgcolor: fullscreen ? 'background.paper' : 'background.paper',
          borderTop: fullscreen ? "5px solid #2596be" : "5px solid #2596be",
          boxShadow: " 0 -2px 4px #0000001a",
          p: 0
          // Main code
          // position: fullscreen ? 'fixed' : 'absolute',
          // top: minimized ? '0%' : '10%',
          // right: minimized ? '0%' : fullscreen ? '0%' : '20%',
          // width: minimized ? '0%' : fullscreen ? '100%' : '450px',
          // height: minimized ? '0%' : fullscreen ? '100%' : '100%',
          // p: 0,




          // position: fullscreen ? 'fixed' : 'absolute',
          // top: minimized ? '0%' : '10%',
          // right: minimized ? '0%' : fullscreen ? '0%' : '20%',
          // width: minimized ? '0%' : fullscreen ? '100%' : '450px',
          // height: minimized ? '0%' : fullscreen ? '100%' : '100%',
          //bgcolor: 'background.paper',
          // backgroundColor:"red",
        }}
      >
        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "60px" }}>
          <div className='p-2 d-flex flex-row'>
            <div>
              <Typography variant="h3" component="h1">Create</Typography>
            </div>
            <div>
              {/* {!minimized && (
                <select
                  style={{ marginLeft: "20px", color: "#2596be", fontSize: "18px" }}
                  defaultValue={'issue'}
                  onChange={handleFormTypeChange}
                  InputProps={{ readOnly: minimized }}
                >
                  <option value={'issue'}>Issue</option>
                  <option value={'to-do'}>To-Do</option>
                  <option value={'rock'}>Rock</option>
                  <option value={'headline'}>Headline</option>
                  <option value={'cascadingMessage'}>Cascading Message</option>
                </select>
              )} */}
              {/* {!minimized && (
                <TextField
                  select
                  style={{ marginLeft: '20px', color: '#2596be', fontSize: '18px' }}
                  defaultValue={'issue'}
                  onChange={handleFormTypeChange}
                  InputProps={{ readOnly: minimized }}
                >
                  <MenuItem value={'issue'}>Issue</MenuItem>
                  <MenuItem value={'to-do'}>To-Do</MenuItem>
                  <MenuItem value={'rock'}>Rock</MenuItem>
                  <MenuItem value={'headline'}>Headline</MenuItem>
                  <MenuItem value={'cascadingMessage'}>Cascading Message</MenuItem>
                </TextField>
              )} */}
              <NativeSelect
                style={{ marginLeft: "20px", color: "#2596be", fontSize: "18px" }}
                defaultValue={'issue'}
                onChange={handleFormTypeChange}
                disabled={minimized}
                inputProps={{ readOnly: minimized }}
              >
                <option value={'issue'}>Issue</option>
                <option value={'to-do'}>To-Do</option>
                <option value={'rock'}>Rock</option>
                <option value={'headline'}>Headline</option>
                <option value={'cascadingMessage'}>Cascading Message</option>
              </NativeSelect>

            </div>
          </div>
          <div className='p-2'>
            <IconButton onClick={toggleMinimized}>
              <MinimizeIcon />
            </IconButton>
            <IconButton onClick={toggleFullscreen}>
              {fullscreen && !minimized ? (
                <CloseFullscreenIcon />
              ) : (
                <FullscreenIcon />
              )}
            </IconButton>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
        <Card className='container-fluid' sx={{ minWidth: 275, height: "73%", marginTop: "2px", overflowY: "auto", boxShadow: " 3px 5px 10px rgba(0, 0, 0, 0.2)" }}>
          {/* {formType === 'issue' ? <IssueForm /> : formType === 'to-do' ? <ToDoForm /> : <RockForm />} */}
          {renderSelectedForm({ setCheckData })}
        </Card>
        <div className='container-fluid d-flex flex-row justify-content-center mt-2'>
          {/* <Button style={{ color: "white", backgroundColor: "#2596be", width: "60%" }} disabled={isCreateButtonDisabled}>Create Issue</Button> */}
          <Button style={{ width: "89%", backgroundColor: "#fff", boxShadow: " 3px 5px 10px rgba(0.8, 0.8, 0, 0.2)" }} onClick={onClose}>Cancel</Button>
        </div>
      </Box>
    </Modal>
  );
};

export default Create;
