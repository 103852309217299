import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
const MeetinglevelModel=[
    // 1
    {
        id:1,
        inputtextname:'To-Do List',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label="This default section cannot be edited" />,
        EditIcon:"",
    },
    // 2
    {
        id:2,
        inputtextname:'SCORECARD',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label="This default section cannot be edited"/>,
        EditIcon:"",

    },
    // 3
    {
        id:3,
        inputtextname:'ROCK REVIEW',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label="This default section cannot be edited"/>,
        EditIcon:"",

    },
    // 4
    {
        id:4,
        inputtextname:'IDS™',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label="This default section cannot be edited"/>,
        EditIcon:"",

    }, 
      // 5
      {
        id:5,
        inputtextname:'SEGUE',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        EditIcon:<EditIcon label='Edit section'/>,

    },
    // 6
    {
        id:6,
        inputtextname:'CUSTOMER / EMPLOYEE HEADLINES',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        EditIcon:<EditIcon label='Edit section' />,

    }, 
    // 7
    {
        id:7,
        inputtextname:'CONCLUDE',
        duration:5,
        lockIcon:<LockIcon label="This default section cannot be edited"/>,
        EditIcon:"",
    }
]
export default MeetinglevelModel;