import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

function SecondaryBar({editComponent}) {


  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: '#2596be' }}>
        <Toolbar>
          <Typography variant="p" component="div" sx={{ flexGrow: 1, fontSize: '16px' }}>
            <span style={{ color: '#ffffff91', marginRight: '8px' }}>FILTERS</span> {/* Add the "Filter" text here */}
            <label htmlFor="owner-select">Team:</label>
            <Select
              variant="standard"
              id="owner-select"
              label="Teams"
              sx={{
                icon: {
                  fill: 'white', // Change the color to white
                },
                marginRight: '16px',
                marginLeft: '10px',
                width: '200px',
                '&:before': {
                  borderColor: 'white',
                },
                '&:after': {
                  borderColor: 'white',
                },
                color: 'white',
              }}
              defaultValue={1}
            >
              <MenuItem value={1}>All</MenuItem>
              <MenuItem value={2}>Dummy Team</MenuItem>
              <MenuItem value={3}>My Team</MenuItem>
            </Select>
          </Typography>

          <Button sx={{ color: 'white'}} onClick={editComponent} startIcon={<ModeEditOutlinedIcon />}>
            Edit Layout
          </Button>

        </Toolbar>
      </AppBar>

    </div>
  );
}

export default SecondaryBar;