import React from 'react';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import BarChartIcon from '@mui/icons-material/BarChart';
import { TextField, IconButton, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faChartLine, faCirclePlus, faUpRightFromSquare, faAngleRight, faChevronRight, faList, faUserPlus, faExclamation, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios'
import Cookies from 'js-cookie'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Avatar from '@mui/material/Avatar';

const Monthly = () => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // measurables
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: '200px',
    bgcolor: 'background.paper',
    p: 3,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleCloses = () => setOpen(false);

  // table

  // const columns = [
  //   { id: 'name', label: 'Title', minWidth: 170 },
  //    {
  //     id: 'goal',
  //     label: 'Goal',
  //     minWidth: 170,
  //     align: 'right',
  //   },
  // ];

  function createData(name, goal) {
    return { name, goal };
  }

  const rows = [
    createData('Revenue', 'TBD'),
    createData('Revenue TTM', 'TBD'),
    createData('Russia', 'TBD'),
    createData('Nigeria', 'TBD'),

  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // quill

  const [value, setValue] = React.useState('');
  var toolbarOptions = [
    ['bold', 'italic'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'direction': 'rtl' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  // offcanvas text-field

  // unit
  const [unit, setUnit] = React.useState('');

  const handleChange = (event) => {
    setUnit(event.target.value);
  };

  // orientation
  const [orientation, setOrientation] = React.useState('');

  const handleOrientationChange = (event) => {
    setOrientation(event.target.value);
  };

  // t4w calculations
  const [t4w, setT4w] = React.useState('');

  const handleT4WChange = (event) => {
    setT4w(event.target.value);
  };

  // owner change
  const [measurableOwner, setmeasurableOwner] = React.useState('');

  const handleOwnerChange = (event) => {
    setmeasurableOwner(event.target.value);
  };

  // seats
  const [seat, setSeat] = React.useState('');

  const handleSeatChange = (event) => {
    setSeat(event.target.value);
  };

  const [text, setText] = React.useState('');

  const handleTextChange = (value) => {
    setText(value);
  };

  //inputvaluestoconsole

  const [inputValues, setInputValues] = React.useState({
    title: '',
    description: '',
    unit: '',
    goal: '',
    goalOrientation: '',
    t4w: '',
    owner: '',
    seat: '',
  });

  const handleInputChange = (e, selectName) => {
    const { name, value } = e.target;

    if (selectName) {
      // Handle the Select separately
      setInputValues({
        ...inputValues,
        [selectName]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    }
  };
  const emptyInputValues = () => {
    setInputValues({
      title: '',
      description: '',
      unit: '',
      goal: '',
      goalOrientation: '',
      t4w: '',
      owner: '',
      seat: '',
      text: ''
    });
  };

  const handleSave = () => {
    const payload = {
      name: inputValues.title,
      description: text.replace(/<\/?[^>]+(>|$)/g, ""),
      unit: inputValues.unit,
      goal: inputValues.goal,
      goalOrientationRole: inputValues.goalOrientation,
      goalCalculation: inputValues.goalCalculation,
      measurableOwner: inputValues.measurableOwner,
      seat: inputValues.seat,
      timeDuration: 'monthly'
    }

    const config = {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    };
    axios.post(`${process.env.REACT_APP_API_URL}scorecards/create`, payload, config)
      .then(response => {
        // Handle successful response
        emptyInputValues();


      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
  };
  // below is used for get data and show that data in table
  const [scorecardData, setScorecardData] = React.useState([]);
  const [isLoading, setisloading] = React.useState();
  const [length, setLength] = React.useState("");
  const columns = [
    { id: 'owner', label: 'Owner' },
    { id: 'name', label: 'Title' },
    { id: 'description', label: "Description" },
    { id: 'unit', label: 'Unit' },
    { id: 'goal', label: 'Goal' },
    { id: 'goalOrientationRole', label: 'Goal Orientation Role' },
    { id: 'seat', label: 'Seat' },
    { id: 'timeDuration', label: 'Time Duration' }
  ];
  // monthly scorecard filtre
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}scorecards/get`);
        const allScorecards = response.data.scoreCards;

        // Filter scorecards based on the monthly duration
        const monthlyScorecards = filterMonthlyScorecards(allScorecards);

        setScorecardData(monthlyScorecards);
        setLength(monthlyScorecards.length);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once on mount

  // Function to filter scorecards based on the monthly duration
  const filterMonthlyScorecards = (allScorecards) => {
    return allScorecards.filter((scorecard) => {
      return scorecard.timeDuration === 'monthly';
    });
  };
  return (
    <>
    <div style={{ overflow: "hidden", overflowY: "auto" }}>
      {isLoading ? (
        <p>Loading...

        </p>

      ) : length > 0 ? (
        <TableContainer component={Paper}>
          <Typography variant='h4' style={{ paddingLeft: "30px", paddingTop: "30px", marginRight: "40px" }}> Monthly
            <AddCircleOutlineIcon onClick={handleClick} style={{ marginLeft: "20px" }} />
          </Typography>
          {/* below is the dummy code */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}><IconButton><EditIcon /></IconButton><button onClick={handleOpen} className="btn text-decoration-none">Add an Existing Measurable</button></MenuItem>
            <MenuItem onClick={handleClose}> <IconButton><AddIcon /></IconButton>
              <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Create and Add New Measurable</button>
            </MenuItem>

          </Menu>
          {/*measurable model*/}
          <Modal
            open={open}
            onClose={handleCloses}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div id="modal-modal-title">
                <h3>Add Measurables</h3>
              </div>
              <div id="modal-modal-description" sx={{ mt: 2 }}>
                <TextField id="standard-basic" fullWidth label="Search Measurables" variant="standard" />
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  {/*
  <TableContainer sx={{ maxHeight: 440 }}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === 'number'
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  </TableContainer>
  <TablePagination
    rowsPerPageOptions={[10, 25, 50]}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    sx={{
                      ".MuiTablePagination-displayedRows": {
                          marginTop: "12px",
                      },
                      ".MuiTablePagination-spacer": {
                          paddingTop: 1,
                      },
                      ".MuiTablePagination-selectLabel": {
                          marginBottom: '8px',
                      },
                      ".MuiSelect-select": {
                          paddingTop: 0,
                      },
                  }}
  /> */}
                </Paper>

                {/*Buttons*/}
                <div className="row mt-2">
                  <div className="col-12 col-lg-6 col-sm-12 d-flex align-text-bottom">
                    <button onClick={handleCloses} className="btn shadow w-100 text-center">Cancel</button>
                  </div>
                  <div className="col-12 col-lg-6 col-sm-12 d-flex align-text-bottom">
                    <button className="btn w-100 text-center shadow" style={{ backgroundColor: '#F6F6F6' }}>SAVE</button>
                  </div>
                </div>
              </div> {/*modal body div*/}
            </Box>
          </Modal>

          {/*offcanvas*/}
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-body">
              <div className="d-flex">
                <button type="button" className="btn" data-bs-dismiss="offcanvas" aria-label="Close"><FontAwesomeIcon icon={faAngleRight} /></button>
                <h5 className="mt-1">Add New Measurable</h5>
              </div>

              <hr />

              {/* Title Field */}
              <div class="d-flex">
                <TextField
                  variant="standard"
                  size="lg"
                  style={{ width: 400 }}
                  label="Title*"
                  name="title"
                  value={inputValues.title}
                  onChange={handleInputChange}
                />
              </div>

              {/* Description starts */}

              <label className="mt-3 text-secondary fs-6">Description</label>
              <ReactQuill modules={module} style={{ marginTop: "8px" }} value={text}
                onChange={handleTextChange} theme="snow" name="description" />

              {/*Text Field*/}

              <div className="row">
                <div className="col-12 col-lg-4 d-flex">
                  <div>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">Unit</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inputValues.unit}
                        onChange={(e) => handleInputChange(e, 'unit')}
                        label="Unit"
                      >
                        <MenuItem value={'Currency'}>Currency</MenuItem>
                        <MenuItem value={'Percentage'}>Percentage</MenuItem>
                        <MenuItem value={'Number'}>Number</MenuItem>
                        <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                        <MenuItem value={'Time'}>Time</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> {/*col*/}

                <div className="col-12 col-lg-8 d-flex">
                  <div>
                    <TextField sx={{ m: 1, minWidth: 120 }} id="standard-basic" label="Goal" variant="standard" name="goal" value={inputValues.goal} onChange={handleInputChange} />
                  </div>
                </div> {/*col*/}

              </div> {/*row*/}

              {/*2nd row*/}

              <div className="row">
                <div className="col-12 col-lg-6 d-flex">
                  <div>
                    <FormControl variant="standard" sx={{ my: 1, minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-standard-label"><small>Goal Orientation Role</small></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Goal Orientation Role"
                        value={inputValues.goalOrientation}
                        onChange={(e) => handleInputChange(e, 'goalOrientation')}
                      >
                        <MenuItem value={'Inside min and max'}>Inside min and max</MenuItem>
                        <MenuItem value={'Outside min and max'}>Outside min and max</MenuItem>
                        <MenuItem value={'Greater than or equal to Goal'}>Greater than or equal to Goal</MenuItem>
                        <MenuItem value={'Greater than Goal'}>Greater than Goal</MenuItem>
                        <MenuItem value={'Equal to Goal'}>Equal to Goal</MenuItem>
                        <MenuItem value={'Less to Goal'}>Less to Goal</MenuItem>
                        <MenuItem value={'Less than or equal to Goal'}>Less than or equal to Goal</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> {/*col*/}

                <div className="col-12 col-lg-6 d-flex">
                  <div className="w-100">
                    <FormControl variant="standard" sx={{ my: 1, ms: 0, minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-standard-label"><small>T4W & T13W Goal Calculation</small></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inputValues.t4w}
                        onChange={(e) => handleInputChange(e, 't4w')}
                        label="T4W & T13W Goal Calculation"
                      >
                        <MenuItem value={'Total'}>Total(default)</MenuItem>
                        <MenuItem value={'Average'}>Average</MenuItem>
                      </Select>
                    </FormControl>

                  </div>
                </div> {/*col*/}

              </div> {/*row*/}

              {/*3rd row*/}

              <div className="row">
                <div className="col-12 col-lg-6 d-flex">
                  <div>
                    <FormControl variant="standard" sx={{ my: 1, minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-standard-label"><small>Measurable Owner</small></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inputValues.owner}
                        onChange={(e) => handleInputChange(e, 'owner')}
                        label="Measurable Owner"
                      >
                        <MenuItem value={'Adeel Saleem'}>Adeel Saleem</MenuItem>
                        <MenuItem value={'Husnain Khalid'}>Husnain Khalid</MenuItem>
                        <MenuItem value={'Muhammad Huzaifa'}>Muhammad Huzaifa</MenuItem>
                        <MenuItem value={'Sadia Saleem'}>Sadia Saleem</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> {/*col*/}

                <div className="col-12 col-lg-6 d-flex">
                  <div className="w-100">
                    <FormControl variant="standard" sx={{ my: 1, ms: 0, minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-standard-label"><small>Seat</small></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inputValues.seat}
                        onChange={(e) => handleInputChange(e, 'seat')}
                        label="Seat"
                      >
                        <MenuItem value={'Total'}>Total(default)</MenuItem>
                        <MenuItem value={'Average'}>Average</MenuItem>
                      </Select>
                    </FormControl>

                  </div>
                </div> {/*col*/}

              </div> {/*row*/}

              {/*Buttons*/}
              <div className="row mt-3">
                <div className="col-12 col-lg-4 col-sm-12 d-flex align-text-bottom">
                  <button data-bs-dismiss="offcanvas" aria-label="Close" className="btn shadow w-100 text-center">Cancel</button>
                </div>
                <div className="col-12 col-lg-8 col-sm-12 d-flex align-text-bottom">
                  <button onClick={handleSave} className="btn w-100 text-center shadow" style={{ backgroundColor: '#F6F6F6' }}>SAVE</button>
                </div>
              </div>

            </div> {/*offcanvas-body*/}
          </div> {/*offcanvas*/}
          {/* end convas when table is shown */}
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(scorecardData) && scorecardData.map((row) => (
                <TableRow key={row._id}>
                  {columns.slice(0, 1).map((column) => (
                    <TableCell key={column.id}>
                      <Avatar>OP</Avatar>
                    </TableCell>
                  ))}
                  {columns.slice(1, 8).map((column) => (
                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>

      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '70vh', // Makes the content take up the full viewport height
          }}
        >
          {/* Icon */}
          <div style={{ fontSize: 90, marginBottom: '16px', color: '#BDBDBD' }} ><FontAwesomeIcon icon={faChartLine} /></div>

          {/* Heading */}
          <Typography variant="p" align="center" gutterBottom sx={{ color: '#0000008a', fontSize: '20px', fontWeight: '500', fontFamily: 'Poppins,sans-serif' }}>
          Your team hasn't added any KPIs yet.
          </Typography>

          {/* Paragraph */}
          <Typography variant="body1" align="center" paragraph sx={{ color: '#0000008a', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans,sans-serif' }}>
          Monitor progress and increase visibility to important goals with Scorecard.
          </Typography>

          {/* Button */}
          <Button onClick={handleClick} variant="contained" sx={{ width: '250px', height: '40px', textTransform: 'capitalize', marginTop: '16px', bgcolor: '#1993c6', '&:hover': { bgcolor: '#156f96', }, }}>
            Add KPIs
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}><IconButton><EditIcon /></IconButton><button onClick={handleOpen} className="btn text-decoration-none">Add an Existing KPis</button></MenuItem>
            <MenuItem onClick={handleClose}> <IconButton><AddIcon /></IconButton>
              <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Create and Add New KPIs</button>
            </MenuItem>

          </Menu>

          {/* Text Link */}
          <Typography variant="body2" align="center" sx={{
            marginTop: '24px', color: '#1993c6', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans,sans-serif', '& a': {
              color: '#1993c6',
              textDecoration: 'none',
              '&:hover': {
                color: '#156f96',
              },
            },
          }}>
            <Link href="#">Learn more about Scorecard</Link>
          </Typography>

          {/*measurable model*/}
          <Modal
            open={open}
            onClose={handleCloses}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div id="modal-modal-title">
                <h3>Add Measurables</h3>
              </div>
              <div id="modal-modal-description" sx={{ mt: 2 }}>
                <TextField id="standard-basic" fullWidth label="Search Measurables" variant="standard" />
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  {/*
  <TableContainer sx={{ maxHeight: 440 }}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format && typeof value === 'number'
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  </TableContainer>
  <TablePagination
    rowsPerPageOptions={[10, 25, 50]}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    sx={{
                      ".MuiTablePagination-displayedRows": {
                          marginTop: "12px",
                      },
                      ".MuiTablePagination-spacer": {
                          paddingTop: 1,
                      },
                      ".MuiTablePagination-selectLabel": {
                          marginBottom: '8px',
                      },
                      ".MuiSelect-select": {
                          paddingTop: 0,
                      },
                  }}
  /> */}
                </Paper>

                {/*Buttons*/}
                <div className="row mt-2">
                  <div className="col-12 col-lg-6 col-sm-12 d-flex align-text-bottom">
                    <button onClick={handleCloses} className="btn shadow w-100 text-center">Cancel</button>
                  </div>
                  <div className="col-12 col-lg-6 col-sm-12 d-flex align-text-bottom">
                    <button className="btn w-100 text-center shadow" style={{ backgroundColor: '#F6F6F6' }}>SAVE</button>
                  </div>
                </div>
              </div> {/*modal body div*/}
            </Box>
          </Modal>

          {/*offcanvas*/}
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-body">
              <div className="d-flex">
                <button type="button" className="btn" data-bs-dismiss="offcanvas" aria-label="Close"><FontAwesomeIcon icon={faAngleRight} /></button>
                <h5 className="mt-1">Add New Measurable</h5>
              </div>

              <hr />

              {/* Title Field */}
              <div class="d-flex">
                <TextField
                  variant="standard"
                  size="lg"
                  style={{ width: 400 }}
                  label="Title*"
                  name="title"
                  value={inputValues.title}
                  onChange={handleInputChange}
                />
              </div>

              {/* Description starts */}

              <label className="mt-3 text-secondary fs-6">Description</label>
              <ReactQuill modules={module} style={{ marginTop: "8px" }} value={text}
                onChange={handleTextChange} theme="snow" name="description" />

              {/*Text Field*/}

              <div className="row">
                <div className="col-12 col-lg-4 d-flex">
                  <div>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label">Unit</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inputValues.unit}
                        onChange={(e) => handleInputChange(e, 'unit')}
                        label="Unit"
                      >
                        <MenuItem value={'Currency'}>Currency</MenuItem>
                        <MenuItem value={'Percentage'}>Percentage</MenuItem>
                        <MenuItem value={'Number'}>Number</MenuItem>
                        <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                        <MenuItem value={'Time'}>Time</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> {/*col*/}

                <div className="col-12 col-lg-8 d-flex">
                  <div>
                    <TextField sx={{ m: 1, minWidth: 120 }} id="standard-basic" label="Goal" variant="standard" name="goal" value={inputValues.goal} onChange={handleInputChange} />
                  </div>
                </div> {/*col*/}

              </div> {/*row*/}

              {/*2nd row*/}

              <div className="row">
                <div className="col-12 col-lg-6 d-flex">
                  <div>
                    <FormControl variant="standard" sx={{ my: 1, minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-standard-label"><small>Goal Orientation Role</small></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Goal Orientation Role"
                        value={inputValues.goalOrientation}
                        onChange={(e) => handleInputChange(e, 'goalOrientation')}
                      >
                        <MenuItem value={'Inside min and max'}>Inside min and max</MenuItem>
                        <MenuItem value={'Outside min and max'}>Outside min and max</MenuItem>
                        <MenuItem value={'Greater than or equal to Goal'}>Greater than or equal to Goal</MenuItem>
                        <MenuItem value={'Greater than Goal'}>Greater than Goal</MenuItem>
                        <MenuItem value={'Equal to Goal'}>Equal to Goal</MenuItem>
                        <MenuItem value={'Less to Goal'}>Less to Goal</MenuItem>
                        <MenuItem value={'Less than or equal to Goal'}>Less than or equal to Goal</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> {/*col*/}

                <div className="col-12 col-lg-6 d-flex">
                  <div className="w-100">
                    <FormControl variant="standard" sx={{ my: 1, ms: 0, minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-standard-label"><small>T4W & T13W Goal Calculation</small></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inputValues.t4w}
                        onChange={(e) => handleInputChange(e, 't4w')}
                        label="T4W & T13W Goal Calculation"
                      >
                        <MenuItem value={'Total'}>Total(default)</MenuItem>
                        <MenuItem value={'Average'}>Average</MenuItem>
                      </Select>
                    </FormControl>

                  </div>
                </div> {/*col*/}

              </div> {/*row*/}

              {/*3rd row*/}

              <div className="row">
                <div className="col-12 col-lg-6 d-flex">
                  <div>
                    <FormControl variant="standard" sx={{ my: 1, minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-standard-label"><small>Measurable Owner</small></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inputValues.owner}
                        onChange={(e) => handleInputChange(e, 'owner')}
                        label="Measurable Owner"
                      >
                        <MenuItem value={'Adeel Saleem'}>Adeel Saleem</MenuItem>
                        <MenuItem value={'Husnain Khalid'}>Husnain Khalid</MenuItem>
                        <MenuItem value={'Muhammad Huzaifa'}>Muhammad Huzaifa</MenuItem>
                        <MenuItem value={'Sadia Saleem'}>Sadia Saleem</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> {/*col*/}

                <div className="col-12 col-lg-6 d-flex">
                  <div className="w-100">
                    <FormControl variant="standard" sx={{ my: 1, ms: 0, minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-standard-label"><small>Seat</small></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={inputValues.seat}
                        onChange={(e) => handleInputChange(e, 'seat')}
                        label="Seat"
                      >
                        <MenuItem value={'Total'}>Total(default)</MenuItem>
                        <MenuItem value={'Average'}>Average</MenuItem>
                      </Select>
                    </FormControl>

                  </div>
                </div> {/*col*/}

              </div> {/*row*/}

              {/*Buttons*/}
              <div className="row mt-3">
                <div className="col-12 col-lg-4 col-sm-12 d-flex align-text-bottom">
                  <button data-bs-dismiss="offcanvas" aria-label="Close" className="btn shadow w-100 text-center">Cancel</button>
                </div>
                <div className="col-12 col-lg-8 col-sm-12 d-flex align-text-bottom">
                  <button onClick={handleSave} className="btn w-100 text-center shadow" style={{ backgroundColor: '#F6F6F6' }}>SAVE</button>
                </div>
              </div>

            </div> {/*offcanvas-body*/}
          </div> {/*offcanvas*/}

        </div>
      )}
    </div>
  </>
  )
}

export default Monthly
