import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

function ContactPhone() {

  const handleEditEmailClick = () => {
    // Handle edit email action here
    console.log('Edit email clicked');
  };

  const [rows, setRows] = useState([]);

  const handleAddRow = () => {
    const newRow = {
      phoneNumber: '',
      type: '',
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSave = () => {
    rows.forEach((row, index) => {
      console.log(`phoneNumber: '${row.phoneNumber}', type: '${row.type}'`);
    });
  };

  return (
    <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Phone
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            Primary Phone Number
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-12px' }}>
          <TextField
            size="small"
            id="outlined-basic"
            fullWidth
            variant="outlined"
            placeholder="None"
            InputProps={{
              endAdornment: (
                <InputAdornment className="me-2" position="end">
                  <IconButton
                    edge="end"
                    aria-label="edit email"
                    onClick={handleEditEmailClick}
                    style={{ fontSize: '19px' }}
                  >
                    <LaunchIcon className="me-1 fs-5" />
                    Edit
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-15px' }}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            This phone number is used for MFA authentication.
          </Typography>
        </Grid>
      </Grid>

      <hr className='mt-4' />
      <h6 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }} className="fw-bold mt-4 mb-3">Additional Phone Numbers</h6>

      {rows.map((row, index) => (
        <div key={index} className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-8">
            <TextField
              className="my-2"
              size="small"
              type="email"
              fullWidth
              variant="outlined"
              placeholder="Phone Number"
              value={row.phoneNumber}
              onChange={(e) => handleChange(index, 'phoneNumber', e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-11 col-md-3 col-lg-3">
          <FormControl fullWidth variant="outlined">
            <Select
              className="my-2"
              size="small"
              value={row.type}
              onChange={(e) => handleChange(index, 'type', e.target.value)}
            >
              <MenuItem value="Main">Main</MenuItem>
              <MenuItem value="Cell">Cell</MenuItem>
              <MenuItem value="Home">Home</MenuItem>
              <MenuItem value="Work">Work</MenuItem>
            </Select>
            </FormControl>
          </div>
          <div className="col-12 col-sm-1 com-md-1 col-lg-1">
            <IconButton
              edge="end"
              className="my-1"
              onClick={() => handleDeleteRow(index)}
            >
              <DeleteIcon className="text-danger" />
            </IconButton>
          </div>
        </div>
      ))}
      <button onClick={handleAddRow} style={{ color: '#1993C6' }} className="btn"><AddCircleOutlineOutlinedIcon />&nbsp;&nbsp;Add Phone Number</button>
    </Paper>
  );
}

export default ContactPhone;

