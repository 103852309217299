import React, { useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  Input,
  InputLabel,
  FormControl,
} from '@mui/material';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignCenter,
  TableChart,
  Link,
  FormatListNumbered,
  FormatListBulleted,
  Palette,
  SelectAll,
  SentimentSatisfied,
} from '@mui/icons-material';
import EmojiPicker from 'emoji-picker-react';

function TextEditor() {
  const [editorState, setEditorState] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const handleBoldClick = () => {
    document.execCommand('bold', false, null);
  };

  const handleItalicClick = () => {
    document.execCommand('italic', false, null);
  };

  const handleUnderlineClick = () => {
    document.execCommand('underline', false, null);
  };

  const handleAlignLeftClick = () => {
    document.execCommand('justifyLeft', false, null);
  };

  const handleAlignRightClick = () => {
    document.execCommand('justifyRight', false, null);
  };

  const handleAlignCenterClick = () => {
    document.execCommand('justifyCenter', false, null);
  };

  const handleColorChange = (e) => {
    const color = e.target.value;
    document.execCommand('foreColor', false, color);
  };

  const createTable = () => {
    const tableHtml = '<table border="1"><tr><td></td><td></td></tr><tr><td></td><td></td></tr></table>';
    document.execCommand('insertHTML', false, tableHtml);
  };

  const insertLink = () => {
    const url = prompt('Enter the URL:');
    if (url) {
      document.execCommand('createLink', false, url);
    }
  };

  const handleOrderedList = () => {
    document.execCommand('insertOrderedList', false, null);
  };

  const handleUnorderedList = () => {
    document.execCommand('insertUnorderedList', false, null);
  };

  const handleSelectAll = () => {
    const textEditor = document.getElementById('text-editor');
    const range = document.createRange();
    range.selectNodeContents(textEditor);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleEmojiClick = (event, emojiObject) => {
    document.execCommand('insertText', false, emojiObject.emoji);
  };

  return (
    <div>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500'}}>
              Biography <span style={{color: '#0000008a', fontSize: '14px'}}>(For this company only)</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleBoldClick}><FormatBold /></Button>
            <Button onClick={handleItalicClick}><FormatItalic /></Button>
            <Button onClick={handleUnderlineClick}><FormatUnderlined /></Button>
            <Button onClick={handleAlignLeftClick}><FormatAlignLeft /></Button>
            <Button onClick={handleAlignRightClick}><FormatAlignRight /></Button>
            <Button onClick={handleAlignCenterClick}><FormatAlignCenter /></Button>
            <Button><input type="color" id="color-picker" onChange={handleColorChange} /><Palette /></Button>
            <Button onClick={createTable}><TableChart /></Button>
            <Button onClick={insertLink}><Link /></Button>
            <Button onClick={handleOrderedList}><FormatListNumbered /></Button>
            <Button onClick={handleUnorderedList}><FormatListBulleted /></Button>
            <Button onClick={handleSelectAll}><SelectAll /></Button>
            <Button onClick={() => setShowEmojiPicker(!showEmojiPicker)}><SentimentSatisfied /></Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              value={editorState}
              onChange={(e) => setEditorState(e.target.value)}
              id="text-editor"
            />
          </Grid>
        </Grid>
      </Paper>
      {showEmojiPicker && (
        <EmojiPicker onEmojiClick={handleEmojiClick} />
      )}
    </div>
  );
}

export default TextEditor;
