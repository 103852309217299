import React, { useState, useEffect } from 'react';
import Createbtn from '../../../../createbtn/create'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { TextField, IconButton, Menu, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faPaperclip, faBinoculars } from '@fortawesome/free-solid-svg-icons'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CommentIcon from '@mui/icons-material/Comment';
import NativeSelect from '@mui/material/NativeSelect';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const CascadingMessages = () => {

  // createbtn modal

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // fetch cascading data from api
  const [cascadingData, setcascadingData] = React.useState([]);
  const [isLoading, setisloading] = React.useState();
  const [length, setLength] = React.useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}todos/get`);
        console.log(response);
        // let shorttermissues=response.data.todos;
        // // console.log(shorttermissues).
        let cascadingResponse = response.data.todos.filter(data => data.type === 'cascading-messagge');
        setcascadingData(cascadingResponse);
        setLength(cascadingResponse.length);
        console.log("This is set cascading data", cascadingResponse);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);
  //quil

  const [value, setValue] = React.useState('');
  var toolbarOptions = [
    ['bold', 'italic'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'direction': 'rtl' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  //menu
  const [anchorEl, setAnchorEl] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  // file upload
  const [file, setFile] = React.useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile); // Save the selected file

    if (selectedFile) {
      setInputValues({
        ...inputValues,
        attachment: selectedFile.name, // Update attachment in inputValues
      });
    }
  };

  const handleCancel = () => {
    setFile(null); // Remove the file
  };

  // apply the style in the uploaded file
  const HoverableDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between",
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#f5f5f5', // Change the background color on hover
    },
  });

  //comment button
  const [isButtonsVisible, setButtonsVisible] = React.useState(false);

  const handleTextFieldClick = () => {
    setButtonsVisible(true);
    setInputValues({
      ...inputValues,
      comment: '', // Set the initial value if needed
    });
  };

  const handleCancelButtonClick = () => {
    setButtonsVisible(false);
  };

  // pagination below

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //inputvaluestoconsole
  const [inputValues, setInputValues] = React.useState({
    title: '',
    description: '',
    team: '',
    attachment: '',
    comment: '',
  });
  const handleInputChange = (e, selectName) => {
    const { name, value } = e.target;

    if (selectName) {
      // Handle the Select separately
      setInputValues({
        ...inputValues,
        [selectName]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    }
  };

  const handleSave = () => {
    console.log(inputValues);
  };
  // edit card state
  const [isEditCard, setEditcardvisible] = React.useState(false);
  const handleCardShow = () => {
    setEditcardvisible(true);
  };
  const handleCardClose = () => {
    setEditcardvisible(false);
  };

  const [isHovered, setIsHovered] = useState(false);
  // date formatting
  const formatDate = (dateString) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };
  return (

    <>
      <div className="">


        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            // justifyContent: 'center',
            minHeight: '60vh',
          }}
        >
          {isLoading ? (
            <p>
              Loading...
            </p>

          ) : length > 0 ? (
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <TableContainer component={Paper}>
                <Typography variant='h4' style={{ paddingLeft: "30px", paddingTop: "30px", marginRight: "40px" }}>
                  Cascading Messages
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ color: '#9C9C9C', width: "20px" }} align='left'>#</TableCell>
                      <TableCell style={{ color: '#9C9C9C', width: "50px" }} align='left'>Title</TableCell>
                      <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>From</TableCell>
                      <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>Created</TableCell>
                      <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>Owner</TableCell>
                      <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>More</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cascadingData.map((cascading, index) => (
                      <TableRow key={index}>
                        <TableCell align='left' style={{ width: "20px" }}>
                          <RadioButtonUncheckedIcon />
                        </TableCell>
                        <TableCell
                          align='left'
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          {cascading.name}
                          {isHovered && (
                            <EditIcon
                              style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                              onClick={handleCardShow}
                            />
                          )}
                        </TableCell>
                        <TableCell align='right'>Team Name</TableCell>
                        <TableCell align='right'>{formatDate(cascading.createdAt)}</TableCell>
                        <TableCell align='right' className='d-flex items-center'>
                          <Avatar >OP</Avatar>
                        </TableCell>
                        <TableCell align='right'>
                          <MoreHorizIcon
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className="d-flex justify-content-between">
                  <Button onClick={openModal} disabled={isModalOpen} className="btn p-0 btn-white">
                    <h6 className="mt-3 ms-2" style={{ color: '#1993c6', fontFamily: 'Poppins, sans-serif' }}>+ Add Cascading Messages</h6>
                  </Button>
                  <Createbtn isOpen={isModalOpen} onClose={closeModal} />
                  <TablePagination
                    component="div"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      ".MuiTablePagination-displayedRows": {
                        marginTop: "12px",
                      },
                      ".MuiTablePagination-spacer": {
                        paddingTop: 1,
                      },
                      ".MuiTablePagination-selectLabel": {
                        marginBottom: '8px',
                      },
                      ".MuiSelect-select": {
                        paddingTop: 0,
                      },
                    }}
                  />
                </div>
              </TableContainer>
              {isEditCard && (
                <>
                  <Card style={{ minWidth: 400, marginLeft: 10, height: 550, overflowY: "auto", paddingBottom: "12px" }}>
                    <CardHeader style={{ marginBottom: "12px" }} title={
                      <Typography gutterBottom variant="h5" component="h2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <RadioButtonUncheckedIcon style={{ fontSize: '28px', marginRight: '10px', color: '#707070' }} onClick={handleSave} />
                        <h5 className="mt-1" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }} >Edit Cascading Message</h5>
                        <Typography style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                          <MoreHorizIcon onClick={handleClick} style={{ color: '#9A9A9A', marginLeft: '90px' }} />
                          <Avatar className="" sx={{ width: 30, height: 30, backgroundColor: '#4E545B' }}>S</Avatar>
                          <CloseIcon style={{ cursor: "pointer" }} onClick={handleCardClose} />

                        </Typography>

                      </Typography>
                    } />
                    <Typography component="hr" />
                    <CardContent>
                      <InputLabel>Title</InputLabel>
                      <TextField id="outlined-basic" size="small" variant="standard" fullWidth name="title" value={inputValues.title} onChange={handleInputChange} />

                      <InputLabel className="mt-4">Description (optional)</InputLabel>
                      <ReactQuill  modules={module}  style={{ marginTop: "8px" }} theme="snow" value={value} onChange={setValue} />

                      <FormControl className='mt-3 mb-3 w-100'>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                          Team*
                        </InputLabel>
                        <NativeSelect
                          defaultValue={'Leadership Team'}
                          inputProps={{
                            name: 'team',
                            id: 'uncontrolled-native',
                          }}
                          onChange={(e) => handleInputChange(e, 'team')}

                        >
                          <option value={'Leadership Team'}>Leadership Team</option>
                          <option value={'Team 1'}>Team 1</option>
                          <option value={'Team 2'}>Team 2</option>
                        </NativeSelect>
                      </FormControl>
                      <hr className="p-0 my-4" />

                      <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Attachments</h5>

                      <div className="py-2 mt-3 rounded rounded-3" style={{ backgroundColor: '#F5F5F5' }} >
                        <p className="text-center pt-2" style={{ fontFamily: 'Poppins, sans-serif', color: '#707070', fontSize: '14px' }}>Drag and drop files to attach, or <label htmlFor="file-upload" className="fw-bold" style={{ color: '#2596BE' }} >browse</label></p>
                      </div>

                      <input
                        type="file"
                        id="file-upload"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                       {file && (
                        <HoverableDiv>
                          <Typography>{file.name}</Typography>
                          <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                        </HoverableDiv>
                      )}

                      <hr className="p-0 my-4" />

                      <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Comments</h5>

                      <div className="d-flex">
                        <Avatar className="ms-3 mt-3" sx={{ width: 30, height: 30, backgroundColor: '#757575' }}>S</Avatar>
                        <TextField className="mt-3 ms-3" placeholder="Add a comment.." id="outlined-basic" size="small" variant="outlined" fullWidth
                          onClick={handleTextFieldClick}
                          onChange={(e) => handleInputChange(e, 'comment')}
                          value={inputValues.comment}
                        />
                      </div>

                      {isButtonsVisible && (
                        <div className="d-flex ms-5 mt-3">
                          <button style={{ backgroundColor: '#2596BE' }} className="btn text-white mx-3">Comment</button>
                          <button onClick={handleCancelButtonClick} className="btn btn-outline-secondary">Cancel</button>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </>
              )}
            </Box>
          ) : (
            <div className='card m-3 shadow'>


              <div className="mt-3 ms-4"><h5 style={{ fontWeight: '600', fontSize: '22px', letterSpacing: '0.5px' }} className="">Cascading Messages</h5></div>
             <div style={{display:"flex",justifyContent:"center"}}>
             {/* <img className="m-0 mb-3 p-0" style={{ width: '100px', marginBottom: '13px'}} src={`megaphone.png`} alt='megaphone' /> */}
             </div>

              {/* Icon */}
       <div className="m-0 p-0 d-flex flex-row justify-content-center align-items-center" style={{ fontSize: 80, marginBottom: '13px', color:'#BDBDBD' }}><FontAwesomeIcon icon={faBullhorn}  style={{ width: '100px', marginBottom: '13px'}} /></div>

              {/* Heading */}
              <Typography variant="p" align="center" gutterBottom sx={{ color: '#757575', fontSize: '19px', fontWeight: '500', fontFamily: 'Space Grotesk, sans-serif' }}>
                Your company hasn't added any Cascading Messages yet.
              </Typography>

              {/* Paragraph */}
              <Typography variant="body1" align="center" paragraph sx={{ color: '#0000008a', fontSize: '14px', fontWeight: '500', fontFamily: 'Space Grotesk, sans-serif' }}>
                Cascading Messages are a great way to share news across teams.
              </Typography>

              {/* Button */}
             <div style={{display:"flex",justifyContent:"center"}}>
             <Button onClick={openModal} disabled={isModalOpen} variant="contained" sx={{ width: '250px', height: '40px', textTransform: 'capitalize', marginTop: '14px', bgcolor: '#1993c6', '&:hover': { bgcolor: '#156f96', }, }}>
                <Createbtn isOpen={isModalOpen} onClose={closeModal} />
                Create Cascading Messages
              </Button>
             </div>


              {/* Text Link */}
              <Typography variant="body2" align="center" sx={{
                marginTop: '23px', marginBottom: '20px', color: '#1993c6', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans,sans-serif', '& a': {
                  color: '#1993c6',
                  textDecoration: 'none',
                  '&:hover': {
                    color: '#156f96',
                  },
                },
              }}>
                <Link href="#">Learn more about Cascading Messages.</Link>
              </Typography>
            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default CascadingMessages;