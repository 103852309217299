import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ResetZoomIcon from '@mui/icons-material/ZoomOutMap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip from MUI
import UndoIcon from '@mui/icons-material/Undo';

// Accountability chart Import Here
import AccountChart from './accountabilitychart'
function AccountabilitychartAppBar({handlePrint,zoomIn,zoomOut,resetZoom}) {
  const handleRefreshClick = () => {
    // Handle the refresh icon click event here
    // You can add your refresh logic in this function
    console.log('Refresh icon clicked');
  };

  const handleSearchChange = (event) => {
    // Handle search field input changes here
    // event.target.value contains the search input value
    console.log('Search input:', event.target.value);
  };

  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: '#2596be' }}>
        <Toolbar>
        <div className="row w-100">
         <div className="col-12 col-lg-6 col-md-12 col-sm-12 d-flex">
          <Typography variant="p" component="div" sx={{ flexGrow: 1, fontSize: '16px', display: 'flex' }}>
            <span className="mt-2" style={{ color: '#ffffff91', marginRight: '8px' }}>FILTERS</span> {/* Add the "Filter" text here */}
            <label className="mt-2" htmlFor="owner-select">Top Seat:</label>
            <Select
              variant="standard"
              id="owner-select"
              label="Teams"
              sx={{ marginRight: '16px', marginLeft: '10px', width: '180px' }}
              defaultValue={1}
              inputProps={{ style: { border: 'none' } }} // Add border style here
            >
              <MenuItem value={1}>Seater 1</MenuItem>
              <MenuItem value={2}>Seater 2</MenuItem>
              <MenuItem value={3}>Seater 3</MenuItem>
            </Select>

            <Tooltip title="Reset">
            <IconButton
              // edge="end"
              color="inherit"
              aria-labelledby="resetLabel"
              onClick={handleRefreshClick}
              sx={{ marginRight: "12px" }}
            >
              <UndoIcon />
            </IconButton>
          </Tooltip>

          </Typography>
         </div>

        <div className="col-12 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end">
          <Tooltip title="Refresh"> {/* Add Tooltip to show text on hover */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="refresh"
              onClick={handleRefreshClick}
              sx={{ marginRight: '6px' }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh"> {/* Add Tooltip to show text on hover */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="refresh"
              onClick={handleRefreshClick}
              sx={{ marginRight: '6px' }}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Zoom In"> {/* Add Tooltip to show text on hover */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="zoom-in"
              sx={{ marginRight: '6px' }}
              onClick={zoomIn}
            >
              <ZoomInIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Zoom Out"> {/* Add Tooltip to show text on hover */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="zoom-out"
              sx={{ marginRight: '6px' }}
              onClick={zoomOut}
            >
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Reset Zoom"> {/* Add Tooltip to show text on hover */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="reset-zoom"
              sx={{ marginRight: '6px' }}
              onClick={resetZoom}
            >
              <ResetZoomIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Print to PDF"> {/* Add Tooltip to show text on hover */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="print-pdf"
              sx={{ marginRight: '6px' }}
              onClick={handlePrint}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>
          </div>
          </div>
        </Toolbar>
      </AppBar>

      {/* Your content goes here */}
      {/* Add your list of teams and users */}

      {/* here is the component chart of account  */}
    </div>
  );
}

export default AccountabilitychartAppBar;
