import React, { useState } from 'react'
import { Box, Typography } from '@mui/material';

import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import ReactQuill from 'react-quill';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import NativeSelect from '@mui/material/NativeSelect';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import axios from 'axios';

export default function CascadingMessaggeForm({ onIssueTitleChange,onClose }) {
    // ReactQuill text area
    const toolbarOptions = [
        'bold',
        'italic',
        'underline',
        'strike',
        { 'header': [1, 2, 3] },
        'align',
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'script': 'sub' },
        { 'script': 'super' },
        { 'direction': 'rtl' },
        { 'size': ['small', false, 'large', 'huge'] },
        { 'color': [] },
        { 'background': [] },
        { 'font': [] },
        'blockquote',
        'code-block',
        'clean',
    ];

    const modules = {
        toolbar: [toolbarOptions],
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'ordered',
        'bullet',
        'sub',
        'super',
        'direction',
        'size',
        'color',
        'background',
        'font',
        'blockquote',
        'code-block',
        'clean',
    ];
    const [text, setText] = useState('');
    const handleTextChange = (value) => {
        setText(value);
    };
    // file upload
    const [file, setFile] = useState(null);
//    const handleFileChange = (e) => {
//        const selectedFile = e.target.files[0];
//        setFile(selectedFile); // Save the selected file
//    };

const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile); // Save the selected file

        if (selectedFile) {
            setInputValues({
                ...inputValues,
                attachment: selectedFile.name, // Update attachment in inputValues
            });
        }
    };
//

    const handleCancel = () => {
        setFile(null); // Remove the file
    };
    // apply the style in the uploaded file
    const HoverableDiv = styled('div')({
        display: 'flex',
        justifyContent: "space-between",
        marginTop: '5px',
        '&:hover': {
            backgroundColor: '#f5f5f5', // Change the background color on hover
        },
    });


    // Getting the value of Issue
    const [issueTitle, setIssueTitle] = useState('');
    const handleIssueChange = (value) => {
        // Update the state in Create based on the issue title input
        setIssueTitle(value);
        onIssueTitleChange(value.trim() === '');
    };


 const [inputValues, setInputValues] = useState({
     title: '',
     description: '',
     team: '',
     attachment: '',
      });

//const handleInputChange = (e) => {
//    const { name, value } = e.target;
//    setInputValues({
//        ...inputValues,
//        [name]: value,
//    });
//};

const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'team') {
            // Handle NativeSelect separately
            setInputValues({
                ...inputValues,
                [name]: value,
            });
        } else {
            setInputValues({
                ...inputValues,
                [name]: value,
            });
        }
    };

const handleCascadingForm = () => {
  console.log(inputValues);
  const payload = {
    name: inputValues.title,
    type: 'cascading-messagge',
    description: text.replace(/<\/?[^>]+(>|$)/g, ""),
    team: inputValues.team,
    attachments: inputValues.attachment,
}
const config = {
    headers: { Authorization: `Bearer ${Cookies.get('token')}` }
};
axios.post(`${process.env.REACT_APP_API_URL}todos/create`, payload, config)
.then(response => {
    // Handle successful response
    console.log('Success:', response.data);
    onClose();
})
.catch(error => {
    // Handle error
    console.error('Error:', error);
});
setInputValues({
    title: '',
    description: '',
    team: '',
    attachment: '',
    })
};

    return (
        <div>
            <CardContent className='container-fluid'
                style={{ maxHeight: '100%', height: "100%" }}>
                <Box style={{ height: "auto" }}
                    component="form"
                    Validate
                    autoComplete="off"

                >
                    <div className='d-flex flex-row justify-content-center'>
                        <AccountCircle className='' style={{ width: "40px", height: "40px" }} />
                        <TextField
                            id="standard-search"
                            label="Name the Cascading Message"
                            type="text"
                            variant="standard"
                            style={{ color: "red", width: "90%" }}
                            name="title"
                            value={inputValues.title}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className='mt-2'>
                        <ReactQuill
                            value={text}
                            onChange={handleTextChange}
                            modules={modules}
                            formats={formats}
                            placeholder={'Description'}
                            theme="snow"
                            style={{
                                height: '15vh', // Adjust the height as needed
                                fontSize: '18px', // Adjust the font size as needed
                                // maxHeight:"200px"
                            }}
                        />
                    </div>
                    <div className='d-flex flex-row' style={{ marginTop: "100px" }}>
                        {/* Team Dropdown */}
                        <div className='w-50'>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native" required>
                                Team
                            </InputLabel>
                            <NativeSelect style={{ marginLeft: "0px", color: "#2596be", fontSize: "18px" }}
                                defaultValue={'Mern Stack'} inputProps={{
                                    name: 'team',
                                    id: 'uncontrolled-native',
                                }}
                                onChange={handleInputChange}
                            >
                                <option value={'Mern Stack'}>Mern Stack</option>
                                <option value={'ai team'}>Ai Team (owner is not on team)</option>
                                <option value={'leadership team'}>Leadership Team (owner is not on Team)</option>
                            </NativeSelect>
                        </div>
                    </div>
                    {/* Important Description */}
                    <div className='col-lg-12 col-md-12 col-sm-12 mt-2'>
                        <small >* Note: Changing teams will filter out selected users not on team</small>
                    </div>
                    <div className='col-lg-2 col-md-12 col-sm-12 mt-2 w-100'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>Attachments</span>
                            <label htmlFor="file-upload">
                                <AddCircleOutlineIcon />
                            </label>
                            <input
                                type="file"
                                id="file-upload"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                        {file && (
                            <HoverableDiv>
                                <Typography>{file.name}</Typography>
                                <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                            </HoverableDiv>
                        )}
                    </div>
<div className='container-fluid d-flex flex-row justify-content-center mt-2'>
  <Button style={{ color: "white", backgroundColor: "#2596be", width: "99%" }} onClick={handleCascadingForm} >Create Cascading Messagge</Button>
  {/* <Button style={{ width: "35%",backgroundColor:"#fff",boxShadow: " 3px 5px 10px rgba(0.8, 0.8, 0, 0.2)" }}>Cancel</Button> */}
</div>
                </Box>
            </CardContent>
        </div>
    )
}
