import React from 'react'
import { Grid } from '@mui/material'
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


export default function FeatureControls() {

    // 1st setting switch

    const [trailingChecked, setTrailingChecked] = React.useState(true);

    const handleTrailingChange = (event) => {
        setTrailingChecked(event.target.checked);
    };

    // 2nd setting switch

    const [averageChecked, setAverageChecked] = React.useState(true);

    const handleAverageChange = (event) => {
        setAverageChecked(event.target.checked);
    };

    // 3rd setting switch

    const [totalChecked, setTotalChecked] = React.useState(true);

    const handleTotalChange = (event) => {
        setTotalChecked(event.target.checked);
    };

    // 4th setting switch

    const [statusColor, setStatusColor] = React.useState(true);

    const handleColorChange = (event) => {
        setStatusColor(event.target.checked);
    };

    const [weeklyStart, setWeeklyStart] = React.useState('');

    const handleWeeklyChange = (event) => {
        setWeeklyStart(event.target.value);
    };

    // 1st rock switch

    const [milestonesChecked, setMilestonesChecked] = React.useState(true);

    const handleMilestonesChange = (event) => {
        setMilestonesChecked(event.target.checked);
    };

    // 2nd rock switch

    const [checked, setChecked] = React.useState(false);

    const handleCreateToDoChange = (event) => {
        setChecked(event.target.checked);
    };

    // 1st issue switch

    const [prioritization, setPrioritization] = React.useState(true);

    const handlePrioritizationChange = (event) => {
        setPrioritization(event.target.checked);
    };

    // 2nd issue switch

    const [voting, setVoting] = React.useState(false);

    const handleVotingChange = (event) => {
        setVoting(event.target.checked);
    };

    // vision switch

    const [vision, setVision] = React.useState(false);

    const handleVisionChange = (event) => {
        setVision(event.target.checked);
    };

    // SMS switch

    const [sms, setSMS] = React.useState(true);

    const handleSMSChange = (event) => {
        setSMS(event.target.checked);
    };

    // security switch

    const [security, setSecurity] = React.useState(false);

    const handleSecurityChange = (event) => {
        setSecurity(event.target.checked);
    };

    // chart switch

    const [chart, setChart] = React.useState(false);

    const handleChartChange = (event) => {
        setChart(event.target.checked);
    };

    // eos switch

    const [eOS, setEOS] = React.useState(true);

    const handleEOSChange = (event) => {
        setEOS(event.target.checked);
    };

    // icons switch

    const [icons, setIcons] = React.useState(false);

    const handleIconsChange = (event) => {
        setIcons(event.target.checked);
    };

    // links switch

    const [links, setLinks] = React.useState(false);

    const handleLinksChange = (event) => {
        setLinks(event.target.checked);
    };


    return (
        <>
            {/*Scorecard*/}
            <div className="mx-1">
                <div className="card p-3">
                    <h6 style={{ fontFamily: 'Poppins, sans-serif' }}>Feature Controls</h6>
                    <h6 className="fw-bold mt-3" style={{ fontFamily: 'Poppins, sans-serif' }}>Scorecard</h6>

                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex flex-column">

                                <div className="d-flex mt-2">
                                    <Switch checked={trailingChecked} color="secondary" onChange={handleTrailingChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: '300', color: '#5E5E5E' }}>Trailing ScoreCard</h6>
                                </div>

                                <div className="d-flex mt-2">
                                    <Switch checked={totalChecked} color="secondary" onChange={handleTotalChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: '300', color: '#5E5E5E' }}>Measurable Total Column</h6>
                                </div>

                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex flex-column">

                                <div className="d-flex mt-2">
                                    <Switch checked={averageChecked} color="secondary" onChange={handleAverageChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: '300', color: '#5E5E5E' }}>Measurable Average Column</h6>
                                </div>

                                <div className="d-flex mt-2 w-100">
                                    <Switch checked={statusColor} color="secondary" onChange={handleColorChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px', fontWeight: '300', color: '#5E5E5E' }}>Measurable Status Color Indicator</h6>
                                </div>

                            </div>

                            <FormControl className="mt-4 mb-3 ms-3 w-50" size="small" >
                                <InputLabel id="demo-simple-select-label">Measurable Weekly Start Day</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={weeklyStart}
                                    onChange={handleWeeklyChange}
                                    label="Measurable Weekly Start Day"
                                >
                                    <MenuItem value={'Monday'}>Monday</MenuItem>
                                    <MenuItem value={'Tuesday'}>Tuesday</MenuItem>
                                    <MenuItem value={'Wednesday'}>Wednesday</MenuItem>
                                    <MenuItem value={'Thursday'}>Thursday</MenuItem>
                                    <MenuItem value={'Friday'}>Friday</MenuItem>
                                    <MenuItem value={'Saturday'}>Saturday</MenuItem>
                                    <MenuItem value={'Sunday'}>Sunday</MenuItem>
                                </Select>
                            </FormControl>

                        </div> {/*row */}
                    </div> {/*container */}

                    <hr className="mb-3" />

                    {/* rocks */}
                    <h6 className="fw-bold mt-3" style={{ fontFamily: 'Poppins, sans-serif' }}>Rocks</h6>

                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-sm-6 col-md-5 col-lg-5 d-flex flex-column">

                                <div className="d-flex mt-2">
                                    <Switch checked={milestonesChecked} color="secondary" onChange={handleMilestonesChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Show Milestones on Rocks</h6>
                                </div>

                            </div>

                            <div className="col-12 col-sm-6 col-md-7 col-lg-7 d-flex flex-column">

                                <div className="d-flex mt-2">
                                    <Switch color="secondary" checked={checked} onChange={handleCreateToDoChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Automatically create To-Dos for Milestones not completed 7 days before due date.</h6>
                                </div>

                                <h6 className="ms-1" style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>This process runs once a day at 06:00 UTC and will reflect the toggle's state at that moment.</h6>

                            </div>

                        </div> {/*row */}
                    </div> {/*container */}

                    <hr className="mb-3" />

                    {/* issues */}
                    <h6 className="fw-bold mt-3" style={{ fontFamily: 'Poppins, sans-serif' }}>Issues</h6>

                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex flex-column">

                                <div className="d-flex mt-2">
                                    <Switch color="secondary" checked={prioritization} onChange={handlePrioritizationChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Show Issue prioritization</h6>
                                </div>

                                <h6 className="ms-1" style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Display an additional field next to your Issues that allows you to prioritize from 1 to 5.</h6>

                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex flex-column">

                                <div className="d-flex mt-2">
                                    <Switch color="secondary" checked={voting} onChange={handleVotingChange} inputProps={{ 'aria-label': 'controlled' }} />
                                    <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Voting</h6>
                                </div>

                                <h6 className="ms-1" style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Allow Meeting attendees to vote on Issue for prioritization during a Meeting.</h6>

                            </div>

                        </div> {/*row */}
                    </div> {/*container */}

                    <hr className="mb-3" />

                    {/* vision */}
                    <h6 className="fw-bold mt-3" style={{ fontFamily: 'Poppins, sans-serif' }}>Vision</h6>

                    <div className="container">

                        <div className="d-flex flex-column">

                            <div className="d-flex mt-2">
                                <Switch color="secondary" checked={vision} onChange={handleVisionChange} inputProps={{ 'aria-label': 'controlled' }} />
                                <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Custom Vision</h6>
                            </div>

                            <h6 className="ms-1" style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Allows modification of the layout and content of the Vision page.</h6>

                        </div>

                    </div> {/*container */}

                    <hr className="mb-3" />

                    {/* Henryx */}
                    <h6 className="fw-bold mt-3" style={{ fontFamily: 'Poppins, sans-serif' }}>Henryx</h6>

                    <div className="container">

                        <div className="d-flex flex-column">

                            <div className="d-flex mt-2">
                                <Switch color="secondary" checked={sms} onChange={handleSMSChange} inputProps={{ 'aria-label': 'controlled' }} />
                                <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>SMS Actions (beta)</h6>
                            </div>

                        </div>

                    </div> {/*container */}

                    <hr className="mb-3" />


                    {/* Security*/}
                    <h6 className="fw-bold mt-3" style={{ fontFamily: 'Poppins, sans-serif' }}>Security</h6>

                    <div className="container">

                        <div className="d-flex flex-column">

                            <div className="d-flex mt-2">
                                <Switch color="secondary" checked={security} onChange={handleSecurityChange} inputProps={{ 'aria-label': 'controlled' }} />
                                <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Require MFA</h6>
                            </div>

                        </div>

                    </div> {/*container */}

                    <hr className="mb-3" />

                    {/* Private Accountability Chart*/}
                    <h6 className="fw-bold mt-3" style={{ fontFamily: 'Poppins, sans-serif' }}>Private Accountability Chart</h6>

                    <div className="container">

                        <div className="d-flex flex-column">

                            <div className="d-flex mt-2">
                                <Switch color="secondary" checked={chart} onChange={handleChartChange} inputProps={{ 'aria-label': 'controlled' }} />
                                <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Toggling this on will make the Accountability Chart unavailable to anyone not an owner, admin, or on the Leadership Team.</h6>
                            </div>

                        </div>

                    </div> {/*container */}

                    <hr className="mb-3" />

                    {/* EOS Toolbox™*/}
                    <h6 className="fw-bold mt-3" style={{ fontFamily: 'Poppins, sans-serif' }}>EOS Toolbox™</h6>

                    <div className="container">

                        <div className="d-flex flex-column">

                            <div className="d-flex mt-2">
                                <Switch color="secondary" checked={eOS} onChange={handleEOSChange} inputProps={{ 'aria-label': 'controlled' }} />
                                <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Enable EOS Toolbox™</h6>
                            </div>

                            <h6 className="ms-1" style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>A customizable tool that allows you to learn and track mastery across your teams.</h6>

                        </div>

                        <div className="d-flex flex-column">

                            <div className="d-flex mt-2">
                                <Switch color="secondary" checked={icons} onChange={handleIconsChange} inputProps={{ 'aria-label': 'controlled' }} />
                                <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Show Icons</h6>
                            </div>

                            <h6 className="ms-1" style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Turning this on will display icons on Tool cards. You can change this icon anytime you create or edit a Tool card.</h6>

                        </div>

                        <div className="d-flex flex-column">

                            <div className="d-flex mt-2">
                                <Switch color="secondary" checked={links} onChange={handleLinksChange} inputProps={{ 'aria-label': 'controlled' }} />
                                <h6 className="ms-2 mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', fontWeight: '300', color: '#5E5E5E' }}>Show links</h6>
                            </div>

                            <h6 className="ms-1" style={{ color: '#0000008a', fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Turning this on will display a "Learn More" button on Tool cards, allowing you to enter a custom URL to link your teammates to learning materials.</h6>

                        </div>

                    </div> {/*container */}


                </div> {/* card */}
            </div> {/* margin */}
        </>
    )
}