import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import DialpadIcon from '@mui/icons-material/Dialpad';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InputAdornment from '@mui/material/InputAdornment';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReactQuill from 'react-quill';
// below are the icons for Add tool section
import WavingHandIcon from '@mui/icons-material/WavingHand';
import FeedIcon from '@mui/icons-material/Feed';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WcIcon from '@mui/icons-material/Wc';
import SchoolIcon from '@mui/icons-material/School';
import DomainVerificationRoundedIcon from '@mui/icons-material/DomainVerificationRounded';
// import model here
import AnnualsessionDay2modal from '../models/annualsessionday2model'
// dragability component
import Draggable from 'react-draggable';
export default function Annualsessionday2() {
    // card 1
    // handel the input state or update of AnnualsessionDay2modal
    const [Annualsessionymodel, setAnnualsessionmodel] = useState(AnnualsessionDay2modal);
    const handleMeetingInputChange = (e, index, field) => {
        // for AnnualsessionDay2modal
        const updatedAnnualsessionDay2modal = [...AnnualsessionDay2modal];
        updatedAnnualsessionDay2modal[index][field] = e.target.value;
        // Update the state with the new values
        setAnnualsessionmodel(AnnualsessionDay2modal);
    };
    // dynamic section Name
    const [inputfield, setinputfield] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    
    const dynamicallyAddInput = () => {
        setinputfield([...inputfield, { sectionName: "", duration: "" }]);
        setEditingIndex(null);
        // setSectionName("");
        setCardOpen(true);
        setSubtitleCard(false)
    };
    // for dynamic input field model
    const handlechange = (index, name, value) => {
        const onChangeVal = [...inputfield];
        onChangeVal[index][name] = value;
        setinputfield(onChangeVal);
    };

    const handleDelete = (index) => {
        const deleteVal = [...inputfield];
        deleteVal.splice(index, 1);
        setinputfield(deleteVal);
    };

    //   For edit icon functionality and handle custom section edit
    // State to track the selected inputfield for editing
    // const [selectedInputField, setSelectedInputField] = useState(null);
    const handleCustomSectionEdit = (index) => {
        setEditingIndex(index);

        // Get the sectionName of the item being edited
        const sectionNameToEdit = inputfield[index].sectionName;

        // Set the SectionName state with the sectionName of the item being edited
        setSectionName(sectionNameToEdit);

        // Open the card for editing
        setCardOpen(true);
        setSubtitleCard(false);
    };
    // tool selection dropdown
    const [selectedTools, setSelectedTools] = useState([]);
    const [availableTools, setAvailableTools] = useState([
        {name: 'Segue™', icon: <WavingHandIcon /> },
        {name:"Scorecard",icon:<DomainVerificationRoundedIcon/>},
        {name:'Headlines',icon:<FeedIcon/>},
        {name:'To-Dos',icon:<ListAltIcon/>},
        {name:'V/TO®',icon:<WcIcon/>},
        {name:'V/TO® - Goals',icon:<WcIcon/>},
        {name:'V/TO® - Long-Term',icon:<WcIcon/>},
        {name:'V/TO® - SWOT',icon:<WcIcon/>},
        {name:'EOS Toolbox™',icon:<SchoolIcon/>},
    ]);
    // sorting tools
    const handleToolSelection = (tool) => {
        // Add the selected tool to the list of selected tools
        setSelectedTools([...selectedTools, tool]);

        // Remove the selected tool from the available tools
        setAvailableTools(availableTools.filter((availableTool) => availableTool !== tool));
    };
    const handleDeleteTol = (index) => {
        // Create a copy of the selected tools array
        const updatedSelectedTools = [...selectedTools];

        // Remove the selected tool at the specified index
        const deletedTool = updatedSelectedTools.splice(index, 1)[0];

        // Update the state with the new array of selected tools
        setSelectedTools(updatedSelectedTools);

        // Update the state to add the deleted tool back to the available tools at the original index
        setAvailableTools((prevAvailableTools) => {
            const newAvailableTools = [...prevAvailableTools];

            // Use splice to insert the deleted tool at the original index
            newAvailableTools.splice(index, 0, deletedTool);

            return newAvailableTools;
        });
    };
    const handleToolInputChange = (index, field, value) => {
        const updatedTools = [...selectedTools];
        updatedTools[index][field] = value;
        setSelectedTools(updatedTools);
    };
    // below will be the duration of all meetings
    const calculateTotalDuration = () => {
        // Calculate total duration for AnnualsessionDay2modal
        const AnnualsessionDay2modalTotalDuration = AnnualsessionDay2modal
            .slice(0, 6)
            .reduce((total, item) => total + parseInt(item.duration, 10) || 0, 0);
    
        // Calculate total duration for inputfield
        const inputfieldTotalDuration = inputfield
            .reduce((total, value) => total + parseInt(value.duration, 10) || 0, 0);
    
        // Calculate total duration for AnnualsessionDay2modal (slice 6, 7)
        const AnnualsessionDay2modalTotalDuration2 = AnnualsessionDay2modal
            .slice(6, 8) // changed from slice(6, 7) to slice(6, 8)
            .reduce((total, item) => total + parseInt(item.duration, 10) || 0, 0);
    
        const Selectedtoolduration = selectedTools.reduce((total, value) => total + parseInt(value.duration, 10) || 0, 0);
        // Sum up all the durations
        const totalMinutes  = AnnualsessionDay2modalTotalDuration + inputfieldTotalDuration + AnnualsessionDay2modalTotalDuration2 + Selectedtoolduration;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
    
        return {
            hours,
            minutes,
        };
    };
    

    // Call the function to get the total duration
    const totalDuration = calculateTotalDuration();
    // Card 2  starts from here
    const [focusedInputIndex, setFocusedInputIndex] = useState(null);
    const [SectionName, setSectionName] = useState('');
    const handleSectionName = (index, field, value) => {
        // Update the sectionName for the currently focused input field
        if (index === focusedInputIndex && field === 'sectionName') {
            const updatedInputField = [...inputfield];
            updatedInputField[index].sectionName = value;
            setinputfield(updatedInputField);
            setSectionName(value);
        }
    };
    // for closing card 2 with icon 
    const [isCardOpen, setCardOpen] = useState(true);
    const handleCloseCard = () => {
        setCardOpen(false);
        // below for close card 3=subtitle card
        setSubtitleCard(false)
    };
    // For checking the valid url 
    const [url, setUrl] = useState('');
    const [isValid, setIsValid] = useState(null);

    const validateUrl = (value) => {
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlPattern.test(value);
    };

    const handleUrlCheck = () => {
        // Perform URL validation logic or API calls here
        const isValidUrl = validateUrl(url);
        console.log('URL:', url);
        console.log('isValidUrl:', isValidUrl);

        setIsValid(isValidUrl);

        // Provide user feedback
        if (isValidUrl) {
            alert('URL is valid!');
        } else {
            alert('Invalid URL. Please enter a valid URL.');
        }
    };
    // warning label to show or hide div
    const [isVisible, setIsVisible] = useState(false);

    const toggleWarningDiv = () => {
        setIsVisible(!isVisible);
    };

    // Text Area Field
    const toolbarOptions = [
        'bold',
        'italic',
        'underline',
        'strike',
        { 'header': [1, 2, 3] },
        'align',
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'script': 'sub' },
        { 'script': 'super' },
        { 'direction': 'rtl' },
        { 'size': ['small', false, 'large', 'huge'] },
        { 'color': [] },
        { 'background': [] },
        { 'font': [] },
        'blockquote',
        'code-block',
        'clean',
    ];

    const modules = {
        toolbar: [toolbarOptions],
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'ordered',
        'bullet',
        'sub',
        'super',
        'direction',
        'size',
        'color',
        'background',
        'font',
        'blockquote',
        'code-block',
        'clean',
    ];
    const [text, setText] = useState('');
    const handleTextChange = (value) => {
        setText(value);
    };

    //card 3 starts from here
    const [isSubtitlecard, setSubtitleCard] = useState(false);
    const [AnnualsessionDayonemodalSectionName, setAnnualsessionDayonemodalSectionName] = useState("");
    const handleSubtitlecardEdit = (AnnualsessionDayoneSectionName) => {
        // below is used for meeting Name
        setAnnualsessionDayonemodalSectionName(AnnualsessionDayoneSectionName);
        setSubtitleCard(true)
        setCardOpen(false)
    }
    const handleStart = (e, index) => {
        // Add logic for handleStart if needed
        console.log('Drag started for index:', index);
    };

    const handleDrag = (e, data, index) => {
        // Update the position of the dragged item
        // const newPosition = Math.floor(data.y / 25);
        // const reorderedInputField = [...inputfield];
        // const draggedItem = reorderedInputField.splice(index, 1)[0];
        // reorderedInputField.splice(newPosition, 0, draggedItem);
        // setinputfield(reorderedInputField);
    };

    const handleStop = (e, index) => {
        // Add logic for handleStop if needed
        console.log('Drag stopped for index:', index);
    };
    return (
        <>
            <div className='d-flex flex-row justify-content-betweeen mt-2' style={{ overflowX: "auto" }}>
                <Card className="mx-2 " style={{ position: 'relative', width: "1100px" }}>
                    <div className='px-3'>
                        <h5 className="my-3" style={{fontFamily: 'Poppins, sans-serif'}}>Annual Session - Day Two Agenda</h5>
                        <p>See More Options to push these agenda changes to all existing teams.</p>
                    </div>
                    <div className='d-flex flex-row w-100'>
                        <h6 style={{ paddingLeft: "70px" }}>Section Name</h6>
                        <h6 style={{ paddingLeft: "450px" }}>Duration (minutes)</h6>
                        <h6 className='ps-4'>Visible</h6>
                    </div>
                    {/* below is the main container for input fields */}
                    <FormControl>
                            {/* Below is model we map to get 4 input field  */}
                            <div>
                                {AnnualsessionDay2modal.slice(0, 7).map((item, index) => (
                                         <Draggable
                                         key={`draggable-${index}`}
                                         axis="y"
                                         handle=".handle"
                                         defaultPosition={{ x: 0, y: 0 }}
                                         position={null}
                                         grid={[25, 25]}
                                         scale={1}
                                         onStart={(e) => handleStart(e, index)}
                                         onDrag={(e, data) => handleDrag(e, data, index)}
                                         onStop={(e) => handleStop(e, index)}
                                     >
                                         <div className='handle'>
                                    <div className='mt-2 d-flex flex-row ms-3'
                                    >
                                        <div className='pt-2 mx-3'>
                                            <DragIndicatorIcon className='d-flex justify-content-center align-items-center' />
                                        </div>
                                        <input
                                            type="text"
                                            color="primary"
                                            value={item.inputtextname}
                                            style={{ height: '30px', width: '500px', borderRadius: '4px', borderWidth: '1px' }}
                                            className='mt-2' onChange={(e) => handleMeetingInputChange(e, index, 'inputtextname')}
                                        />
                                        <input
                                            type="number"
                                            value={item.duration}
                                            style={{ height: '30px', marginLeft: '10px', width: '100px', borderRadius: '6px', borderWidth: '1px' }}
                                            className='mt-2 ms-5' onChange={(e) => handleMeetingInputChange(e, index, 'duration')}
                                        />
                                        <Tooltip title="Hide default section from agenda" className='ms-4' arrow>
                                            {item.visibility}
                                        </Tooltip>
                                        <Tooltip title='this default section cannot be edited' className='ms-2'>{item.lockIcon}</Tooltip>
                                        <Tooltip title="Edit section" onClick={() => handleSubtitlecardEdit(item.inputtextname)}>{item.EditIcon}</Tooltip>
                                    </div>
                                    </div>
                                    </Draggable>
                                ))}
                            </div>
                            {/* Render dynamic Section Name fields */}
                            <div>
                                {inputfield.map((value, index) => (
                                         <Draggable
                                         key={`draggable-${index}`}
                                         axis="y"
                                         handle=".handle"
                                         defaultPosition={{ x: 0, y: 0 }}
                                         position={null}
                                         grid={[25, 25]}
                                         scale={1}
                                         onStart={(e) => handleStart(e, index)}
                                         onDrag={(e, data) => handleDrag(e, data, index)}
                                         onStop={(e) => handleStop(e, index)}
                                     >
                                         <div className='handle'>
                                    <div className="d-flex flex-row mt-2">
                                        <Tooltip title='Drag from here' style={{ marginLeft: "30px" }}>
                                            <DragIndicatorIcon className='d-flex justify-content-center align-items-center' />
                                        </Tooltip>
                                        {/* ... Input fields here */}
                                        <input
                                            name="sectionName"
                                            className="mt-2 ms-3"
                                            style={{ height: "30px", width: "500px", borderRadius: "6px", borderWidth: "2px", borderColor: "red" }}
                                            type="text"
                                            value={inputfield[index].sectionName}
                                            onChange={(e) => {
                                                handleSectionName(index, 'sectionName', e.target.value);
                                            }}
                                            onFocus={() => setFocusedInputIndex(index)}
                                            onBlur={() => setFocusedInputIndex(null)}
                                        />
                                        <input
                                            name="Duration"
                                            style={{ height: "30px", width: "100px", borderRadius: "6px", borderWidth: "1px", marginLeft: "50px" }}
                                            type="number"
                                            className="mt-2"
                                            value={value.duration}
                                            onChange={(e) => handlechange(index, 'duration', e.target.value)}
                                        />
                                        {/* ... Icons Here */}
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10px" }}>
                                            <Tooltip title='Delete'>
                                                <DeleteIcon onClick={handleDelete} style={{ marginLeft: "40px", paddingTop: "2px", top: "10%" }} />
                                            </Tooltip>
                                            <Tooltip title={editingIndex === index ? 'Save' : 'Edit'} className='ms-4'>
                                                <EditIcon onClick={() => handleCustomSectionEdit(index)} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    </div>
                                    </Draggable>
                                ))}
                            </div>
                            {/* below willl be the selected tools from the dropdown button */}
                            {selectedTools.map((selectedTool, index) => (
                                     <Draggable
                                     key={`draggable-${index}`}
                                     axis="y"
                                     handle=".handle"
                                     defaultPosition={{ x: 0, y: 0 }}
                                     position={null}
                                     grid={[25, 25]}
                                     scale={1}
                                     onStart={(e) => handleStart(e, index)}
                                     onDrag={(e, data) => handleDrag(e, data, index)}
                                     onStop={(e) => handleStop(e, index)}
                                 >
                                     <div className='handle'>
                                <div>
                                    <div className='d-flex flex-row ms-3 mt-2'>
                                        <div className='pt-2 mx-3'>
                                            <DragIndicatorIcon className='d-flex justify-content-center align-items-center' />
                                        </div>
                                        <input
                                            type="text"
                                            id={`toolInput-${index}`}
                                            name={`toolInput-${index}`}
                                            color="primary"
                                            value={selectedTool.name || ''}
                                            style={{ height: '30px', width: '500px', borderRadius: '4px', borderWidth: '1px' }}
                                            className='mt-2'
                                            placeholder='Section Name' onChange={(e) => handleToolInputChange(index, 'name', e.target.value)}
                                        />
                                        <input
                                            type="number"
                                            style={{ height: '30px', marginLeft: '10px', width: '100px', borderRadius: '6px', borderWidth: '1px' }}
                                            className='mt-2 ms-5'
                                            placeholder='Duration'
                                            value={selectedTool.duration || ''} onChange={(e) => handleToolInputChange(index, 'duration', e.target.value)}
                                        />
                                        <Tooltip title='Delete' style={{ paddingTop: "40px" }}>
                                            <DeleteIcon onClick={handleDeleteTol} style={{ marginLeft: "40px", top: "10%" }} />
                                        </Tooltip>
                                        <Tooltip title='Lock'>
                                            <LockIcon style={{ marginLeft: "30px", paddingTop: "2px", top: "10%" }} />
                                        </Tooltip>
                                    </div>
                                </div>
                                </div>
                                </Draggable>
                            ))}
                            {/* conclude input field  that will be constant and dont edit or change*/}
                            <div>
                                {AnnualsessionDay2modal.slice(7, 8).map((item, index) => (
                                         <Draggable
                                         key={`draggable-${index}`}
                                         axis="y"
                                         handle=".handle"
                                         defaultPosition={{ x: 0, y: 0 }}
                                         position={null}
                                         grid={[25, 25]}
                                         scale={1}
                                         onStart={(e) => handleStart(e, index)}
                                         onDrag={(e, data) => handleDrag(e, data, index)}
                                         onStop={(e) => handleStop(e, index)}
                                     >
                                         <div className='handle'>
                                    <div className='mt-2 d-flex flex-row align-items-center' style={{ marginLeft: "70px" }}>
                                        <input
                                            color="primary"
                                            value={item.inputtextname}
                                            style={{ height: "30px", width: "500px", borderRadius: "6px", borderWidth: "1px" }}
                                            className='mt-2'
                                        />
                                        <input
                                            value={item.duration}
                                            style={{ height: "30px", width: "100px", borderRadius: "6px", borderWidth: "1px", marginLeft: "55px" }}
                                            className='mt-2'
                                        />
                                        <Tooltip title="Edit section" className='pt-2' style={{marginLeft:"80px"}} onClick={() => handleSubtitlecardEdit(item.inputtextname)}>{item.EditIcon}</Tooltip>

                                    </div>
                                    </div>
                                    </Draggable>
                                ))}
                            </div>
                    </FormControl>
                    {/* button for creating the input field */}
                    <div className='d-flex flex-row mt-3 mx-5'>
                        <button type='text' className='btn' style={{ border: "none", color: "#1993c6" }} onClick={dynamicallyAddInput}>
                            <AddIcon />  Add Custom Section
                        </button>
                        {/* 2nd Button  */}
                        <div className="dropdown">
                            <button class="btn dropdown-toggle mx-2" type="text" data-bs-toggle="dropdown" aria-expanded="false" style={{ border: "none" }}>
                                <AddIcon />  Add Tool Section
                            </button>
                            <ul className="dropdown-menu">
                                {availableTools.map((tool, index) => (
                                    <li key={index}>
                                        <a
                                            className="dropdown-item"
                                            href="##"
                                            onClick={() => handleToolSelection(tool)}
                                        >
                                            {tool.icon} {tool.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className='d-flex flex-row mt-2 ps-5'>
                          <h6 className="fw-bold text-secondary" style={{fontFamily: 'Poppins, sans-serif', fontSize: '14px'}} >Total Duration</h6>
                          <h6 className="fw-bold ms-2" style={{fontFamily: 'Poppins, sans-serif', fontSize: '14px'}}>{`${totalDuration.hours} hours, ${totalDuration.minutes} minutes`} </h6>
                        </div>

                    </div>
                </Card >
                {isCardOpen && inputfield.length > 0 && (
                    <Card className='pt-3 container-fluid' style={{ height: "400px", width: "40%" }}>
                        <div>
                            {/* for close icon and textsection Name*/}
                            <div className="d-flex flex-row">
                                <div>
                                    <Tooltip title={'Close Detail'} onClick={handleCloseCard}>
                                        <KeyboardArrowRightIcon />
                                    </Tooltip>
                                </div>
                                <span style={{ borderLeft: "1px solid grey", height: "36px" }}>

                                </span>
                                <span className='ps-3'>
                                    {SectionName}
                                </span>
                            </div>
                            {/* input field for url checker */}
                            <div className='ms-2 my-3'>
                                <TextField
                                    id="outlined-basic"
                                    label="Web page URL to load"
                                    variant="outlined"
                                    size="small"
                                    placeholder='https://www.ninety.io'
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    style={{ width: '88%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button variant='text' onClick={handleUrlCheck}>
                                                    Check URL
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <ReportProblemIcon className='ms-2 mt-1' style={{ color: "#1993c6", paddingTop: "3px" }} onClick={toggleWarningDiv} />
                                {isVisible && (
                                    <div className='mt-3' style={{ backgroundColor: "yellow", width: "70%" }}>
                                        *** Some websites block the ability to be loaded into an iframe on a different domain. Please make sure your page is visible before saving. If you are unable to see the page, double check the URL. Then see if there is an iframe friendly link.
                                    </div>
                                )}
                            </div>
                            <div className='mx-2'>
                                <ReactQuill
                                    value={text}
                                    onChange={handleTextChange}
                                    modules={modules}
                                    formats={formats}
                                    placeholder={'Section Details'}
                                    theme="snow"
                                    style={{
                                        width: "90%",
                                        height: '14vh', // Adjust the height as needed
                                        fontSize: '18px', // Adjust the font size as needed
                                    }}
                                />
                            </div>

                        </div>
                    </Card>
                )}
                {/* card 3 visibility  name= subtitle card*/}
                {isSubtitlecard && (
                    <Card className='pt-3 container-fluid' style={{ height: "290px", width: "35%" }}>
                        <div>
                            {/* for close icon and textsection Name*/}
                            <div className="d-flex flex-row">
                                <div>
                                    <Tooltip title={'Close Detail'} onClick={handleCloseCard}>
                                        <KeyboardArrowRightIcon />
                                    </Tooltip>
                                </div>
                                <span style={{ borderLeft: "1px solid grey", height: "36px" }}>

                                </span>
                                <span className='ps-3'>
                                    {AnnualsessionDayonemodalSectionName}
                                </span>
                            </div>
                            {/* input field for url checker */}
                            <div className='container-fluid mt-2 ms-2'>
                                <TextField className='w-100' id="outlined-subtitle" label="Subtitle" variant="outlined" size="small" />
                                <div className='mt-2'>
                                <ReactQuill
                                    value={text}
                                    onChange={handleTextChange}
                                    modules={modules}
                                    formats={formats}
                                    placeholder={'Section Detail'}
                                    theme="snow"
                                    style={{
                                        width: "100%",
                                        height: '14vh', // Adjust the height as needed
                                        fontSize: '18px', // Adjust the font size as needed
                                    }}
                                />
                            </div>
                            </div>

                        </div>
                    </Card>
                )}
            </div>

        </>
    );
}
