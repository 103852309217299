import React, { useState, useEffect } from 'react';
import Createbtn from '../../../../createbtn/create'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { TextField, IconButton, Menu, MenuItem, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Avatar from '@mui/material/Avatar';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/system';
import LandscapeIcon from '@mui/icons-material/Landscape';
import CampaignIcon from '@mui/icons-material/Campaign';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import NativeSelect from '@mui/material/NativeSelect';
import axios from 'axios'
//table
function createData(title, created, owner) {
  return { title, created, owner };
}

const rows = [
  createData('Post any announcements for the team here.  Just use the CREATE button to add a headline.', 'Dec 26, 2023', 'S'),
];

//modal
const style = {
  position: 'absolute',
  bottom: '0%',
  right: '0%',
  width: 400,
  bgcolor: 'background.paper',
  p: 3,
};

//teams
const teams = ['Team 1', 'Team 2', 'Team 3'];

const HeadlinesTable = ({ handleEditCardOpen }) => {

  // createbtn modal

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //modal
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  //quil

  const [value, setValue] = React.useState('');

  var toolbarOptions = [
    ['bold', 'italic'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'direction': 'rtl' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  //teams
  const [team, setTeam] = React.useState('');

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };


  // attachment
  // file upload
  const [file, setFile] = React.useState(null);
  //  const handleFileChange = (e) => {
  //    const selectedFile = e.target.files[0];
  //    setFile(selectedFile); // Save the selected file
  //  };

  //
  //
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile); // Save the selected file

    if (selectedFile) {
      setInputValues({
        ...inputValues,
        attachment: selectedFile.name, // Update attachment in inputValues
      });
    }
  };
  //
  const handleCancel = () => {
    setFile(null); // Remove the file
  };
  // apply the style in the uploaded file
  const HoverableDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between",
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#f5f5f5', // Change the background color on hover
    },
  });

  //comment button
  const [isButtonsVisible, setButtonsVisible] = React.useState(false);

  const handleTextFieldClick = () => {
    setButtonsVisible(true);
    setInputValues({
      ...inputValues,
      comment: '', // Set the initial value if needed
    });
  };

  const handleCancelButtonClick = () => {
    setButtonsVisible(false);
  };

  //inputvaluestoconsole

  const [inputValues, setInputValues] = React.useState({
    title: '',
    description: '',
    team: '',
    attachment: '',
    comment: '',
  });

  //const handleInputChange = (e) => {
  //    const { name, value } = e.target;
  //    setInputValues({
  //        ...inputValues,
  //        [name]: value,
  //    });
  //};

  const handleInputChange = (e, selectName) => {
    const { name, value } = e.target;

    if (selectName) {
      // Handle the Select separately
      setInputValues({
        ...inputValues,
        [selectName]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    }
  };

  const handleSave = () => {
    console.log(inputValues);
  };
  const [isEditCard, setEditcardvisible] = useState(false);
  const handleCardShow = () => {
    setEditcardvisible(true);
    handleEditCardOpen(); // Call handleEditCardOpen to open the edit card
  };
  const handleCardClose = () => {
    setEditcardvisible(false);
  };

  // Api calling for Headlines
  const [data, setdata] = useState([]);
  const [isloading, setisloading] = useState(true);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}todos/get`);
        const ApiResponse = response.data.todos;
        const headlines = ApiResponse.filter(data => data.type === 'headlines');
        setdata(headlines)
        console.log(headlines)
        setisloading(false);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData(); 
  }, []);
  // date formatting
  const formatDate = (dateString) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
  
    return `${month} ${day}, ${year}`;
  };
  return (
    <>
      <div className='d-flex flex-row w-100'>
        <div className="card m-3 shadow" style={{ height: "auto%" }}>
          <div className="ms-3 mt-3"><h5 style={{ fontFamily: 'Space Grotesk, sans-serif', fontWeight: '500', letterSpacing: '0.5px' }} >Headlines</h5></div>

          <TableContainer>
            {isloading ? (
              <h2>Loading Api...</h2> // Loader component
            ) : (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell align="left">Created</TableCell>
                    <TableCell align="left">Owner</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.title}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className="d-flex" component="th" scope="row">
                        <RadioButtonUncheckedIcon onClick={handleCardShow} style={{ fontSize: '26px', marginRight: '10px', color: '#E3E3E3' }} />
                        <p className="pt-1" style={{ fontFamily: 'Poppins, sans-serif', color: '#5c5c5c' }} >{row.title}</p>
                      </TableCell>
                      <TableCell style={{ fontFamily: 'Poppins, sans-serif' }} align="left">{row.created}</TableCell>
                      <TableCell align="left"><Avatar>{row.owner}</Avatar></TableCell>
                      <TableCell align="left"><MoreHorizIcon onClick={handleClick} style={{ color: '#9A9A9A' }} /></TableCell>
                    </TableRow>
                  ))}
                  {
                    data.map((headline, index) => (
                      <TableRow key={index}>
                        <TableCell>
                        <RadioButtonUncheckedIcon onClick={handleCardShow} style={{ fontSize: '26px', marginRight: '10px', color: '#E3E3E3' }} />
                          {headline.name}</TableCell>
                        <TableCell align="left">{formatDate(headline.createdAt)}</TableCell>
                        <TableCell align="left"><Avatar>OP</Avatar></TableCell>
                      <TableCell align="left"><MoreHorizIcon onClick={handleClick} style={{ color: '#9A9A9A' }} /></TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <button className="btn d-flex ms-3 mb-3 btn-outline-none w-25 mt-3"><AddIcon style={{ fontSize: '17px', color: '#2596BE' }} /><h6 className="m-0 ms-1 p-0 fw-bold" style={{ fontSize: '16px', color: '#2596BE' }} onClick={openModal} >Add Headline</h6></button>
          <Createbtn isOpen={isModalOpen} onClose={closeModal}/>
          {/*menu code*/}
          {/* <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={openModal} > <LandscapeIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Create Rock</MenuItem>
              <MenuItem onClick={openModal} > <CheckBoxOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '10px' }} /> Create To-Do</MenuItem>
              <MenuItem onClick={openModal} > <GppMaybeOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '10px' }} /> Create Issue</MenuItem>
              <MenuItem onClick={openModal} > <FontAwesomeIcon icon={faBullhorn} style={{ color: '#989898', marginRight: '10px' }} /> Create Headline</MenuItem> <hr className="m-0 p-0" />
              <Createbtn isOpen={isModalOpen} onClose={closeModal} />
              <MenuItem onClick={handleClose} > <ArchiveOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Archive</MenuItem>
              <MenuItem onClick={handleClose} > <LinkOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Copy Link</MenuItem> <hr className="m-0 p-0" />
              <MenuItem onClick={handleClose} ><FontAwesomeIcon icon={faTrashCan} style={{ color: '#989898', marginRight: '19px' }} />Delete</MenuItem>
            </Menu>
          </div> */}

        </div>

        {/*off-canvas code*/}

        {/* <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">

          <div class="offcanvas-header">

            <div className="offcanvas-title d-flex">
              <RadioButtonUncheckedIcon onClick={handleSave} style={{ fontSize: '28px', marginRight: '10px', color: '#707070' }} />
              <h5 className="mt-1" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }} >Edit Headline</h5>
            </div>
            <MoreHorizIcon onClick={handleClick} style={{ color: '#9A9A9A', marginLeft: '90px' }} />
            <Avatar className="" sx={{ width: 30, height: 30, backgroundColor: '#4E545B' }}>S</Avatar>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>

          <div class="offcanvas-body">

            <InputLabel>Title</InputLabel>
            <TextField id="outlined-basic" size="small" variant="outlined" fullWidth name="title" value={inputValues.title} onChange={handleInputChange} />

            <InputLabel className="mt-4">Description (optional)</InputLabel>
            <ReactQuill modules={module} style={{ marginTop: "8px" }} theme="snow" value={value} onChange={setValue} />

            <FormControl sx={{ m: 1, mt: 4, minWidth: '100%' }} size="small">
              <InputLabel id="demo-select-small-label">Team</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Team"
                value={inputValues.unit}
                onChange={(e) => handleInputChange(e, 'team')}
              >
                {teams.map((team) => (
                  <MenuItem key={team} value={team}>
                    {team}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <hr className="p-0 my-4" />

            <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Attachments</h5>

            <div className="py-2 mt-3 rounded rounded-3" style={{ backgroundColor: '#F5F5F5' }} >
              <p className="text-center pt-2" style={{ fontFamily: 'Poppins, sans-serif', color: '#707070', fontSize: '14px' }}>Drag and drop files to attach, or <label htmlFor="file-upload" className="fw-bold" style={{ color: '#2596BE' }} >browse</label></p>
            </div>

            <input
              type="file"
              id="file-upload"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />

            {file && (
              <HoverableDiv>
                <Typography>{file.name}</Typography>
                <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
              </HoverableDiv>
            )}

            <hr className="p-0 my-4" />

            <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Comments</h5>

            <div className="d-flex">
              <Avatar className="ms-3 mt-3" sx={{ width: 30, height: 30, backgroundColor: '#757575' }}>S</Avatar>
              <TextField className="mt-3 ms-3" placeholder="Add a comment.." id="outlined-basic" size="small" variant="outlined" fullWidth
                onClick={handleTextFieldClick}
                onChange={(e) => handleInputChange(e, 'comment')}
                value={inputValues.comment}
              />
            </div>

            {isButtonsVisible && (
              <div className="d-flex ms-5 mt-3">
                <button style={{ backgroundColor: '#2596BE' }} className="btn text-white mx-3">Comment</button>
                <button onClick={handleCancelButtonClick} className="btn btn-outline-secondary">Cancel</button>
              </div>
            )}
            <div className="mt-4 mb-2">
              <small style={{ fontFamily: 'Poppins, sans-serif', color: '#9D9D9D' }}>Created by Sadia on Dec 30, 2023</small>
            </div>


          </div>

        </div> */}
        {/* edit card */}
        {isEditCard && (
          <Card>
            <CardHeader style={{ marginBottom: "12px" }} title={
              <Typography gutterBottom variant="h5" component="h2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <RadioButtonUncheckedIcon style={{ fontSize: '28px', marginRight: '10px', color: '#707070' }} onClick={handleSave} />
                <h5 className="mt-1" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }} >Edit Issue</h5>
                <Typography style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <MoreHorizIcon onClick={handleClick} style={{ color: '#9A9A9A', marginLeft: '90px' }} />
                  <Avatar className="" sx={{ width: 30, height: 30, backgroundColor: '#4E545B' }}>S</Avatar>
                  <CloseIcon onClick={handleCardClose} style={{ cursor: "pointer" }} />
                </Typography>

              </Typography>
            } />
            <Typography component="hr" />
            <CardContent>
              <div className="d-flex flex-column w-100">
                <InputLabel>Title</InputLabel>
                <TextField id="outlined-basic" size="small" variant="outlined" fullWidth name="title" value={inputValues.title} onChange={handleInputChange} />

                <InputLabel className="mt-4">Description (optional)</InputLabel>
                <ReactQuill modules={module} style={{ marginTop: "8px" }} theme="snow" value={value} onChange={setValue} />

                <FormControl sx={{ m: 1, mt: 4, minWidth: '100%' }} size="small">
                  <InputLabel id="demo-select-small-label">Team</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Team"
                    value={inputValues.unit}
                    onChange={(e) => handleInputChange(e, 'team')}
                  >
                    {teams.map((team) => (
                      <MenuItem key={team} value={team}>
                        {team}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <hr className="p-0 my-4" />

              <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Attachments</h5>

              <div className="py-2 mt-3 rounded rounded-3" style={{ backgroundColor: '#F5F5F5' }} >
                <p className="text-center pt-2" style={{ fontFamily: 'Poppins, sans-serif', color: '#707070', fontSize: '14px' }}>Drag and drop files to attach, or <label htmlFor="file-upload" className="fw-bold" style={{ color: '#2596BE' }} >browse</label></p>
              </div>

              <input
                type="file"
                id="file-upload"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />

              {file && (
                <HoverableDiv>
                  <Typography>{file.name}</Typography>
                  <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                </HoverableDiv>
              )}

              <hr className="p-0 my-4" />

              <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Comments</h5>

              <div className="d-flex">
                <Avatar className="ms-3 mt-3" sx={{ width: 30, height: 30, backgroundColor: '#757575' }}>S</Avatar>
                <TextField className="mt-3 ms-3" placeholder="Add a comment.." id="outlined-basic" size="small" variant="outlined" fullWidth
                  onClick={handleTextFieldClick}
                  onChange={(e) => handleInputChange(e, 'comment')}
                  value={inputValues.comment}
                />
              </div>

              {isButtonsVisible && (
                <div className="d-flex ms-5 mt-3">
                  <button style={{ backgroundColor: '#2596BE' }} className="btn text-white mx-3">Comment</button>
                  <button onClick={handleCancelButtonClick} className="btn btn-outline-secondary">Cancel</button>
                </div>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    </>
  );
};

export default HeadlinesTable;