import React,{useState} from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SecondaryBar from '../../components/secondaryAppBar/secondaryBar';
import { styled } from '@mui/material/styles';
import Todo from '../../components/dashboardCards/todo';
import PersonalTodo from '../../components/dashboardCards/personalTodo';
import RocksMilestone from '../../components/dashboardCards/rocksMilestones';
import TeamTodoTable from '../../components/teamTodoTable/teamTodoTable';
import ScoreCardTable from '../../components/scoreCardTable/table';
import ScorecardDashboard from '../../components/dashboardCards/scorecardDashboard';
import EditLayout from '../../components/dashboardCards/editLayout';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CompanyADS = () => {

  const [showEditCard,setShowEditCard]=useState(false)
  const [gridMd, setGridMd] = useState(4); // Initial value for md prop

const showEditComponent=()=>{
setShowEditCard(true)
setGridMd(3);
//setGridMd(4);
}

const closeEditLayout=()=>{
  setShowEditCard(false);
}

  const handleClick = () => {
    // Update md prop value when the button is clicked
    setGridMd(3);
  };

   const handleMd4Click = () => {
    // Set md prop back to 4 when the second button is clicked
    setGridMd(4)
  };

  const handleCancelClick = () => {
    handleMd4Click();
    closeEditLayout();
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12}>
          {!showEditCard?<SecondaryBar editComponent={showEditComponent} />:''}
          {/* for edit topBar */}
         {showEditCard?( <Grid>
          {/*TopBar*/}
            <AppBar position="static" sx={{ bgcolor: '#2596be', width:'100%' }}>
              <Toolbar>
                <div className="row w-100">

                  <div className="col-12 col-lg-10 col-md-6"></div> {/*col-1*/}

                  <div className="col-12 col-lg-2 col-md-6 d-flex justify-content-end">
                    <Button className="bg-white text-black" variant="contained" onClick={handleCancelClick}>Cancel</Button>
                    <Button onClick={handleClick} sx={{ bgcolor: '#2596be' }} className="ms-2" variant="contained">SAVE</Button>
                  </div> {/*col-2*/}

                </div> {/*row*/}
              </Toolbar>
            </AppBar>
          </Grid>):''}
        </Grid>
        <div>
        <Grid container my={3} px={2} spacing={2}>
          <Grid item xs={12} md={gridMd}>
            <Todo />
          </Grid>
          <Grid item xs={12} md={gridMd}>
            <PersonalTodo />
          </Grid>
          <Grid item xs={12} md={gridMd}>
            <RocksMilestone />
          </Grid>
          <Grid item xs={false} sm={12} md={3} >
           { showEditCard? <EditLayout />:''}
          </Grid>
          <Grid item xs={16} md={12}>
            <ScorecardDashboard />
          </Grid>
        </Grid>
        </div>
      </Grid>
    </>
  )
}

export default CompanyADS