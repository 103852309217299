import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';


function DirectoryAppBar() {
  const handleRefreshClick = () => {
    // Handle the refresh icon click event here
    // You can add your refresh logic in this function
    console.log('Refresh icon clicked');
  };

  const handleSearchChange = (event) => {
    // Handle search field input changes here
    // event.target.value contains the search input value
    console.log('Search input:', event.target.value);
  };

  return (
    <div>
      <AppBar position="static" sx={{ bgcolor: '#2596be' }}>
        <Toolbar>
        <div className="row w-100">
         <div className="col-12 col-lg-5 col-md-12 col-sm-12 d-flex">
          <Typography variant="p" component="div" sx={{ flexGrow: 1, fontSize:'16px', display: 'flex' }}>
          <span className="mt-2" style={{ color: '#ffffff91', marginRight:'8px' }}>FILTERS</span> {/* Add the "Filter" text here */}
            <label className="mt-2" htmlFor="teams-select">Teams:</label>
            <Select
              variant="standard"
              id="teams-select"
              label="Teams"
              sx={{ marginRight: '16px',marginLeft:'10px', width: '180px'}}
              defaultValue={1}
              inputProps={{ style: { border: 'none' } }} // Add border style here
            >
              <MenuItem value={1}>Team 1</MenuItem>
              <MenuItem value={2}>Team 2</MenuItem>
              <MenuItem value={3}>Team 3</MenuItem>
            </Select>
           </Typography>
          </div>

          <div className="col-12 col-lg-7 col-md-12 col-sm-12 d-flex justify-content-between">
            <label className="mt-2" htmlFor="users-select">Users:</label>
            <Select
              variant="standard"
              id="users-select"
              label="Users"
              sx={{ marginRight: '16px',marginLeft:'10px', width: '200px', }}
              defaultValue={1}
              inputProps={{ style: { border: 'none' } }} // Add border style here
            >
              <MenuItem value={1}>User 1</MenuItem>
              <MenuItem value={2}>User 2</MenuItem>
              <MenuItem value={3}>User 3</MenuItem>
            </Select>

          <IconButton
            edge="end"
            color="inherit"
            aria-label="refresh"
            onClick={handleRefreshClick}
            sx={{ marginRight: '6px' }}
          >
            <RefreshIcon />
          </IconButton>

          <SearchIcon className="mt-2"/>
          <InputBase
            placeholder="Search Directory"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearchChange}
            sx={{ marginRight: '6px', borderBottom: '1px solid white' }}
          />
          </div>
          </div>
        </Toolbar>
      </AppBar>

      {/* Your content goes here */}
      {/* Add your list of teams and users */}
    </div>
  );
}

export default DirectoryAppBar;