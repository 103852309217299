import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Paper, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

function AddContactButton() {

  //contact
  const [contacts, setContacts] = useState([{ name: '', relation: '', phoneNumber: '' }]);

  const handleAddContact = () => {
    if (contacts.length < 3) {
      setContacts(prevContacts => [...prevContacts, { name: '', relation: '', phoneNumber: '' }]);
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const handleDeleteContact = (index) => {
    const updatedContacts = contacts.filter((_, idx) => idx !== index);
    setContacts(updatedContacts);
  };

  const handleSave = () => {
    console.log(contacts);
  };

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Grid item xs={12} sx={{ marginBottom: '25px' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
          Emergency Contact(s)
        </Typography>
      </Grid>

      <div className="row">
        <div className="col-12 col-lg-5 col-md-5">
          <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Name</label>
        </div>

        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
          <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Relationship</label>
        </div>

        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
          <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Phone Number</label>
        </div>
      </div>
      {/* TextField */}
      {contacts.map((contact, index) => (
        <div className="row" key={index}>
          <div className="col-12 col-lg-5 col-md-5">
            <TextField
              id={`name-${index}`}
              placeholder="Name"
              variant="outlined"
              size="small"
              fullWidth
              value={contact.name}
              onChange={(e) => handleContactChange(index, 'name', e.target.value)}
              className="my-2"
            />
          </div>

          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <TextField
              id={`relation-${index}`}
              placeholder="Relationship"
              variant="outlined"
              size="small"
              fullWidth
              value={contact.relation}
              onChange={(e) => handleContactChange(index, 'relation', e.target.value)}
              className="my-2"
            />

          </div>

          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <TextField
              id={`phoneNumber-${index}`}
              // id="outlined-basic"
              placeholder="Phone Number"
              variant="outlined"
              size="small"
              fullWidth
              value={contact.phoneNumber}
              onChange={(e) => handleContactChange(index, 'phoneNumber', e.target.value)}
              className="my-2"
            />

          </div>

          <div className="col-12 col-sm-12 col-md-1 col-lg-1">
            <DeleteIcon onClick={() => handleDeleteContact(index)} className="text-danger my-2" />
          </div>
        </div>
      ))}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <button onClick={handleAddContact} style={{ color: '#1993C6' }} className="btn mt-3"><AddCircleOutlineOutlinedIcon />&nbsp;&nbsp;Add Contact</button>
        </Grid>
      </Grid>
      
    </Paper>
  );
}

export default AddContactButton;
