import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { TextField, IconButton, Menu, MenuItem,Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { Done } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import Card from '@mui/material/Card';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import InputList from './dragInput';
import VTOAppBar from './vtoAppBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCaretDown, faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DeleteIcon from '@mui/icons-material/Delete';
import Createbtn from '../createbtn/create'



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(5),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function VisionTabs() {
  const [value, setValue] = React.useState(0);
  const defaultDate = new Date('2025-11-30');

  //for swot

  const sectionStyle = {
    textAlign: 'left',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const buttonStyle = {
    color: 'orange',
    '&:hover': {
      backgroundColor: '#eee',
    },
  };

  const handleButtonClickSwot = () => {
    // Handle button click action here
    console.log('Button clicked!');
  };

  //for swot end

  const [inputValueVision, setInputValueVision] = useState('Core Value');
  const [showValue, setShowValue] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [valueDescription, setValueDescription] = useState([{ coreValue: '', description: '' }]);
  const [doubleQui, setDoubleQui] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ReactQuill text area
  const toolbarOptions = [
    'bold',
    'italic',
    'underline',
    'strike',
    { 'header': [1, 2, 3] },
    'align',
    { 'list': 'ordered' },
    { 'list': 'bullet' },
    { 'script': 'sub' },
    { 'script': 'super' },
    { 'direction': 'rtl' },
    { 'size': ['small', false, 'large', 'huge'] },
    { 'color': [] },
    { 'background': [] },
    { 'font': [] },
    'blockquote',
    'code-block',
    'clean',
  ];

  const modules = {
    toolbar: [toolbarOptions],
  };
  

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'ordered',
    'bullet',
    'sub',
    'super',
    'direction',
    'size',
    'color',
    'background',
    'font',
    'blockquote',
    'code-block',
    'clean',
  ];
  const [text, setText] = useState('');
  const handleTextChange = (value) => {
    setText(value);
  };


  //for Description
  const handleValueDescription = (index, field, value) => {
    const updatedInputs = [...valueDescription];
    updatedInputs[index][field] = value;
    setValueDescription(updatedInputs);
  };
  const handleAddInput = () => {
    setValueDescription([...valueDescription, { coreValue: '', description: '' }]);
  };

  const handleRemoveInput = (index) => {
    const updatedInputs = valueDescription.filter((_, i) => i !== index);
    setValueDescription(updatedInputs);
  };

  const handleInputChange = (event) => {
    setInputValueVision(event.target.value);
  };

  const handleButtonClick = () => {
    setIsEditing(false);
    setShowValue(true);
  };
  const handleButtonEdit = () => {
    setIsEditing(true);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const doubleQuile = () => {
    setDoubleQui(true)
  }
  const closeDoubleQuile = () => {
    setDoubleQui(false)
  }
  const textColor = {
    color: '#969588',
  };

  const backgroundColor = {
    backgroundColor: '#FAFAFA', 
    padding: '8px', 
  };

  const vtoPrint=()=>{
    window.print();
  }

//Strengths
const [showTextField1, setShowTextField1] = useState(false);
  const [textFieldValue1, setTextFieldValue1] = useState('');

  const handleAddButtonClick1 = () => {
    setShowTextField1(true);
  };

  const handleTextFieldChange1 = (e) => {
    setTextFieldValue1(e.target.value);
  };

    const handleSaveButtonClick1 = () => {
    // Handle saving the text field value or perform any other necessary action
    console.log('Text field value:', textFieldValue1);

    // Reset the state to hide the text field
    setShowTextField1(false);
    setTextFieldValue1('');
  };


  //weakness
const [showTextField2, setShowTextField2] = useState(false);
  const [textFieldValue2, setTextFieldValue2] = useState('');

  const handleAddButtonClick2 = () => {
    setShowTextField2(true);
  };

  const handleTextFieldChange2 = (e) => {
    setTextFieldValue2(e.target.value);
  };

    const handleSaveButtonClick2 = () => {
    // Handle saving the text field value or perform any other necessary action
    console.log('Text field value:', textFieldValue2);

    // Reset the state to hide the text field
    setShowTextField2(false);
    setTextFieldValue2('');
  };


  //Opportunities
const [showTextField3, setShowTextField3] = useState(false);
  const [textFieldValue3, setTextFieldValue3] = useState('');

  const handleAddButtonClick3 = () => {
    setShowTextField3(true);
  };

  const handleTextFieldChange3 = (e) => {
    setTextFieldValue3(e.target.value);
  };

  const handleSaveButtonClick3 = () => {
    // Handle saving the text field value or perform any other necessary action
    console.log('Text field value:', textFieldValue3);

    // Reset the state to hide the text field
    setShowTextField3(false);
    setTextFieldValue3('');
  };

    //threats
const [showTextField4, setShowTextField4] = useState(false);
  const [textFieldValue4, setTextFieldValue4] = useState('');

  const handleAddButtonClick4 = () => {
    setShowTextField4(true);
  };

  const handleTextFieldChange4 = (e) => {
    setTextFieldValue4(e.target.value);
  };

  const handleSaveButtonClick4 = () => {
    // Handle saving the text field value or perform any other necessary action
    console.log('Text field value:', textFieldValue4);

    // Reset the state to hide the text field
    setShowTextField4(false);
    setTextFieldValue4('');
  };

//swot menu
  const [swotMenu, setSwotMenu] = React.useState(null);
  const open = Boolean(swotMenu);
  const handleMenuClick = (event) => {
    setSwotMenu(event.currentTarget);
  };
  const handleMenuClose = () => {
    setSwotMenu(null);
  };

   // createbtn modal

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>

  {/* For Toggle Button */}
  <Grid>
      <VTOAppBar printVTO={vtoPrint}/>
    </Grid>

    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" indicatorColor="secondary">
          <Tab label="Vision" {...a11yProps(0)} sx={{ '&.Mui-selected': { color: '#2596be' }, color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400', textTransform: 'capitalize', paddingTop: '28px', paddingBottom: '20px', paddingRight: '50px', paddingLeft: '50px' }} />
          <Tab label="Traction" {...a11yProps(1)} sx={{ '&.Mui-selected': { color: '#2596be' }, color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400', textTransform: 'capitalize', paddingTop: '28px', paddingBottom: '20px', paddingRight: '45px', paddingLeft: '45px' }} />
          <Tab label="SWOT" {...a11yProps(2)} sx={{ '&.Mui-selected': { color: '#2596be' }, color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400', textTransform: 'upercase', paddingTop: '28px', paddingBottom: '20px', paddingRight: '45px', paddingLeft: '45px' }} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ m: 1, p: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={8} >
              <Item>
                {isEditing ? (<>
                  <TextField sx={{ width: '86%' }} id="standard-basic" label="Core Value" variant="standard" value={inputValueVision}
                    onChange={handleInputChange} />
                  <IconButton onClick={handleButtonClick} style={{ float: 'right', marginTop: '15px' }}>
                    <Done />
                  </IconButton>
                  <IconButton style={{ float: 'right', marginTop: '15px' }} onClick={handleAddInput}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  {/* for add button */}

                  {valueDescription.map((input, index) => (
                    <div key={index}>
                      <Box sx={{ pt: 4 }}>
                        <TextField sx={{ width: '20%', mr: 1 }} value={input.coreValue} id="standard-basic" label="Core Value" variant="standard"
                          onChange={(e) => handleValueDescription(index, 'coreValue', e.target.value)} />
                        <TextField sx={{ width: '65%' }} value={input.description} id="standard-basic" label="Description" variant="standard"
                          onChange={(e) => handleValueDescription(index, 'description', e.target.value)} />
                        <IconButton style={{ float: 'right', marginTop: '15px' }} onClick={() => handleRemoveInput(index)}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </div>))}

                </>
                ) : (
                  <>
                    <IconButton onClick={handleButtonEdit} style={{ float: 'right', marginTop: '15px' }}>
                      <EditIcon />
                    </IconButton>
                    <Typography sx={{ margin: 2 }}>{inputValueVision}</Typography>
                  </>

                )}

                <Divider />
              </Item>
              <Item sx={{ mt: 1 }}>
                {/* Quilboot  DIv */}
                {doubleQui ? (<>
                  <TextField sx={{ width: '92%' }} id="standard-basic" label="Core Focus" variant="standard" />
                  <IconButton onClick={closeDoubleQuile} style={{ float: 'right', marginTop: '15px' }}>
                    <Done />
                  </IconButton>

                  <TextField className='my-4' sx={{ width: '100%' }} id="standard-basic" label="Purpose / Cause / Passion" variant="standard"
                  />
                  <Box>
                    <ReactQuill
                      className={"mb-4 rounded"}
                      value={text}
                      onChange={handleTextChange}
                      modules={modules}
                      formats={formats}
                      placeholder={'Description'}
                      theme="snow"
                      style={{
                        height: '15vh', // Adjust the height as needed
                        fontSize: '18px', // Adjust the font size as needed
                        borderRadius: '2px solid black'
                        // maxHeight:"200px"
                      }}
                    />
                  </Box>
                  <TextField className='my-5' sx={{ width: '100%' }} id="standard-basic" label="Purpose / Cause / Passion" variant="standard"
                  />
                  <Box>
                    <ReactQuill
                      className={"mb-4 rounded"}
                      value={text}
                      onChange={handleTextChange}
                      modules={modules}
                      formats={formats}
                      placeholder={'Description'}
                      theme="snow"
                      style={{
                      paddingBottom:'25px',
                        height: '18vh', // Adjust the height as needed
                        fontSize: '18px', // Adjust the font size as needed
                        borderRadius: '2px solid black'
                        // maxHeight:"200px"
                      }}
                    />
                  </Box>
                </>) : (
                  <>
                    <div style={{ display: 'flex' }}>
                      <Typography variant="h6">Core Focus</Typography>
                      <div style={{ flex: 1 }}>
                      </div>
                      <IconButton onClick={doubleQuile}>
                        <EditIcon />
                      </IconButton>
                    </div>
                    <hr />
                    <b>Niche</b>
                  </>
                )}


              </Item>
              {/* Second Div */}

              <Item sx={{ mt: 1 }}>
                <div style={{ display: 'flex' }}>
                  <TextField sx={{ width: '95%' }} id="standard-basic" label="Core Focus" variant="standard" />

                  <div style={{ flex: 1 }}>
                  </div>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </div>
                <hr />
                {/* Quilboot  DIv */}
                <div>
                  <Box>
                    <ReactQuill
                      className={"mb-5 rounded"}
                      value={text}
                      onChange={handleTextChange}
                      modules={modules}
                      formats={formats}
                      placeholder={'Description'}
                      theme="snow"
                      style={{
                        height: '14vh', // Adjust the height as needed
                        fontSize: '18px', // Adjust the font size as needed
                        borderRadius: '2px solid black'
                        // maxHeight:"200px"
                      }}
                    />
                  </Box>

                </div>
              </Item>

              {/* Third Div */}

              <Item sx={{ mt: 1 }}>
                <div style={{ display: 'flex' }}>
                  <Typography variant="h6">Goto Market Strategy</Typography>
                  <div style={{ flex: 1 }}>
                  </div>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </div>
                <TextField sx={{ width: '90%' }} id="standard-basic" label="Goto Market Strategy" variant="standard" />
                <hr />
                {/* Quilboot  DIv */}
                <div>
                  <TextField className='my-3' id="standard-basic" label="Target Market" variant="standard"
                  />
                  <Box>
                    <ReactQuill
                      className={"mb-5 rounded"}
                      value={text}
                      onChange={handleTextChange}
                      modules={modules}
                      formats={formats}
                      placeholder={'Description'}
                      theme="snow"
                      style={{
                        height: '10vh', // Adjust the height as needed
                        fontSize: '18px', // Adjust the font size as needed
                        borderRadius: '2px solid black'
                        // maxHeight:"200px"
                      }}
                    />
                  </Box>

                  <Grid className='pt-4' container spacing={1}>
                    <Grid item xs={12}>
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="contained-button-file">
                        <b>Attachments</b>
                        <IconButton variant="contained" component="span">
                          < AddCircleOutlineIcon />
                        </IconButton>
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Input 1"
                        variant="standard"
                        id="standard-basic"
                      />
                      <IconButton style={{ marginTop: '15px' }} onClick={handleAddInput}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                      {/* for add button */}

                      {valueDescription.map((input, index) => (
                        <div key={index}>
                          <Box sx={{ pt: 4 }}>
                            <TextField sx={{ width: '22%', mr: 1 }} value={input.coreValue} id="standard-basic" label="Unique" variant="standard"
                              onChange={(e) => handleValueDescription(index, 'coreValue', e.target.value)} />
                            <TextField sx={{ width: '69%' }} value={input.description} id="standard-basic" label="Description" variant="standard"
                              onChange={(e) => handleValueDescription(index, 'description', e.target.value)} />
                            <IconButton style={{ float: 'right', marginTop: '15px' }} onClick={() => handleRemoveInput(index)}>
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </div>))}

                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className='mb-4'
                        label="Input 2"
                        variant="standard"
                        id="standard-basic"
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <ReactQuill
                      className={"mb-5 rounded"}
                      value={text}
                      onChange={handleTextChange}
                      modules={modules}
                      formats={formats}
                      placeholder={'Description'}
                      theme="snow"
                      style={{
                        height: '10vh', // Adjust the height as needed
                        fontSize: '18px', // Adjust the font size as needed
                        borderRadius: '2px solid black'
                        // maxHeight:"200px"
                      }}
                    />
                  </Box>
                </div>
                <div>
                  <TextField className='my-3' id="standard-basic" label="Target Market" variant="standard"
                  />
                  <Box>
                    <ReactQuill
                      className={"mb-4 rounded"}
                      value={text}
                      onChange={handleTextChange}
                      modules={modules}
                      formats={formats}
                      placeholder={'Description'}
                      theme="snow"
                      style={{
                        height: '10vh', // Adjust the height as needed
                        fontSize: '18px', // Adjust the font size as needed
                        borderRadius: '2px solid black'
                        // maxHeight:"200px"
                      }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{ marginTop: "90px" }}>
                    <Typography variant="body1" fontSize="17px" fontWeight="bold" color="black">
                      Target Market
                    </Typography>
                    <Typography variant="body1" marginTop="20px" fontSize="17px" fontWeight="bold" color="black">
                      Attachments
                    </Typography>
                    <Typography variant="body1" fontSize="17px" fontWeight="700" color="black">
                      Three Uniques
                    </Typography>
                    <Typography variant="body1" marginTop="20px" fontSize="17px" fontWeight="bold" color="black">
                      Proven Process:
                    </Typography>
                    <Typography variant="body1" fontSize="17px" fontWeight="bold" color="black">
                      Guarantee:
                    </Typography>
                  </Box>
                </div>
              </Item>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Card sx={{ display: 'flex', flexDirection: 'column', borderRadius: 1, padding: '10px' }}>
                <Typography sx={{ marginBottom: '2px' }}>
                  3 Year
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-18px' }}>
                    <IconButton sx={{ marginBottom: '-19px' }}>
                      <EditIcon />
                    </IconButton>
                  </Box>

                  <TextField fullWidth id="standard-basic" label="3 Year" variant="standard" />
                </Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Future Date:</b>
                </Typography>
                <LocalizationProvider variant="standard" dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                    <DatePicker

                      label={'Pick date'}
                      views={['year', 'month', 'day']}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Revenue:<IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Box><TextField
                  className='mx-1'
                  sx={{ width: '35vh' }}
                  label="Description"
                  variant="standard"
                  id="standard-basic"
                />
                  <TextField sx={{ width: '12vh' }} label="Value"
                    variant="standard"
                    id="standard-basic"
                  />
                  <IconButton sx={{ margin: '0' }}>
                    <CloseIcon />
                  </IconButton></Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Profit:<IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Box><TextField
                  className='mx-1'
                  sx={{ width: '35vh' }}
                  label="Description"
                  variant="standard"
                  id="standard-basic"
                />
                  <TextField sx={{ width: '12vh' }} label="Value"
                    variant="standard"
                    id="standard-basic"
                  />
                  <IconButton sx={{ margin: '0' }}>
                    <CloseIcon />
                  </IconButton></Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Measurables:<IconButton onClick={handleClick}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}><IconButton>
                    <EditIcon/>
                  </IconButton>Add an Existing Measurable</MenuItem>
        <MenuItem onClick={handleClose}> <IconButton>
                    <AddIcon/>
                  </IconButton>Create and Add New Measurable</MenuItem>
        
      </Menu>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>What Does It Look Like?<IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>
      {/* Second Tab */}
      <CustomTabPanel className='m-1 pb-3 pt-1' value={value} index={1}>
        <Grid container xs={12} sm={12} md={12} lg={12} sx={{display:'flex', flexDirection:'row'}}>


    <Grid item xs={12} sm={12} md={12} lg={4}>
      <Card sx={{ display: 'flex', flexDirection: 'column', borderRadius: 1, padding: '7px' }}>
                <Typography sx={{ marginBottom: '2px' }}>
                  3 Year
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-18px' }}>
                    <IconButton sx={{ marginBottom: '-19px' }}>
                      <EditIcon />
                    </IconButton>
                  </Box>

                  <TextField fullWidth id="standard-basic" label="3 Year" variant="standard" />
                </Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Future Date:</b>
                </Typography>
               <Box >
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer variant='standard' components={['DatePicker', 'DatePicker', 'DatePicker']}>
                  <Box borderBottom={1} borderColor="divider">
                    <DatePicker
                      InputProps={{ disableUnderline: true }}
                      label={'Pick date'}
                      views={['year', 'month', 'day']}
                    />
                    </Box>
                  </DemoContainer>
                </LocalizationProvider>
               </Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Revenue:<IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Box><TextField
                  className='mx-1'
                  sx={{ width: '35vh' }}
                  label="Description"
                  variant="standard"
                  id="standard-basic"
                />
                  <TextField sx={{ width: '12vh' }} label="Value"
                    variant="standard"
                    id="standard-basic"
                  />
                  <IconButton sx={{ margin: '0' }}>
                    <CloseIcon />
                  </IconButton></Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Profit:<IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Box><TextField
                  className='mx-1'
                  sx={{ width: '35vh' }}
                  label="Description"
                  variant="standard"
                  id="standard-basic"
                />
                  <TextField sx={{ width: '12vh' }} label="Value"
                    variant="standard"
                    id="standard-basic"
                  />
                  <IconButton sx={{ margin: '0' }}>
                    <CloseIcon />
                  </IconButton></Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Measurables:<IconButton onClick={handleClick}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}><IconButton>
                    <EditIcon/>
                  </IconButton>Add an Existing Measurable</MenuItem>
        <MenuItem onClick={handleClose}> <IconButton>
                    <AddIcon/>
                  </IconButton>Create and Add New Measurable</MenuItem>
        
      </Menu>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>What Does It Look Like?<IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
              </Card>
              </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Card className="mt-2" sx={{ display: 'flex', flexDirection: 'column', borderRadius: 1, padding: '10px',marginLeft:'6px' }}>
                <Typography sx={{ marginBottom: '1px' }}>
                  3 Year
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-18px' }}>
                    <IconButton sx={{ marginBottom: '-19px' }}>
                      <EditIcon />
                    </IconButton>
                  </Box>

                  <TextField fullWidth id="standard-basic" label="3 Year" variant="standard" />
                </Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Future Date:</b>
                </Typography>
               <Box >
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer variant='standard' components={['DatePicker', 'DatePicker', 'DatePicker']}>
                  <Box borderBottom={1} borderColor="divider">
                    <DatePicker
                      InputProps={{ disableUnderline: true }}
                      label={'Pick date'}
                      views={['year', 'month', 'day']}
                    />
                    </Box>
                  </DemoContainer>
                </LocalizationProvider>
               </Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Revenue:<IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Box><TextField
                  className='mx-1'
                  sx={{ width: '35vh' }}
                  label="Description"
                  variant="standard"
                  id="standard-basic"
                />
                  <TextField sx={{ width: '12vh' }} label="Value"
                    variant="standard"
                    id="standard-basic"
                  />
                  <IconButton sx={{ margin: '0' }}>
                    <CloseIcon />
                  </IconButton></Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Profit:<IconButton>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Box><TextField
                  className='mx-1'
                  sx={{ width: '35vh' }}
                  label="Description"
                  variant="standard"
                  id="standard-basic"
                />
                  <TextField sx={{ width: '12vh' }} label="Value"
                    variant="standard"
                    id="standard-basic"
                  />
                  <IconButton sx={{ margin: '0' }}>
                    <CloseIcon />
                  </IconButton></Box>
                <hr />
                <Typography sx={{ marginBottom: '-3px' }} variant="h6" gutterBottom>
                  <b>Measurables:<IconButton onClick={handleClick}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  </b>
                </Typography>
                <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}><IconButton>
                    <EditIcon/>
                  </IconButton>Add an Existing Measurable</MenuItem>
        <MenuItem onClick={handleClose}> <IconButton>
                    <AddIcon/>
                  </IconButton>Create and Add New Measurable</MenuItem>
        
      </Menu>
                <hr />
                <Box style={backgroundColor}>
      <Typography variant="body1" style={textColor}>
      There are no Departmental Rocks for the selected team.
      </Typography>
    </Box>
              </Card>
            </Grid>

           <Grid item xs={12} sm={12} md={12} lg={4}>
              <Card sx={{height:'30vh',display: 'flex', flexDirection: 'column', borderRadius: 1, padding: '20px', marginLeft:'30px',marginTop:'20px' }}>
                <Typography>Long-Term Issues</Typography>
                <Box sx={{marginTop:'20px'}} style={backgroundColor}>
      <Typography variant="body1" style={textColor}>
      There are no Long-Term Issues for the selected team.
      </Typography>
    </Box>
              </Card>
              </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <div style={{margin: '8px'}}>
      <Grid container spacing={1}>
        {/* First row */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
        <Card >
          <div style={sectionStyle}>
          Strengths
            <Button style={buttonStyle} onClick={handleAddButtonClick1}>
             Add Strengths
            </Button>
          </div>
          {/* <InputList/> */}
            <div className="mt-0 d-flex">
              {showTextField1 && (
                <div className="text-center ms-5 pb-3 w-100 d-flex">
                  <TextField style={{width: '80%', me:'0'}} value={textFieldValue1} onChange={handleTextFieldChange1} id="standard-basic" variant="standard" />
                  <button onClick={handleMenuClick} className="btn p-0 mt-3 fs-5 text-secondary ms-3"><FontAwesomeIcon icon={faSquareCaretDown} /></button>
                </div>
              )}
            </div>

        </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card>
          <div style={sectionStyle}>
          Weaknesses
            <Button style={buttonStyle} onClick={handleAddButtonClick2}>
            Add Weaknesses
            </Button>
          </div>
          {/* <InputList/> */}
            <div className="mt-0 d-flex">
              {showTextField2 && (
                <div className="text-center ms-5 pb-3 w-100 d-flex">
                  <TextField style={{width: '80%', me:'0'}} value={textFieldValue2} onChange={handleTextFieldChange2} id="standard-basic" variant="standard" />
                  <button onClick={handleMenuClick} className="btn p-0 mt-3 fs-5 text-secondary ms-3"><FontAwesomeIcon icon={faSquareCaretDown} /></button>
                </div>
              )}
            </div>
          </Card>
        </Grid>
        
        {/* Second row */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card>
          <div style={sectionStyle}>
          Opportunities
            <Button style={buttonStyle} onClick={handleAddButtonClick3}>
              Add Opportunities
            </Button>
          </div>
          {/* <InputList/> */}
            <div className="mt-0 d-flex">
              {showTextField3 && (
                <div className="text-center ms-5 pb-3 w-100 d-flex">
                  <TextField style={{width: '80%', me:'0'}} value={textFieldValue3} onChange={handleTextFieldChange3} id="standard-basic" variant="standard" />
                  <button onClick={handleMenuClick} className="btn p-0 mt-3 fs-5 text-secondary ms-3"><FontAwesomeIcon icon={faSquareCaretDown} /></button>
                </div>
              )}
            </div>

          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card>
          <div style={sectionStyle}>
            Threats
            <Button style={buttonStyle} onClick={handleAddButtonClick4}>
            Add Threates
            </Button>
          </div>

          {/* <InputList/> */}
            <div className="mt-0 d-flex">
              {showTextField4 && (
                <div className="text-center ms-5 pb-3 w-100 d-flex">
                  <TextField style={{width: '80%', me:'0'}} value={textFieldValue4} onChange={handleTextFieldChange4} id="standard-basic" variant="standard" />
                  <button onClick={handleMenuClick} className="btn p-0  mt-3 fs-5 text-secondary ms-3"><FontAwesomeIcon icon={faSquareCaretDown} /></button>
                </div>
              )}
            </div>

            {/*more menu code*/}
<Menu
  id="basic-menu"
  swotMenu={setSwotMenu}
  open={open}
  onClose={handleMenuClose}
  MenuListProps={{
    'aria-labelledby': 'basic-button',
  }}
>
  <MenuItem onClick={openModal} disabled={isModalOpen}><PriorityHighIcon />&nbsp; Make it an Issue</MenuItem>
  <Createbtn isOpen={isModalOpen} onClose={closeModal} />
  <MenuItem onClick={openModal} disabled={isModalOpen}><div className="p-0 fs-5"><FontAwesomeIcon icon={faSquareCheck} /></div>&nbsp;&nbsp;&nbsp; Create a To-do</MenuItem>
  <MenuItem className="text-danger"><DeleteIcon />&nbsp;&nbsp;&nbsp;Delete</MenuItem>
</Menu>

          </Card>


        </Grid>
      </Grid>
    </div>
      </CustomTabPanel>
    </Box>
    </>
  );
}