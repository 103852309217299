import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LaunchIcon from '@mui/icons-material/Launch';

function SocialLinkedIn() {
  const handleEditEmailClick = () => {
    // Handle edit email action here
    console.log('Edit email clicked');
  };

  return (
    <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Social
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="ms-4" sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            LinkedIn
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-12px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <TextField
            style={{width: '95%'}}
            id="outlined-basic"
            // fullWidth
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="edit email"
                    onClick={handleEditEmailClick}
                  >
                    <LaunchIcon className='fs-5 text-black'/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default SocialLinkedIn;
