import React from 'react'
import { Grid } from '@mui/material'
import TextComponentTop from './textcomponenttop'
import ContactEmail from './contactemail'
import ContactPhone from './contactphone'
import ContactAditionalmail from './contacteditionalemail'

export default function Contact() {
    return (
        <>
            <Grid>
                <TextComponentTop />
            </Grid>
            <Grid sx={{ margin: '10px 5px 1px 5px' }}>
                <ContactEmail />
            </Grid>
            <Grid sx={{ margin: '0px 5px 5px 5px' }}>
                <ContactAditionalmail />
            </Grid>
            <Grid sx={{ margin: '10px 5px 5px 5px' }}>
                <ContactPhone />
            </Grid>
        </>
    )
}
