import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { DateRangePicker } from 'rsuite';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import 'rsuite/DateRangePicker/styles/index.css';
import {
  FaCalendar,
} from 'react-icons/fa';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));



// table
function createData(Owner, Title, Goal, Average, Total, Jun17, Jun10, Jun3, May27, May20, May13, May6, April29, April22, April13, April8, April1, Mar23) {
  return { Owner, Title, Goal, Average, Total, Jun17, Jun10, Jun3, May27, May20, May13, May6, April29, April22, April13, April8, April1, Mar23 };
}
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 24, 159, 6.0, 24, 4.0, 159, 6.0, 24, 4.0, 4.0, 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 90, 37, 43, 159, 6.0, 24, 4.0, 159, 6.0, 24, 4.0, 24, 4.0, 159, 6.0, 24, 4.0),
  createData('Eclair', 262, 16.0, 24, 6.0, 159, 6.0, 24, 4.0, 159, 6.0, 24, 4.0, 159, 6.0, 24, 4.0, 4.0, 159, 6.0, 24, 4.0, 159, 6.0, 24, 4.0),
];

const Annual = () => {
  const [weeklyApi, setweeklyApi] = useState([]);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleSelect = (ranges) => {
    console.log(ranges);
    // Here you can access ranges.selection.startDate and ranges.selection.endDate
    // if you need to do something with the selected dates.
    setSelectionRange(ranges.selection);
  };
  const [selectTeam, setselectTeam] = React.useState('');

  const handleChange = (event) => {
    setselectTeam(event.target.value);
  };




  return (
    <>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined" style={{ width: "900px" }}>
          <CardContent>
            <div className='d-flex flex-row justify-content-between align-items-center mx-4'>
              <div style={{ width: "15%" }}>
                <Typography variant="h5">
                  Annual
                  <AddCircleOutlineIcon className='ms-2' />
                </Typography>
              </div>
              <div style={{ width: "85%" }}>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                  <Typography variant='h6' style={{ fontSize: "12px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    Drag and Drop
                    <FormControlLabel className='ms-1'
                      control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                    />
                  </Typography>
                  <DateRangePicker size="lg" placeholder="Date" caretAs={FaCalendar} className='mx-2' style={{ width: "200px" }} />
                  <FormControl style={{ width: "600px", height: "40px" }}>
                    <InputLabel id="demo-simple-select-label">Default Team Interval</InputLabel>
                    <Select style={{ height: "40px", width: "390px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectTeam}
                      label="Default Team Interval"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Rolling 13 WEEKS</MenuItem>
                      <MenuItem value={20}>QTD</MenuItem>
                      <MenuItem value={30}>Current Quarter</MenuItem>
                      <MenuItem value={40}>YTD</MenuItem>
                      <MenuItem value={50}>Current Year</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className='w-100 mt-3'>
              <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                <Table sx={{ minWidth: 900 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Owner</TableCell>
                      <TableCell align="right">Title</TableCell>
                      <TableCell align="right">Goal</TableCell>
                      <TableCell align="right">Average</TableCell>
                      <TableCell align="right">Jun 17 - Jun 23</TableCell>
                      <TableCell align="right">Jun 10 - Jun 16</TableCell>
                      <TableCell align="right">Jun 03 - Jun 09</TableCell>
                      <TableCell align="right">May 27 - Jun 02</TableCell>
                      <TableCell align="right">May 20 - May 26</TableCell>
                      <TableCell align="right">May 13 - May 19</TableCell>
                      <TableCell align="right">May 06 - May 12</TableCell>
                      <TableCell align="right">Apr 29 - May 05</TableCell>
                      <TableCell align="right">Apr 22 - Apr 28</TableCell>
                      <TableCell align="right">Apr 15 - Apr 21</TableCell>
                      <TableCell align="right">Apr 08 - Apr 14</TableCell>
                      <TableCell align="right">Apr 01 - Apr 07</TableCell>
                      <TableCell align="right">Mar 25 - Mar 31</TableCell>
                      <TableCell align="right">Apr 08 - Apr 14</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.Owner}
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.Title}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.Average}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.Goal}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April1}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April13}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April22}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April22}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                        <TableCell align="right" sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{row.April29}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>

  );
}

export default Annual;
