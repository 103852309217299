import * as React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyName, setIndustry, setEmployee, setOS } from '../../store/slices/userSlice/createUserSlice';
import { sendDataToBackend } from '../../store/slices/apiSlice/apiSliceReducer';
import { selectEmail, selectPersonalInfo, selectCompanyInfo } from '../../store/slices/userSlice/createUserSlice';
import Cookies from 'js-cookie';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#1993c6',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#1993c6',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#1993c6',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#1993c6',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        borderColor: '#1993c6',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <CheckCircleOutlineIcon className="QontoStepIcon-completedIcon" />
            ) : (
                <RadioButtonUncheckedIcon className="QontoStepIcon-completedIcon" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;


    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {/* {icons[String(props.icon)]} */}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


function Copyright(props) {
    return (
        <Box>
            {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
            <FormGroup>
                <FormControlLabel
                    control={
                        <>
                            <Checkbox />
                            <Typography variant="body2" color="text.secondary" {...props}>
                                By continuing, you agree to the {' '}
                                <Link className="text-decoration-none" color="inherit" href="#">
                                    Terms and conditions
                                </Link>{' and'}{' '}
                                <Link className="text-decoration-none" color="inherit" href="#">
                                    Privacy Policy
                                </Link>
                            </Typography>
                        </>
                    }
                />
            </FormGroup>

        </Box>

    );
}

// TODO remove, this demo shouldn't need to reset the theme.
const steps = ['Email', 'Account', 'Company'];

const defaultTheme = createTheme();

export default function SetUpCompany({ onLogin }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const email = useSelector(selectEmail);
    const personalInfo = useSelector(selectPersonalInfo);
    const companyInfo = useSelector(selectCompanyInfo);

    const handleFieldChange = (field) => (e) => {
        const newValue = e.target.value;
        switch (field) {
            case 'companyName':
                dispatch(setCompanyName(newValue));
                break;
            case 'industry':
                dispatch(setIndustry(newValue));
                break;
            case 'employee':
                dispatch(setEmployee(newValue));
                break;
            case 'os':
                dispatch(setOS(newValue));
                break;
            default:
                break;
        }
    };
    const handleDataSubmission = () => {
        // Combine the data from different slices into a single payload
        // const payload = {
        //     email: email.email, // Adjust this based on your email state structure
        //     personalInfo,
        //     companyInfo,
        //     // ... other data you want to include
        // };
        const payload={
            email:email.email,
            password:personalInfo.password,
            firstName:personalInfo.firstName,
            lastName:personalInfo.lastName,
            companyName:companyInfo.companyName,
            industry:companyInfo.industry,
            employee:companyInfo.employee,
            operatingSystem:companyInfo.os,
            role:'owner',
        }
         dispatch(sendDataToBackend(payload))
            .then((response) => {
             if(response.payload.success){
                // Cookies.set('token',response.payload.token);
                 navigate('/login');
             }
            })
            .catch((error) => {
                console.error('Failed to send data:', error);
            });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid item xs={12} sm={12} md={6} lg={6} component={Paper} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                flexDirection: 'column',
                            }}>
                            <Typography component="h1" variant="h1" fontSize={'25px'} fontWeight={'700'} mt={2} color={"#1993c6"}>
                                Tell Us About Your Company
                            </Typography>
                            <Typography component="h5" variant="h5" fontSize={'14px'} fontWeight={'300'} mt={2} sx={{ color: "#000000DE" }}>
                                Let's get a few details about your company and industry.
                            </Typography>
                            <Box component="form" noValidate onSubmit={onLogin} mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} mt={1}>
                                        <Typography >Company Name</Typography>
                                        <TextField
                                            mt={2}
                                            required
                                            fullWidth
                                            id="companyName"
                                            placeholder='Enter your Company Name'
                                            name="company-name"
                                            type="text"
                                            value={companyInfo.companyName}
                                            onChange={handleFieldChange('companyName')}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={8} mt={2} >
                                        <Typography>Industry</Typography>
                                        <Select
                                            fullWidth
                                            labelId="industry-label"
                                            id="industry"
                                            value={companyInfo.industry}
                                            onChange={handleFieldChange('industry')}

                                        >

                                            <MenuItem value={'Professional Services'}>Professional Services</MenuItem>
                                            <MenuItem value={'Internet Software & Services'}>Internet Software & Services</MenuItem>
                                            <MenuItem value={'Construction & Engineering'}>Construction & Engineering</MenuItem>
                                            <MenuItem value={'Capital Markets'}>Capital Markets</MenuItem>
                                            <MenuItem value={'Diversified Financial Services'}>Diversified Financial Services</MenuItem>
                                            <MenuItem value={'Health Care Providers & Services'}>Health Care Providers & Services</MenuItem>
                                            <MenuItem value={'Media'}>Media</MenuItem>
                                            <MenuItem value={'Consumer Discretionary'}>Consumer Discretionary</MenuItem>
                                            <MenuItem value={'Real Estate'}>Real Estate</MenuItem>
                                            <MenuItem value={'Other'}>Other</MenuItem>
                                            <MenuItem value={'Education Services'}>Education Services</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} mt={2} >
                                        <Typography>Employees</Typography>

                                        <Select
                                            fullWidth
                                            labelId="employee-label"
                                            id="employee"
                                            value={companyInfo.employee}
                                            onChange={handleFieldChange('employee')}
                                        >
                                            <MenuItem value=''>1-10</MenuItem>

                                            <MenuItem value={'11-50'}>11-50</MenuItem>
                                            <MenuItem value={'51-250'}>51-250</MenuItem>
                                            <MenuItem value={'251-1K'}>251-1K</MenuItem>
                                            <MenuItem value={'1K+'}>1K+</MenuItem>

                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} mt={2} >
                                        <Typography>What Business Operating System are you using?</Typography>

                                        <Select
                                            fullWidth
                                            labelId="os-label"
                                            id="os"
                                            value={companyInfo.os}
                                            onChange={handleFieldChange('os')}
                                        >
                                            <MenuItem value={'Empire'}>Empire</MenuItem>
                                            <MenuItem value={'EOS'}>EOS</MenuItem>
                                            <MenuItem value={'Fireproof'}>Fireproof</MenuItem>
                                            <MenuItem value={'Pinnacle'}>Pinnacle</MenuItem>
                                            <MenuItem value={"I'm not sure"}>I'm not sure</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} mt={2} >
                                    <Copyright />
                                </Grid>
                                <Button
                                    mt={2}
                                    // onClick={() => {
                                    //     navigate('/home');
                                    // }}
                                    // type="submit"
                                    onClick={handleDataSubmission}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, p: 2, borderRadius: '40px', backgroundColor: '#1993c6' }}
                                >
                                    All Set, Start My Trial
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Stepper alternativeLabel activeStep={2} connector={<QontoConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>

                    </Box>
                </Grid>
                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={6}
                    lg={6}
                    sx={{
                        backgroundImage: 'url(login_img.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Grid>
        </ThemeProvider >
    );
}