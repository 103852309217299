import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TablePagination,
    Typography,
    Grid,
} from '@mui/material';

const PastMeeting = ({ data, onPageChange, rowsPerPage, page }) => {
    const handleResend = (id) => {
        // Handle resend action here
    };

    return (
        <Grid sx={{ margin: '10px 5px 5px 5px', bgcolor: 'white', paddingBottom: '25px' }}>
        <TableContainer component={Paper} >
            <Typography variant="h5" align="left" gutterBottom sx={{marginLeft:'10px', marginTop:'10px'}}>
                Past Level 10 Meetings™
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontFamily: 'Poppins, sans-serif', color:'#757575'}} >Presenter</TableCell>
                        <TableCell style={{fontFamily: 'Poppins, sans-serif', color:'#757575'}}>Date</TableCell>
                        <TableCell style={{fontFamily: 'Poppins, sans-serif', color:'#757575'}}>Duration</TableCell>
                        <TableCell style={{fontFamily: 'Poppins, sans-serif', color:'#757575'}}>Rating</TableCell>
                        <TableCell style={{fontFamily: 'Poppins, sans-serif', color:'#757575'}}>Resend</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.length > 0 ? (
                        data.map((row, index) => (
                            <TableRow className="my-3" key={index}>
                                <TableCell>{row.presenter}</TableCell>
                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.duration}</TableCell>
                                <TableCell>{row.rating}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" onClick={() => handleResend(row.id)}>
                                        Resend
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell className="py-2 mt-3" colSpan={5} sx={{ bgcolor: '#FAFAFA' }}>
                                <Typography style={{fontFamily: 'Poppins, sans-serif', color:'#757575', fontSize:'14px'}} variant="body1" align="center">
                                    This team does not have any past Level 10 Meetings™.
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {data && data.length > 0 && (
                <TablePagination
                    component="div"
                    count={data.length}
                    page={page}
                    onPageChange={onPageChange}
                    rowsPerPage={rowsPerPage}
                    sx={{
                        ".MuiTablePagination-displayedRows": {
                          marginTop: "12px",
                        },
                        ".MuiTablePagination-spacer": {
                          paddingTop: 1,
                        },
                        ".MuiTablePagination-selectLabel": {
                          marginBottom: '8px',
                        },
                        ".MuiSelect-select": {
                          paddingTop: 0,
                        },
                      }}
                />
            )}
        </TableContainer>
        </Grid>
    );
};

export default PastMeeting;
