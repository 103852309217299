import React from 'react'
import { Grid } from '@mui/material'
import DateFormat from './preferences/dateformat'
import DefaultTeam from './preferences/defaultteam'
import AppBarPerferences from './preferences/appbar'

export default function Perferences() {
    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <AppBarPerferences />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ margin: '10px 5px 5px 5px' }}>
                    <DateFormat />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ margin: '10px 5px 5px 5px' }}>
                    <DefaultTeam />
                </Grid>
            </Grid>
        </>
    )
}
