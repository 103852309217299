import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export default function PersonalInformation() {
    // Define state variables for each input
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [optionalName, setOptionalName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [title, setTitle] = useState('');

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <label style={{color: '#0000008a'}}>Fisrt Name</label>
                    <TextField
                        fullWidth
                        placeholder="First Name"
                        id="outlined-size-small"
                        size="small"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <label style={{color: '#0000008a'}}>Last Name</label>
                    <TextField
                        fullWidth
                        placeholder="Last Name"
                        id="outlined-size-small"
                        size="small"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <label style={{color: '#0000008a'}}>Pronouns (Optional)</label>
                    <TextField
                        fullWidth
                        id="outlined-size-small"
                        placeholder="Enter your pronuns"
                        size="small"
                        value={optionalName}
                        onChange={(e) => setOptionalName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <label style={{color: '#0000008a'}}>Date of Birth (Optional)</label>
                    <TextField
                        fullWidth
                        id="outlined-size-small"
                        placeholder="Select your date of birth"
                        type="date"
                        size="small"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="d-flex flex-column">
                        <label style={{color: '#0000008a'}}>Title</label>
                        <TextField
                            fullWidth
                            id="outlined-size-small"
                            placeholder="Enter a title.."
                            size="small"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
