import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function TextComponentTop() {
  return (
    <Grid
      sx={{
        borderRadius: '5px',
        bgcolor: '#ffffff',
        marginTop: '7px',
        marginRight: '3px',
        marginLeft: '3px',
        boxShadow:
          '0 2px 1px -1px rgba(0, 0, 0, 0.03), 0 1px 1px rgba(0, 0, 0, 0.24), 0 1px 3px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography
        sx={{
          color: '#0000008a',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '14px',
          fontWeight: '400',
          padding: '16px',
        }}
      >
        Information entered in this section will be visible across all of your Companies.
      </Typography>
    </Grid>
  );
}
