import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import SecondaryBar from '../../components/secondaryAppBar/secondaryBar';
import { styled } from '@mui/material/styles';
// import Todo from '../../components/dashboardCards/todo';
// import PersonalTodo from '../../components/dashboardCards/personalTodo';
// import RocksMilestone from '../../components/dashboardCards/rocksMilestones';
// import TeamTodoTable from '../../components/teamTodoTable/teamTodoTable';
// import ScoreCardTable from '../../components/scoreCardTable/table';
// import TableScoreCard from '../../components/scorecard/tableScoreCard';
// import ScoreCardAppBar from '../../components/secondaryAppBar/scorecardAppBar';
// import TabsScoreCard from '../../components/tabs/tabsScoreCard';
import RocksBar from './rocksbar';
import Card from '@mui/material/Card';
import { Typography, IconButton, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Createbtn from '../../../../createbtn/create';
import ArchiveRock from './rocksArchive';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Done } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faAngleRight, faChevronRight, faUserPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import Select from '@mui/material/Select';
import { TextField, Menu, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axios from 'axios'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import MovingIcon from '@mui/icons-material/Moving';
import RocksAppbar from './rocksAppbar';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Rocks = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showComponent, setShowComponent] = useState(true);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const textColor = {
    color: '#969588',
  };

  const backgroundColor = {
    backgroundColor: '#FAFAFA',
    padding: '8px',
  };

  const handlePrint = () => {
    window.print();
  };

  const handleArchive = () => {
    setShowComponent(!showComponent)
  }

  //Accordions
  const [expanded, setExpanded] = useState(false);

  //create and edit

  const [inputValueDay, setInputValueDay] = useState('90 Days');
  const [showValue, setShowValue] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [valueDescription, setValueDescription] = useState([{ coreValue: '', description: '' }]);
  const [value, setValue] = useState(dayjs(''));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleButtonClick = () => {
    setIsEditing(false);
    setShowValue(true);
  };
  const handleButtonEdit = () => {
    setIsEditing(true);
  }


  //for Description
  const handleValueDescription = (index, field, value) => {
    const updatedInputs = [...valueDescription];
    updatedInputs[index][field] = value;
    setValueDescription(updatedInputs);
  };
  const handleAddInput = () => {
    setValueDescription([...valueDescription, { coreValue: '', description: '' }]);
  };


  const handleRemoveInput = (index) => {
    const updatedInputs = valueDescription.filter((_, i) => i !== index);
    setValueDescription(updatedInputs);
  };

  const handleInputChange = (event) => {
    setInputValueDay(event.target.value);
  };

  // measurables

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // measurables
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    height: 450,
    bgcolor: 'background.paper',
    p: 3,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleCloses = () => setOpen(false);
  // quill

  const [values, setValues] = useState('');
  var toolbarOptions = [
    ['bold', 'italic'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'direction': 'rtl' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  // offcanvas text-field

  // unit
  const [unit, setUnit] = useState('');

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  // orientation
  const [orientation, setOrientation] = useState('');

  const handleOrientationChange = (event) => {
    setOrientation(event.target.value);
  };

  // t4w calculations
  const [t4w, setT4w] = useState('');

  const handleT4WChange = (event) => {
    setT4w(event.target.value);
  };

  // owner change
  const [measurableOwner, setmeasurableOwner] = useState('');

  const handleOwnerChange = (event) => {
    setmeasurableOwner(event.target.value);
  };

  // seats
  const [seat, setSeat] = useState('');

  const handleSeatChange = (event) => {
    setSeat(event.target.value);
  };

  //inputvaluestoconsole

  const [inputValues, setInputValues] = React.useState({
    title: '',
    description: '',
    unit: '',
    goal: '',
    goalOrientation: '',
    t4w: '',
    owner: '',
    seat: '',
  });

  //const handleInputChange = (e) => {
  //    const { name, value } = e.target;
  //    setInputValues({
  //        ...inputValues,
  //        [name]: value,
  //    });
  //};

  const handleInputChange2 = (e, selectName) => {
    const { name, value } = e.target;

    if (selectName) {
      // Handle the Select separately
      setInputValues({
        ...inputValues,
        [selectName]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    }
  };

  const handleSave = () => {
    console.log(inputValues);
  };
  // Mian gee from here
  // api call below
  const [departmentrocksData, setdepartmentrocksData] = React.useState([]);
  const [employeerockdata, setemployeerockdata] = React.useState([]);
  const [isLoading, setisloading] = React.useState();
  const [length, setLength] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}todos/get`);
        const allrocks = response.data.todos;
        // Filter departmental rocks based on type === 'rocks' and rock.departmentrock === 'true'
        const departmentalRocks = allrocks.filter(rock => rock.type === 'rocks' && rock.departmentrock === 'true');
        const employeeRocks = allrocks.filter(rock => rock.type === 'rocks' && rock.departmentrock === 'false');
        console.log(employeeRocks);
        console.log(departmentalRocks);
        setdepartmentrocksData(departmentalRocks);
        setemployeerockdata(employeeRocks)
        setLength(departmentalRocks.length);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);
  const columns = [
    { id: 'status', label: 'Status' },
    { id: 'name', label: 'Title' },
    { id: 'date', label: 'Due By' },
    { id: 'owner', label: 'Owner' },
    // { id: 'goal', label: 'Goal' },
    // { id: 'goalOrientationRole', label: 'Goal Orientation Role' },
    // { id: 'seat', label: 'Seat' },
    // { id: 'timeDuration', label: 'Time Duration' }
  ];

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <RocksAppbar handlePrintRock={handlePrint} handleSwitchChange={handleArchive} />
        </Grid>

        {/* Archive work */}
        {showComponent ? <Grid item xs={12}>
          {/*accordian code*/}
          <div className="mt-5 w-100">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ py: '20px' }}
              >
                <Typography sx={{ fontFamily: 'Poppins, sans-serif' }}>V/TO® | Revenue, Profit, Measurables</Typography>
              </AccordionSummary>
              <AccordionDetails>

                <div className="w-75">
                  {isEditing ? (<>
                    <TextField sx={{ width: '86%' }} id="standard-basic" label="90 Days" variant="standard" value={inputValueDay}
                      onChange={handleInputChange} />
                    <IconButton onClick={handleButtonClick} style={{ float: 'right', marginTop: '15px' }}>
                      <Done />
                    </IconButton>
                    <IconButton style={{ float: 'right', marginTop: '15px' }} onClick={handleAddInput}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                    {/* for add button */}

                    {/*Date*/}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <h6 className="fw-bold text-black mt-2" style={{ fontFamily: 'Poppins, sans=serif' }} >Future Date:</h6>
                      <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                          value={value}
                          onChange={(newValue) => setValue(newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    {/*Revenue*/}
                    <div className="d-flex mt-4">
                      <h6 className="fw-bold text-black mt-2" style={{ fontFamily: 'Poppins, sans=serif' }} >Revenue:</h6>
                      <IconButton onClick={handleAddInput}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </div>

                    {valueDescription.map((input, index) => (
                      <div key={index}>
                        <Box sx={{ pt: 3 }}>

                          <TextField sx={{ width: '70%', mx: '0' }} value={input.description} id="standard-basic" label="Description" variant="standard"
                            onChange={(e) => handleValueDescription(index, 'description', e.target.value)} /> &nbsp;
                          <TextField sx={{ width: '20%' }} value={input.coreValue} id="standard-basic" label="Value" variant="standard"
                            onChange={(e) => handleValueDescription(index, 'coreValue', e.target.value)} />
                          <IconButton style={{ float: 'right', marginTop: '15px' }} onClick={() => handleRemoveInput(index)}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </div>))}

                    {/*Profit*/}
                    <div className="d-flex mt-4">
                      <h6 className="fw-bold text-black mt-2" style={{ fontFamily: 'Poppins, sans=serif' }} >Profit:</h6>
                      <IconButton onClick={handleAddInput}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </div>

                    {valueDescription.map((input, index) => (
                      <div key={index}>
                        <Box sx={{ pt: 3 }}>

                          <TextField sx={{ width: '70%', mx: '0' }} value={input.description} id="standard-basic" label="Description" variant="standard"
                            onChange={(e) => handleValueDescription(index, 'description', e.target.value)} /> &nbsp;
                          <TextField sx={{ width: '20%' }} value={input.coreValue} id="standard-basic" label="Value" variant="standard"
                            onChange={(e) => handleValueDescription(index, 'coreValue', e.target.value)} />
                          <IconButton style={{ float: 'right', marginTop: '15px' }} onClick={() => handleRemoveInput(index)}>
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </div>))}

                    <div className="d-flex mt-3">
                      <h6 className="fw-bold text-black" style={{ fontFamily: 'Poppins, sans=serif' }} >Measurables:</h6>
                      <span>&nbsp;&nbsp;</span>
                      <AddCircleOutlineIcon onClick={handleClick} sx={{ fontSize: 22 }} />
                    </div>

                  </>
                  ) : (
                    <>
                      <IconButton onClick={handleButtonEdit} style={{ float: 'right', marginTop: '15px' }}>
                        <EditIcon />
                      </IconButton>
                      <Typography sx={{ margin: 2 }}>{inputValueDay}</Typography>
                    </>

                  )}

                  <Divider />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* rendering starts from here */}

          <Card
            sx={{
              borderRadius: 1,
              margin: '8px',
              alignItems: 'center',
            }}
          >
            {/* <Typography className='m-4' variant='h6'>Company Rocks</Typography> */}
            <TableContainer component={Paper}>
              <div className="d-flex justify-content-between pt-3 px-3 pb-3">
                <Typography variant='h5' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '500' }}> Company Rocks</Typography>
                <MovingIcon style={{ color: '#1993c6' }} />
              </div>
              {departmentrocksData.length > 0 ? (
                // Render the table if there is data
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* Display 'Status' and 'Title' columns first on the left side */}
                      {columns.slice(0, 2).map((column) => (
                        <TableCell
                          // onMouseEnter={() => setIsHovered(true)}
                          // onMouseLeave={() => setIsHovered(false)}
                          align='left' key={column.id}>
                          {column.label}
                          {/* {isHovered && (
                            <EditIcon
                              style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                              onClick={() => console.log('Edit clicked')} // Replace console.log with your edit action
                            />
                          )} */}
                        </TableCell>
                      ))}
                      {/* Display 'Due By' and 'Owner' columns on the right side */}
                      {columns.slice(2, 4).map((column) => (
                        <TableCell align='right' key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflowY: "auto" }}>
                    {departmentrocksData.map((row) => (
                      <TableRow key={row._id}>
                        {/* Render 'Status' and 'Title' cells on the left side */}
                        {columns.slice(0, 2).map((column) => (
                          <TableCell align='left' key={column.id}>{row[column.id]}</TableCell>
                        ))}
                        {/* Render 'Due By' and 'Owner' cells on the right side */}
                        {columns.slice(2, 3).map((column) => (
                          <TableCell align='right' key={column.id}>{row[column.id]}</TableCell>
                        ))}
                        {columns.slice(3, 4).map((column) => (
                          <TableCell align='right' style={{ display: "flex", justifyContent: "end" }}>
                            <Avatar>OP</Avatar>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                // {/* <div class="container">
                //   <div class="row">
                //     <div class="col-6 d-flex justify-content-start">
                //       <typography className='m-3'>Status</typography><br />
                //       <typography className='m-3'>Title</typography>
                //     </div>
                //     <div class="col-6 d-flex justify-content-end">
                //       <typography className='m-3'>Due By</typography><br />
                //       <typography className='m-3'>Owner</typography>
                //     </div>
                //   </div>
                // </div> */}
              ) : (
                // Render the <Box> tag if there is no data
                <Box style={backgroundColor}>
                  <Typography sx={{
                    flexGrow: '1',
                    textAlign: "center"
                  }} variant="body1" style={textColor}>
                    There are no Departmental Rocks for the selected team.
                  </Typography>
                </Box>
              )}
            </TableContainer>
          </Card>
          <Card
            sx={{
              borderRadius: 1,
              margin: '8px',
              alignItems: 'center',
            }}
          >
            <TableContainer component={Paper}>
              <div className="d-flex justify-content-between pt-3 px-3 pb-3">
                <Typography variant='h5' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '500' }}>Test Testkilo</Typography>
              </div>
              {employeerockdata.length > 0 ? (
                // Render the table if there is data
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* Display 'Status' and 'Title' columns first on the left side */}
                      {columns.slice(0, 2).map((column) => (
                        <TableCell align='left' key={column.id}>{column.label}</TableCell>
                      ))}
                      {/* Display 'Due By' and 'Owner' columns on the right side */}
                      {columns.slice(2, 4).map((column) => (
                        <TableCell align='right' key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflowY: "auto" }}>
                    {employeerockdata.map((row) => (
                      <TableRow key={row._id}>
                        {/* Render 'Status' and 'Title' cells on the left side */}
                        {columns.slice(0, 2).map((column) => (
                          <TableCell
                            // onMouseEnter={() => setIsHovered(true)}
                            // onMouseLeave={() => setIsHovered(false)}
                            align='left' key={column.id}>
                            {row[column.id]}
                            {/* {isHovered && (
                              <EditIcon
                                style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                                onClick={() => console.log('Edit clicked')} // Replace console.log with your edit action
                              />
                            )} */}
                          </TableCell>
                        ))}
                        {/* Render 'Due By' and 'Owner' cells on the right side */}
                        {columns.slice(2, 3).map((column) => (
                          <TableCell align='right' key={column.id}>{row[column.id]}</TableCell>
                        ))}
                        {columns.slice(3, 4).map((column) => (
                          <TableCell align='right' style={{ display: "flex", justifyContent: "end" }}>
                            <Avatar>OP</Avatar>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                // Render the <Box> tag if there is no data
                <Box style={backgroundColor}>
                  <Typography sx={{
                    flexGrow: '1',
                    textAlign: "center"
                  }} variant="body1" style={textColor}>
                    <Button startIcon={<ControlPointIcon />} onClick={openModal} >
                      Create Rock
                    </Button>
                  </Typography>
                  <Createbtn isOpen={isModalOpen} onClose={closeModal} />
                </Box>
              )}
            </TableContainer>

            {/* <Typography className='m-4' variant='h6'>Huzaifa</Typography>
            <div class="container">
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <typography className='m-3'>Status</typography><br />
                  <typography className='m-3'>Title</typography>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <typography className='m-3'>Due By</typography><br />
                  <typography className='m-3'>Owner</typography>
                </div>
              </div>
            </div>
            <Box style={backgroundColor}>
              <Typography sx={{
                flexGrow: '1',
                textAlign: "center"
              }} variant="body1" style={textColor}>
                <Button startIcon={<ControlPointIcon />} onClick={openModal} >
                  Create Rock
                </Button>
              </Typography>
              <Createbtn isOpen={isModalOpen} onClose={closeModal} />
            </Box> */}
          </Card>


          {/* <Card
            sx={{
              borderRadius: 1,
              margin: '8px',
              alignItems: 'center',
            }}
          >
            <Typography className='m-4' variant='h6'>Ayann</Typography>

            <div class="container">
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <typography className='m-3'>Status</typography><br />
                  <typography className='m-3'>Title</typography>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <typography className='m-3'>Due By</typography><br />
                  <typography className='m-3'>Owner</typography>
                </div>
              </div>
            </div>
            <Box style={backgroundColor}>
              <Typography sx={{
                flexGrow: '1',
                textAlign: "center"
              }} variant="body1" style={textColor}>
                <Button startIcon={<ControlPointIcon />} onClick={openModal}>
                  Create Rock
                </Button>
              </Typography>
            </Box>
          </Card> */}

          {/* <Card
            sx={{
              borderRadius: 1,
              margin: '8px',
              alignItems: 'center',
            }}
          >
            <Typography className='m-4' variant='h6'>Company Rocks</Typography>

            <div class="container">
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <typography className='m-3'>Status</typography><br />
                  <typography className='m-3'>Title</typography>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <typography className='m-3'>Due By</typography><br />
                  <typography className='m-3'>Owner</typography>
                </div>
              </div>
            </div>
            <Box style={backgroundColor}>
              <Typography sx={{
                flexGrow: '1',
                textAlign: "center"
              }} variant="body1" style={textColor}>
                <Button startIcon={<ControlPointIcon />} onClick={openModal}>
                  Create Rock
                </Button>
              </Typography>
            </Box>
          </Card> */}

          {/* <Card
            sx={{
              borderRadius: 1,
              margin: '8px',
              alignItems: 'center',
            }}
          >
            <Typography className='m-4' variant='h6'>Company Rocks</Typography>

            <div class="container">
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <typography className='m-3'>Status</typography><br />
                  <typography className='m-3'>Title</typography>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <typography className='m-3'>Due By</typography><br />
                  <typography className='m-3'>Owner</typography>
                </div>
              </div>
            </div>
            <Box style={backgroundColor}>
              <Typography sx={{
                flexGrow: '1',
                textAlign: "center"
              }} variant="body1" style={textColor}>
                <Button startIcon={<ControlPointIcon />} onClick={openModal}>
                  Create Rock
                </Button>
              </Typography>
            </Box>
          </Card> */}


        </Grid> : <ArchiveRock />}

        {/*Menu*/}

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}><IconButton><EditIcon /></IconButton><button onClick={handleOpen} className="btn text-decoration-none">Add an Existing Measurable</button></MenuItem>
          <MenuItem onClick={handleClose}> <IconButton><AddIcon /></IconButton>
            <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Create and Add New Measurable</button>
          </MenuItem>

        </Menu>

        {/*measurable model*/}
        <Modal
          open={open}
          onClose={handleCloses}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div id="modal-modal-title">
              <h3>Add Measurables</h3>
            </div>
            <div id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField id="standard-basic" style={{ width: 800 }} label="Search Measurables" variant="standard" />



              {/*Buttons*/}
              <div className="row mt-2">
                <div className="col-12 col-lg-6 col-sm-12 d-flex align-text-bottom">
                  <button className="btn shadow w-100 text-center">Cancel</button>
                </div>
                <div className="col-12 col-lg-6 col-sm-12 d-flex align-text-bottom">
                  <button className="btn w-100 text-center shadow" style={{ backgroundColor: '#F6F6F6' }}>SAVE</button>
                </div>
              </div>
            </div> {/*modal body div*/}
          </Box>
        </Modal>

        {/*offcanvas*/}
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
          <div class="offcanvas-body">
            <div className="d-flex">
              <button type="button" class="btn" data-bs-dismiss="offcanvas" aria-label="Close"><FontAwesomeIcon icon={faAngleRight} /></button>
              <h5 className="mt-1">Add New Measurable</h5>
            </div>

            <hr />

            {/* Title Field */}
            <div class="d-flex">
              <TextField
                variant="standard"
                size="lg"
                style={{ width: 400 }}
                label="Title*"
                name="title"
                value={inputValues.title}
                onChange={handleInputChange2}
              />
            </div>

            {/* Description starts */}

            <label className="mt-3 text-secondary fs-6">Description</label>
            <ReactQuill modules={module} style={{ marginTop: "8px" }} theme="snow" value={values} onChange={setValues} />

            {/*Text Field*/}

            <div className="row">
              <div className="col-12 col-lg-4 d-flex">
                <div>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Unit</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={inputValues.unit}
                      onChange={(e) => handleInputChange2(e, 'unit')}
                      label="Unit"
                    >
                      <MenuItem value={'Currency'}>Currency</MenuItem>
                      <MenuItem value={'Percentage'}>Percentage</MenuItem>
                      <MenuItem value={'Number'}>Number</MenuItem>
                      <MenuItem value={'Yes/No'}>Yes/No</MenuItem>
                      <MenuItem value={'Time'}>Time</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div> {/*col*/}

              <div className="col-12 col-lg-8 d-flex">
                <div>
                  <TextField sx={{ m: 1, minWidth: 120 }} id="standard-basic" label="Goal" variant="standard" name="goal" value={inputValues.goal} onChange={handleInputChange2} />
                </div>
              </div> {/*col*/}

            </div> {/*row*/}

            {/*2nd row*/}

            <div className="row">
              <div className="col-12 col-lg-6 d-flex">
                <div>
                  <FormControl variant="standard" sx={{ my: 1, minWidth: 160 }}>
                    <InputLabel id="demo-simple-select-standard-label"><small>Goal Orientation Role</small></InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={inputValues.goalOrientation}
                      onChange={(e) => handleInputChange2(e, 'goalOrientation')}
                      label="Goal Orientation Role"
                    >
                      <MenuItem value={'Inside min and max'}>Inside min and max</MenuItem>
                      <MenuItem value={'Outside min and max'}>Outside min and max</MenuItem>
                      <MenuItem value={'Greater than or equal to Goal'}>Greater than or equal to Goal</MenuItem>
                      <MenuItem value={'Greater than Goal'}>Greater than Goal</MenuItem>
                      <MenuItem value={'Equal to Goal'}>Equal to Goal</MenuItem>
                      <MenuItem value={'Less to Goal'}>Less to Goal</MenuItem>
                      <MenuItem value={'Less than or equal to Goal'}>Less than or equal to Goal</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div> {/*col*/}

              <div className="col-12 col-lg-6 d-flex">
                <div className="w-100">
                  <FormControl variant="standard" sx={{ my: 1, ms: 0, minWidth: 160 }}>
                    <InputLabel id="demo-simple-select-standard-label"><small>T4W & T13W Goal Calculation</small></InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={inputValues.t4w}
                      onChange={(e) => handleInputChange2(e, 't4w')}
                      label="T4W & T13W Goal Calculation"
                    >
                      <MenuItem value={'Total'}>Total(default)</MenuItem>
                      <MenuItem value={'Average'}>Average</MenuItem>
                    </Select>
                  </FormControl>

                </div>
              </div> {/*col*/}

            </div> {/*row*/}

            {/*3rd row*/}

            <div className="row">
              <div className="col-12 col-lg-6 d-flex">
                <div>
                  <FormControl variant="standard" sx={{ my: 1, minWidth: 160 }}>
                    <InputLabel id="demo-simple-select-standard-label"><small>Measurable Owner</small></InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={inputValues.owner}
                      onChange={(e) => handleInputChange2(e, 'owner')}
                      label="Measurable Owner"
                    >
                      <MenuItem value={'Adeel Saleem'}>Adeel Saleem</MenuItem>
                      <MenuItem value={'Husnain Khalid'}>Husnain Khalid</MenuItem>
                      <MenuItem value={'Muhammad Huzaifa'}>Muhammad Huzaifa</MenuItem>
                      <MenuItem value={'Sadia Saleem'}>Sadia Saleem</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div> {/*col*/}

              <div className="col-12 col-lg-6 d-flex">
                <div className="w-100">
                  <FormControl variant="standard" sx={{ my: 1, ms: 0, minWidth: 160 }}>
                    <InputLabel id="demo-simple-select-standard-label"><small>Seat</small></InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={inputValues.seat}
                      onChange={(e) => handleInputChange2(e, 'seat')}
                      label="Seat"
                    >
                      <MenuItem value={'Total'}>Total(default)</MenuItem>
                      <MenuItem value={'Average'}>Average</MenuItem>
                    </Select>
                  </FormControl>

                </div>
              </div> {/*col*/}

            </div> {/*row*/}

            {/*Buttons*/}
            <div className="row mt-3">
              <div className="col-12 col-lg-4 col-sm-12 d-flex align-text-bottom">
                <button data-bs-dismiss="offcanvas" aria-label="Close" className="btn shadow w-100 text-center">Cancel</button>
              </div>
              <div className="col-12 col-lg-8 col-sm-12 d-flex align-text-bottom">
                <button onClick={handleSave} className="btn w-100 text-center shadow" style={{ backgroundColor: '#F6F6F6' }}>SAVE</button>
              </div>
            </div>

          </div> {/*offcanvas-body*/}
        </div> {/*offcanvas*/}

        {/* <Grid container spacing={2} sx={{ margin: '3px'}}>
          <Grid item xs={12} md={12}>
            <TableScoreCard />
          </Grid>
        </Grid> */}
      </Grid>
    </>
  )
}

export default Rocks;
