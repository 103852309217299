import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PersonalTodoTable from './tables/personalTodoTable';
import Createbtn from '../createbtn/create'

const PersonalTodo = () => {

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    // createbtn modal

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Card sx={{ height: "100%", display: 'flex', flexDirection: 'column', borderRadius: 3 }}>
            <CardContent>
                <Box sx={{ flexGrow: 1, display: 'flex-column', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 600, fontFamily: 'Poppins,sans-serif', lineHeight: '150%' }} color="text.black" gutterBottom>
                        Personal To-Dos
                        <Badge badgeContent={3} color="primary" sx={{ marginLeft: "15px" }}>
                        </Badge>
                    </Typography>
                    <FormControl sx={{ minWidth: 120 }}>
                        <Select
                            value={age}
                            onChange={handleChange}
                            displayEmpty
                            sx={{ height: '35px' }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="">
                                Incomplete
                            </MenuItem>
                            <MenuItem value={10}>Complete</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <FormControl style={{overflowY:"auto",height:"560px",width:"100%"}}>
                <PersonalTodoTable />
                </FormControl>
            </CardContent>
            <div style={{ flex: 1 }}></div>
            <CardActions>
                <Button onClick={openModal} disabled={isModalOpen} sx={{ color: "rgba(25, 147, 198)" }} size="small" startIcon={<AddIcon />}>Add a To-Do</Button>
                <Createbtn isOpen={isModalOpen} onClose={closeModal} />
            </CardActions>
        </Card>
    )
}

export default PersonalTodo