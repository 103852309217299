import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import TablePagination from '@mui/material/TablePagination';
import { Grid } from '@mui/material';

const tableData = [
  { type: 'Meeting 1', presenter: 'John Doe', date: '2023-10-15' },
  { type: 'Meeting 2', presenter: 'Jane Smith', date: '2023-10-16' },
  { type: 'Meeting 3', presenter: 'Alice Johnson', date: '2023-10-17' },
  // Add more data as needed
];

const cellStyle = {
  color: '#000000de',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: '14px',
  fontWeight: '400',
  textTransform: 'capitalize',
};

const buttonStyle = {
  color: '#1993c6',
  background: 'transparent',
};

function MeetingTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dataSlice = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Grid sx={{ margin: '10px 5px 5px 5px', bgcolor: 'white' }}>

      {/* Second Card*/}
      <div className="card mt-2 w-100 mx-1">
        <div className="card-body">
          <h5 className="fs-6" style={{ fontFamily: 'Poppins, sans-serif' }} >Active Level 10 Meetings™</h5>

          {/*Table code*/}
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="left">Presenter</TableCell>
                </TableRow>
              </TableHead>
              {/*
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.status}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="left">{row.presenter}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}
            </Table>
          </TableContainer>

          <div className="mt-3 mb-0 mx-0">
            <button className="btn w-100" style={{ fontFamily: 'Poppins, sans-serif', backgroundColor: '#FAFAFA', color: '#757575', fontSize: '14px' }} >This team does not have any active Level 10 Meetings™.</button>
          </div>

        </div> {/*card body*/}
      </div> {/*card*/}

      {/* <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={cellStyle}>Type</TableCell>
                <TableCell sx={cellStyle}>Presenter</TableCell>
                <TableCell sx={cellStyle}>Date</TableCell>
                <TableCell sx={cellStyle}>Start Meeting</TableCell>
                <TableCell sx={cellStyle}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSlice.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={cellStyle}>{row.type}</TableCell>
                  <TableCell sx={cellStyle}>
                    <Avatar>
                      {row.presenter.split(' ').map(name => name[0]).join('')}
                    </Avatar>
                  </TableCell>
                  <TableCell sx={cellStyle}>{row.date}</TableCell>
                  <TableCell sx={cellStyle}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<PlayArrowIcon />}
                      sx={buttonStyle}
                    >
                      Start
                    </Button>
                  </TableCell>
                  <TableCell sx={cellStyle}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      sx={buttonStyle}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={tableData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10]}
          sx={{
            ".MuiTablePagination-displayedRows": {
              marginTop: "12px",
            },
            ".MuiTablePagination-spacer": {
              paddingTop: 1,
            },
            ".MuiTablePagination-selectLabel": {
              marginBottom: '8px',
            },
            ".MuiSelect-select": {
              paddingTop: 0,
            },
          }}
        />
      </div> */}
    </Grid>
  );
}

export default MeetingTable;
