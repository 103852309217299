import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SecondaryBar from '../../components/secondaryAppBar/secondaryBar';
import { styled } from '@mui/material/styles';
import Todo from '../../components/dashboardCards/todo';
import PersonalTodo from '../../components/dashboardCards/personalTodo';
import RocksMilestone from '../../components/dashboardCards/rocksMilestones';
import TeamTodoTable from '../../components/teamTodoTable/teamTodoTable';
import ScoreCardTable from '../../components/scoreCardTable/table';
import TableScoreCard from '../../components/scorecard/tableScoreCard';
import ScoreCardAppBar from '../../components/secondaryAppBar/scorecardAppBar';
import TabsScoreCard from '../../components/tabs/tabsScoreCard';
const ExcelJS = require('exceljs');

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const handlePrint = () => {
  window.print();
};
const handleXls=()=>{
  console.log("button click");
  const workbook=new ExcelJS.workbook();
  const sheet =workbook.addWorksheet("My sheet")
}
const Scorecard = () => {
  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <ScoreCardAppBar handlePrintSoc={handlePrint} handleXLScor={handleXls} />
        <Grid item xs={12}>
          <TabsScoreCard />
        </Grid>
        </Grid>
        {/* <Grid container spacing={2} sx={{ margin: '3px'}}>
          <Grid item xs={12} md={12}>
            <TableScoreCard />
          </Grid>
        </Grid> */}
      </Grid>
    </>
  )
}

export default Scorecard