import Switch from '@mui/material/Switch';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
const AnnualsessionDayonemodal=[
    // 1
    {
        id:1,
        inputtextname:'OBJECTIVES',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        Editicon:<EditIcon label='Edit section' />

    },
    // 2
    {
        id:2,
        inputtextname:'HITTING THE CEILING',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        Editicon:<EditIcon label='Edit section'/>,

    },
    // 3
    {
        id:3,
        inputtextname:'ACCOUNTABILITY CHART',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        Editicon:""
    },
    // 4
    {
        id:4,
        inputtextname:'ROCKS',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        Editicon:""

    }, 
    // 5
    {
        id:5,
        inputtextname:'MEETING PULSE',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        Editicon:<EditIcon label='Edit section'/>,

    },
    // 6
    {
        id:6,
        inputtextname:'DATA',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        Editicon:"",
    },
    // 7
    {
        id:7,
        inputtextname:'NEXT STEPS',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        Editicon:<EditIcon label='Edit section'/>,

    },
    // 8
    {
        id:8,
        inputtextname:'CONCLUDE',
        duration:5,
        lockIcon:"",
        Editicon:<EditIcon label='Edit section'/>,
    }
]
export default AnnualsessionDayonemodal;