import React from 'react';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import Accountabilitychart from './accountabilitychart';

const Accountabilitychartmodal = ({ isOpen, onClose, data, onSaveChanges }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "340px", height: "400px", backgroundColor: 'white', boxShadow: 24, padding: "24px", borderRadius: "4px", boxSizing: "border-box" }}>
                <TextField
                    label="Title"
                    variant="standard"
                    fullWidth
                    value={data.title}
                    onChange={(e) => onSaveChanges({ ...data, title: e.target.value })}
                    style={{ marginBottom: '20px' }}
                    required />
                <div className='d-flex flex-row'>
                    <p>Roles and Responsibilities</p>
                    <AddCircleOutlineIcon className='mx-2' />
                </div>
                <FormControl scroll style={{ height: "160px", width: "280px", overflowY: 'auto' }}>
                    <ul>
                        {data.responsibilities && data.responsibilities.map((responsibility, index) => (
                            <li key={index} className="responsibility-item w-100 d-flex" style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
                                <span style={{ marginRight: '10px' }}>{responsibility}</span>
                                <div style={{ marginLeft: 'auto' }}>
                                    <IconButton className="delete-icon" aria-label="delete">
                                        <DeleteIcon style={{ fontSize: '20px' }} />
                                    </IconButton>
                                </div>
                            </li>
                        ))}
                    </ul>
                </FormControl>
                <Typography className='d-flex flex-row'>
                    <h4>Attachments</h4>
                    <AddCircleOutlineIcon className='mx-2' />
                </Typography>
                <Typography style={{display:"flex",justifyContent:"flex-end"}}>
                <Button variant="outlined" className='mt-3 mx-2' onClick={() => onSaveChanges(data)}>
                    Cancel
                </Button>
                <Button className='mt-3 mx-2 bg-primary text-white' onClick={() => onSaveChanges(data)}>
                    Save
                </Button>
                </Typography>
            </div>
        </Modal>
    );
};

export default Accountabilitychartmodal;
