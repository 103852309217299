// redux/slices/apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
export const sendDataToBackend = createAsyncThunk(
  'api/sendDataToBackend',
  async (data, thunkAPI) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}users/signup`,data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
       return response.data;
    } catch (error) {
      console.error('Error sending data to the backend:', error);
      throw error;
    }
  }
);

const apiSlice = createSlice({
  name: 'api',
  initialState: {
    // ... any other state related to API calls
  },
  reducers: {
    // ... any other reducers related to API calls
  },
  extraReducers: (builder) => {
    builder.addCase(sendDataToBackend.fulfilled, (state, action) => {
      // Handle success if needed
    });
    builder.addCase(sendDataToBackend.rejected, (state, action) => {
      // Handle errors if needed
    });
  },
});

export default apiSlice.reducer;






// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// export const sendDataToBackend = createAsyncThunk(
//   'api/sendDataToBackend',
//   async (data, thunkAPI) => {
//     console.log("this is the data from apislice",data)
//     try {
//       // Perform your API request here
//       const response = await fetch('your-api-endpoint', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           // Add any other headers if needed
//         },
//         body: JSON.stringify(data),
//       });

//       // Check if the request was successful (you may need to adjust this based on your API)
//       if (!response.ok) {
//         throw new Error('Failed to send data to the backend');
//       }

//       // Return the response if you need to handle it in your component
//       return await response.json();
//     } catch (error) {
//       console.error('Error sending data to the backend:', error);
//       throw error;
//     }
//   }
// );

// const apiSlice = createSlice({
//   name: 'api',
//   initialState: {
//     // ... any other state related to API calls
//   },
//   reducers: {
//     // ... any other reducers related to API calls
//   },
//   extraReducers: (builder) => {
//     builder.addCase(sendDataToBackend.fulfilled, (state, action) => {
//       // Handle success if needed
//     });
//     builder.addCase(sendDataToBackend.rejected, (state, action) => {
//       // Handle errors if needed
//     });
//   },
// });

// export default apiSlice.reducer;
