import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

function DefaultTeam() {
  const [selectedTeam, setSelectedTeam] = useState('Team 1'); // Default team

  const handleTeamChange = (event) => {
    // Handle team selection change here
    setSelectedTeam(event.target.value);
  };

  const [selectedTimeZone, setSelectedTimeZone] = useState('(00:00) Greenwich');

  const handleTimeChange = (event) => {
    setSelectedTimeZone(event.target.value);
  };

  const [defaultTime, setDefaultTime] = React.useState(false);

  const handleDefaultTimeChange = (event) => {
    setDefaultTime(event.target.checked);
  };


  return (
    <>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
              Default Team
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
              This is the team that gets selected when you log in to ICTM.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <FormControl style={{ width: '43%' }} variant="outlined">
              <InputLabel id="team-label">&nbsp;</InputLabel>
              <Select
                size="small"
                labelId="team-label"
                id="team-select"
                value={selectedTeam}
                onChange={handleTeamChange}
                label=""
              >
                <MenuItem value="Team 1">Team 1</MenuItem>
                <MenuItem value="Team 2">Team 2</MenuItem>
                <MenuItem value="Team 3">Team 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} className="mt-2" style={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
              Preferred Timezone
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
              Select a timezone for your account to be used for timing emails.
            </Typography>
          </Grid>
          <Grid item xs={12} className="d-flex">
            <Checkbox
              color="secondary"
              checked={defaultTime}
              onChange={handleDefaultTimeChange}
              inputProps={{ 'aria-label': 'controlled' }}
              className="m-0 p-0"
            />
            <h6 className="ms-2 mt-1" style={{fontFamily: 'Poppins, sans-serif', fontSize: '15px'}}>Set timezone automatically</h6>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <FormControl style={{ width: '30%' }} variant="outlined">
              <Select
                size="small"
                id="timezone-select"
                value={selectedTimeZone}
                onChange={handleTimeChange}
              >
                <MenuItem value="(00:00) Greenwich">(00:00) Greenwich</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default DefaultTeam;
