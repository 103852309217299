import React,{useState} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TodoTable from './tables/todoTable';
import Createbtn from '../createbtn/create'
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
const Todo = () => {
  const [age, setAge] = React.useState('')
  // createbtn modal
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Table function
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Card sx={{ height: "720px", display: 'flex', flexDirection: 'column', borderRadius: 3 }}>
           <CardContent>
                <Box sx={{ flexGrow: 1, display: 'flex-column', justifyContent: 'space-between'}}>
                <Typography sx={{ fontSize: '20px', fontWeight: 600, fontFamily: 'Poppins,sans-serif', lineHeight: '150%' }} color="text.black" gutterBottom>
                Team To-Do
                    <Badge badgeContent={3} color="primary" sx={{ marginLeft: "15px" }}>
                    </Badge>
                </Typography>
                </Box>
                  {/* Table start */}
                  <FormControl className="w-100" style={{overflowY:"auto",height:"560px"}}>
                <TodoTable />
                </FormControl>
                </CardContent>
                <div style={{ flex: 1 }}></div>
            <CardActions>
                <Button onClick={openModal} disabled={isModalOpen} sx={{ color: "rgba(25, 147, 198)" }} size="small" startIcon={<AddIcon />}>Create a To-Do</Button>
                <Createbtn isOpen={isModalOpen} onClose={closeModal} />
            </CardActions>
  
      </Card>
  )
}

export default Todo;