import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

const styles = {
  dropdown: {
    borderTop: 'none', // Remove the top border
  },
};

function AddressForm() {
  const [formData, setFormData] = useState({
    street: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Grid item xs={12} sx={{ marginBottom: '25px' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
          Address
        </Typography>
      </Grid>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <label style={{ color: '#0000008a' }}>Street</label>
            <TextField
              fullWidth
              id="outlined-size-small"
              size="small"
              name="street"
              placeholder="Street"
              value={formData.street}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label style={{ color: '#0000008a' }}>City</label>
            <TextField
              fullWidth
              id="outlined-size-small"
              size="small"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <label style={{ color: '#0000008a' }}>Region</label>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="region"
                value={formData.region}
                onChange={handleInputChange}
                sx={styles.dropdown} // Apply the styles here
              >
                <MenuItem value="">Select Region</MenuItem>
                <MenuItem value="Region 1">Region 1</MenuItem>
                <MenuItem value="Region 2">Region 2</MenuItem>
                {/* Add more regions as needed */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <label style={{ color: '#0000008a' }}>Postal Code</label>
            <TextField
              fullWidth
              id="outlined-size-small"
              size="small"
              name="postalCode"
              placeholder="Postal Code"
              value={formData.postalCode}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <label style={{ color: '#0000008a' }}>Country</label>
              <Select
                name="country"
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={formData.country}
                onChange={handleInputChange}
                sx={styles.dropdown}
              >
                <MenuItem value="">Select Country</MenuItem>
                <MenuItem value="Country 1">Country 1</MenuItem>
                <MenuItem value="Country 2">Country 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default AddressForm;
