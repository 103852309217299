import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';
import TableScoreCard from '../scorecard/tableScoreCard';
import ScoreCardTable from '../scoreCardTable/table';

const ScorecardDashboard = () => {
  return (
    <Card sx={{ height: "410px", display: 'flex', flexDirection: 'column', borderRadius: 3 }}>
            <CardContent>
                <ScoreCardTable />
                {/* <RocksTable /> */}
            </CardContent>
            <div style={{ flex: 1 }}></div>
            
        </Card>
  )
}

export default ScorecardDashboard