import React,{useState} from 'react'
import HeadlinesTopBar from './topbar'
import HeadlinesTable from './headlinestable'
import CascadingMessages from './cascadingmessages'
import HeadlinesArchivePage from './headlinesarchive'
import HeadlinesPage from './headlinesmain'
import { Grid } from '@mui/material'

export default function Headlines() {

//archive
const [showComponent, setShowComponent] = React.useState(true);
  const archiveHandle=()=>{
    setShowComponent(!showComponent)
  }

  return (
    <>
    <Grid>
      <HeadlinesTopBar handleArchive={archiveHandle}/>
    </Grid>

    <Grid container xs={12}>
       {showComponent ? <HeadlinesPage/> : <HeadlinesArchivePage/>}
    </Grid>
    </>
  )
}