import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Createbtn from '../createbtn/create'


function CreateNewTool({ onIssueTitleChange }) {
    // State for handling the new tool dialog
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [date, setDate] = useState('');
    const [cards, setCards] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleRefreshClick = () => {
        // Handle the refresh icon click event here
        // You can add your refresh logic in this function
        console.log('Refresh icon clicked');
    };

    const handleSearchChange = (event) => {
        // Handle search field input changes here
        // event.target.value contains the search input value
        console.log('Search input:', event.target.value);
    };

    const handleNewToolClick = () => {
        // Open the new tool dialog
        setOpen(true);
    };

    const handleClose = () => {
        // Close the new tool dialog
        setOpen(false);
    };

    const handleSave = () => {
        // Save the entered data in cards
        const newCard = { name, description, url, date };
        setCards([...cards, newCard]);
        // Clear the form fields
        setName('');
        setDescription('');
        setUrl('');
        setDate('');
        // Close the dialog
        setOpen(false);
    };

    // Function to handle "Edit Layout" button click
    const handleEditLayoutClick = () => {
        // Perform the action when the "Edit Layout" button is clicked
        console.log('Edit Layout button clicked');
        // Add your logic here for editing the layout
    };

    const handleMoreClick = (event) => {
        // Open the more options menu
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        // Close the more options menu
        setAnchorEl(null);
    };

    const handleResetAll = () => {
        // Reset all cards
        setCards([]);
        handleCloseMenu();
    };

    const handleDeleteAll = () => {
        // Delete all cards
        setCards([]);
        handleCloseMenu();
    };

    // Getting the value of Issue
    const [issueTitle, setIssueTitle] = React.useState('');
    const handleIssueChange = (value) => {
        // Update the state in Create based on the issue title input
        setIssueTitle(value);
        onIssueTitleChange(value.trim() === '');
    };

    // createbtn modal

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
             <div className="row w-100 justify-content-between mt-3">
                <div class="col-12 col-lg-4 col-md-12 col-sm-12 d-flex">
                  <h4>EOS Toolbox™</h4>
                </div>

                <div class="col-12 col-lg-8 col-md-12 col-sm-12 d-flex justify-content-end">
                  <button class="btn me-3 btn-outline-secondary bg-white py-1 fs-5 fw-bold" onClick={handleMoreClick}><FontAwesomeIcon icon={faEllipsis} /></button>
                  {/* Add "Edit Layout" button with icon and label */}
                    <Button
                        variant="outlined"
                        startIcon={<EditIcon />} // Add Edit icon
                        onClick={handleEditLayoutClick} // Use the function as the click handler
                        sx={{ marginRight: '30PX' }}
                    >
                        Edit Layout
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNewToolClick}
                        style={{ backgroundColor: "#1993c6" }}
                        sx={{ marginRight: '-12px' }}
                    >
                        <ControlPointIcon />
                        New Tool
                    </Button>
                </div>
              </div>

            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Menu
                        id="more-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={handleResetAll}><div class="text-secondary my-0 d-flex"><RestartAltIcon /><span>&nbsp;&nbsp;&nbsp;</span>Reset to Default</div></MenuItem>
                        <hr />
                        <MenuItem onClick={handleDeleteAll}><div class="text-danger my-0 d-flex"><DeleteIcon /><span>&nbsp;&nbsp;&nbsp;</span>Delete All</div></MenuItem>
                    </Menu>
            </Toolbar>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle style={{ fontSize: "20px" }}>New Tool</DialogTitle>
                <DialogContent  >

                    <div class="mb-3">
                        <label class="form-label fs-6 text-secondary">Name*</label>
                        <input id="name" value={name} onChange={(e) => setName(e.target.value)} type="text" class="form-control" placeholder="Enter a title.." />
                    </div>
                    <div class="mb-3">
                        <label class="form-label fs-6 text-secondary">Description</label>
                        <textarea id="description" onChange={(e) => setDescription(e.target.value)} value={description} class="form-control" placeholder="Enter a description (optional).." rows="3"></textarea>
                    </div>
                    <div class="mb-3">
                        <label class="form-label fs-6 text-secondary">URL</label>
                        <input id="url" value={url} onChange={(e) => setUrl(e.target.value)} type="text" class="form-control" placeholder="Enter a URL (optional).." />
                    </div>

                    {/*
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="url"
                        label="URL"
                        type="url"
                        fullWidth
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="date"
                        label=""
                        type="date"
                        fullWidth
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <button class="btn btn-outline-secondary" onClick={handleClose} color="primary">
                        Cancel
                    </button>
                    <button class="btn px-3 text-white" style={{ backgroundColor: "#1993c6" }} onClick={handleSave} color="primary">
                        Save
                    </button>
                </DialogActions>
            </Dialog>

            <div>
                <Grid container spacing={2}>
                    {cards.map((card, index) => (
                        <Grid item xs={4} key={index}>
                            <Paper elevation={3} sx={{ padding: '10px', width: '320px', height: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox style={{ color: "green" }} color="primary" />
                                    <h6 class="mt-2">Not Mastered</h6>
                                    <button class="btn text-end text-secondary fs-6 fw-bold" onClick={handleMoreClick} style={{ marginLeft: '125px' }}><FontAwesomeIcon icon={faEllipsis} /></button>
                                </div>
                                {/* second menu starts */}
                                <Menu
                                    id="more-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={handleNewToolClick}><div class="text-secondary my-0 d-flex"><EditIcon /><span>&nbsp;&nbsp;&nbsp;</span>Edit</div></MenuItem>
                                    <hr />
                                    <MenuItem onClick={openModal} disabled={isModalOpen}><div class="text-secondary my-0 d-flex"><PriorityHighIcon /><span>&nbsp;&nbsp;</span>Make it an Issue</div></MenuItem>
                                    <Createbtn isOpen={isModalOpen} onClose={closeModal} />
                                    <MenuItem onClick={openModal} disabled={isModalOpen}><div class="text-secondary my-0 d-flex"><LibraryAddCheckIcon /><span>&nbsp;&nbsp;&nbsp;</span>Create a To-Do</div></MenuItem>
                                    <hr />
                                    <MenuItem><div class="text-danger my-0 d-flex"><DeleteIcon /><span>&nbsp;&nbsp;&nbsp;</span>Delete</div></MenuItem>
                                </Menu>
                                {/*second menu ends*/}
                                <h4 class="text-center my-4">{card.name}</h4>
                                <p class="mt-3 mb-2">{card.description}</p>
                                {/* <a href={card.url}>Visit</a> */}
                                {/*<p>Date: {card.date}</p>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button variant="outlined" startIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                    <Button variant="outlined" startIcon={<DeleteIcon />}>
                                        Delete
                                    </Button>
                                </div> */}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
}

export default CreateNewTool;

{/*
<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
   <DialogTitle style={{ fontSize: "20px" }}>Edit Tool</DialogTitle>
      <DialogContent>

<div class="mb-3">
  <label class="form-label fs-6 text-secondary">Name*</label>
  <input id="name" value={name} onChange={(e) => setName(e.target.value)} type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter a title.." />
</div>
<div class="mb-3">
  <label class="form-label fs-6 text-secondary">Description</label>
  <textarea id="description" onChange={(e) => setDescription(e.target.value)} value={description} class="form-control" placeholder="Enter a description (optional).." rows="3"></textarea>
</div>
<div class="mb-3">
  <label class="form-label fs-6 text-secondary">URL</label>
  <input id="url" value={url} onChange={(e) => setUrl(e.target.value)} type="text" class="form-control" placeholder="Enter a URL (optional).." />
</div>

    </DialogContent>
  <DialogActions>
    <button class="btn btn-outline-secondary" onClick={handleClose} color="primary">Cancel</button>
    <button class="btn px-3 text-white" style={{ backgroundColor: "#1993c6" }} onClick={handleSave} color="primary">Update Tool</button>
  </DialogActions>
</Dialog>
*/}