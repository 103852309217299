import Switch from '@mui/material/Switch';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
const AnnualsessionDay2modal=[
    // 1
    {
        id:1,
        inputtextname:'OBJECTIVES',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<EditIcon label="Edit section" />,

    },
    // 2
    {
        id:2,
        inputtextname:'CHECK-IN',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<EditIcon label='Edit section'/>,

    },
    // 3
    {
        id:3,
        inputtextname:'REVIEW ISSUES LIST & 3 YEAR PICTURE™',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,

    },
    // 4
    {
        id:4,
        inputtextname:'ONE YEAR PLAN',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,

    }, 
    // 5
    {
        id:5,
        inputtextname:'ROCKS',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited' />,

    },
    // 6
    {
        id:6,
        inputtextname:'TACKLING KEY ISSUES',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited' />,

    },
    // 7
    {
        id:7,
        inputtextname:'NEXT STEPS',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited' />,

    },
    // 8
    {
        id:8,
        inputtextname:'CONCLUDE',
        duration:5,
        lockIcon:<EditIcon label='Edit section'/>,
    }
]
export default AnnualsessionDay2modal;