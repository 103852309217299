import React from 'react'
import { Grid } from '@mui/material'
import OneononeTopBar from '../components/oneonone/oneononetopbar'
import OneononeTabs from '../components/oneonone/oneononetabs'
import OneOnOneSettings from '../components/oneonone/oneononesetting'


export default function Oneonone() {
  // open oneonone setting
const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
const handleSettingsClick = () => {
  setIsSettingsOpen(!isSettingsOpen);
};
  return (
    <>
    <Grid>
    <OneononeTopBar handleSettingsClick={handleSettingsClick}/>
    </Grid>
    <Grid>
    {isSettingsOpen ? <OneOnOneSettings /> : <OneononeTabs/>}

      
    </Grid>
    </>
  )
}
