import React, { useState, useEffect } from 'react'
import Tooltip from '@mui/material/Tooltip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Switch from '@mui/material/Switch';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from '@mui/styles';
import { Button, Box, AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, CssBaseline, Divider, Card, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { styled } from '@mui/material/styles';
// modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 3,
};

const useStyles = makeStyles((theme, drawerWidth) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1, backdropFilter: "none",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "white",
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: "white",
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    textbar: {
        backgroundColor: "rgb(25, 147, 198)",
    },
    listItem: {
        width: "100%", // Ensure each ListItem takes full width
    },
}));
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const DataAppBar = ({ toggleSidebar }) => {
    const classes = useStyles();
    const handleRefreshClick = () => {
        // Handle the refresh icon click event here
        // You can add your refresh logic in this function
        console.log('Refresh icon clicked');
    };

    const handleSearchChange = (event) => {
        // Handle search field input changes here
        // event.target.value contains the search input value
        console.log('Search input:', event.target.value);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleArchive = () => {
        console.log('archive')
    }
    const handlePrint = () => {
        console.log('handle print')
    }
    //prioritization
    const [priorChecked, setPriorChecked] = React.useState(true);

    const handlePriorChange = (event) => {
        setPriorChecked(event.target.checked);
    };

    //vote
    const [voteChecked, setVoteChecked] = React.useState(true);

    const handleVoteChange = (event) => {
        setVoteChecked(event.target.checked);
    };

    //solve rate
    const [rateChecked, setRateChecked] = React.useState(true);

    const handleRateChange = (event) => {
        setRateChecked(event.target.checked);
    };
    const [day, setday] = React.useState('');

    const handleChange = (event) => {
      setday(event.target.value);
    };
  
    return (
        <>
            <Toolbar sx={{ bgcolor: '#2596be' }}>
                <div className="row w-100">
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 d-flex py-0">
                        <Typography variant="p" component="div" sx={{ flexGrow: 1, fontSize: '16px', display: 'flex' }}>
                            <span className="mt-2" style={{ color: '#ffffff91', marginRight: '8px' }}>FILTERS</span> {/* Add the "Filter" text here */}
                            <label className="mt-2" htmlFor="owner-select">Team:</label>
                            <Select
                                variant="standard"
                                id="owner-select"
                                label="Teams"
                                sx={{
                                    icon: {
                                        fill: 'white', // Change the color to white
                                    },
                                    marginRight: '16px',
                                    marginLeft: '10px',
                                    width: '150px',
                                    '&:before': {
                                        borderColor: 'white',
                                    },
                                    '&:after': {
                                        borderColor: 'white',
                                    },
                                    color: 'white',
                                }}
                                defaultValue={1}
                                inputProps={{ style: { border: 'none' } }} // Add border style here
                            >
                                <MenuItem value={1}>All</MenuItem>
                                <MenuItem value={2}>Team 1</MenuItem>
                                <MenuItem value={3}>Team 2</MenuItem>
                            </Select>

                        </Typography>
                    </div>

                    <div className="col-12 col-lg-8 col-md-12 col-sm-12 d-flex justify-content-around ">

                        <FormControlLabel
                            control={<Switch onChange={handleArchive} />}
                            label="Archive"
                            labelPlacement="start" // Adjust label placement as needed
                            sx={{ marginLeft: '-1px' }} // Add any desired styling
                        />

                        <Tooltip title="Refresh"> {/* Add Tooltip to show text on hover */}
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="refresh"
                                onClick={handleRefreshClick}
                                sx={{ marginRight: '6px' }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Print to PDF"> {/* Add Tooltip to show text on hover */}
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="zoom-out"
                                sx={{ marginRight: '6px' }}
                                onClick={handlePrint}
                            >
                                <PictureAsPdfIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Archive all completed"> {/* Add Tooltip to show text on hover */}
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="reset-zoom"
                                sx={{ marginRight: '6px' }}
                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                            >
                                <DoneAllOutlinedIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Search"> {/* Add Tooltip to show text on hover */}
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="search"
                                sx={{ marginRight: '6px' }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>

                        <InputBase
                            placeholder="Search Issues"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={handleSearchChange}
                            sx={{ marginRight: '6px', borderBottom: '1px solid white', color: 'white' }}
                        />
                        <Tooltip title="Open Rocks Settings"> {/* Add Tooltip to show text on hover */}
                            <IconButton
                                onClick={handleOpen}
                                edge="end"
                                color="inherit"
                                aria-label="reset-zoom"
                                sx={{ marginRight: '6px' }}
                            >
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>

            {/*setting modal*/}
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h6 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '20px' }} >Data Settings</h6>

                        <div className="d-flex flex-row justify-content-between mt-4">
                            <div className='d-flex flex-column w-50'>
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                    label="Trailing Data"
                                />
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                    label="KPI Total Column"
                                />
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                    label="Hide current week for Leadership Team?"
                                />
                            </div>
                            <div className='d-flex flex-column w-50'>
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                    label="KPI Average Column"
                                />
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                    label="KPI Status Color Indicator"
                                />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-helper-label">KPI Weekly Start Day</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={day}
                                        label="KPI Weekly Start Day"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Sunday</MenuItem>
                                        <MenuItem value={20}>Monday</MenuItem>
                                        <MenuItem value={30}>Tuesday</MenuItem>
                                        <MenuItem value={40}>Wednesday</MenuItem>
                                        <MenuItem value={50}>Thursday</MenuItem>
                                        <MenuItem value={60}>Friday</MenuItem>
                                        <MenuItem value={70}>Saturday</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="text-end mt-2">
                            <Button className="ms-2" sx={{ backgroundColor: '#2596be' }} variant="contained" onClick={handleClose}>Close</Button>
                        </div>

                    </Box>
                </Modal>
            </div>

            {/*archive all modal*/}
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" >
                    <div className="modal-content py-3">
                        <div className="modal-body">
                            <h5 className="fw-bold" style={{ fontFamily: 'Poppins,sans-serif' }} >Archive Completed?</h5>
                            <hr />
                            <h6>All completed Rocks will be archived.</h6>

                        </div>
                        <div className="text-end me-3">
                            <button type="button" class="btn btn-white shadow me-2" data-bs-dismiss="modal">Close</button>
                            <button style={{ backgroundColor: '#2596be' }} type="button" className="btn text-white shadow">Archive</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataAppBar
