import React, { useState } from 'react'
import { Grid } from '@mui/material'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { TextField, IconButton, Menu, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Checkbox from '@mui/material/Checkbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import axios from 'axios';


const roles = ['Owner', 'Admin', 'Manager', 'Managee', 'Observer', 'Implementer'];
const teams = ['Team 1', 'Team 2', 'Team 3'];

//modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 3,
};

//tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function InviteModal({ onClose, open }) {


  // const [open, setOpen] = useState(true);
  const [inviteFirstName, setInviteFirstName] = useState('');
  const [inviteLastName, setInviteLastName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [invitePassword, setInvitePassword] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [sendInvitation, setSendInvitation] = useState(false);
  const [addFirstName, setAddFirstName] = useState('');
  const [addLastName, setAddLastName] = useState('');
  const [addEmail, setAddEmail] = useState('');
  const [users, setUsers] = useState([]);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    // setOpen(false);
    onClose();
  };

  const handleSaveUser = () => {

    const inviteUser = {
      firstName: inviteFirstName,
      lastName: inviteLastName,
      email: inviteEmail,
      password: invitePassword,
      role: selectedRole,
      team: selectedTeam,
      sendInvitation,
    };

    const directoryUser = {
      firstName: addFirstName,
      lastName: addLastName,
      email: addEmail,
    };

    console.log("Invite To Company", inviteUser);
    console.log("Add to Directory", directoryUser);


    axios.post(`${process.env.REACT_APP_API_URL}users/signup`, inviteUser)
      .then(response => {
        // Handle successful response
        console.log('Success:', response.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });

    // Add the new user to the users array
    setUsers([...users, inviteUser]);

    // Close the dialog
    // setOpen(false);

    // Reset the form fields
    setInviteFirstName('');
    setInviteLastName('');
    setInviteEmail('');
    setInvitePassword('');
    setSelectedRole('');
    setSelectedTeam('');
    setSendInvitation(false);
    setAddFirstName('');
    setAddLastName('');
    setAddEmail('');
  };

  //tabs

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // file upload
  const [file, setFile] = React.useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile); // Save the selected file
  };
  const handleCancel = () => {
    setFile(null); // Remove the file
  };
  // apply the style in the uploaded file
  const HoverableDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between",
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#f5f5f5', // Change the background color on hover
    },
  });

  //tabs
  const [divCount, setDivCount] = useState(1);

  const handleDuplicate = () => {
    setDivCount(prevCount => prevCount + 1);
  };

  return (
    <>

      {/*onClick={handleClickOpen}*/}
      <div>
        {/*form*/}

        <Dialog maxWidth="md" open={open} onClose={handleClose}>
          <DialogTitle className="d-flex justify-content-between"><p className="text-white">.</p><h3 class="text-center fw-bold">Add People</h3><CloseIcon onClick={handleClose} /></DialogTitle>
          <DialogContent>
            <Box sx={{ width: '100%' }}>
              <Box sx={{}}>
                <div className="rounded-pill" style={{ backgroundColor: '#E8E8E8' }} >
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                    <Tab className="text-capitalize" sx={{ fontSize: '17px' }} label="Invite to Company" {...a11yProps(0)} />
                    <Tab className="text-capitalize" sx={{ fontSize: '17px' }} label="Add to Directory" {...a11yProps(1)} />
                  </Tabs>
                </div>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }} ><b>You are currently using 1 of 1 available licenses.</b> Users added to licenses will be assigned permissions depending on the selected role. You can always manage your licenses on the Billing page.</p>

                {/*form code*/}
                {[...Array(divCount)].map((_, index) => (
                  <div key={index} className="rounded rounded-1 border border-light-subtle mb-3">

                    <div className="d-flex p-1 pb-2">
                      <AccountCircleIcon sx={{ color: '#AAAAAA', fontSize: '40px' }} />
                      <label htmlFor="file-upload">
                        <EditIcon className="ms-2 mt-1" />
                      </label>

                      <input type="file" id="file-upload" style={{ display: 'none' }} onChange={handleFileChange} />

                      <TextField
                        onChange={(e) => setInviteFirstName(e.target.value)}
                        name="inviteFirstName"
                        value={inviteFirstName}
                        className="ms-4"
                        placeholder="First Name *"
                        variant="standard"
                        focused
                        required />

                      <TextField
                        onChange={(e) => setInviteLastName(e.target.value)}
                        name="inviteLastName"
                        value={inviteLastName}
                        className="ms-5"
                        placeholder="Last Name *"
                        variant="standard"
                        focused
                        required />

                      <TextField
                        onChange={(e) => setInviteEmail(e.target.value)}
                        name="inviteEmail"
                        value={inviteEmail}
                        className="ms-5"
                        placeholder="Email *"
                        type="email"
                        variant="standard"
                        focused
                        required
                      />


                      <TextField
                        onChange={(e) => setInvitePassword(e.target.value)}
                        name="invitePassword"
                        value={invitePassword}
                        className="ms-5"
                        placeholder="Password"
                        type="password"
                        variant="standard"
                        focused
                        required />

                    </div>

                    <div className="row w-100 mb-3">

                      <div className="col-12 col-lg-2 col-md-4 col-sm-4">
                        {file && (
                          <HoverableDiv>
                            <Typography>{file.name}</Typography>
                            <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                          </HoverableDiv>
                        )}
                      </div>

                      <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                        <InputLabel id="demo-simple-select-label">Teams *</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          variant="standard"
                          color="warning"
                          label="Teams *"
                          name="selectedTeam"
                          fullWidth
                          value={selectedTeam}
                          onChange={(e) => setSelectedTeam(e.target.value)}
                        >
                          {teams.map((team) => (
                            <MenuItem key={team} value={team}>
                              {team}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                        <InputLabel id="demo-simple-select-label">Role *</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          variant="standard"
                          color="warning"
                          name="selectedRole"
                          label="Role *"
                          fullWidth
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                  </div>
                ))}

                {/* <div className="rounded rounded-1 d-flex text-center" style={{ backgroundColor: '#E5E4E2' }} >
                  <button onClick={handleDuplicate} style={{ color: '#2596BE' }} className="d-flex btn w-100 text-center justify-content-center py-1"><h6 className="pt-1"><AddCircleOutlineIcon /></h6> <h6 className="fw-bolder ms-2 pt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '17px' }}>ADD ANOTHER USER</h6> </button>
                </div> */}


                <div className="m-0 mt-2 d-flex">
                  <Checkbox checked={sendInvitation} onChange={() => setSendInvitation(!sendInvitation)} sx={{ m: '0', p: '0' }} style={{ color: '#2596BE' }} inputProps={{ 'aria-label': 'controlled' }} />
                  <h6 className="pt-2 ms-2" style={{ fontFamily: 'Poppins, sans-serif' }}>Send invite email</h6>
                </div>

                <p className="mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Adding users to paid roles beyond your current license count will increase your subscription cost. The card on file will be immediately charged the prorated difference. <b>Have multiple users? Add them now to prevent multiple charges on your credit card.</b></p>

                <div className="rounded rounded-1 d-flex text-center" style={{ backgroundColor: '#2596BE' }} >
                  <button onClick={handleSaveUser} style={{ color: 'white' }} className="d-flex btn w-100 text-center justify-content-center py-1"><h6 className="fw-bolder pt-1" style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '17px', letterSpacing: '1px' }}>INVITE TO COMPANY</h6> </button>
                </div>

              </CustomTabPanel>

              <CustomTabPanel value={value} index={1}>
                <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }} ><b>Add an entry to the Directory.</b> The entry will still appear in the Accountability Chart, but won't have a login attached and can't be assigned contents like Rocks, To-Dos, and Issues.</p>

                {/*form code*/}
                {[...Array(divCount)].map((_, index) => (
                  <div key={index} className="rounded rounded-1 border border-light-subtle mb-3">

                    <div className="d-flex p-1 pb-4">
                      <AccountCircleIcon sx={{ color: '#AAAAAA', fontSize: '40px' }} />
                      <label htmlFor="file-upload">
                        <EditIcon className="ms-2 mt-1" />
                      </label>

                      <input type="file" id="file-upload" style={{ display: 'none' }} onChange={handleFileChange} />

                      <TextField
                        onChange={(e) => setAddFirstName(e.target.value)}
                        value={addFirstName}
                        name="addFirstName"
                        className="ms-4"
                        placeholder="First Name *"
                        variant="standard"
                        focused
                        required
                        id={`first-name-${index}`}
                      />

                      <TextField
                        onChange={(e) => setAddLastName(e.target.value)}
                        value={addLastName}
                        name="addLastName"
                        className="ms-5"
                        placeholder="Last Name *"
                        variant="standard"
                        focused
                        required
                        id={`last-name-${index}`}
                      />

                      <TextField
                        onChange={(e) => setAddEmail(e.target.value)}
                        value={addEmail}
                        name="addEmail"
                        className="ms-5"
                        placeholder="Email *"
                        type="email"
                        variant="standard"
                        focused
                        required
                        id={`email-${index}`}
                      />
                    </div>


                    {file && (
                      <HoverableDiv>
                        <Typography>{file.name}</Typography>
                        <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                      </HoverableDiv>
                    )}

                  </div>
                ))}

                {/* <div onClick={handleDuplicate} className="rounded rounded-1 d-flex text-center" style={{ backgroundColor: '#E5E4E2' }} >
                  <button style={{ color: '#2596BE' }} className="d-flex btn w-100 text-center justify-content-center py-1"><h6 className="pt-1"><AddCircleOutlineIcon /></h6> <h6 className="fw-bolder ms-2 pt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '17px' }}>ADD ANOTHER ENTRY</h6> </button>
                </div> */}

                <div className="rounded rounded-1 d-flex text-center mt-3" style={{ backgroundColor: '#2596BE' }} >
                  <button onClick={handleSaveUser} style={{ color: 'white' }} className="d-flex btn w-100 text-center justify-content-center py-1"><h6 className="fw-bolder pt-1" style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '17px', letterSpacing: '1px' }}>ADD TO DIRECTORY</h6> </button>
                </div>
              </CustomTabPanel>
            </Box>


          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>

        {/*form ends*/}


      </div>

    </>
  )
}