import * as React from 'react';
import { useState } from 'react'
import { Box, IconButton, Typography, Button, Modal, Divider } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import NavBar from '../NavBar/NavBar';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from 'js-cookie';
// import create button fom create Folder
import Createbtn from '../createbtn/create'
import axios from 'axios';
const Topbar = ({ selectedOption, currentComponentName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handlelogout = () => {
    Cookies.remove('token');

    // Log a message (optional)
   window.location.href = '/';
  }
  const openLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };
  const [userEmail,setuserEmail]=useState();
  const [firstName,setfirstname]=useState();
  const [lastName,setLastname]=useState();
  const [Owner,setOwner]=useState();
  React.useEffect(()=>{
    const payload={email:localStorage.getItem('userEmail')}
    axios.post(`${process.env.REACT_APP_API_URL}users/getUserDetails`,payload)
              .then(response => {
               console.log(response)
               if (response.data.success) {
                const emailFromApi = response.data.user.email;
                const firstnameApi=response.data.user.firstName;
                const lastnameApi=response.data.user.lastName;
                const ownernameApi=response.data.user.role;
                setuserEmail(emailFromApi);
                setfirstname(firstnameApi);
                setLastname(lastnameApi);
                setOwner(ownernameApi);
              }
                // Handle successful response
                  // if(response.data.success){
                  //     Cookies.set('token',response.data.token);
                  //     localStorage.setItem('userEmail', inputValues.email);
                  //     setIsLoggedIn(true);
                  //     navigate('/home')
                  // }
              })
              .catch(error => {
                  // Handle error
                  console.error('Error:', error);
              });
  })
  return (
    <>
      <Box display="flex" justifyContent="space-between" p={2} backgroundColor={'white'}
        sx={{
          // boxShadow: 0,
          // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          // color: (theme) =>
          // theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          // textAlign: 'center',
          // fontSize: '2rem',
          // fontWeight: '700',
        }}
      >
        <Typography className="d-flex" variant="h3" color="initial"><div className="d-lg-none "><button className="btn p-0 m-0"><MenuIcon /></button></div>{currentComponentName}</Typography>
        <Box
          display="flex"
          // backgroundColor={colors.primary[900]}
          borderRadius="3px"
        >
        </Box>
        <Box display="flex">
          <Button variant="contained" size="medium" sx={{ backgroundColor: "#1993c6", marginRight: "20px" }} onClick={openModal} disabled={isModalOpen}>Create</Button>
          {/* below is for opening the modal */}
          <Createbtn isOpen={isModalOpen} onClose={closeModal} />
          {/* Avatar with modal for logout */}
          <Avatar
            sx={{ bgcolor: 'grey', cursor: 'pointer', marginLeft: '20px' }}
            onClick={openLogoutModal}
          >
            OP
          </Avatar>
        </Box>
        {/* Logout Modal */}
        <Modal open={isLogoutModalOpen} onClose={closeLogoutModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '12%',
              right: '1%',
              width: "230px",
              // transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              // border: '2px solid #000',
              borderRadius: "12px",
              boxShadow: 24,
              p: 3,
            }}
          >
            <Avatar
              sx={{ bgcolor: 'grey', cursor: 'pointer', width: "100px", height: "100px", marginLeft: "25px" }}
            >
              OP
            </Avatar>
            {/* Your logout content goes here */}
            <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {firstName} {lastName}
            </Typography>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
             {Owner}
            </Typography>
            <Typography variant="p" sx={{ textAlign: 'center' }}>
             {userEmail}
            </Typography>
            <Divider sx={{ my: 2, height: '2px', backgroundColor: 'primary.main' }} />
            <Typography
              variant="body1"
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'blue' }}
              onClick={handlelogout}
            >
              <IconButton size="small" sx={{ ml: 1 }}>
                <LogoutIcon />
              </IconButton>
              Logout
            </Typography>
            {/* <Button  variant="contained" color="primary">
              Logout
            </Button> */}
          </Box>
        </Modal>
      </Box>
    </>

  );
};

export default Topbar;