import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

function ContactAditionalmail() {
  const [emails, setEmails] = useState(['']); // Initialize with an empty email field

  const handleEditEmailClick = (index) => {
    // Handle edit email action here for the email at the specified index
    console.log(`Edit email at index ${index} clicked`);
  };

  const handleDeleteEmailClick = (index) => {
    // Handle delete email action here for the email at the specified index
    console.log(`Delete email at index ${index} clicked`);
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1); // Remove the email at the specified index
    setEmails(updatedEmails);
  };

  const handleAddEmailClick = () => {
    // Add an empty email field when the "Add Email" button is clicked
    setEmails([...emails, '']);
  };

  return (
    <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>

      <h6 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }} className="fw-bold mt-2 mb-3">Additional Emails</h6>

      <div className='row'>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
          <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Email Addresses</label>
        </div>

        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
          <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Type</label>
        </div>
      </div>

      <div className="row">
        {emails.map((email, index) => (
          <React.Fragment key={index}>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              {/* <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Email Addresses</label> */}
              <TextField
                className="my-2"
                size="small"
                type="email"
                id="outlined-basic"
                fullWidth
                variant="outlined"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  const updatedEmails = [...emails];
                  updatedEmails[index] = e.target.value;
                  setEmails(updatedEmails);
                }}
              />

            </div>
            <div className="col-12 col-sm-11 col-md-3 col-lg-3">
              {/* <label className="mb-1" style={{ color: '#0000008a', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>Type</label> */}
              <FormControl fullWidth variant="outlined">
                <Select
                  className="my-2"
                  size="small"
                  labelId={`email-type-label-${index}`}
                  id={`email-type-select-${index}`}
                >
                  <MenuItem value="main">Main</MenuItem>
                  <MenuItem value="work">Work</MenuItem>
                  <MenuItem value="personal">Personal</MenuItem>
                </Select>
              </FormControl>

            </div>
            <div className="col-12 col-sm-1 com-md-1 col-lg-1">
              <IconButton
                edge="end"
                aria-label={`delete email ${index}`}
                onClick={() => handleDeleteEmailClick(index)}
                className="my-1"
              >
                <DeleteIcon className="text-danger" />
              </IconButton>

            </div>
          </React.Fragment>
        ))}
      </div>

      <button onClick={handleAddEmailClick} style={{ color: '#1993C6' }} className="btn"><AddCircleOutlineOutlinedIcon />&nbsp;&nbsp;Add Email Address</button>


    </Paper>
  );
}

export default ContactAditionalmail;
