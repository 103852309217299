import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';

import CardHeader from '@mui/material/CardHeader';
import TeamTodoTable from '../teamTodoTable/teamTodoTable';
import RocksTable from './tables/rocksTable';
import Createbtn from '../createbtn/create'
import { FormControl } from '@mui/material';

const RocksMilestone = () => {

// createbtn modal

const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

    return (
        <Card sx={{ height: "100%", display: 'flex', flexDirection: 'column', borderRadius: 3 }}>
            <CardContent>
                <Typography sx={{ fontSize: '20px', fontWeight: 600, fontFamily: 'Poppins,sans-serif', lineHeight: '150%' }} color="text.black" gutterBottom>
                Rocks & Milestones
                </Typography>
                <FormControl style={{overflowY:"auto",height:"560px",width:"100%"}}>
                <RocksTable />
                </FormControl>
             
            </CardContent>
            <div style={{ flex: 1 }}></div>
            <CardActions>
                <Button onClick={openModal} disabled={isModalOpen} sx={{ color: "rgba(25, 147, 198)"}}size="small" startIcon={<AddIcon />}>Create Rock</Button>
                <Createbtn isOpen={isModalOpen} onClose={closeModal} />
            </CardActions>
        </Card>
    )
}

export default RocksMilestone;