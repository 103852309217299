import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Weekly from './tabComponent/weekly';
import T13 from './tabComponent/T13'
import Monthly from './tabComponent/monthly';
import Quaterly from './tabComponent/quaterly';
import Annual from './tabComponent/annual';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TwoTabLayout() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%',marginTop:"50px" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider',height:"47px"}}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" indicatorColor="secondary">
          <Tab label="Weekly" {...a11yProps(0)} sx={{ '&.Mui-selected': { color: '#2596be'}, color: '#000000de', fontFamily:'Nunito Sans,Sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', paddingTop:'10px', paddingBottom:'20px', paddingRight:'50px' ,paddingLeft:'20px' }}/>
          <Tab label="T4W & T13W" {...a11yProps(1)} sx={{ '&.Mui-selected': { color: '#2596be'}, color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', paddingTop:'10px', paddingBottom:'20px', paddingRight:'45px' ,paddingLeft:'25px' }}/>
          <Tab label="Monthly" {...a11yProps(2)} sx={{ '&.Mui-selected': { color: '#2596be'}, color: '#000000de', fontFamily:'Nunito Sans,Sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', paddingTop:'10px', paddingBottom:'20px', paddingRight:'50px' ,paddingLeft:'20px' }}/>
          <Tab label="Quaterly" {...a11yProps(3)} sx={{ '&.Mui-selected': { color: '#2596be'}, color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', paddingTop:'10px', paddingBottom:'20px', paddingRight:'45px' ,paddingLeft:'25px' }}/>
          <Tab label="Annual" {...a11yProps(4)} sx={{ '&.Mui-selected': { color: '#2596be'}, color: '#000000de', fontFamily:'Nunito Sans,Sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', paddingTop:'10px', paddingBottom:'20px', paddingRight:'50px' ,paddingLeft:'20px' }}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Weekly/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <T13/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
  <Monthly/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
       <Quaterly/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
     <Annual/>
      </CustomTabPanel>
    </Box>
  );
}