import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

function MatricsClifton() {

  // top 5
  const [top5fields, setTop5Fields] = useState(0);
  const [top5Values, setTop5Values] = useState(Array(5).fill(''));
  const [top5, setTop5] = useState({});

  const handleAddTop5TextField = () => {
    if (top5fields < 5) {
      setTop5Fields(prevCount => prevCount + 1);
    }
  };

  const handleTop5ValuesChange = (index, value) => {
    const newTop5Values = [...top5Values];
    newTop5Values[index] = value;
    setTop5Values(newTop5Values);

    const newTop5 = { ...top5 };
    newTop5[`top${index + 1}`] = value;
    setTop5(newTop5);
  };

  const handleRemoveTop5TextField = (index) => {
    const newTop5Values = [...top5Values];
    newTop5Values.splice(index, 1);
    setTop5Values(newTop5Values);

    const newTop5 = { ...top5 };
    delete newTop5[`top${index + 1}`];
    setTop5(newTop5);

    setTop5Fields(prevCount => prevCount - 1);
  };

  //bottom 5
  const [bottom5fields, setBottom5Fields] = useState(0);
  const [bottom5Values, setBottom5Values] = useState(Array(5).fill(''));
  const [bottom5, setBottom5] = useState({});

  const handleAddBottom5TextField = () => {
    if (bottom5fields < 5) {
      setBottom5Fields(prevCount => prevCount + 1);
    }
  };

  const handleBottom5ValuesChange = (index, value) => {
    const newBottom5Values = [...bottom5Values];
    newBottom5Values[index] = value;
    setBottom5Values(newBottom5Values);

    const newBottom5 = { ...bottom5 };
    newBottom5[`bottom${index + 1}`] = value;
    setBottom5(newBottom5);
  };

  const handleRemoveBottom5TextField = (index) => {
    const newBottom5Values = [...bottom5Values];
    newBottom5Values.splice(index, 1);
    setBottom5Values(newBottom5Values);

    const newBottom5 = { ...bottom5 };
    delete newBottom5[`bottom${index + 1}`];
    setBottom5(newBottom5);

    setBottom5Fields(prevCount => prevCount - 1);
  };


  return (
    <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
            CliftonStrengths™
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            The CliftonStrengths assessment analyzes your talent DNA; your natural patterns of thinking, feeling, and behaving, and categorizes them in rank order.
          </Typography>
        </Grid>
        <Grid item xs={6} >
          <Typography variant="h4" gutterBottom sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14PX', fontWeight: 'bold', marginTop: '10px', marginBottom: '30px' }}>
            Top Five
          </Typography>
          {[...Array(top5fields)].map((_, index) => (
            <div className='row'>
              <div className='col-12 col-lg-1 col-md-1'>
                <h6 className="text-secondary mt-3">{index + 1}</h6>
              </div>

              <div className='col-12 col-lg-8 col-md-8'>
                <TextField
                  placeholder="Top Five Strength"
                  variant="outlined"
                  size="small"
                  className="my-2"
                  fullWidth
                  key={index}
                  id={`top5-${index + 1}`}
                  value={top5Values[index]}
                  onChange={(e) => handleTop5ValuesChange(index, e.target.value)}
                />
              </div>

              <div className='col-12 col-lg-3 col-md-3'>
                <DeleteIcon onClick={() => handleRemoveTop5TextField(index)} className="text-danger ms-3 mt-3" />
              </div>
            </div>
          ))}
          <button onClick={handleAddTop5TextField} style={{ color: '#2596be' }} className="btn"><AddCircleOutlineIcon /> Add Top Strength</button>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" gutterBottom sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14PX', fontWeight: 'bold', marginTop: '10px', marginBottom: '30px' }}>
            Bottom Five
          </Typography>

          {[...Array(bottom5fields)].map((_, index) => (
            <div className='row'>
              <div className='col-12 col-lg-1 col-md-1'>
                <h6 className="text-secondary mt-3">{index + 1}</h6>
              </div>

              <div className='col-12 col-lg-8 col-md-8'>
                <TextField
                  placeholder="Bottom Five Strength"
                  variant="outlined"
                  size="small"
                  className="my-2"
                  fullWidth
                  key={index}
                  id={`bottom5-${index + 1}`}
                  value={bottom5Values[index]}
                  onChange={(e) => handleBottom5ValuesChange(index, e.target.value)}
                />
              </div>

              <div className='col-12 col-lg-3 col-md-3'>
                <DeleteIcon onClick={() => handleRemoveBottom5TextField(index)} className="text-danger ms-3 mt-3" />
              </div>
            </div>
          ))}

          <button onClick={handleAddBottom5TextField} style={{ color: '#2596be' }} className="btn"><AddCircleOutlineIcon /> Add Bottom Strength</button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default MatricsClifton;
