import React,{useState} from 'react'
import MeetingTopBar from '../../components/meetings/topbar'
import MeetingTabs from './meetingtabs'
import { Grid } from '@mui/material'
import Meetingsettings from '../../components/meetings/meetingsettings';

export default function Meetings() {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const handleSettingsClick = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };
  return (
    <>
    <Grid>
      < MeetingTopBar handleSettingsClick={handleSettingsClick}/>
    </Grid>
    <Grid>
    {isSettingsOpen? <Meetingsettings />: <MeetingTabs/>}
     
    </Grid>
    </>
  )
}
