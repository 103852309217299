import React from 'react'
// below are the forms
import Levelmeeting from './levelmeeting';
import QuaterlySessionForm from './QuaterlySessionForm'
import AnnualsessionDay1 from './annualsessionDay1';
import Annualsessionday2 from './annualsessionday2';
import Focusday from './focusday'
import Visionbuildingday1 from './visionbuildingday1';
import Visionbuildingday2 from './visionbuildingday2';
export default function Mainagendaform({ selectedAgenda }) {
  // const [selectedAgenda, setSelectedAgenda] = useState();
  // Render different form Below
  const RenderForm = () => {
    switch (selectedAgenda) {
      case 1:
        return <Levelmeeting />;
      case 2:
        return <QuaterlySessionForm />;
      // Add cases for other agendas as needed
      case 3:
        return <AnnualsessionDay1 />
      case 4:
        return <Annualsessionday2 />
      case 5:
        return <Focusday />
      case 6:
        return <Visionbuildingday1 />
      case 7:
        return <Visionbuildingday2 />
      default:
        return null;
    }
  };
  return (
    <div >
      {RenderForm()}
    </div>
  )
}
