import React, { useState } from 'react';
import { Button, Box, AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, CssBaseline, Divider, Card, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Selectablelist from '../selectablelist';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PreviewIcon from '@mui/icons-material/Preview';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import RatingConfirmation from '../modal/ratingConfirmationModal';
import { Link, Outlet } from 'react-router-dom'
import Videomeetingroutes from './videomeetingroutes';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
// import IconButton from '@mui/material/IconButton';

import DataAppBar from './pages/data/DataAppBar';
import RocksAppbar from './pages/rocks/rocksAppbar';
import HeadlinesAppbar from './pages/headlines/HeadlinesAppbar';
import TodosAppbar from './pages/todos/todosAppbar';
import IssueTopbar from './pages/issue/issueTopBar'
import Create from '../../createbtn/create';
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: "100%"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1, backdropFilter: "none",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "white",
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: "white",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    // hide: {
    //     display: 'none',
    // },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    textbar: {
        backgroundColor: "rgb(25, 147, 198)",
    },
    listItem: {
        width: "100%", // Ensure each ListItem takes full width
    },
}));

const Meetinglayout = () => {
    const classes = useStyles();
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // list items
    const items = [
        { id: 1, name: 'Segue', duration: "5 MIN", link: 'meeting-seige' },
        { id: 2, name: 'Data', duration: "5 MIN", link: 'meeting-data' },
        { id: 3, name: 'Rocks', duration: "5 MIN", link: 'meeting-rocks' },
        { id: 4, name: 'Headlines', duration: "5 MIN", link: 'meeting-headlines' },
        { id: 5, name: 'To-Dos', duration: "5 MIN", link: 'meeting-todos' },
        { id: 6, name: 'Issues', duration: "5 MIN", link: 'meeting-issues' },
        { id: 7, name: 'Conclude', duration: "5 MIN", link: 'conclude' },
    ];

    const [ratingConfirmationModel, setRatingConfirmationModal] = useState(false);

    const handleRantingModal = () => {
        setRatingConfirmationModal(true);
    };

    const handleRantingModalClose = () => {
        setRatingConfirmationModal(false);
    };

    const handleRantingModalOpen = () => {
        setRatingConfirmationModal(true);
    };
    const [selectedItem, setSelectedItem] = useState(items.find(item => item.name === 'Segue'));
    const handleItemClick = (item) => {
        setSelectedItem(item);
    };
    const handleRefreshClick = () => {
        // Handle the refresh icon click event here
        // You can add your refresh logic in this function
        console.log('Refresh icon clicked');
    };

    const handleSearchChange = (event) => {
        // Handle search field input changes here
        // event.target.value contains the search input value
        console.log('Search input:', event.target.value);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleArchive = () => {
        console.log('archive')
    }
    //  Create button 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    const handlePrint = () => {
        window.print();
      };
    return (
        <div className={classes.root}>
            <CssBaseline />
            {/* <AppBar
                position="fixed"
                className={isSidebarOpen ? classes.appBarShift : classes.appBar}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSidebar}
                        edge="start"
                        // className={isSidebarOpen ? classes.hide : classes.menuButton}
                    >
                        <MenuIcon style={{ color: "black" }} />
                    </IconButton>
                    <Typography variant="h6" style={{ alignItems: "center", fontSize: "30px", fontWeight: "bolder", color: "black", padding: "10px" }}>
                        Segue | Weekly Meeting- Leadership Team
                    </Typography>
                    <Button style={{ backgroundColor: '#1993c6', color: "white" }}>Create</Button>
                </Toolbar>
                <Toolbar className={classes.textbar}>
                    <Typography variant="h6" style={{ alignItems: "center", fontSize: "20px" }}>
                        Share Your Good News
                    </Typography>
                </Toolbar>
            </AppBar> */}


            {/* Segue Start */}
            {selectedItem.name === 'Segue' && (
                <AppBar
                    position="fixed"
                    className={isSidebarOpen ? classes.appBarShift : classes.appBar}
                >
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleSidebar}
                                edge="start"
                            >
                                <MenuIcon style={{ color: "black" }} />
                            </IconButton>
                            <Typography variant="h6" style={{ fontSize: "30px", fontWeight: "bold", color: "black", padding: "10px" }}>
                                Segue | Weekly Meeting- Leadership Team
                            </Typography>
                        </div>
                        <Button style={{ backgroundColor: '#1993c6', color: "white" }} onClick={openModal}>Create</Button>
                    </Toolbar>
                    <Create isOpen={isModalOpen} onClose={closeModal} />
                    <Toolbar className={classes.textbar}>
                        <Typography variant="h6" style={{ alignItems: "center", fontSize: "20px" }}>
                            Share Your Good News
                        </Typography>
                    </Toolbar>
                </AppBar>
            )}
            {/* Data Start */}
            {selectedItem.name === 'Data' && (
                <>
                    <AppBar position="fixed"
                        className={isSidebarOpen ? classes.appBarShift : classes.appBar}>
                        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleSidebar}
                                    edge="start"
                                >
                                    <MenuIcon style={{ color: "black" }} />
                                </IconButton>
                                <Typography variant="h6" style={{ fontSize: "30px", fontWeight: "bold", color: "black", padding: "10px" }}>
                                    Scorecard | Weekly Meeting- Leadership Team
                                </Typography>
                            </div>
                            <Button style={{ backgroundColor: '#1993c6', color: "white" }} onClick={openModal}>Create</Button>
                        </Toolbar>
                        <Create isOpen={isModalOpen} onClose={closeModal} />
                        <DataAppBar />
                    </AppBar>

                </>
            )}
            {/* Rocks Start */}
            {selectedItem.name === 'Rocks' && (
                <>
                    <AppBar position="fixed"
                        className={isSidebarOpen ? classes.appBarShift : classes.appBar}>
                        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleSidebar}
                                    edge="start"
                                >
                                    <MenuIcon style={{ color: "black" }} />
                                </IconButton>
                                <Typography variant="h6" style={{ fontSize: "30px", fontWeight: "bold", color: "black", padding: "10px" }}>
                                    Rocks | Weekly Meeting- Leadership Team
                                </Typography>
                            </div>
                            <Button style={{ backgroundColor: '#1993c6', color: "white" }} onClick={openModal}>Create</Button>
                        </Toolbar>
                        <Create isOpen={isModalOpen} onClose={closeModal} />
                    </AppBar>
                </>
            )}
            {/* Headlines and Cascading Messages Start */}
            {selectedItem.name === 'Headlines' && (
                <>
                    <AppBar position="fixed"
                        className={isSidebarOpen ? classes.appBarShift : classes.appBar}>
                        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleSidebar}
                                    edge="start"
                                >
                                    <MenuIcon style={{ color: "black" }} />
                                </IconButton>
                                <Typography variant="h6" style={{ fontSize: "30px", fontWeight: "bold", color: "black", padding: "10px" }}>
                                    Headlines- Leadership Team
                                </Typography>
                            </div>
                            <Button style={{ backgroundColor: '#1993c6', color: "white" }} onClick={openModal}>Create</Button>
                        </Toolbar>
                        <Create isOpen={isModalOpen} onClose={closeModal} />
                    </AppBar>
                </>
            )}
            {/* To-Dos */}
            {selectedItem.name === 'To-Dos' && (
                <>
                    <AppBar position="fixed"
                        className={isSidebarOpen ? classes.appBarShift : classes.appBar}>
                        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleSidebar}
                                    edge="start"
                                >
                                    <MenuIcon style={{ color: "black" }} />
                                </IconButton>
                                <Typography variant="h6" style={{ fontSize: "30px", fontWeight: "bold", color: "black", padding: "10px" }}>
                                    To-Dos | Weekly Meeting- Leadership Team
                                </Typography>
                            </div>
                            <Button style={{ backgroundColor: '#1993c6', color: "white" }} onClick={openModal}>Create</Button>
                        </Toolbar>
                        <Create isOpen={isModalOpen} onClose={closeModal} />
                    </AppBar>
                </>
            )}
            {/* Issues*/}
            {selectedItem.name === 'Issues' && (
                <>
                    <AppBar position="fixed"
                        className={isSidebarOpen ? classes.appBarShift : classes.appBar}>
                        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleSidebar}
                                    edge="start"
                                >
                                    <MenuIcon style={{ color: "black" }} />
                                </IconButton>
                                <Typography variant="h6" style={{ fontSize: "30px", fontWeight: "bold", color: "black", padding: "10px" }}>
                                    To-Dos | Weekly Meeting- Leadership Team
                                </Typography>
                            </div>
                            <Button style={{ backgroundColor: '#1993c6', color: "white" }} onClick={openModal}>Create</Button>
                        </Toolbar>
                        <Create isOpen={isModalOpen} onClose={closeModal} />
                    </AppBar>
                </>
            )}
             {selectedItem.name === 'Conclude' && (
                <>
                    <AppBar position="fixed"
                        className={isSidebarOpen ? classes.appBarShift : classes.appBar}>
                        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleSidebar}
                                    edge="start"
                                >
                                    <MenuIcon style={{ color: "black" }} />
                                </IconButton>
                                <Typography variant="h6" style={{ fontSize: "30px", fontWeight: "bold", color: "black", padding: "10px" }}>
                                Conclude | Weekly Meeting- Leadership Team
                                </Typography>
                            </div>
                            <Button style={{ backgroundColor: '#1993c6', color: "white" }} onClick={openModal}>Create</Button>
                        </Toolbar>
                        <Create isOpen={isModalOpen} onClose={closeModal} />
                        <Toolbar  style={{ backgroundColor: '#1993c6', color: "white" }}>
                        </Toolbar>
                    </AppBar>
                   
                </>
            )}
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={isSidebarOpen}
                classes={{ paper: classes.drawerPaper }}
            >
                <List>
                    <ListItem>
                        <AudioPlayer
                            autoPlay
                            src="http://example.com/audio.mp3"
                            onPlay={e => console.log("onPlay")}
                            style={{ width: "100%" }}
                        />
                    </ListItem>
                    <ListItem sx={{ width: "100%", paddingLeft: "0px", color: 'black' }}>
                        <Selectablelist items={items} onItemClick={handleItemClick} />
                    </ListItem>
                    <ListItem onClick={handleRantingModal} style={{cursor:"pointer"}}>
                        <ExitToAppIcon />
                        <ListItemText primary="Finish" />
                    </ListItem>
                    <Divider />
                    <ListItem style={{cursor:"pointer"}} >
                        <PreviewIcon />
                        <ListItemText primary="View Tool" />
                    </ListItem>
                    <Divider />
                    <ListItem style={{cursor:"pointer"}} >
                        <SpeakerNotesIcon />
                        <ListItemText primary="View Tool" />
                    </ListItem>
                </List>
            </Drawer>

            {ratingConfirmationModel && (
                <RatingConfirmation onClose={handleRantingModalClose} onOpen={handleRantingModalOpen} />
            )}

            <main className={`${classes.content} ${isSidebarOpen ? classes.contentShift : ''}`}>
                <Toolbar />
                <Videomeetingroutes />
            </main>
        </div>
    );
};

export default Meetinglayout;
