import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

function DateFormat() {
  const [dateFormat, setDateFormat] = useState('mdy'); // Default date format


  const handleDateFormatChange = (event) => {
    // Handle date format change here
    setDateFormat(event.target.value);
  };

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
          Preferred Date Format
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
          Choose the way dates get displayed across the app. 
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <FormControlLabel
            control={
              <RadioGroup value={dateFormat} onChange={handleDateFormatChange}>
                <FormControlLabel
                  value="mdy"
                  control={<Radio />}
                  label="10/6/2023 | October 6, 2023 | Oct 6"
                />
                <FormControlLabel
                  value="dmy"
                  control={<Radio />}
                  label="06/10/2023 | October 6, 2023 | 6 Oct"
                />
                <FormControlLabel
                  value="ymd"
                  control={<Radio />}
                  label="2023-10-06 | October 6, 2023 | Oct 6"
                />
              </RadioGroup>
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DateFormat;
