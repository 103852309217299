import React, { useState } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, Menu } from '@mui/material';
import { IconButton, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Grid, } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Checkbox from '@mui/material/Checkbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import axios from 'axios';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-regular-svg-icons'
import NativeSelect from '@mui/material/NativeSelect';
import AddIcon from '@mui/icons-material/Add';

const roles = ['Owner', 'Admin', 'Manager', 'Managee', 'Observer', 'Implementer'];
const teams = ['Team 1', 'Team 2', 'Team 3'];


//tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function DirectoryUser() {

  const [open, setOpen] = useState(false);
  const [inviteFirstName, setInviteFirstName] = useState('');
  const [inviteLastName, setInviteLastName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [sendInvitation, setSendInvitation] = useState(false);
  const [addFirstName, setAddFirstName] = useState('');
  const [addLastName, setAddLastName] = useState('');
  const [addEmail, setAddEmail] = useState('');
  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveUser = () => {

    const inviteUser = {
      firstName: inviteFirstName,
      lastName: inviteLastName,
      email: inviteEmail,
      role: selectedRole,
      team: selectedTeam,
      sendInvitation,
    };

    const directoryUser = {
      firstName: addFirstName,
      lastName: addLastName,
      email: addEmail,
    };

    console.log("Invite To Company", inviteUser);
    console.log("Add to Directory", directoryUser);

    axios.post(`${process.env.REACT_APP_API_URL}users/signup`, inviteUser)
      .then(response => {
        // Handle successful response
        console.log('Success:', response.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });

    setUsers([...users, inviteUser]);

    // Close the dialog
    setOpen(false);

    // Reset the form fields
    setInviteFirstName('');
    setInviteLastName('');
    setInviteEmail('');
    setSelectedRole('');
    setSelectedTeam('');
    setSendInvitation(false);
    setAddFirstName('');
    setAddLastName('');
    setAddEmail('');

  };

  //tabs

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  // file upload
  const [file, setFile] = React.useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile); // Save the selected file
  };
  const handleCancel = () => {
    setFile(null); // Remove the file
  };
  // apply the style in the uploaded file
  const HoverableDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between",
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#f5f5f5', // Change the background color on hover
    },
  });

  //tabs
  const [divCount, setDivCount] = useState(1);

  const handleDuplicate = () => {
    setDivCount(prevCount => prevCount + 1);
  };

  const [showUserCard, setShowUserCard] = useState(false)
  const [gridMd, setGridMd] = useState(12);

  const showUserComponent = () => {
    setShowUserCard(true)
    setGridMd(7);
  }

  const closeEditLayout = () => {
    setShowUserCard(false);
  }

  const handleMd12Click = () => {
    // Set md prop back to 4 when the second button is clicked
    setGridMd(12)
  };

  const handleCancelClick = () => {
    handleMd12Click();
    closeEditLayout();
  };

  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleIconClick = () => {
    document.getElementById('imageInput').click();
  };

  //
  const [editMode, setEditMode] = useState(false);
  const [factFinder, setFactFinder] = useState();
  const [followThru, setFollowThru] = useState();
  const [quickStart, setQuickStart] = useState();
  const [implementor, setImplementor] = useState();
  const [myersBriggs, setMyersBriggs] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [bio, setBio] = useState('');

  const handleUserCardSave = () => {

    const userData = {
      factFinder,
      followThru,
      quickStart,
      implementor,
      myersBriggs,
      top5,
      bottom5,
      dateOfBirth,
      streetAddress,
      country,
      region,
      city,
      postalCode,
      linkedIn,
      bio,
      emails,
      contacts
    };

    console.log('User Data:', userData);

    setEditMode(false); // Exit edit mode
  };

  // top 5
  const [top5fields, setTop5Fields] = useState(0);
  const [top5Values, setTop5Values] = useState(Array(5).fill(''));
  const [top5, setTop5] = useState({});

  const handleAddTop5TextField = () => {
    if (top5fields < 5) {
      setTop5Fields(prevCount => prevCount + 1);
    }
  };

  const handleTop5ValuesChange = (index, value) => {
    const newTop5Values = [...top5Values];
    newTop5Values[index] = value;
    setTop5Values(newTop5Values);

    const newTop5 = { ...top5 };
    newTop5[`top${index + 1}`] = value;
    setTop5(newTop5);
  };

  const handleRemoveTop5TextField = (index) => {
    const newTop5Values = [...top5Values];
    newTop5Values.splice(index, 1);
    setTop5Values(newTop5Values);

    const newTop5 = { ...top5 };
    delete newTop5[`top${index + 1}`];
    setTop5(newTop5);

    setTop5Fields(prevCount => prevCount - 1);
  };

  //bottom 5
  const [bottom5fields, setBottom5Fields] = useState(0);
  const [bottom5Values, setBottom5Values] = useState(Array(5).fill(''));
  const [bottom5, setBottom5] = useState({});

  const handleAddBottom5TextField = () => {
    if (bottom5fields < 5) {
      setBottom5Fields(prevCount => prevCount + 1);
    }
  };

  const handleBottom5ValuesChange = (index, value) => {
    const newBottom5Values = [...bottom5Values];
    newBottom5Values[index] = value;
    setBottom5Values(newBottom5Values);

    const newBottom5 = { ...bottom5 };
    newBottom5[`bottom${index + 1}`] = value;
    setBottom5(newBottom5);
  };

  const handleRemoveBottom5TextField = (index) => {
    const newBottom5Values = [...bottom5Values];
    newBottom5Values.splice(index, 1);
    setBottom5Values(newBottom5Values);

    const newBottom5 = { ...bottom5 };
    delete newBottom5[`bottom${index + 1}`];
    setBottom5(newBottom5);

    setBottom5Fields(prevCount => prevCount - 1);
  };

  const [emails, setEmails] = useState([{ type: '', value: '' }]);
  const maxEmails = 2;

  const addEmailField = () => {
    if (emails.length < maxEmails) {
      setEmails([...emails, { type: '', value: '' }]);
    }
  };

  const handleEmailTypeChange = (index, event) => {
    const newEmails = [...emails];
    newEmails[index].type = event.target.value;
    setEmails(newEmails);
  };

  const handleEmailValueChange = (index, event) => {
    const newEmails = [...emails];
    newEmails[index].value = event.target.value;
    setEmails(newEmails);
  };

  //contact
  const [contacts, setContacts] = useState([{ name: '', relation: '', phoneNumber: '' }]);

  const handleAddContact = () => {
    if (contacts.length < 3) {
      setContacts(prevContacts => [...prevContacts, { name: '', relation: '', phoneNumber: '' }]);
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const [rows, setRows] = useState([]);

  const handleAddRow = () => {
    const newRow = {
      phoneNumber: '',
      type: '',
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handlePhoneNumberChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSave = () => {
    rows.forEach((row, index) => {
      console.log(`phoneNumber: '${row.phoneNumber}', type: '${row.type}'`);
    });
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={gridMd} lg={gridMd}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <h5 class="ms-2">Directory</h5>
            <Button onClick={handleClickOpen} variant="contained" color="primary" style={{ backgroundColor: "#1993c6", marginRight: "20px" }}>
              <PersonAddIcon /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>Add People
            </Button>
          </Box>

          <TableContainer style={{ marginTop: "20px" }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: '#0000008a', fontSize: '15px', fontWeight: 500 }} align='left'>Name</TableCell>
                  <TableCell style={{ color: '#0000008a', fontSize: '15px', fontWeight: 500 }} align='right'>Teams</TableCell>
                  <TableCell style={{ color: '#0000008a', fontSize: '15px', fontWeight: 500 }} align='right'>Role</TableCell>
                  <TableCell style={{ color: '#0000008a', fontSize: '15px', fontWeight: 500 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell onClick={showUserComponent} align='left'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar sx={{ bgcolor: 'grey', width: 32, height: 32, marginRight: 1 }}>
                          {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
                        </Avatar>
                        {`${user.firstName} ${user.lastName}`}
                      </div>
                    </TableCell>
                    <TableCell align='right'>{user.team}</TableCell>
                    <TableCell align='right'>{user.role}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiTablePagination-displayedRows": {
                  marginTop: "12px",
                },
                ".MuiTablePagination-spacer": {
                  paddingTop: 1,
                },
                ".MuiTablePagination-selectLabel": {
                  marginBottom: '8px',
                },
                ".MuiSelect-select": {
                  paddingTop: 0,
                },
              }}
            />
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          {showUserCard && (
            users.map((user, index) => (

              <div style={{ overflowY: 'auto', maxHeight: '500px' }} className="card p-2 mb-2" key={index}>

                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <h6 className="ms-4 my-3 fw-bold"><ArrowForwardIosIcon onClick={handleCancelClick} style={{ fontSize: '17px' }} /></h6>
                    <h6 className="mt-3 ms-4" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px' }}>{`${user.firstName} ${user.lastName}`}</h6>
                  </div>
                  <h6 className="my-3 me-4"><EditIcon onClick={() => setEditMode(true)} /></h6>
                </div>

                <hr className='m-0 p-0' />

                <div className="row mt-3">

                  <div className="col-12 col-sm-6 col-md-6 col-lg-5 d-flex flex-column">

                    <div onClick={handleIconClick} style={{ borderRadius: '50%', height: '150px', width: '150px', display: 'inline-block', backgroundColor: '#676767' }} className="text-center ms-2">
                      {image ? (
                        <img
                          src={image}
                          alt="Selected"
                          style={{ height: '100%', width: '100%', borderRadius: '50%' }}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faImages} style={{ fontSize: '33px' }} className="text-white mt-5" />
                      )}
                      <input
                        type="file"
                        id="imageInput"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                    </div>

                    {/*Getting the values of headings*/}
                    {editMode ? (
                      <>
                        <div className="mt-3 ms-3 d-flex flex-column" style={{ color: '#757575', fontFamily: 'Poppins, sans-serif' }}>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold">Kolbe</h6>
                          <FormControl style={{ width: '150px' }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Fact Finder
                            </InputLabel>
                            <NativeSelect
                              defaultValue={factFinder}
                              inputProps={{
                                name: 'factFinder',
                                id: 'uncontrolled-native',
                              }}
                              onChange={(e) => setFactFinder(e.target.value)}
                            >

                              {[...Array(11).keys()].map((number) => ( // Generate options from 0 to 10
                                <option key={number} value={number}>{number}</option>
                              ))}

                            </NativeSelect>
                          </FormControl>

                          <FormControl className="mt-3" style={{ width: '150px' }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Follow Thru
                            </InputLabel>
                            <NativeSelect
                              defaultValue={followThru}
                              inputProps={{
                                name: 'followThru',
                                id: 'uncontrolled-native',
                              }}
                              onChange={(e) => setFollowThru(e.target.value)}
                            >

                              {[...Array(11).keys()].map((number) => (
                                <option key={number} value={number}>{number}</option>
                              ))}

                            </NativeSelect>
                          </FormControl>

                          <FormControl className="mt-3" style={{ width: '150px' }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Quick Start
                            </InputLabel>
                            <NativeSelect
                              defaultValue={quickStart}
                              inputProps={{
                                name: 'quickStart',
                                id: 'uncontrolled-native',
                              }}
                              onChange={(e) => setQuickStart(e.target.value)}
                            >

                              {[...Array(11).keys()].map((number) => (
                                <option key={number} value={number}>{number}</option>
                              ))}

                            </NativeSelect>
                          </FormControl>

                          <FormControl className="mt-3" style={{ width: '150px' }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Implementor
                            </InputLabel>
                            <NativeSelect
                              defaultValue={implementor}
                              inputProps={{
                                name: 'implementor',
                                id: 'uncontrolled-native',
                              }}
                              onChange={(e) => setImplementor(e.target.value)}
                            >

                              {[...Array(11).keys()].map((number) => (
                                <option key={number} value={number}>{number}</option>
                              ))}

                            </NativeSelect>
                          </FormControl>

                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">MyersBriggs</h6>

                          <FormControl className="mt-3" style={{ width: '150px' }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Myers Briggs
                            </InputLabel>
                            <NativeSelect
                              defaultValue={myersBriggs}
                              inputProps={{
                                name: 'myersBriggsr',
                                id: 'uncontrolled-native',
                              }}
                              onChange={(e) => setMyersBriggs(e.target.value)}
                            >
                              <option value={''}></option> <option value={'ESTP'}>ESTP</option> <option value={'ESTJ'}>ESTJ</option>
                              <option value={'ESFP'}>ESFP</option> <option value={'ESFJ'}>ESFJ</option> <option value={'ENTP'}>ENTP</option>
                              <option value={'ENTJ'}>ENTJ</option> <option value={'ENFP'}>ENFP</option> <option value={'ENFJ'}>ENFJ</option>
                              <option value={'ISTP'}>ISTP</option> <option value={'ISTJ'}>ISTJ</option> <option value={'ISFP'}>ISFP</option>
                              <option value={'ISFJ'}>ISFJ</option> <option value={'INTP'}>INTP</option> <option value={'INTJ'}>INTJ</option>
                              <option value={'INFP'}>INFP</option> <option value={'INFJ'}>INFJ</option>

                            </NativeSelect>
                          </FormControl>

                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">CliftonStrengths™</h6>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Top 5 <AddIcon onClick={handleAddTop5TextField} className="text-black ms-3" /></h6>
                          {[...Array(top5fields)].map((_, index) => (
                            <div className="d-flex">
                              <TextField
                                key={index}
                                id={`top5-${index + 1}`}
                                label={`${index + 1}.`}
                                variant="standard"
                                className="mt-2"
                                value={top5Values[index]}
                                onChange={(e) => handleTop5ValuesChange(index, e.target.value)}
                              />
                              <CloseIcon className="mt-4" onClick={() => handleRemoveTop5TextField(index)} />
                            </div>
                          ))}
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Bottom 5 <AddIcon onClick={handleAddBottom5TextField} className="text-black ms-3" /></h6>
                          {[...Array(bottom5fields)].map((_, index) => (
                            <div className="d-flex">
                              <TextField
                                key={index}
                                id={`bottom5-${index + 1}`}
                                label={`${index + 1}.`}
                                variant="standard"
                                className="mt-2"
                                value={bottom5Values[index]}
                                onChange={(e) => handleBottom5ValuesChange(index, e.target.value)}
                              />
                              <CloseIcon className="mt-4" onClick={() => handleRemoveBottom5TextField(index)} />
                            </div>
                          ))}

                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Date of Birth</h6>
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            name="dateOfBirth"
                            type="date"
                            value={dateOfBirth}
                            onChange={(e) => setDateOfBirth(e.target.value)}
                          />

                        </div>
                        {/* <button className='btn' onClick={handleUserCardSave}>SAVE INFORMATION</button> */}
                      </>
                    ) : (
                      <>
                        <div className="mt-3 ms-3 d-flex flex-column" style={{ color: '#757575', fontFamily: 'Poppins, sans-serif' }}>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold">Kolbe <br />
                            {factFinder ? `${factFinder}-` : ''}
                            {followThru ? `${followThru}-` : ''}
                            {quickStart ? `${quickStart}-` : ''}
                            {implementor ? implementor : ''}
                          </h6>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">MyersBriggs</h6>
                          <p>{myersBriggs}</p>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold">CliftonStrengths™</h6>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Top 5<br />
                            {top5Values.filter(value => value !== '').map((value, index) => (
                              <div key={index}>
                                {index + 1}. {value}
                              </div>
                            ))}
                          </h6>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Bottom 5<br />
                            {bottom5Values.filter(value => value !== '').map((value, index) => (
                              <div key={index}>
                                {index + 1}. {value}
                              </div>
                            ))}
                          </h6>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Date of Birth<br />{dateOfBirth}</h6>
                        </div>
                      </>
                    )}

                  </div> {/* 1st col ends */}

                  <div className="col-12 col-sm-12 col-md-12 col-lg-7">

                    <h5 className="fw-bold" style={{ fontFamily: 'Poppins, sans-serif', color: '#0000008a', fontSize: '1.1em' }}>Administration Options</h5>
                    <div className="mt-3 ms-2">
                      <h6 style={{ fontFamily: 'Poppins, sans-serif', color: '#2596BE', fontSize: '15px' }}>DEACTIVATE USER</h6>
                      <h6 className="mt-3" style={{ fontFamily: 'Poppins, sans-serif', color: '#2596BE', fontSize: '15px' }}>DELETE USER</h6>
                    </div>

                    <hr className="m-0 p-0" />

                    {editMode ? (
                      <>
                        <div className="mt-3 ms-3 d-flex flex-column" style={{ color: '#757575', fontFamily: 'Poppins, sans-serif' }}>

                          <div className='d-flex'>
                            <TextField id="standard-basic" label="First Name*" name="firstName" variant="standard" value={user.firstName}
                              onChange={(e) => setInviteFirstName(e.target.value)} />
                            <TextField className="ms-1" id="standard-basic" label="Last Name*" name="lastName" variant="standard" value={user.lastName}
                              onChange={(e) => setInviteLastName(e.target.value)} />
                          </div>

                          <hr className="my-3 p-0" />
                          <h6 style={{ fontSize: '14px' }} className="fw-bold">Email(s) <AddIcon onClick={addEmailField} className="text-black ms-3" /></h6>

                          <div className='d-flex'>
                            <TextField
                              disabled
                              id="standard-disabled"
                              label="Email"
                              value={user.email}
                              variant="standard"
                              className="me-2"
                            />
                            <FormControl disabled className="mt-3" style={{ width: '120px' }}>
                              <NativeSelect
                                id="demo-simple-select-disabled"
                                defaultValue={'Main'}
                                inputProps={{
                                  name: 'email',
                                  id: 'uncontrolled-native',
                                }}
                              >
                                <option value={'Main'}>Main</option>
                              </NativeSelect>
                            </FormControl>
                          </div>

                          {/* email */}
                          {emails.map((email, index) => (
                            <div key={index} className="d-flex mt-2">
                              <TextField
                                id={`email-${index}`}
                                name="email"
                                type="email"
                                label="Email"
                                variant="standard"
                                value={email.value}
                                onChange={(event) => handleEmailValueChange(index, event)}
                                style={{ width: '145px' }}
                              />
                              <NativeSelect
                                className="ms-2"
                                style={{ width: '120px' }}
                                defaultValue={email.type}
                                inputProps={{
                                  name: 'emailType',
                                  id: `email-type-${index}`,
                                }}
                                onChange={(event) => handleEmailTypeChange(index, event)}
                              >
                                <option value={'Work'}>Work</option>
                                <option value={'Personal'}>Personal</option>
                              </NativeSelect>
                            </div>
                          ))}
                          {/* email */}

                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Phone Number(s) <AddIcon onClick={handleAddRow} className="text-black ms-3" /></h6>

                          {rows.map((row, index) => (
                            <div key={index} className="d-flex justify-content-between">
                              <TextField
                                className="my-2"
                                size="small"
                                type="email"
                                fullWidth
                                variant="standard"
                                placeholder="Phone Number"
                                value={row.phoneNumber}
                                onChange={(e) => handlePhoneNumberChange(index, 'phoneNumber', e.target.value)}
                              />

                              <FormControl fullWidth variant="standard">
                                <Select
                                  className="my-2 mx-1"
                                  size="small"
                                  value={row.type}
                                  onChange={(e) => handlePhoneNumberChange(index, 'type', e.target.value)}
                                >
                                  <MenuItem value="Main">Main</MenuItem>
                                  <MenuItem value="Cell">Cell</MenuItem>
                                  <MenuItem value="Home">Home</MenuItem>
                                  <MenuItem value="Work">Work</MenuItem>
                                </Select>
                              </FormControl>
                              <IconButton
                                edge="end"
                                className="my-1"
                                onClick={() => handleDeleteRow(index)}
                              >
                                <CloseIcon className="text-black" />
                              </IconButton>
                            </div>
                          ))}

                          <hr className="m-0 p-0" />

                          <TextField className="mt-2" id="standard-basic" label="Street Address" variant="standard" name="streetAddress" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />

                          <div className="d-flex mt-2 justify-content-center">
                            <TextField className="me-1" id="standard-basic" label="Country" variant="standard" name="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                            <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Region
                              </InputLabel>
                              <NativeSelect
                                defaultValue={region}
                                inputProps={{
                                  name: 'region',
                                  id: 'uncontrolled-native',
                                }}
                                onChange={(e) => setRegion(e.target.value)}
                              >
                                <option value={''}></option>

                              </NativeSelect>
                            </FormControl>
                          </div>

                          <div className="d-flex mt-2 justify-content-center">
                            <TextField className="me-1" id="standard-basic" label="City" variant="standard" name="city" value={city} onChange={(e) => setCity(e.target.value)} />
                            <TextField id="standard-basic" label="Postal Code" variant="standard" name="postalCode" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                          </div>

                          <hr className="mt-4 mb-0 p-0" />

                          <TextField className="mt-2" type="url" id="standard-basic" label="LinkedIn" variant="standard" name="linkedIn" value={linkedIn} onChange={(e) => setLinkedIn(e.target.value)} />

                          <textarea name="bio" value={bio} onChange={(e) => setBio(e.target.value)} placeholder="Bio..." class="form-control mt-3" id="exampleFormControlTextarea1" rows="3"></textarea>

                          <h6 style={{ fontSize: '14px' }} className="fw-bold my-3">Emergency Contact(s) <AddIcon onClick={handleAddContact} className="text-black ms-3" /></h6>

                          {contacts.map((contact, index) => (
                            <div key={index} className="d-flex">
                              <TextField
                                id={`name-${index}`}
                                label="Name"
                                variant="standard"
                                value={contact.name}
                                onChange={(e) => handleContactChange(index, 'name', e.target.value)}
                              />
                              <TextField
                                className="mx-1"
                                id={`relation-${index}`}
                                label="Relation"
                                variant="standard"
                                value={contact.relation}
                                onChange={(e) => handleContactChange(index, 'relation', e.target.value)}
                              />
                              <TextField
                                id={`phoneNumber-${index}`}
                                label="Phone Number"
                                variant="standard"
                                value={contact.phoneNumber}
                                onChange={(e) => handleContactChange(index, 'phoneNumber', e.target.value)}
                              />
                            </div>
                          ))}

                        </div>
                        <button style={{ color: '#2496BE', fontFamily: 'Poppins, sans-serif' }} className="btn mt-3 ms-2" onClick={handleUserCardSave}>SAVE INFORMATION</button>
                      </>
                    ) : (
                      <>
                        <div className="mt-3 ms-3 d-flex flex-column" style={{ color: '#757575', fontFamily: 'Poppins, sans-serif' }}>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold">Email(s)</h6>
                          <p style={{ fontSize: '14px', fontFamily: 'Poppins, sans-serif' }} className="text-black mt-1 mb-2">{user.email}</p>
                          <button style={{ backgroundColor: '#DEDEDE', color: '#494949', width: '65px' }} className="rounded-pill py-1 mt-0 btn text-black ms-3">Main</button>
                          {emails.map((email, index) => (
                            <div key={index} className="d-flex flex-column">
                              <p style={{ fontSize: '14px', fontFamily: 'Poppins, sans-serif' }} className="text-black mt-1 mb-2">{email.value}</p>
                              <button style={{ backgroundColor: '#DEDEDE', color: '#494949', width: '44%' }} className="rounded-pill py-1 mt-0 btn text-black ms-3">
                                {email.type}
                              </button>
                            </div>
                          ))}
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Phone Number(s)</h6>
                          <hr className="m-0 p-0" />
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Street Address <br />
                            {streetAddress}
                          </h6>

                          <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column">
                              <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Country<br />
                                {country}
                              </h6>
                              <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">City<br />
                                {city}
                              </h6>
                            </div>

                            <div className="d-flex flex-column">
                              <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Region<br />
                                {region}
                              </h6>
                              <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Postal Code<br />
                                {postalCode}
                              </h6>
                            </div>

                          </div>

                          <hr className="m-0 p-0" />
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">LinkedIn<br />
                            {linkedIn}
                          </h6>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Bio<br />
                            {bio}
                          </h6>
                          <h6 style={{ fontSize: '14px' }} className="fw-bold mt-3">Emergency Contact(s)<br />
                            {contacts.map((contact, index) => (
                              <span className="mt-1" key={index}>
                                {contact.name} &nbsp;&nbsp; {contact.relation} &nbsp;&nbsp; {contact.phoneNumber}{index !== contacts.length - 1 && ', '}
                              </span>
                            ))}
                          </h6>
                        </div>
                      </>
                    )}
                  </div>

                </div> {/* row ends */}
                {/* card ends */}
              </div>
            ))
          )}
        </Grid>

        {/*form*/}

        <Dialog maxWidth="md" open={open} onClose={handleClose}>
          <DialogTitle className="d-flex justify-content-between"><p className="text-white">.</p><h3 class="text-center fw-bold">Add People</h3><CloseIcon onClick={handleClose} /></DialogTitle>
          <DialogContent style={{ overflowX: 'scroll' }} >
            <Box sx={{ width: '100%' }}>
              <Box sx={{}}>
                <div className="rounded-pill" style={{ backgroundColor: '#E8E8E8' }} >
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                    <Tab className="text-capitalize" sx={{ fontSize: '17px', color: '#2596BE' }} label="Invite to Company" {...a11yProps(0)} />
                    <Tab className="text-capitalize" sx={{ fontSize: '17px', color: '#2596BE' }} label="Add to Directory" {...a11yProps(1)} />
                  </Tabs>
                </div>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }} ><b>You are currently using 1 of 1 available licenses.</b> Users added to licenses will be assigned permissions depending on the selected role. You can always manage your licenses on the Billing page.</p>

                {/*form code*/}
                {[...Array(divCount)].map((_, index) => (
                  <div key={index} className="rounded rounded-1 border border-light-subtle mb-3" >

                    <div className="d-flex p-1 pb-2">
                      <AccountCircleIcon sx={{ color: '#AAAAAA', fontSize: '40px' }} />
                      <label htmlFor="file-upload">
                        <EditIcon className="ms-2 mt-1" />
                      </label>

                      <input type="file" id="file-upload" style={{ display: 'none' }} onChange={handleFileChange} />
                      <TextField
                        onChange={(e) => setInviteFirstName(e.target.value)}
                        name="inviteFirstName"
                        value={inviteFirstName}
                        className="ms-4"
                        id="standard-basic"
                        placeholder="First Name *"
                        variant="standard"
                        focused
                        required
                      />

                      <TextField
                        onChange={(e) => setInviteLastName(e.target.value)}
                        name="inviteLastName"
                        value={inviteLastName}
                        className="ms-5"
                        id="standard-basic"
                        placeholder="Last Name *"
                        variant="standard"
                        focused
                        required
                      />
                      <TextField
                        onChange={(e) => setInviteEmail(e.target.value)}
                        name="inviteEmail"
                        value={inviteEmail}
                        className="ms-5"
                        id="standard-basic"
                        placeholder="Email *"
                        type="email"
                        variant="standard"
                        focused
                        required />
                    </div>

                    <div className="row w-100 mb-3">

                      <div className="col-12 col-lg-2 col-md-4 col-sm-4">
                        {file && (
                          <HoverableDiv>
                            <Typography>{file.name}</Typography>
                            <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                          </HoverableDiv>
                        )}
                      </div>

                      <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                        <InputLabel className="" id="demo-simple-select-label">Teams *</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          variant="standard"
                          label="Teams *"
                          name="selectedTeam"
                          fullWidth
                          value={selectedTeam}
                          onChange={(e) => setSelectedTeam(e.target.value)}
                        >
                          {teams.map((team) => (
                            <MenuItem key={team} value={team}>
                              {team}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div className="col-12 col-lg-4 col-md-4 col-sm-4">
                        <InputLabel className="" id="demo-simple-select-label">Role *</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          variant="standard"
                          color="warning"
                          label="Role *"
                          fullWidth
                          name="selectedRole"
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                    </div>

                  </div>
                ))}

                {/* <div className="rounded rounded-1 d-flex text-center" style={{ backgroundColor: '#E5E4E2' }} >
                <button onClick={handleDuplicate} style={{ color: '#2596BE' }} className="d-flex btn w-100 text-center justify-content-center py-1"><h6 className="pt-1"><AddCircleOutlineIcon /></h6> <h6 className="fw-bolder ms-2 pt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '17px' }}>ADD ANOTHER USER</h6> </button>
              </div> */}

                <div className="m-0 mt-2 d-flex">
                  <Checkbox checked={sendInvitation} onChange={() => setSendInvitation(!sendInvitation)} sx={{ m: '0', p: '0' }} style={{ color: '#2596BE' }} inputProps={{ 'aria-label': 'controlled' }} />
                  <h6 className="pt-2 ms-2" style={{ fontFamily: 'Poppins, sans-serif' }}>Send invite email</h6>
                </div>

                <p className="mt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }}>Adding users to paid roles beyond your current license count will increase your subscription cost. The card on file will be immediately charged the prorated difference. <b>Have multiple users? Add them now to prevent multiple charges on your credit card.</b></p>

                <div className="rounded rounded-1 d-flex text-center" style={{ backgroundColor: '#2596BE' }} >
                  <button onClick={handleSaveUser} style={{ color: 'white' }} className="d-flex btn w-100 text-center justify-content-center py-1"><h6 className="fw-bolder pt-1" style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '17px', letterSpacing: '1px' }}>INVITE TO COMPANY</h6> </button>
                </div>

              </CustomTabPanel>

              <CustomTabPanel value={value} index={1} >
                <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }} ><b>Add an entry to the Directory.</b> The entry will still appear in the Accountability Chart, but won't have a login attached and can't be assigned contents like Rocks, To-Dos, and Issues.</p>

                {/*form code*/}
                {[...Array(divCount)].map((_, index) => (
                  <div key={index} className="rounded rounded-1 border border-light-subtle mb-3">

                    <div className="d-flex p-1 pb-4">
                      <AccountCircleIcon sx={{ color: '#AAAAAA', fontSize: '40px' }} />
                      <label htmlFor="file-upload">
                        <EditIcon className="ms-2 mt-1" />
                      </label>

                      <input type="file" id="file-upload" style={{ display: 'none' }} onChange={handleFileChange} />

                      <TextField
                        onChange={(e) => setAddFirstName(e.target.value)}
                        value={addFirstName}
                        name="addFirstName"
                        className="ms-4"
                        placeholder="First Name *"
                        variant="standard"
                        focused
                        required
                        id={`first-name-${index}`}
                      />

                      <TextField
                        onChange={(e) => setAddLastName(e.target.value)}
                        value={addLastName}
                        name="addLastName"
                        className="ms-5"
                        placeholder="Last Name *"
                        variant="standard"
                        focused
                        required
                        id={`last-name-${index}`}
                      />

                      <TextField
                        onChange={(e) => setAddEmail(e.target.value)}
                        value={addEmail}
                        name="addEmail"
                        className="ms-5"
                        placeholder="Email *"
                        type="email"
                        variant="standard"
                        focused
                        required
                        id={`email-${index}`}
                      />

                    </div>


                    {file && (
                      <HoverableDiv>
                        <Typography>{file.name}</Typography>
                        <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                      </HoverableDiv>
                    )}

                  </div>
                ))}

                {/* <div onClick={handleDuplicate} className="rounded rounded-1 d-flex text-center" style={{ backgroundColor: '#E5E4E2' }} >
                <button style={{ color: '#2596BE' }} className="d-flex btn w-100 text-center justify-content-center py-1"><h6 className="pt-1"><AddCircleOutlineIcon /></h6> <h6 className="fw-bolder ms-2 pt-2" style={{ fontFamily: 'Poppins, sans-serif', fontSize: '17px' }}>ADD ANOTHER ENTRY</h6> </button>
              </div> */}

                <div className="rounded rounded-1 d-flex text-center mt-3" style={{ backgroundColor: '#2596BE' }} >
                  <button onClick={handleSaveUser} style={{ color: 'white' }} className="d-flex btn w-100 text-center justify-content-center py-1"><h6 className="fw-bolder pt-1" style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '17px', letterSpacing: '1px' }}>ADD TO DIRECTORY</h6> </button>
                </div>
              </CustomTabPanel>
            </Box>


          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>

        {/*form ends*/}
      </Grid>

    </div>
  );
}

export default DirectoryUser;