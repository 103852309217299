import Switch from '@mui/material/Switch';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
const Visionbuildingday2=[
    // 1
    {
        id:1,
        inputtextname:'CHECK-IN',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        EditIcon:<EditIcon  label="Edit section"/>,
    },
    // 2
    {
        id:2,
        inputtextname:'REVIEWING FOCUS DAY TOOLS',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:"",
        EditIcon:<EditIcon  label="Edit section"/>
    },
    // 3
    {
        id:3,
        inputtextname:'CORE VALUES',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:"",
    },
    // 4
    {
        id:4,
        inputtextname:'CORE FOCUS',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:"",

    }, 
       // 5
       {
        id:5,
        inputtextname:'10 YEAR TARGET',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:""
    },
    // 6
    {
        id:6,
        inputtextname:'MARKETING STRATEGY',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:""
    },
    // 7
    {
        id:7,
        inputtextname:'3 YEAR PICTURE™',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:"",
    },
    // 8
    {
        id:8,
        inputtextname:'1 YEAR PLAN',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:""
    }, 
    // 9
    {
        id:9,
        inputtextname:'ROCKS',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:""
    },
     // 10
     {
        id:10,
        inputtextname:'ISSUES LIST',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:""
    },
     // 11
     {
        id:11,
        inputtextname:'NEXT STEPS',
        duration:5,
        visibility:<Switch style={{color:"#1993c6"}}/>,
        lockIcon:<LockIcon label='This default section cannot be edited'/>,
        EditIcon:""
    },
    // 12
    {
        id:12,
        inputtextname:'CONCLUDE',
        duration:5,
        lockIcon:"",
        EditIcon:<EditIcon label='Edit Section' />
    }
]
export default Visionbuildingday2;