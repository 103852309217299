import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
const Seige = () => {
  return (
    <>
      <Card sx={{ maxWidth: '100%', marginTop: "70px",marginTop:"60px" }}>
        <CardContent>
          <Typography sx={{ fontSize: 25, fontWeight: "bolder" }} gutterBottom>
            Personal and Professional Bests
          </Typography>
          <Typography sx={{ mb: 1.5, fontSize: "20px" }}>
            <ul>
              <li> Share a personal and professional best from the past week.</li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </>

  )
}

export default Seige
