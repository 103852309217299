import React from 'react'
import { Grid } from '@mui/material'
import DirectoryAppBar from '../components/directorycomponents/directoryappbar'
import DirectoryUser from '../components/directorycomponents/directoryuser'

export default function Directory() {
  return (
    <>
    <Grid>
    <DirectoryAppBar/>
    </Grid>
    <Grid sx={{ margin: '10px 5px 5px 5px' }}>
    <DirectoryUser/>
    </Grid>
    </>
  )
}
