import React, { useState } from 'react'
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { TextField, IconButton, Menu, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UndoIcon from '@mui/icons-material/Undo';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import './style.css'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import HttpsIcon from '@mui/icons-material/Https';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SchoolIcon from '@mui/icons-material/School';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBinoculars } from '@fortawesome/free-solid-svg-icons'
import WavingHandIcon from '@mui/icons-material/WavingHand';
import FeedIcon from '@mui/icons-material/Feed';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import DialpadIcon from '@mui/icons-material/Dialpad';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material'
import SettingMeetingAppBar from '../../pages/settingcomp/meetingcomp/settingmeeting'


export default function Meetingsettings() {

  return (
    <>
    <Grid container xs={12}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <SettingMeetingAppBar />
      </Grid>
    </Grid>
    </>
  )
}
