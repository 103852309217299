
import React,{useState} from 'react';
import { Button,Typography,Modal,Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FinalCoonfirmationModal from './finalConfirmationModal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RatingConfirmation({onClose,onOpen}) {
const [finalconfirmationModal,setfinalConfirmationModal]=useState(false)
const handlefinalconfirmationModal = () => {
    setfinalConfirmationModal(true)
}
const handlefinalconfirmationModalClose = () => {
    setfinalConfirmationModal(false)
}
const handlefinalconfirmationModalOpen = () => {
    setfinalConfirmationModal(true)
}
  return (
    <div>
      <Modal
      open={onOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" style={{display:"flex",justifyContent:"space-between"}}>
          All those without a rating will be marked as absent.<CloseIcon onClick={onClose}/>
          </Typography>
          <Typography sx={{ mt: 8,display:"flex",justifyContent:"end" }}>
           <Button onClick={onClose}>Cancel</Button>
           <Button variant="contained" onClick={handlefinalconfirmationModal}>OK</Button>
          </Typography>
        </Box>
      </Modal>
      {finalconfirmationModal &&(
        <FinalCoonfirmationModal onClose={handlefinalconfirmationModalClose} onOpen={handlefinalconfirmationModalOpen} />
      )}
    </div>
  );
}
