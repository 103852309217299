import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const names = [
    "Company 1",
    "Company 2",
    "Company 3",
    "Company 4",
  ];

const CompanyMenu = () => {

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={names}
      sx={{ 
        margin: '5px 20px', // Corrected the margin format
      }}
      renderInput={(params) => <TextField {...params}/>}
      defaultValue={names[0]}
    />
  )
}

export default CompanyMenu