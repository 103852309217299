import React, { useState } from 'react'
import { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Createbtn from '../components/createbtn/create'
import { Grid } from '@mui/material'
import { Drawer, Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faCirclePlus, faUpRightFromSquare, faAngleRight, faChevronRight, faList, faUserPlus, faExclamation, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import MoreIcon from '@mui/icons-material/More';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ProcessAppBar from '../components/process/processtopbar'
import { shadows } from '@mui/system';
import Divider from '@mui/material/Divider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import NativeSelect from '@mui/material/NativeSelect';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 3,
};

export default function Process({ onIssueTitleChange }) {

  const [value, setValue] = React.useState('');
  const [zoomLevel, setZoomLevel] = useState(1); // Initial zoom level set to 100% (1x)

  const handleZoomin = () => {
    setZoomLevel(zoomLevel + 0.1); // Increase zoom level
  };

  const handleZoomout = () => {
    setZoomLevel(zoomLevel - 0.1); // Decrease zoom level
  };

  const handleResetZom = () => {
    setZoomLevel(1); // Reset zoom to 100%
  };

  const containerStyle = {
    transform: `scale(${zoomLevel})`, // Apply zoom level to the container
    transformOrigin: 'top left', // Set the origin point for zooming
  };

  var toolbarOptions = [
    ['bold', 'italic'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'direction': 'rtl' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']
  ];
  const module = {
    toolbar: toolbarOptions,
  };

  // yes/no
  const [documented, setDocumented] = React.useState('');
  const handleDocChange = (event) => {
    setDocumented(event.target.value);
  };

  const [followed, setFollowed] = React.useState('');
  const handleFollowChange = (event) => {
    setFollowed(event.target.value);
  };

  const [effective, setEffective] = React.useState('');
  const handleEffectiveChange = (event) => {
    setEffective(event.target.value);
  };

  // measurable modal

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    height: 450,
    bgcolor: 'background.paper',
    p: 3,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  // file upload
  const [file, setFile] = React.useState(null);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile); // Save the selected file

    if (selectedFile) {
      setInputValues({
        ...inputValues,
        attachment: selectedFile.name, // Update attachment in inputValues
      });
    }
  };
  const handleCancel = () => {
    setFile(null); // Remove the file
  };
  // apply the style in the uploaded file
  const HoverableDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between",
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#f5f5f5', // Change the background color on hover
    },
  });


  // Getting the value of Issue
  const [issueTitle, setIssueTitle] = React.useState('');
  const handleIssueChange = (value) => {
    // Update the state in Create based on the issue title input
    setIssueTitle(value);
    onIssueTitleChange(value.trim() === '');
  };

  // createbtn modal

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // table

  const columns = [
    { id: 'name', label: 'Title', minWidth: 170 },
    {
      id: 'goal',
      label: 'Goal',
      minWidth: 170,
      align: 'right',
    },
  ];

  function createData(name, goal) {
    return { name, goal };
  }

  const rows = [
    createData('Revenue', 'TBD'),
    createData('Revenue TTM', 'TBD'),
    createData('Russia', 'TBD'),
    createData('Nigeria', 'TBD'),

  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const processPrint = () => {
    window.print();
  }

  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false)

  //inputValuesToConsole

  //new process
  const [newProcessValues, setNewProcessValues] = React.useState({
    title: '',
    description: '',
  });

  const handleNewProcessInputChange = (e) => {
    const { name, value } = e.target;
    setNewProcessValues({
      ...newProcessValues,
      [name]: value,
    });
  };

  const handleNewProcessSave = () => {
    console.log(newProcessValues);
  };

  //new step
  const [newStepValues, setNewStepValues] = React.useState({
    title: '',
    description: '',
  });

  const handleNewStepInputChange = (e) => {
    const { name, value } = e.target;
    setNewStepValues({
      ...newStepValues,
      [name]: value,
    });
  };

  const handleNewStepSave = () => {
    console.log(newStepValues);
  };

  //offcanvas
  const [inputValues, setInputValues] = React.useState({
    title: '',
    description: '',
    documented: '',
    followed: '',
    effective: '',
    attachment: '',
  });


  const handleInputChange = (e, selectName) => {
    const { name, value } = e.target;

    if (selectName) {
      // Handle the Select separately
      setInputValues({
        ...inputValues,
        [selectName]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    }
  };

  const handleSave = () => {
    console.log(inputValues);
  };

  return (
    <>
      <Grid>
        <ProcessAppBar handlePrint={processPrint}
          zoomIn={handleZoomin} zoomOut={handleZoomout} resetZoom={handleResetZom}
        />
      </Grid>

      {/* Add Icon Code Start */}
      <div class="text-center fs-3 mt-3" style={{ color: "#2596BE" }}>
        <button class="btn fs-3" style={{ color: "#2596BE" }} data-bs-toggle="modal" data-bs-target="#exampleModal">
          <FontAwesomeIcon icon={faCirclePlus} />
        </button>
      </div>

      {/* Add Icon Pop-up */}
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style={{ width: '380px' }} >
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-4" id="exampleModalLabel">New Core Process</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">

              <div class="d-flex">
                <TextField
                  fullWidth
                  variant="standard"
                  size="lg"
                  label="Title*"
                  name="title"
                  value={newProcessValues.title}
                  onChange={handleNewProcessInputChange}
                />
              </div>

              <div class="d-flex mt-4">
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Description"
                  rows={4}
                  name="description"
                  value={newProcessValues.description}
                  onChange={handleNewProcessInputChange}
                />
              </div>

            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary shadow" data-bs-dismiss="modal">Cancel</button>
              <button onClick={handleNewProcessSave} type="button" class="btn btn-primary shadow">SAVE</button>
            </div>
          </div>
        </div>
      </div> {/* Add Icon Pop-up Ends */}

      <div className="zoom-container" style={containerStyle}>
        {/* Add div Output */}
        <div class="container mt-3">
          <div class="row">

            <div class="col-12 col-lg-4 col-md-6 col-sm-6">
              <div class="card">
                <div class="card-body">

                  <div class="grid gap-3 d-flex justify-content-between">

                    <div class="p-2 g-col-6">
                      <h5 class="card-title">Title</h5>
                    </div>

                    <div className="g-col-6">
                      <button class="btn last-btn text-white w-100" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><div class="text-body-secondary fs-6"><FontAwesomeIcon icon={faUpRightFromSquare} /></div></button>
                    </div>

                  </div> {/* grid ends */}

                  {/* Documented, Followed, Effective */}
                  <div class="d-flex justify-content-around mt-3">

                    <div class="d-flex flex-column">
                      <div class="text-body-secondary fw-bold" style={{ fontSize: 13 }}>Documented</div>
                      <h6 class="text-black text-center mt-3">N/A</h6>
                    </div>

                    <div class="d-flex flex-column">
                      <div class="text-body-secondary fw-bold" style={{ fontSize: 13 }}>Followed By All</div>
                      <h6 class="text-black text-center mt-3">N/A</h6>
                    </div>

                    <div class="d-flex flex-column">
                      <div class="text-body-secondary fw-bold" style={{ fontSize: 13 }}>Effective</div>
                      <h6 class="text-black text-center mt-3">N/A</h6>
                    </div>

                  </div>

                  <hr />


                  {/* Last Add Icon */}
                  <div class="text-center fs-4 mt-3 text-black">
                    <button class="fs-4 btn text-black" onClick={handleOpens}>
                      <FontAwesomeIcon icon={faCirclePlus} />
                    </button>
                  </div>

                  <div>
                    <Modal
                      open={opens}
                      onClose={handleCloses}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style1}>
                        <div class="grid gap-3 d-flex justify-content-between">
                          <div class="g-col-6 d-flex">
                            <h1 class="modal-title fs-4" id="exampleModalLabel">New Step</h1>
                          </div>
                          <div class="g-col-6 d-flex">
                            <button type="button" class="btn-close" onClick={handleCloses}></button>
                          </div>
                        </div>

                        <div className="d-flex flex-column">
                          <TextField
                            fullWidth
                            variant="standard"
                            size="lg"
                            name="title"
                            value={newStepValues.title}
                            onChange={handleNewStepInputChange}

                            label="Title*" />

                          <TextField
                            className="mt-3"
                            fullWidth
                            id="outlined-multiline-static"
                            label="Description"
                            rows={4}
                            name="description"
                            value={newStepValues.description}
                            onChange={handleNewStepInputChange}
                          />
                        </div>

                        <div class="text-end mt-3">
                          <button class="btn btn-outline-secondary shadow" onClick={handleCloses} >Cancel</button>
                          <button onClick={handleNewStepSave} style={{ backgroundColor: '#2596BE' }} type="button" class="btn text-white shadow px-3 ms-3">SAVE</button>
                        </div>

                      </Box>
                    </Modal>
                  </div>


                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      {/*view detail code*/}

      {/* View Detail Icon */}
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-body">

          {/* 1st row icons */}
          <div class="grid gap-3 d-flex justify-content-between">
            <div class="g-col-6 d-flex">
              <button type="button" class="btn" onClick={handleSave} ><FontAwesomeIcon icon={faAngleRight} /></button> {/*data-bs-dismiss="offcanvas" aria-label="Close"*/}

              <div class="dropdown d-flex"><button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false"><MoreIcon fontSize="small" /></button>
                <ul class="dropdown-menu">
                  {/*make it an issue modal*/}
                  <li>
                    <button class="dropdown-item" variant="contained" size="medium" sx={{ backgroundColor: "#1993c6", marginRight: "20px" }} onClick={openModal} disabled={isModalOpen}>
                      <Createbtn isOpen={isModalOpen} onClose={closeModal} />
                      <div class="text-center d-flex fs-4 text-secondary fw-bold">
                        <FontAwesomeIcon icon={faExclamation} />
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <h6 class="text-end my-1">Make it an Issue</h6></div>
                    </button>
                  </li>

                  <li>
                    <button class="dropdown-item" variant="contained" size="medium" sx={{ backgroundColor: "#1993c6", marginRight: "20px" }} onClick={openModal} disabled={isModalOpen}>
                      <Createbtn isOpen={isModalOpen} onClose={closeModal} />
                      <div class="text-center d-flex fs-5 text-secondary fw-bold">
                        <FontAwesomeIcon icon={faPenToSquare} />
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <h6 class="text-end my-1">Create a To-do</h6></div>
                    </button>
                  </li>

                  <li>
                    <button class="dropdown-item">
                      <div class="text-center d-flex fs-5 text-danger fw-bold"><FontAwesomeIcon icon={faTrash} /><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>  <h6 class="text-end my-1">Delete</h6></div>
                    </button>
                  </li>
                </ul>
              </div>

            </div>
            <div class=" g-col-6">
              <button type="button" class="btn"><FontAwesomeIcon icon={faUserPlus} /></button>
            </div>
          </div>

          <hr />

          {/* Title Field */}
          <div class="d-flex">
            <TextField
              variant="standard"
              size="lg"
              style={{ width: 400 }}
              label="Title*"
              name="title"
              value={inputValues.title}
              onChange={handleInputChange}
            />
          </div>

          {/* Description starts */}

          <ReactQuill modules={module} style={{ marginTop: "20px" }} theme="snow" value={value} onChange={setValue} />

          <div>
            <CardContent className='container-fluid'
              style={{ maxHeight: '100%', height: "100%" }}>
              <Box style={{ height: "auto" }}
                component="form"
                Validate
                autoComplete="off"

              >

                <div className='d-flex flex-row' style={{ marginTop: "0px" }}>
                  {/* Documented Dropdown */}
                  <FormControl variant="standard" sx={{ minWidth: 320 }}>
                    <InputLabel id="demo-simple-select-standard-label">Documented</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={inputValues.documented}
                      onChange={(e) => handleInputChange(e, 'documented')}
                      label="Documented"
                    >
                      <MenuItem value={'yes'}>Yes</MenuItem>
                      <MenuItem value={'no'}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* Followed Dropdown */}
                <div className='d-flex flex-row' style={{ marginTop: "5px" }}>
                  <FormControl variant="standard" sx={{ minWidth: 320 }}>
                    <InputLabel id="demo-simple-select-standard-label">Followed By All</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={inputValues.followed}
                      onChange={(e) => handleInputChange(e, 'followed')}
                      label="Followed By All"
                    >
                      <MenuItem value={'yes'}>Yes</MenuItem>
                      <MenuItem value={'no'}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* Effective Dropdown */}
                <div className='d-flex flex-row' style={{ marginTop: "5px" }}>
                  <FormControl variant="standard" sx={{ minWidth: 320 }}>
                    <InputLabel id="demo-simple-select-standard-label">Effective</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={inputValues.effective}
                      onChange={(e) => handleInputChange(e, 'effective')}
                      label="Effective"
                    >
                      <MenuItem value={'yes'}>Yes</MenuItem>
                      <MenuItem value={'no'}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* measurable text */}

                <div class="d-flex align-items-center" style={{ marginTop: "30px" }}>
                  <span style={{ marginRight: '10px' }}>Measurables</span>
                  {/* measurable modal */}
                  <div>
                    <Button variant="text" style={{ padding: 0 }} onClick={handleOpen}><AddCircleOutlineIcon /></Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <div id="modal-modal-title">
                          <h3>Add Measurables</h3>
                        </div>
                        <div id="modal-modal-description" sx={{ mt: 2 }}>
                          <TextField id="standard-basic" style={{ width: 800 }} label="Search Measurables" variant="standard" />
                          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    {columns.map((column) => (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                      >
                                        {column.label}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                      return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                          {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                              <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                  ? column.format(value)
                                                  : value}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <TablePagination
                              rowsPerPageOptions={[10, 25, 50]}
                              component="div"
                              count={rows.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              sx={{
                                ".MuiTablePagination-displayedRows": {
                                    marginTop: "12px",
                                },
                                ".MuiTablePagination-spacer": {
                                    paddingTop: 1,
                                },
                                ".MuiTablePagination-selectLabel": {
                                    marginBottom: '8px',
                                },
                                ".MuiSelect-select": {
                                    paddingTop: 0,
                                },
                            }}
                            />
                          </Paper>
                        </div>
                      </Box>
                    </Modal>
                  </div>

                </div>

                {/* Attachments */}
                <div className='col-lg-2 col-md-12 col-sm-12 mt-2 w-100'>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: "20px" }}>
                    <span style={{ marginRight: '10px' }}>Attachments</span>
                    <label htmlFor="file-upload">
                      <AddCircleOutlineIcon />
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </div>
                  {file && (
                    <HoverableDiv>
                      <Typography>{file.name}</Typography>
                      <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                    </HoverableDiv>
                  )}
                </div>
              </Box>
            </CardContent>
          </div>



        </div>
      </div>

    </>
  );
}

