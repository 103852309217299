import React, { useState,useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import MenuIcon from '@mui/icons-material/Menu';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import UndoIcon from '@mui/icons-material/Undo';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// Main Agenda forms are import Here
import Mainagendaform from './agendaforms/mainagendaform';
// custom Meeting component import below
import CustomMeeting from './customMeeting';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function SettingMeetingAppBar() {
  const navigate=useNavigate();
  // below is for custom meeting agenda
  const [isCustomMeetingComponentVisible, setCustomMeetingComponentVisible] = useState(false);
  const handleCustomMeetingButton = () => {
    setCustomMeetingComponentVisible(!isCustomMeetingComponentVisible);
  };

  // State for the Menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // handling forms and agenda from below
  const [selectedAgenda, setSelectedAgenda] = useState(1);
  const handleAgendaChange = (event) => {
    setSelectedAgenda(event.target.value);
  };
const handleCloseIcon=()=>{
  navigate('/meetings')
}
// custom meeting title Name Get
const [customMeetingNames, setCustomMeetingNames] = useState([]);
const fetchData = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}meetings/get`);
    const meetings = response.data.meetings;
const customNames = meetings.map(meeting => meeting.name);
setCustomMeetingNames(customNames);
    console.log("App data from meeting custom Names", customNames);
  } catch (error) {
    console.error('Error:', error);
  }
};

useEffect(() => {
  fetchData();
}, []); 
  return (
    <div className='vh-75'>
      <AppBar position="staic" style={{ backgroundColor: 'white', width: '100%' }}>
        <Toolbar style={{justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl variant="outlined" style={{ marginRight: '10px', width: '190px' }}>
              <InputLabel htmlFor="agenda-select" sx={{ fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans, sans-serif', background: 'white', padding: '0 8px' }}>
                Agenda
              </InputLabel>
              <Select
                labelId="agenda-select"
                label="Agenda"
                variant="outlined"
                // defaultValue={1}
                value={selectedAgenda}
                onChange={handleAgendaChange}
                sx={{ height: '38px', width: "200px" }}
                MenuProps={{
                  MenuListProps: {
                    style: { maxHeight: 200, overflow: 'auto' },
                  },
                }}
              >
                <MenuItem disabled>Default Agendas</MenuItem>
                <MenuItem value={1}>Level 10 Meeting™</MenuItem>
                <MenuItem value={2}>Quaterly Session</MenuItem>
                <MenuItem value={3}>Annual Session- Day One</MenuItem>
                <MenuItem value={4}>Annual Session- Day Two</MenuItem>
                <MenuItem value={5}>Focus Day®</MenuItem>
                <MenuItem value={6}>Vision Building® Day 1</MenuItem>
                <MenuItem value={7}>Vision Building® Day 2</MenuItem>
                <MenuItem disabled>Custom Agendas</MenuItem>
                {/* <MenuItem value={8} disabled>NoCustom Agendas</MenuItem> */}
                {customMeetingNames.length > 0 ? (
          customMeetingNames.map((name, index) => (
            <MenuItem key={index} value={index + 8}>{name}</MenuItem>
          ))
        ) : (
          <MenuItem value={8} disabled>No Custom Agendas</MenuItem>
        )}
              </Select>
            </FormControl>
            {/* <p>{customMeetingName}</p> */}
            <button className='btn d-flex align-items-center justify-content-center p-2' style={{ outline: "1px solid grey", width: "30px", height: "36px" }} onClick={handleCustomMeetingButton}>
              <AddIcon style={{ color: "rgb(25, 147, 198)" }} />
            </button>
            {/* <Button
              variant="outlined"
              startIcon={<AddIcon /> }
              color="primary"
              sx={{width:"10px" ,outline:"1px solid orange"}}
              // size="small"
              style={{  height: '36px', alignContent: 'center', borderColor: '#0000008a', color: '#1993c6', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans, sans-serif' }}
            >            
            </Button> */}

          </div>

          <div>
            {/* <Button
              variant="outlined"
              startIcon={<SaveIcon />}
              color="primary"
              style={{ borderColor: '#0000008a', color: '#0000008a', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans, sans-serif', textTransform: 'capitalize' }}
            >
              Save
            </Button> */}
            <Button
              variant="outlined"
              startIcon={<MenuIcon />}
              color="primary"
              style={{ marginRight: '-16px', borderColor: '#0000008a', color: '#0000008a', fontSize: '14px', fontWeight: '400', fontFamily: 'Nunito Sans, sans-serif', textTransform: 'capitalize' }}
              onClick={handleMenuClick}
            >
              More Options
            </Button>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {/* Add your menu items here */}
              <MenuItem onClick={handleMenuClose} disabled>
                <UndoIcon /> Undo All Chnages
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <KeyboardDoubleArrowRightIcon /> Push to all Teams
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <RestartAltIcon /> Reset to Default
              </MenuItem>
            </Menu>
            <IconButton color="gray" sx={{ marginLeft: '30px' }}>
              <CloseIcon onClick={handleCloseIcon}/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div>
        <div>
          {/* below is custom meeting component based on rendering */}
          {isCustomMeetingComponentVisible ? (
            <CustomMeeting />
          ) : (
            <Mainagendaform selectedAgenda={selectedAgenda} />
          )}
          {/* {isCustomMeetingComponentVisible && <CustomMeeting />} */}
        </div>
      </div>
    </div>
  );
}

export default SettingMeetingAppBar;
