import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Paper from '@mui/material/Paper';

const CompanyInfoPage = () => {
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [coachStatus, setCoachStatus] = useState('');
  const [howDidYouHear, setHowDidYouHear] = useState('');

  const handleChangeCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangeCoachStatus = (event) => {
    setCoachStatus(event.target.value);
  };

  const handleChangeHowDidYouHear = (event) => {
    setHowDidYouHear(event.target.value);
  };

  const handleUploadClick = () => {
    // Trigger the hidden file input
    document.getElementById('fileInput').click();
  };

  const handleFileChange = (event) => {
    // Handle the selected file here
    const selectedFile = event.target.files[0];
    // You can perform actions with the selected file, e.g., upload it
    console.log('Selected File:', selectedFile);
  };

  return (
    <Grid sx={{ margin: '10px 5px 5px 5px' }}>
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Company Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{marginTop:'65px', marginLeft:'0px'}}>
          {/* Upload Image */}
          <div style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100px', height: '100px', borderRadius: '50%' }}>
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <IconButton onClick={handleUploadClick}>
              <PhotoCameraIcon fontSize="large" color="primary" />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={8}>
          {/* Form Fields */}
          <form>
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              value={companyName}
              onChange={handleChangeCompanyName}
              margin="normal"
            />
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={phoneNumber}
              onChange={handleChangePhoneNumber}
              margin="normal"
            />
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>Are you working with a business coach?</InputLabel>
              <Select
                label="Are you working with a business coach?"
                value={coachStatus}
                onChange={handleChangeCoachStatus}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>How did you hear about us?</InputLabel>
              <Select
                label="How did you hear about us?"
                value={howDidYouHear}
                onChange={handleChangeHowDidYouHear}
              >
                <MenuItem value="search">Search Engine</MenuItem>
                <MenuItem value="social">Social Media</MenuItem>
                <MenuItem value="friend">Friend/Family</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </Paper>
    </Grid>
  );
};

export default CompanyInfoPage;
