import React, { useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function WeeklyTable() {
  // Initialize selectedTime and selectedAmPm with default values
  const initialSelectedTime = {};
  const initialSelectedAmPm = {};

  // Populate initialSelectedTime and initialSelectedAmPm with default values
  daysOfWeek.forEach((day) => {
    initialSelectedTime[day] = '8:00'; // Set default time to '6:30'
    initialSelectedAmPm[day] = 'AM';   // Set default AM/PM to 'AM'
  });

  const [selectedTime, setSelectedTime] = useState(initialSelectedTime);
  const [selectedAmPm, setSelectedAmPm] = useState(initialSelectedAmPm);

  const handleTimeChange = (day, value) => {
    setSelectedTime((prev) => ({ ...prev, [day]: value }));
  };

  const handleAmPmChange = (day, value) => {
    setSelectedAmPm((prev) => ({ ...prev, [day]: value }));
  };

  const rowStyle = {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  };

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
          Scheduling
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
          Get a list of all your To-Dos, Rocks and Issues, delivered to your your primary email address on the days/times of your choice.
          </Typography>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableBody>
            {daysOfWeek.map((day) => (
              <TableRow key={day} sx={rowStyle}>
                <TableCell>
                  <FormControlLabel
                    label={day}
                    control={
                      <Checkbox
                        checked={selectedTime[day] !== ''} // Adjust the condition as needed
                        onChange={() => handleTimeChange(day, selectedTime[day] === '' ? '6:30' : '')}
                      />
                    }
                  />
                </TableCell>
                <TableCell>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor={`time-${day}`}>Time</InputLabel>
                    <Select
                      label="Time"
                      size="small"
                      id={`time-${day}`}
                      value={selectedTime[day]}
                      onChange={(e) => handleTimeChange(day, e.target.value)}
                    >
                      <MenuItem value="6:30">6:30</MenuItem>
                      <MenuItem value="7:00">7:00</MenuItem>
                      <MenuItem value="8:00">8:00</MenuItem>
                      {/* Add more time options as needed */}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor={`am-pm-${day}`}>AM/PM</InputLabel>
                    <Select
                      label="AM/PM"
                      size="small"
                      id={`am-pm-${day}`}
                      value={selectedAmPm[day]}
                      onChange={(e) => handleAmPmChange(day, e.target.value)}
                    >
                      <MenuItem value="AM">AM</MenuItem>
                      <MenuItem value="PM">PM</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default WeeklyTable;
