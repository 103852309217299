import React, { useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  FormControlLabel,
  Radio,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';

function TeamsTable() {
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState({
    name: '',
    isLT: false,
    isProject: false,
    isPrivate: false,
    addMyself: false,
  });

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewTeam({
      ...newTeam,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleCreateTeam = () => {
    setTeams([...teams, newTeam]);
    setNewTeam({
      name: '',
      isLT: false,
      isProject: false,
      isPrivate: false,
      addMyself: false,
    });
    setOpenCreateDialog(false);
  };

  const handleDeleteTeam = (index) => {
    const updatedTeams = [...teams];
    updatedTeams.splice(index, 1);
    setTeams(updatedTeams);
  };

  return (
    <>
    <Grid sx={{ margin: '10px 5px 5px 5px' }}>
    <Paper elevation={3} style={{ padding: '16px',width:"1080px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Teams</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <button className='btn text-black' style={{backgroundColor: '#F5F5F5', fontFamily: 'Poppins, sans-serif', fontSize: '14px'}} onClick={() => setOpenCreateDialog(true)}>
            CREATE TEAM
          </button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="right">LT</TableCell>
              <TableCell align="right">Project</TableCell>
              <TableCell align="right">Private</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  {team.addMyself ? (
                    <Tooltip title="You are on this team">
                      <span>
                        {team.name} <PersonIcon style={{ verticalAlign: 'middle' }} />
                      </span>
                    </Tooltip>
                  ) : (
                    team.name
                  )}
                </TableCell>
                <TableCell align="right">
                  <FormControlLabel
                    control={<Radio checked={team.isLT} />}
                    label="LT"
                  />
                </TableCell>
                <TableCell align="right">
                  <FormControlLabel
                    control={<Checkbox checked={team.isProject} />}
                    label="Project"
                  />
                </TableCell>
                <TableCell align="right">
                  <FormControlLabel
                    control={<Checkbox checked={team.isPrivate} />}
                    label="Private"
                  />
                </TableCell>
                {/* <TableCell>
                  <FormControlLabel
                    control={<Checkbox checked={team.addMyself} />}
                    label="Add Myself"
                    onChange={() => {
                      const updatedTeams = [...teams];
                      updatedTeams[index].addMyself = !team.addMyself;
                      setTeams(updatedTeams);
                    }}
                  />
                </TableCell> */}
                <TableCell align="right">
                  <IconButton onClick={() => handleDeleteTeam(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <DialogTitle><h5 style={{fontFamily: 'Poppins, sans-serif'}}>Create New Team</h5></DialogTitle>
        <DialogContent>
          <p style={{fontFamily: 'Poppins, sans-serif', color: '#0000008a', fontSize: '15px'}}>Submit this form to create a new team within your company.</p>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Team Name"
                  variant="standard"
                  fullWidth
                  value={newTeam.name}
                  onChange={handleInputChange}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isProject"
                      checked={newTeam.isProject}
                      onChange={handleInputChange}
                    />
                  }
                  label="Project"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isPrivate"
                      checked={newTeam.isPrivate}
                      onChange={handleInputChange}
                    />
                  }
                  label="Private"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isLT"
                      checked={newTeam.isLT}
                      onChange={handleInputChange}
                    />
                  }
                  label="Leadership Type (LT)"
                />
              </Grid> */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="addMyself"
                      color="secondary"
                      checked={newTeam.addMyself}
                      onChange={handleInputChange}
                    />
                  }
                  label="Add yourself as a team member"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="bg-white text-black" onClick={() => setOpenCreateDialog(false)}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={handleCreateTeam}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
    </Grid>
    </>
  );
}

export default TeamsTable;
