import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LaunchIcon from '@mui/icons-material/Launch';

function ContactEmail() {
  const handleEditEmailClick = () => {
    // Handle edit email action here
    console.log('Edit email clicked');
  };

  return (
    <Paper elevation={3} style={{ padding: '16px 16px 25px 16px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography sx={{ color: '#000000de', fontFamily: 'Nunito Sans,sans-serif', fontSize: '15px', fontWeight: '500' }}>
            Email
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Nunito Sans,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            Primary Email
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-12px' }}>
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            variant="outlined"
            label="Email"
            placeholder="Enter email"
            InputProps={{
              endAdornment: (
                <InputAdornment className="me-2" position="end">
                  <IconButton
                    edge="end"
                    aria-label="edit email"
                    onClick={handleEditEmailClick}
                    style={{fontSize: '17px'}}
                  >
                    <LaunchIcon className="me-1 fs-5"/>
                    Edit
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-15px' }}>
          <Typography sx={{ color: '#0000008a', fontFamily: 'Poppins ,sans-serif', fontSize: '14px', fontWeight: '400' }}>
            This email is used to log in to ICTM and for platform-related communications.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ContactEmail;
