import React, { useState, useEffect } from 'react';
import Createbtn from '../../../../createbtn/create'
import '../style.css';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { TextField, IconButton, Menu, MenuItem, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import Checkbox from '@mui/material/Checkbox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Switch from '@mui/material/Switch';
import { faBullhorn, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import MovingIcon from '@mui/icons-material/Moving';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
// card for hide and show
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CachedIcon from '@mui/icons-material/Cached';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const RecapTable = () => {

    // is edit card visible or not
    const [iscardvisible, setcardvisible] = useState(false)

    const handleCardShow = () => {
        setcardvisible(true);
    };
    const handleCardClose = () => {
        setcardvisible(false);
    };
    // createbtn modal

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    // calling Api Here
    const [rowes, setRows] = useState([]);
    const [isLoading, setisloading] = React.useState();
    const [length, setLength] = React.useState("");
    const columns = [
        { id: 'name', label: 'Title' },
        { id: 'date', label: 'Due by' },
        { id: 'owner', label: 'Owner' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}todos/get`);
                if (response.data && response.data.todos) {
                    const totalResponse = response.data.todos;
                    let teamTodo = totalResponse.filter(team => team.type === 'team-todo');
                    setLength(teamTodo.length);

                    const data = teamTodo.map((item, index) => ({
                        name: item.name,
                        date: item.date,
                        owner: <Avatar />,
                    }));
                    setRows(data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    });

    // pagination below

    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //checkbox
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event, id) => {
        setChecked(event.target.checked);
    };

    //menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //quil

    const [value, setValue] = React.useState('');

    var toolbarOptions = [
        ['bold', 'italic'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'direction': 'rtl' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']
    ];
    const module = {
        toolbar: toolbarOptions,
    };

    const [repeat, setRepeat] = React.useState('');

    const handleRepeatChange = (event) => {
        setRepeat(event.target.value);
    };

    // attachment
    // file upload
    const [file, setFile] = React.useState(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile); // Save the selected file

        if (selectedFile) {
            setInputValues({
                ...inputValues,
                attachment: selectedFile.name, // Update attachment in inputValues
            });
        }
    };

    const handleCancel = () => {
        setFile(null); // Remove the file
    };

    // apply the style in the uploaded file
    const HoverableDiv = styled('div')({
        display: 'flex',
        justifyContent: "space-between",
        marginTop: '5px',
        '&:hover': {
            backgroundColor: '#f5f5f5', // Change the background color on hover
        },
    });

    //comment button
    const [isButtonsVisible, setButtonsVisible] = React.useState(false);

    const handleTextFieldClick = () => {
        setButtonsVisible(true);
        setInputValues({
            ...inputValues,
            comment: '', // Set the initial value if needed
        });
    };

    const handleCancelButtonClick = () => {
        setButtonsVisible(false);
    };


    //datepicker
    const [date, setDate] = React.useState(dayjs('Y-M-D'));

    // handle switch button
    const [personalChecked, setPersonalChecked] = useState(false);

    const handleSwitchChange = (event) => {
        setPersonalChecked(event.target.checked);
        //console.log('Switch value:', personalChecked)
    };

    const [team, setTeam] = React.useState('');

    const handleTeamChange = (event) => {
        setTeam(event.target.value);
    };

    //inputvaluestoconsole

    const [inputValues, setInputValues] = React.useState({
        title: '',
        description: '',
        dueDate: '',
        repeat: '',
        personalChecked: '',
        team: '',
        attachment: '',
        comment: '',
    });

    const handleInputChange = (e, selectName) => {
        const { name, value } = e.target;

        if (selectName) {
            // Handle the Select separately
            setInputValues({
                ...inputValues,
                [selectName]: value,
            });
        } else {
            setInputValues({
                ...inputValues,
                [name]: value,
            });
        }
    };

    const handleSave = () => {
        console.log(inputValues);
    };

    const [isHovered, setIsHovered] = useState(false);


    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
            >
                {isLoading ? (
                    <p>
                        Loading...
                    </p>

                ) : length > 0 ? (
                    <>

                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "60px" }}>
                            <TableContainer component={Paper} style={{ paddingLeft: "10px", paddingRight: "20px", width: "100%" }}>
                                <div className="d-flex justify-content-between pt-3 px-3">
                                    <Typography variant='h5' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '500' }}> Recap To-Dos
                                    </Typography>
                                    <MovingIcon style={{ color: '#1993c6' }} />
                                </div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.slice(0, 1).map((column) => (
                                                <TableCell style={{ color: '#9C9C9C' }} align='left' key={column.id}>{column.label}</TableCell>
                                            ))}
                                            {/*attachments and comments*/}
                                            <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>Attachments</TableCell>
                                            <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>Comments</TableCell>
                                            {/*attachments and comments*/}
                                            {columns.slice(1, 3).map((column) => (
                                                <TableCell style={{ color: '#9C9C9C', width: "100px" }} align='right' key={column.id}>{column.label}</TableCell>
                                            ))}
                                            <TableCell style={{ color: '#9C9C9C', width: "40px" }} align='right'>More</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(rowes) && rowes.map((row) => (
                                            <TableRow key={row.id} >
                                                {columns.slice(0, 1).map((column) => (
                                                    <TableCell
                                                        style={{ display: 'flex' }}
                                                        align='left'
                                                        key={column.id}
                                                        onMouseEnter={() => setIsHovered(true)}
                                                        onMouseLeave={() => setIsHovered(false)}
                                                    >
                                                        <Checkbox
                                                            checked={checked[column.id]}
                                                            onChange={handleChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            className="p-0"
                                                            color="success"
                                                        />
                                                        <h6 className="mt-2 ms-2">{row[column.id]}</h6>
                                                        {isHovered && (
                                                            <EditIcon
                                                                style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                                                                onClick={handleCardShow}
                                                            />
                                                        )}
                                                        {/* <span className="edit-icon" onClick={handleCardShow}><EditIcon/></span> */}
                                                    </TableCell>
                                                ))}

                                                {/*attachments and comments*/}
                                                <TableCell style={{ color: '#9C9C9C' }} align='right'><FontAwesomeIcon icon={faPaperclip} style={{ fontSize: '20px' }} />&nbsp; &nbsp; 0</TableCell>
                                                <TableCell style={{ color: '#9C9C9C' }} align='right'><CommentIcon />&nbsp; &nbsp; 0</TableCell>
                                                {/*attachments and comments*/}
                                                {columns.slice(1, 2).map((column) => (
                                                    <TableCell align='right' key={column.id}>{row[column.id]}</TableCell>
                                                ))}
                                                {columns.slice(2, 3).map((column) => (
                                                    <TableCell align='right' key={column.id} style={{ display: "flex", justifyContent: "end" }}>{row[column.id]}</TableCell>
                                                ))}
                                                <TableCell align='right'>
                                                    <MoreHorizIcon id="basic-button"
                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                                <Pagination count={10} color="secondary" style={{ marginBottom: "12px", marginTop: "12px" }} />
                            </TableContainer>

                            {iscardvisible && (
                                <div>
                                    {/* below is the card that will show or hide */}
                                    <Card style={{ minWidth: 400, marginLeft: 10, height: 460, overflowY: "auto" }}>
                                        <CardHeader
                                            title={
                                                <Typography gutterBottom variant="h5" component="h2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <RadioButtonUncheckedIcon style={{ fontSize: '28px', marginRight: '10px', color: '#707070' }} onClick={handleSave} />
                                                    <h5 className="mt-1" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }} >Edit To-Do</h5>
                                                    <Typography style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                        <MoreHorizIcon onClick={handleClick} style={{ color: '#9A9A9A', marginLeft: '90px' }} />
                                                        <Avatar className="" sx={{ width: 30, height: 30, backgroundColor: '#4E545B' }}>S</Avatar>
                                                        <CloseIcon onClick={handleCardClose} style={{ cursor: "pointer" }} />
                                                    </Typography>

                                                </Typography>} />
                                        <Typography component="hr" />
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                Word of the Day
                                            </Typography>
                                            <InputLabel>Title</InputLabel>
                                            <TextField id="outlined-basic" size="small" variant="outlined" fullWidth name="title" value={inputValues.title} onChange={handleInputChange} />

                                            <InputLabel className="mt-4">Description (optional)</InputLabel>
                                            <ReactQuill modules={module} style={{ marginTop: "8px" }} theme="snow" value={value} onChange={setValue} />

                                            <InputLabel id="demo-select-small-label" className="mt-3">Due Date</InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                    <DatePicker
                                                        value={date}
                                                        onChange={(newValue) => {
                                                            setInputValues(prevState => ({
                                                                ...prevState,
                                                                date: newValue.format('YYYY-MM-DD'), // assuming newValue is a moment object
                                                            }));
                                                            setDate(newValue); // Optionally update the local state as well
                                                        }}
                                                        fullWidth
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>

                                            <InputLabel className="mt-3 my-2" id="demo-select-small-label">Repeat</InputLabel>
                                            <FormControl sx={{ minWidth: '100%' }} size="small">
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={repeat}
                                                    defaultValue={'Do not Repeat'}
                                                    label=""
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        setInputValues(prevState => ({
                                                            ...prevState,
                                                            repeat: selectedValue,
                                                        }));
                                                        setRepeat(selectedValue); // Optionally update the local state as well
                                                    }}
                                                >
                                                    <MenuItem value={'Do not Repeat'}>Do not Repeat</MenuItem>
                                                    <MenuItem value={'Daily'}>Daily</MenuItem>
                                                    <MenuItem value={'Weekly'}>Weekly</MenuItem>
                                                    <MenuItem value={'Monthly'}>Monthly</MenuItem>
                                                    <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                                                    <MenuItem value={'Annual'}>Annual</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <hr className="p-0 my-4" />

                                            <InputLabel className="mt-3 my-2" id="demo-select-small-label">Personal To-Do:</InputLabel>
                                            <div className="d-flex">
                                                <Switch
                                                    color="secondary"
                                                    defaultChecked={personalChecked}
                                                    onChange={(event) => {
                                                        const isChecked = event.target.checked;
                                                        setInputValues(prevState => ({
                                                            ...prevState,
                                                            personalChecked: isChecked,
                                                        }));
                                                    }}
                                                />
                                                <h6 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '14px' }} className="mt-2">This To-Do is visible to the entire team.</h6>
                                            </div>

                                            <FormControl className="mt-3" fullWidth>
                                                <InputLabel id="demo-simple-select-label">Team</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={team}
                                                    label="Team"
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        setInputValues(prevState => ({
                                                            ...prevState,
                                                            team: selectedValue,
                                                        }));
                                                        setTeam(selectedValue);
                                                    }}
                                                >
                                                    <MenuItem value={'Leadership Team'}>Leadership Team</MenuItem>
                                                    <MenuItem value={'Team 1'}>Team 1</MenuItem>
                                                    <MenuItem value={'Team 2'}>Team 2</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <small style={{ color: '#9C9C9C' }} >Changing the team will affect which users the To-Do can be assigned to.</small>

                                            <hr className="p-0 my-4" />

                                            <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Attachments</h5>

                                            <div className="py-2 mt-3 rounded rounded-3" style={{ backgroundColor: '#F5F5F5' }} >
                                                <p className="text-center pt-2" style={{ fontFamily: 'Poppins, sans-serif', color: '#707070', fontSize: '14px' }}>Drag and drop files to attach, or <label htmlFor="file-upload" className="fw-bold" style={{ color: '#2596BE' }} >browse</label></p>
                                            </div>

                                            <input
                                                type="file"
                                                id="file-upload"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />

                                            {file && (
                                                <HoverableDiv>
                                                    <Typography>{file.name}</Typography>
                                                    <CloseIcon onClick={handleCancel} style={{ cursor: 'pointer', color: "black", backgroundColor: "none" }} />
                                                </HoverableDiv>
                                            )}

                                            <hr className="p-0 my-4" />

                                            <h5 className="ms-3" style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}>Comments</h5>

                                            <div className="d-flex">
                                                <Avatar className="ms-3 mt-3" sx={{ width: 30, height: 30, backgroundColor: '#757575' }}>S</Avatar>
                                                <TextField className="mt-3 ms-3" placeholder="Add a comment.." id="outlined-basic" size="small" variant="outlined" fullWidth
                                                    onClick={handleTextFieldClick}
                                                    onChange={(e) => handleInputChange(e, 'comment')}
                                                    value={inputValues.comment}
                                                />
                                            </div>

                                            {isButtonsVisible && (
                                                <div className="d-flex ms-5 mt-3">
                                                    <button style={{ backgroundColor: '#2596BE' }} className="btn text-white mx-3">Comment</button>
                                                    <button onClick={handleCancelButtonClick} className="btn btn-outline-secondary">Cancel</button>
                                                </div>
                                            )}
                                            <div className="mt-4 mb-2">
                                                <small style={{ fontFamily: 'Poppins, sans-serif', color: '#9D9D9D' }}>Created by Sadia on Dec 30, 2023</small>
                                            </div>
                                        </CardContent>

                                    </Card>
                                </div>
                            )}
                        </Box>

                    </>

                ) : (
                    <div className="text-center">
                        <Typography variant="p" align="center" gutterBottom sx={{ color: '#0000008a', fontSize: '20px', fontWeight: '600', fontFamily: 'Poppins,sans-serif' }}>
                            No data Found
                        </Typography>

                    </div>
                )}
            </div>
            <Box className='mt-4'>
                <Card className='p-5'>
                    <div className='d-flex flex-row justify-content-between align-items-center'>
                        <Typography variant='h2'>
                            Ratings (1-10)
                        </Typography>
                        <CachedIcon style={{ fontSize: "40px" }} />
                    </div>
                    <Typography variant='p' style={{ fontSize: "20px", marginTop: "8px" }}>Score the session out of 10 points (10 = We had a great session, 1 = We wasted our time together).</Typography>
                    <TableContainer component={Paper} style={{ paddingLeft: "10px", paddingRight: "20px", width: "100%" }}>
                        <div className="d-flex justify-content-between pt-3 px-3">
                            <Typography variant='h5' style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '500' }}> 
                            </Typography>
                            <MovingIcon style={{ color: '#1993c6' }} />
                        </div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell style={{ color: '#9C9C9C' }} align='left' >Title</TableCell>
                                        <TableCell style={{ color: '#9C9C9C' }} align='right' >Ratting</TableCell>
                                        <TableCell style={{ color: '#9C9C9C', width: "100px" }} align='right' >Absent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow>
                                <TableCell align='left'>Muhammad Huzaifa</TableCell>
                                <TableCell align='right'>________________</TableCell>
                                <TableCell align='right'> <Checkbox /></TableCell>
                            </TableRow>
                            </TableBody>

                        </Table>
                        {/* <Pagination count={10} color="secondary" style={{ marginBottom: "12px", marginTop: "12px" }} /> */}
                    </TableContainer>
                </Card>
            </Box>
            <Box className='mb-4 mt-4'>
                <Card className='d-flex flex-row justify-content-between align-items-center pt-2 pb-3 mb-4'>
                    <Typography>
                    <Checkbox />Archive All Completed Headlines, To-Dos and Issues for the Leadership Team
                    </Typography>
                    <Typography>
                    <Checkbox />Send Meeting Recap Email
                    </Typography>
                    <Button style={{backgroundColor:"rgb(25, 147, 198)",color:"white"}}>Finish <ExitToAppIcon/></Button>
                </Card>
            </Box>

            {/*menu*/}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}><ArrowUpwardIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Top of List</MenuItem>
                <MenuItem onClick={handleClose}><ArrowDownwardIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Bottom of List</MenuItem> <hr className="m-0 p-0" />
                <MenuItem onClick={handleClose}><ArchiveOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Archive</MenuItem>
                <MenuItem onClick={openModal}><LandscapeIcon style={{ color: '#989898', fontSize: '22px', marginRight: '12px' }} /> Create Rock</MenuItem>
                <MenuItem onClick={openModal} > <CheckBoxOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '10px' }} /> Create To-Do</MenuItem>
                <MenuItem onClick={openModal} > <GppMaybeOutlinedIcon style={{ color: '#989898', fontSize: '22px', marginRight: '10px' }} /> Create Issue</MenuItem>
                <MenuItem onClick={openModal} > <FontAwesomeIcon icon={faBullhorn} style={{ color: '#989898', marginRight: '10px' }} /> Create Headline</MenuItem> <hr className="m-0 p-0" />
                <Createbtn isOpen={isModalOpen} onClose={closeModal} />
                <MenuItem onClick={handleClose} ><FontAwesomeIcon icon={faTrashCan} style={{ color: '#989898', marginRight: '19px' }} />Delete</MenuItem>
            </Menu>

        </>
    )
}

export default RecapTable
