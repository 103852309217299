import React,{useState} from 'react'
import { Button, Typography, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const FinalCoonfirmationModal = ({ onClose, onOpen }) => {
    return (
        <div>
            <Modal
                open={onOpen}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h6" component="h2" style={{ display: "flex", justifyContent: "space-between" }}>
                    Once confirmed, the Meeting will end for all participants. Are you sure you want to finish the Meeting?<CloseIcon onClick={onClose} />
                    </Typography>
                    <Typography sx={{ mt: 8, display: "flex", justifyContent: "end" }}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button variant="contained" style={{backgroundColor:"red"}} onClick={onClose}>Finish</Button>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default FinalCoonfirmationModal