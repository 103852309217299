import React from 'react'
import { Grid } from '@mui/material'
import CompanyInfoPage from './configuration'
import FeatureControls from './featurescontrol'


export default function ConfigurationMain() {
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CompanyInfoPage />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FeatureControls />
                </Grid>
            </Grid>
        </>
    )
}