import React from 'react'
import { Grid } from '@mui/material'
import EmailPhoneVerification from './accountcomp/emailphoneverificaltion'
import EnableMFA from './accountcomp/authentication'
import ChangePassword from './accountcomp/changepassword'

export default function Account() {
    return (
        <>
            <Grid sx={{ margin: '10px 5px 5px 5px' }}>
                <EmailPhoneVerification />
            </Grid>
            <Grid sx={{ margin: '10px 5px 5px 5px' }}>
                <EnableMFA />
            </Grid>
            <Grid sx={{ margin: '10px 5px 5px 5px' }}>
                <ChangePassword />
            </Grid>
        </>
    )
}
