import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Data } from './Data';
import { Divider } from '@mui/material';
import CompanyMenu from './CompanyMenu';
import ProvideFeedback from '../../pages/providefeedback';
import InviteTeamate from '../../pages/invitemodal'
import axios from 'axios'
import LearningSupport from '../../pages/learningsupport';
import './style.css'

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    padding: '20px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function NavBar({ data, onOptionClick }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState({});
  // below is feedback modal
  const [isFeedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
  const [isInviteTeamate, setInviteTeamate] = React.useState(false);
const [isLearningSupportmodal,setLearningSupportmodal]=React.useState(false);

  const handleFeedbackModalClose = () => {
    setFeedbackModalOpen(false);
    console.log("state is false");
  };
  // handle learning support
const handlelearningSupportModalClose= ()=>{
  setLearningSupportmodal(false);
}
  const handleListItemClick = (index) => {
    setSelectedItem(index);
    if (data[index]?.name === "Provide Feedback") {
      setFeedbackModalOpen(true);
    } 
    else if (data[index]?.name === "Invite Teammates") {
      setInviteTeamate(true);
    }
    else if(data[index]?.name === "Learning and Support") {
      setLearningSupportmodal(true);
      console.log("work perfectly")
    }
  };
// handle invite modal
const handleInviteModalClose= ()=>{
  setInviteTeamate(false)
}

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box id='hideOnPrint' sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* below is for opening feedback modal */}
      {isFeedbackModalOpen && (
        <ProvideFeedback onClose={handleFeedbackModalClose} open={isFeedbackModalOpen} />
      )}
       {isInviteTeamate && (
        <InviteTeamate onClose={handleInviteModalClose} open={isInviteTeamate} />
      )}
       {isLearningSupportmodal && (
        <LearningSupport onClose={handlelearningSupportModalClose} open={isLearningSupportmodal} />
      )}
      <Drawer variant="permanent" open={open}>
        {open ? (
          <>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '10px', marginBottom: '15px' }}>
              <img
                alt="profile-user"
                width="150px"
                // height="150px"
                src={`WhatsApp Image 2023-09-26 at 06.09.09.jpg`}
                style={{ cursor: "pointer", alignItems: "center", justifyContent: "center", textAlign: "center" }}
              />
            </Box>
            <CompanyMenu />
          </>
        ) : (
          <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '10px' }}>
            <img
              alt="profile-user"
              width="40px"
              height="40px"
              src={`logo-sm.png`}
              style={{ cursor: "pointer", alignItems: "center", justifyContent: "center", textAlign: "center" }}
            />
          </Box>
        )
        }
        <Divider sx={{ marginTop: '20px', color: "grey" }} />
        <List className="custom-scrollbar">
          {data.map((item, index) => (
            <ListItem key={index} disablePadding
              onClick={() => onOptionClick(item.name)}
              sx={{
                display: 'block',
                borderLeft: selectedItem === index ? "4px solid rgba(25, 147, 198)" : "",
              }}
            >
              <Link
                to={item.link}
                onClick={() => handleListItemClick(index)}
                style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }}>
                <ListItemButton
                  sx={{
                    marginLeft: '5px',
                    minHeight: 10,
                    justifyContent: open ? 'initial' : 'center',
                    backgroundColor: selectedItem === index ? 'rgba(168, 167, 167, 0.1)' : 'transparent',

                    '&:hover': {
                      backgroundColor: 'rgba(168, 167, 167, 0.1)',
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: selectedItem === index ? 'rgba(25, 147, 198)' : '#a3a3a3'
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: selectedItem === index ? 'rgba(25, 147, 198)' : 'inherit'
                    }}

                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <Divider />
          {open ?
            (<ListItemButton
              sx={{
                marginLeft: '15px',
                minHeight: 60,
              }}
              onClick={handleDrawerClose}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  // color: selectedItem === index ? 'rgba(25, 147, 198)' : 'inherit'
                }}
              >
                <MenuOpenIcon />
              </ListItemIcon>
              {/* <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'block' }),
                }}
              >
                <MenuOpenIcon />
              </IconButton> */}
              <ListItemText primary={'Collapse'} />
            </ListItemButton>) :
            (
              <ListItemButton onClick={handleDrawerOpen}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  sx={{
                    transform: 'rotate(180deg)',
                    marginRight: 5,
                    ...(open && { display: 'block' }),
                  }}
                >
                  <MenuOpenIcon />
                </IconButton>
                <ListItemText primary={'Collapse'} />
              </ListItemButton>
            )
          }
        </List>
      </Drawer>
    </Box>
  );
}
