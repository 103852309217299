import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function ImageUploader() {
    const [image, setImage] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImage(event.target.result);
            };
            reader.readAsDataURL(selectedImage);
        }
    };

    const deleteImage = () => {
        setImage(null);
        setAnchorEl(null);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <div style={{ position: 'relative', width: '100px', height: '100px', borderRadius: '50%', overflow: 'hidden' }}>
                        {image ? (
                            <>
                                <img src={image} alt="Selected" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </>
                        ) : (
                            <>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="image-upload"
                                    type="file"
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="image-upload">
                                    <IconButton
                                        color="primary"
                                        onClick={handleMenuClick}
                                        component="span"
                                        style={{ position: 'absolute', top: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%' }}
                                    >
                                        <PhotoCameraIcon />
                                    </IconButton>
                                </label>
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
            {image && (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="image-upload"
                            type="file"
                            onChange={handleImageChange}
                        />
                        <label htmlFor="image-upload">Change Image</label>
                    </MenuItem>
                    <MenuItem onClick={deleteImage}>
                        <DeleteIcon />
                        Delete Image
                    </MenuItem>
                </Menu>
            )}
        </div>
    );
}
