import React,{useState} from 'react'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SchoolIcon from '@mui/icons-material/School';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForumIcon from '@mui/icons-material/Forum';
import RateReviewIcon from '@mui/icons-material/RateReview';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import SettingsIcon from '@mui/icons-material/Settings';
import LoopIcon from '@mui/icons-material/Loop';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LandscapeIcon from '@mui/icons-material/Landscape';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


export const Data = [
    { name: "ICTM ", icon: <CrisisAlertIcon />, link: "/home"},
    { name: "Scorecard", icon: <EqualizerIcon />, link: "/scorecard"  },
    { name: "Rocks", icon: <LandscapeIcon />, link: "/rocks" },
    { name: "To-Dos", icon: <TaskAltIcon />, link: "/todo" },
    { name: "Issues", icon: <PriorityHighIcon />, link: "/issues" },
    { name: "Meetings", icon: <SpeakerNotesIcon />, link: "/meetings" },
    { name: "Headlines", icon: <FontAwesomeIcon icon={faBullhorn} className="fs-5"/>, link: "/headlines" },
    { name: "V/TO", icon: <RemoveRedEyeIcon />, link: "/vision" },
    { name: "Accountability Chart", icon: <AccountTreeIcon />, link: "/chart" },
    { name: "1-on-1", icon: <ForumIcon />, link: "/1-on-1" },
    { name: "Process", icon: <LoopIcon />, link:"/process"},
    { name: "Directory", icon: <FolderSharedIcon />, link: "/directory" },
    { name: "EOS Toolbox", icon: <SchoolIcon />, link: "/mastery" },
    { name: "Invite Teammates", icon: <FontAwesomeIcon icon={faUserPlus}/>},
    { name: "Provide Feedback", icon: <RateReviewIcon />},
    { name: "Learning and Support", icon: <HelpOutlineIcon /> },
    { name: "Settings", icon: <SettingsIcon />, link: "/settings" },
];