import React,{useState} from 'react'
import { Grid } from '@mui/material'
import AccountabilitychartAppBar from '../components/accountabilitychart/accountabilitycharttopbar';
import AccountChart from '../components/accountabilitychart/accountabilitychart'
export default function Accountabilitychart() {

  const [zoomLevel, setZoomLevel] = useState(1); // Initial zoom level set to 100% (1x)

  const handleZoomin = () => {
    setZoomLevel(zoomLevel + 0.1); // Increase zoom level
  };

  const handleZoomout = () => {
    setZoomLevel(zoomLevel - 0.1); // Decrease zoom level
  };

  const handleResetZom = () => {
    setZoomLevel(1); // Reset zoom to 100%
  };

  const containerStyle = {
    transform: `scale(${zoomLevel})`, // Apply zoom level to the container
    transformOrigin: 'top left', // Set the origin point for zooming
  };

  const accountabilitychartPrint=()=>{
    window.print();
  }
  return (
    <>
    <Grid>
      <AccountabilitychartAppBar handlePrint={accountabilitychartPrint}
      zoomIn={handleZoomin} zoomOut={handleZoomout} resetZoom={handleResetZom}      
      />
    </Grid>
    <div className="zoom-container" style={containerStyle}>
        <AccountChart/>
        <div className="zoom-content">
    </div>
      </div>
    </>
  )
}