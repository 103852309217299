import React from 'react'
import { Grid } from '@mui/material'
import WeeklyTable from './dailemailsschedule';
import PreferredTimeZone from './timezone';

export default function Notification() {
    return (
        <>
        <Grid container>
            <Grid item xs={12} md={12} lg={12} sx={{ margin: '10px 5px 5px 5px' }}>
                <WeeklyTable />
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ margin: '10px 5px 5px 5px' }}>
                <PreferredTimeZone />
            </Grid> */}

            </Grid>
        </>
    )
}
