import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SecondaryBar from '../../components/secondaryAppBar/secondaryBar';
import { styled } from '@mui/material/styles';
import Todo from '../../components/dashboardCards/todo';
import PersonalTodo from '../../components/dashboardCards/personalTodo';
import RocksMilestone from '../../components/dashboardCards/rocksMilestones';
import TeamTodoTable from '../../components/teamTodoTable/teamTodoTable';
import ScoreCardTable from '../../components/scoreCardTable/table';
import IssuesTable from '../../components/issuesTable/issuesTable';
import IssuesBar from '../../components/secondaryAppBar/issuesBar';
import IssuesTabs from '../../components/tabs/issuesTabs';
import FunctionContext from '../../components/secondaryAppBar/functionContex';
import ArchiveShortIssuesTable from '../../components/issuesTable/archiveshortissues';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const Issues = ({onChange}) => {
  const issuePrint=()=>{
    window.print();
  }

//archive
const [showComponent, setShowComponent] = React.useState(false);
  const archiveHandle=()=>{
    setShowComponent(!showComponent)
  }

  return (
    <>
    
      <Grid container xs={12}>
        <Grid item xs={12}>
          <IssuesBar handleArchive={archiveHandle} handlePrint={issuePrint}  />
        </Grid>

        <Grid container xs={12}>
          {/* {showComponent || <ArchiveShortIssuesTable/> } */}
          {showComponent ? <ArchiveShortIssuesTable /> : null}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <IssuesTabs /> */}
            {showComponent ? null :<IssuesTabs /> }
          </Grid>
        </Grid>
      </Grid>


    </>
  )
}

export default Issues