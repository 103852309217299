import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProfileTabs from './settingcomp/profile';
import TeamsTable from './settingcomp/profilecomp/team';
import Account from './settingcomp/account';
import Perferences from './settingcomp/preferences';
import Notification from './settingcomp/notifications/notification';
import CompanyInfoPage from './settingcomp/configurationcomp/configuration';
import ConfigurationMain from './settingcomp/configurationcomp/configurationmain'
import SettingMeetingAppBar from './settingcomp/meetingcomp/settingmeeting';
// Main Agenda forms are import Here
import Mainagendaform from './settingcomp/meetingcomp/agendaforms/mainagendaform';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box display={'flex'} sx={{bgcolor:'#f5f5f5', position:"relative",overflow:"hidden",overflowX:"hidden"}}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider', width:'150px'}}
      > 
        <Tab label="Profile" {...a11yProps(0)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', },  }}/>
        <Tab label="Account" {...a11yProps(1)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', }, }}/>
        <Tab label="Preferences" {...a11yProps(2)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', }, }} />
        <Tab label="Notification" {...a11yProps(3)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', }, }}/>
        <Tab label="Teams" {...a11yProps(4)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', }, }}/>
        <Tab label="Configuration" {...a11yProps(5)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', }, }}/>
        <Tab label="Meeting" {...a11yProps(6)} sx={{ color: '#000000de', fontFamily:'Nunito Sans,sans-serif', fontSize:'14px', fontWeight:'400', textTransform:'capitalize', textAlign:'left', '&.Mui-selected': { backgroundColor: '#ddd', color: '#000000de', }, }}/>
      </Tabs>
      <div style={{ flex: 1 }}>
      <TabPanel value={value} index={0} >
        <ProfileTabs/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Account/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Perferences/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Notification/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TeamsTable/>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <ConfigurationMain/>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <SettingMeetingAppBar  />
      </TabPanel>
      </div>
    </Box>
  );
}
