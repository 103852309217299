import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const CustomListItem = styled(ListItem)(({ theme, isSelected }) => ({
  cursor: 'pointer',
  borderLeft: isSelected ? '4px solid rgb(25, 147, 198)' : 'none',
  width: '100%', // Ensure each item takes 100% width
  padding: '0px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    width: '100%',
  },
}));

export default function SelectableList({ items,onItemClick  }) {
  const [selectedItem, setSelectedItem] = useState(items.find(item => item.name === 'Segue'));


  const handleItemClick = (item) => {
    setSelectedItem(item); // Update selectedItem to the clicked item
    onItemClick (item)
  };

  return (
    <div style={{width:"100%", height: '100%', overflowY: 'auto' }}>
        {items.map((item) => (
          <CustomListItem
            key={item.id}
            component={Link}
            to={`/videomeeting/${item.link}`}
            onClick={() => handleItemClick(item)}
            isSelected={selectedItem && selectedItem.id === item.id}
          >
            <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
            <ListItemText primary={item.name} style={{width:"100%",marginLeft:"10px",color:'black'}} />
            <div style={{width:"20%",color:'black'}}>{item.duration}</div>
            </div>
          </CustomListItem>
        ))}
    </div>
  );
}
